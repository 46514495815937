<template>
    <div>
        <div class="tbl-header">
            <p class="title-name">{{computeTblHeader}}</p>
            <button class="add-row-btn" @click="addRows()"><i class="fa fa-plus" aria-hidden="true"></i>
            </button>
        </div>
        <div class="input-tbl">
            <i-table
            :columns="tblColumns"
            :data="tblData"
            class="input-table"
            disabled-hover
            border
            >
              <template  slot-scope="{ index }" slot="language">
                <i-select  v-model="tblData[index].language" filterable>
                  <i-option
                   v-for="option in languageOptions"
                   :key="option.id"
                   :value="option.id"
                  >{{ option.name }}</i-option>
                </i-select>
              </template>
              <template slot-scope="{ index }" slot="adType">
                <i-select v-model="tblData[index].adType">
                  <i-option
                   v-for="option in adTypeOptions"
                   :key="option.id"
                   :value="option.id"
                  >{{ option.name }}</i-option>
                </i-select>
              </template>
              <template slot-scope="{ index }" slot="specifications">
                <button class="spec-button truncate-text-container" >{{tblData[index].specifications }}</button>
              </template>
              <template slot-scope="{ index }" slot="dueDate">
                <i-date-picker v-model="tblData[index].dueDate" format="yyyy-MM-dd"></i-date-picker>
              </template>
              <template slot-scope="{ index }" slot="dueTime">
                <i-time-picker v-model="tblData[index].dueTime" format="HH:mm"></i-time-picker>
              </template>
              <template slot-scope="{ index }" slot="linkLineItems">
                <tree-select
                  :multiple="true"
                  :options="publisherLineItemOptions"
                  v-model="tblData[index].linkLineItems"
                  :default-expand-level="1"
                  :searchable="true"
                  value-consists-of= 'LEAF_PRIORITY'
                  :limit="1"
                  @select="calculateSummary"
                  @deselect="removeFromSummary"
                ></tree-select>
              </template>
              <template slot-scope="{ row }" slot="actions">
                <button class="delete-row-btn" @click="deleteRow(row)"><i class="fa fa-trash" aria-hidden="true"></i></button>
              </template>
            </i-table>
        </div>
        <div class="">
          <i-modal
            v-model="enableConfirmPopup"
            :footer-hide="false"
            :closable="false"
            :mask-closable="false"
            class-name="vertical-center-modal"
            >
              <h4 slot="header">
                Delete Line Item
              </h4>
              <div>
                <p class="confirm-box-text-style">Are you sure you want to delete?</p>
              </div>
              <div slot="footer">
                  <button
                    type="button"
                    class="btn create-btn btn-white button-style"
                    v-on:click="confirmBoxCancel"
                    >Cancel</button>
                  <button
                    type="button"
                    class="btn create-btn btn-success button-style"
                    v-on:click="confirmAction"
                    >OK</button>
              </div>
          </i-modal>
        </div>
    </div>
</template>

<script>
import { Table, Select, DatePicker, TimePicker, Option, locale, Modal } from 'iview'
import TreeSelect from '@riophae/vue-treeselect'
import lang from 'iview/dist/locale/en-US'

locale(lang)
export default {
  components: {
    "i-table": Table,
    "i-select": Select,
    "i-date-picker": DatePicker,
    "i-time-picker": TimePicker,
    "tree-select": TreeSelect,
    "i-option": Option,
    "i-modal": Modal
  },
  computed: {
    computeTblHeader () {
      switch (this.lineItemMode) {
        case 1: return 'Digital'

        case 2: return 'TV'

        case 3: return 'Radio'

        case 4: return 'Press'

        case 5: return 'OOH'

        case 6: return 'Cinema'

        case 7: return 'Magazine'

        default: return ''
      }
    }
  },
  props: {
    lineItemMode: Number
  },
  data () {
    return {
      tblColumns: [
        {
          title: 'Language',
          key: 'language',
          slot: 'language',
          align: 'center',
          width: 100
        },
        {
          title: 'Ad Type',
          key: 'adType',
          slot: 'adType',
          align: 'center',
          minWidth: 130
        },
        {
          title: 'Specifications',
          key: 'specifications',
          slot: 'specifications',
          align: 'center',
          width: 500
        },
        {
          title: 'Due Date',
          key: 'dueDate',
          slot: 'dueDate',
          align: 'center',
          minWidth: 100
        },
        {
          title: 'Due Time',
          key: 'dueTime',
          slot: 'dueTime',
          align: 'center',
          minWidth: 100
        },
        {
          title: 'LinkLineItems',
          key: 'linkLineItems',
          slot: 'linkLineItems',
          align: 'center',
          minWidth: 350
        },
        {
          title: 'Actions',
          key: 'actions',
          slot: 'actions',
          align: 'center',
          minWidth: 70
        }
      ],
      tblData: [],
      publisherLineItemOptions: [],
      lasttblIndex: 0,
      adTypeslist: [],
      languageOptions: [],
      adTypeOptions: [],
      enableConfirmPopup: false,
      rowToDelete: null
    }
  },
  methods: {
    calculateSummary (node, instanceId) {
      this.$emit('linkLineItems', { mode: this.lineItemMode, lineItemId: node.id });
    },
    confirmBoxCancel () {
      this.enableConfirmPopup = false
    },
    confirmAction () {
      const rowIndex = this.tblData.findIndex(item => item.index == this.rowToDelete.index);
      this.tblData.splice(rowIndex, 1);
      this.lasttblIndex = this.lasttblIndex - 1;
      this.enableConfirmPopup = false;
    },
    removeFromSummary (node, instanceId) {
      this.$emit('unLinkLineItems', { mode: this.lineItemMode, lineItemId: node.id });
    },
    addRows () {
      this.tblData.push(
        {
          index: this.lasttblIndex + 1,
          id: null,
          language: null,
          adType: null,
          specifications: null,
          dueDate: null,
          dueTime: null,
          linkLineItems: null,
          status: 1
        }
      )
      this.lasttblIndex += 1
    },
    deleteRow (row) {
      this.rowToDelete = row
      this.enableConfirmPopup = true;
    }
  }

}
</script>

<style scoped>
.input-tbl{
    padding-bottom: 20px;
    width: 100%;
}
.title-name{
    display: inline-block;
}
.add-row-btn{
    float: inline-end;
    border: none;
    background-color: white;
}
::v-deep .input-tbl td .ivu-table-cell{
  height: 100% !important;
}
::v-deep .input-tbl td .ivu-table-cell{
  height: 100% !important;
}
::v-deep .vue-treeselect__menu {
  z-index: 99999 !important;
}
::v-deep .input-table .ivu-table-body table tbody tr td:nth-child(3) .ivu-table-cell div{
  height: 100% !important;
}
.confirm-box-text-style{
  font-size: 13px;
  color: black;
}
.delete-row-btn{
  border: none ;
  font-size: large;
  background-color: white;
}
.spec-button{
  height: 100%;
  width: 100%;
  border: none;
  background-color: white;

}
::v-deep .ivu-table-header table{
  width: 100% !important;
}
::v-deep .ivu-table-body table{
  width: 100% !important;
}
::v-deep li.ivu-select-item{
  float: left !important;
}
.vertical-center-modal{
          display: flex;
          align-items: center;
          justify-content: center;
  }
.truncate-text-container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .input-table .ivu-table-cell{
  padding: 0px !important;
}
::v-deep .input-table .ivu-table td, .ivu-table th{
  height: 35px !important;
}
::v-deep .input-table .ivu-select-selection{
  border: none !important;
}
::v-deep .input-table .ivu-input-with-suffix{
  border: none !important;
}
 ::v-deep .vue-treeselect__menu-container{
  margin-left: 73%;
  width:20%;
 }
 ::v-deep .vue-treeselect{
  position: static !important;
 }
 ::v-deep .vue-treeselect--open-below:not(.vue-treeselect--append-to-body) .vue-treeselect__menu-container{
  top: unset !important;
 }
::v-deep .vue-treeselect__control{
  height: 32px !important;
}
::v-deep .vue-treeselect__menu-container .vue-treeselect__checkbox--checked {
  border-color: var(--main-bg-color) !important;
  background: var(--main-bg-color) !important;
}
::v-deep .vue-treeselect svg {
    fill: black;
}
::v-deep .vue-treeselect svg {
    fill: black !important;
}
::v-deep .vue-treeselect__indent-level-1 .vue-treeselect__option {
    margin-left: 50px !important;
}
::v-deep .vue-treeselect__menu-container .vue-treeselect__checkbox--checked {
  border-color: var(--main-bg-color) !important;
  background: var(--main-bg-color) !important;
}
::v-deep .vue-treeselect__checkbox--indeterminate {
  border-color: var(--main-bg-color) !important;
  background: var(--main-bg-color) !important;
}

::v-deep .vue-treeselect__multi-value-item {
  background: #f8f8f8 !important;
  color: #737a8a !important;
  border: 1px solid #d4d4d4 !important;
}
::v-deep .vue-treeselect--has-value .vue-treeselect__multi-value {
  margin-bottom: 0px !important;
}
::v-deep .vue-treeselect__multi-value-item {
  margin-left: 5px !important;
  margin-right: 5px !important
}
::v-deep .input-table .vue-treeselect__control{
  border: none !important;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
<style scoped src = "../../../../../../node_modules/bootstrap-icons/font/bootstrap-icons.css"></style>
<style src = "../../../../../../node_modules/@riophae/vue-treeselect/dist/vue-treeselect.css"></style>
