<template>
  <div class="row">
    <div class="col-md-12">
      <alert
        class="error-message"
        v-model="actionSummary.show"
        :type="actionSummary.type"
        dismissable
      >
        <span v-html="actionSummary.message"></span>
      </alert>
      <div v-if="!isCampaignLuncher" class="panel panel-default">
        <div class="row">
          <div class="col-md-9">
            <div class="input-group">
              <div class="input-group-btn">
                <v-select
                  v-model="engine.selected"
                  :options="engine.options"
                  v-show="true"
                  options-value="id"
                ></v-select>
                <button
                  v-show="adCreationSheet.readyToView"
                  type="button"
                  class="btn btn-white"
                  v-on:click="openSheet()"
                >Open</button>
                <button
                  v-show="adCreationSheet.readyToView && showNewSheet"
                  type="button"
                  class="btn btn-white"
                  v-on:click="refreshSheet()"
                >
                  Refresh
                  <i v-show="adCreationSheet.refreshing" class="fa fa-spinner fa-spin"></i>
                </button>
                <v-select
                  class="submission-dropdown"
                  v-model="currentSubmissionId"
                  :options="submissions"
                  v-show="!showNewSheet"
                  options-value="Id"
                  options-label="Name"
                ></v-select>
              </div>
              <input
                type="text"
                id="sheetUrl"
                v-show="adCreationSheet.readyToView"
                v-model="adCreationSheet.data.ViewableLink"
                class="form-control"
                aria-label="..."
                readonly
              >
              <div class="input-group-btn" v-show="adCreationSheet.readyToView">
                <v-tooltip ref="clipboardTooltip" trigger="click" content="copied">
                  <button
                    type="button"
                    class="btn btn-white"
                    @click.prevent.stop="copySheetUrl"
                    @mouseleave.stop="closeTooltip"
                  >
                    <i class="fa fa-copy"></i> Copy
                  </button>
                </v-tooltip>
                <button type="button" class="btn btn-success" v-on:click="fetchDataFromSheet()">
                  Fetch
                  <i v-show="adCreationSheet.processing" class="fa fa-spinner fa-spin"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-group-btn">
              <button
                v-show="adCreationSheet.readyToView && showNewSheet && submissions.length > 0"
                type="button"
                class="btn btn-white show-submissions"
                v-on:click="displaySubmissions"
              >Submissions</button>
              <button
                v-show="adCreationSheet.readyToView && !showNewSheet && submissions.length > 0"
                type="button"
                class="btn btn-success new-sheet"
                v-on:click="displayNewSheet"
              >New</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="isCampaignLuncher && displayExistingSubmissions" class="panel panel-default">
        <div class="row">
          <div class="col-md-3">
            <Select
                placeholder="Select a submission"
                v-model="currentSubmissionId"
                :transfer="true"
                filterable
              >
              <Option v-for="(item, index) in submissions" :key="index" :value="item.Id">{{ item.Name }}</Option>
            </Select>
          </div>
        </div>
      </div> -->
      <i class="fa fa-2x fa-spin fa-spinner" v-show="submissionLoading" style="left: 50%;position: relative"></i>
      <div class="row" v-show="!submissionLoading">
        <div class="col-md-12">
          <slot name="authFlow"></slot>
        </div>
      </div>
      <div class="panel panel-default pt-0" style="min-height:400px"  v-show="!submissionLoading">
        <div class="row">
          <div class="col-md-9">
            <div id="tab-controls" class="tab-controls col-md-12 col-sm-12" >
              <div class="row tab-bar">
                <div class="col-md-9 tab-list">
                  <ul class="nav nav-tabs" ref="navList">
                    <li v-if="isPromotionMode || selectedPublisher !== publisher.Facebook || !showTab['summary']" class="active">
                      <a class="tab-link" data-toggle="tab" href="#elements">Elements</a>
                    </li>
                    <li v-if="!isPromotionMode && selectedPublisher === publisher.Facebook && showTab['summary']" :class="{ active: activeTab === 'summary' }">
                      <a class="tab-link" data-toggle="tab" @click="scrollToSection('summary')">Summary</a>
                    </li>
                    <li v-if="!isPromotionMode && selectedPublisher === publisher.Facebook && showTab['campaignSetup']" :class="{ active: activeTab === 'campaignSetup' }">
                      <a class="tab-link" data-toggle="tab" @click="scrollToSection('campaignSetup')">Campaign Setup</a>
                    </li>
                    <li v-if="!isPromotionMode && selectedPublisher === publisher.Facebook && showTab['reachFrequencyEstimate']" :class="{ active: activeTab === 'reachFrequencyEstimate' }">
                      <a class="tab-link" data-toggle="tab" @click="scrollToSection('reachFrequencyEstimate')">Reach & Frequency Estimate</a>
                    </li>
                    <li v-if="!isPromotionMode && selectedPublisher === publisher.Facebook && showTab['targeting']" :class="{ active: activeTab === 'targeting' }">
                      <a class="tab-link" data-toggle="tab" @click="scrollToSection('targeting')">Targeting</a>
                    </li>
                    <li v-if="!isPromotionMode && selectedPublisher === publisher.Facebook && showTab['locations']" :class="{ active: activeTab === 'locations' }">
                      <a class="tab-link" data-toggle="tab" @click="scrollToSection('locations')">Locations</a>
                    </li>
                    <li v-if="!isPromotionMode && selectedPublisher === publisher.Facebook && showTab['excludedLocations']" :class="{ active: activeTab === 'excludedLocations' }">
                      <a class="tab-link" data-toggle="tab" @click="scrollToSection('excludedLocations')">Excluded Locations</a>
                    </li>
                    <li v-if="!isPromotionMode && selectedPublisher === publisher.Facebook && showTab['pageSets']" :class="{ active: activeTab === 'pageSets' }">
                      <a class="tab-link" data-toggle="tab" @click="scrollToSection('pageSets')">Page Sets</a>
                    </li>
                    <li v-if="!isPromotionMode && selectedPublisher === publisher.Facebook && showTab['pagesCreative']" :class="{ active: activeTab === 'pagesCreative' }">
                      <a class="tab-link" data-toggle="tab" @click="scrollToSection('pagesCreative')">Pages & Creative</a>
                    </li>
                    <li v-if="!isPromotionMode && selectedPublisher === publisher.Facebook && showTab['placements']" :class="{ active: activeTab === 'placements' }">
                      <a class="tab-link" data-toggle="tab" @click="scrollToSection('placements')">Placements</a>
                    </li>
                    <li v-if="showAdPreview">
                      <a class="tab-link" data-toggle="tab" href="#adPreview">Ad Preview</a>
                    </li>
                    <li v-if="selectedPublisher === publisher.AMS.SponsoredProducts">
                      <a class="tab-link" data-toggle="tab" href="#spTargets">Targets</a>
                    </li>
                  </ul>
                </div>
                <div v-if="!isPromotionMode" class="col-md-3 submission-select">
                  <Select
                      transfer-class-name="cl-select-dropdown"
                      v-model="currentSelectedView"
                      :transfer="true"
                      filterable
                      @on-change="checkSubmissionOption"
                    >
                    <Option v-if="summaryLocation" value="Changes">Changes</Option>
                    <Option v-if="summaryLocation" value="Full setup">Full setup</Option>
                    <template v-if="isCampaignLuncher && authFlowExists">
                      <Option v-for="(item, index) in submissions" :key="index" :value="item.Id" :label="item.Name">
                        <span class="textContent">{{ item.Name }} </span>
                        <span class="badge">{{ getPublisherName(item.SearchEngineTypeId) }}</span>
                      </Option>
                    </template>
                  </Select>
                </div>
              </div>
              <div class="tab-content">
                <div id="elements" class="tab-pane fade in active" >
                  <elements-view ref="elementsFacebook"
                    v-if="selectedPublisher === publisher.Facebook"
                    :isSubmission="showNewSheet"
                    :publishSummary = "publishSummary"
                    :adgroupsData="adgroupsData"
                    :adsData="adsData"
                    :isCampaignLuncher="isCampaignLuncher"
                    :creativeMedia="creativeMedia"
                    :viewType="viewType"
                    :submissionId="currentSubmissionId"
                    :authFlowExists="authFlowExists"
                    @elementsVerified="setElementsVerified"
                    @updatedPublishSummary="updatePublishSummary"
                    @updateRnfPredictionsValid="updateRnfPredictionsValid"
                    @updateRnfDataProcessing="updateRnfDataProcessing"
                    @showElementsTab="showElementsTab"
                  />
                  <ams-elementsView
                    v-else-if="selectedPublisher === publisher.AMS.SponsoredBrands && amsEntitiesResponse.data"
                    :isSubmission="showNewSheet"
                    @elementsVerified="setElementsVerified"
                    @allBudgetsVerified="allSBBudgetsVerified"
                  />
                  <AmsSpElementView
                    v-else-if="selectedPublisher === publisher.AMS.SponsoredProducts && amsEntitiesResponse.data"
                    :isSubmission="showNewSheet"
                    @elementsVerified="setElementsVerified"
                  />
                   <AmsSdElementView v-else-if="selectedPublisher == publisher.AMS.SponsoredDisplay && amsEntitiesResponse.data"
                      :isSubmission="showNewSheet"
                    @elementsVerified="setElementsVerified"
                  />
                  <dbm-elementsView
                    v-else-if="selectedPublisher === publisher.DV360 && dv360EntitiesResponse.data"
                    :isSubmission="showNewSheet"
                    @elementsVerified="setElementsVerified"
                  />
                  <linkedin-elements-view
                    v-else-if="selectedPublisher === publisher.LinkedIn && linkedInEntitiesResponse.data"
                    :isSubmission="showNewSheet"
                    :submissionId="currentSubmissionId"
                    @elementsVerified="setElementsVerified"
                  />
                  <div v-else-if="selectedPublisher === publisher.Twitter && twitterEntitiesResponse.data">
                    <twitter-elements-view
                      :isSubmission="showNewSheet"
                      @elementsVerified="setElementsVerified"
                    />
                  </div>
                  <adwords-elementsView-new
                    v-else-if="deltax.isAdwordsCampaignLaucherFlow && adwordsEntitiesResponse.campaigns"
                    :isSubmission="showNewSheet"
                    :viewType="viewType"
                    @elementsVerified="setElementsVerified"
                  >
                  </adwords-elementsView-new>
                  <adwords-elementsView
                    v-else-if="!deltax.isAdwordsCampaignLaucherFlow && adwordsEntitiesResponse.campaigns"
                    :isSubmission="showNewSheet"
                    @elementsVerified="setElementsVerified"
                  >
                  </adwords-elementsView>
                  <div v-else-if="selectedPublisher === publisher.Moloco && this.molocoCampaignLauncherPublishDataId">
                    <moloco-elements-view
                      :isSubmission="showNewSheet"
                      @elementsVerified="setElementsVerified"
                      :submissionId="currentSubmissionId"
                      :viewType="viewType"
                    />
                  </div>
                </div>
                <div v-if="showAdPreview" id="adPreview" class="tab-pane fade">
                  <AdWordsAdPreviewView v-if="deltax.isAdwordsCampaignLaucherFlow && selectedPublisher == publisher.Adwords && adwordsEntitiesResponse.campaigns" :viewType="viewType"/>
                </div>
                <div v-if="selectedPublisher === publisher.AMS.SponsoredProducts && amsEntitiesResponse.data" id="spTargets" class="tab-pane fade">
                  <AmsSpTargetPreview></AmsSpTargetPreview>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 ad-summary-container"
            :class="{
              'ad-summary-container-cl' : !isPromotionMode,
              'ad-summary-container-promotion' : isPromotionMode
              }"
            >
            <div>
              <Row type="flex">
                <Row-Col>
                  <h3 style="margin:5px 0 0 0">
                    Summary
                    <i class="fa fa-spinner fa-spin" v-show="publishInProgress"></i>
                  </h3>
                </Row-Col>
              </Row>
              <template v-if="uploadProgress.toShow">
                <Progress
                  v-if="publishProgressPercentage < 100 && publishInProgress"
                  :percent="publishProgressPercentage"
                  class="upload-progress"
                  :stroke-width="5"
                />

                <Progress
                  v-else
                  :percent="publishTotalPercentage"
                  :success-percent="publishSuccessPercentage"
                  stroke-color="red"
                  class="upload-progress"
                  :stroke-width="5"
                >
                  <div class="retry-busy-spinner" v-if="retryFlagSpinner">
                    <i class="fa fa-spinner fa-spin"></i>
                  </div>
                  <div v-else style="color: #999999">
                    {{ uploadProgress.successCount }} / {{ uploadProgress.totalCount }}
                  </div>
                </Progress>
                <Row v-show="(deltax.isDeltaxApiFlowEnabled && ((authFlowExists && submissionApproved) || !authFlowExists) && ((!campaignPublishDataLogId) || (campaignPublishDataLogId && isPublishButtonRequired)))" type="flex">
                  <Row-Col>
                    <Checkbox class="summary-checkboxes" :disabled="publishInProgress || retryFlagSpinner" v-model="isRetryEnabled">
                      <span> Retry Failed Ads</span>
                    </Checkbox>
                  </Row-Col>
                  <Row-Col v-show="!isPromotionMode && selectedPublisher === publisher.Facebook && showPublishCampaignsOnlyCheckbox">
                    <Checkbox class="summary-checkboxes" :disabled="publishInProgress || retryFlagSpinner" v-model="isCampaignPublishOnly">
                      <span> Publish Campaigns only</span>
                    </Checkbox>
                  </Row-Col>
                </Row>
              </template>
               <div v-if="(selectedPublisher === publisher.AMS.SponsoredProducts || selectedPublisher === publisher.AMS.SponsoredBrands  || selectedPublisher === publisher.AMS.SponsoredDisplay) && amsEntitiesResponse.data">
                <ams-summary></ams-summary>
              </div>
              <div v-else-if="selectedPublisher === publisher.DV360 && dv360EntitiesResponse.data">
                <dbm-summary />
              </div>
              <div v-else-if="selectedPublisher === publisher.Adwords">
                <adwords-summary />
              </div>
              <div v-else-if="selectedPublisher === publisher.LinkedIn && linkedInEntitiesResponse.data">
                <linkedin-summary />
              </div>
              <div v-else-if="selectedPublisher === publisher.Twitter && twitterEntitiesResponse.data">
                <twitter-summary />
              </div>
              <div v-else-if="selectedPublisher === publisher.Moloco && this.molocoCampaignLauncherPublishDataId">
                <moloco-summary
                :submissionId="currentSubmissionId"/>
              </div>
              <div v-else >
                <Row v-if="fbEntityStatusCount != null" style="margin: 3px 0 7px 0;">
                  <Row-Col span="6">
                    Success: {{ fbEntityStatusCount.TotalSuccess }}
                  </Row-Col>
                  <Row-Col span="6" offset="3">
                    Failed: {{ fbEntityStatusCount.TotalFailed }}
                  </Row-Col>
                  <Row-Col span="6" offset="3">
                    New: {{ fbEntityStatusCount.TotalNew }}
                  </Row-Col>
                </Row>
                <Row style="margin: 6px 0;">
                  <Row-Col span="10">
                    <i-input
                      size="small"
                      v-model="fbSummarySearchQuery"
                      placeholder="Search"
                    />
                  </Row-Col>
                  <Row-Col span="14">
                    <button-group size="small" style="float: right;">
                      <i-button
                        v-for="(className, status) in fbEntitiesToShowOption"
                        :key="status"
                        :class="{ 'status-active-btn': fbEntityStatusToShow.includes(status) }"
                        @click="toggleFbEntitiesToShow(status)"
                        >
                          <i v-if="status != 'All'" :class="className"></i>
                          <Icon v-else :type="className"></Icon>
                        </i-button
                      >
                    </button-group>
                  </Row-Col>
                </Row>

              <ul class="summmary-list dx-scroll">
                <li v-for="(campaign,campaignIndex) in fbFilteredPublishSummary" :key="campaignIndex">
                  <div
                    data-toggle="collapse"
                    class="summary-collapse"
                    v-bind:href="'#campaign-summary-' + campaignIndex"
                  >
                    <span>
                      <i
                        v-if="campaign.RowData.isEntityEdited == 'Edited' || campaign.RowData.isEntityEdited == 'FailedEdit'"
                        v-bind:class="getEntityStatusIcon(campaign.RowData.isEntityEdited)"
                      ></i>
                      <i
                        v-else
                        v-bind:class="getEntityStatusIcon(campaign.Status)"
                      ></i>
                      <span>
                        {{ campaign.Name }}
                      </span>
                      <span v-if="campaign.Id != '' && selectedPublisher === publisher.Facebook" class="entity-label">
                        ({{ campaign.Id }})
                      </span>
                    </span>
                    <span
                      class="entity-export-error"
                      v-if="campaign.FailureReason != ''"
                    >{{campaign.FailureReason}}</span>
                  </div>
                  <ul
                    v-bind:id="'campaign-summary-' + campaignIndex"
                    class="collapse-open collapse in campaign-summary"
                  >
                    <li
                      class="panel-collapse"
                      v-for="(adgroup,adgroupIndex) in campaign.ChildEntities"
                      :key="adgroupIndex"
                    >
                      <div
                        data-toggle="collapse"
                        class="summary-collapse collapsed"
                        :href="`#adgroup-summary-${campaignIndex}-${adgroupIndex}`"
                      >
                        <span>
                          <i
                            v-if="adgroup.RowData.isEntityEdited=='Edited' || adgroup.RowData.isEntityEdited=='FailedEdit'"
                            v-bind:class="getEntityStatusIcon(adgroup.RowData.isEntityEdited)"
                          ></i>
                          <i v-else v-bind:class="getEntityStatusIcon(adgroup.Status)"></i>
                          <span>
                            {{ adgroup.Name }}
                          </span>
                          <span v-if="adgroup.Id != ''" class="entity-label">
                            ({{ adgroup.Id }})
                          </span>
                        </span>
                        <span
                          class="entity-export-error"
                          v-if="adgroup.FailureReason != ''"
                        >{{adgroup.FailureReason}}</span>
                      </div>
                      <ul
                        :id="`adgroup-summary-${campaignIndex}-${adgroupIndex}`"
                        class="collapse-open in collapse adgroup-summary"
                      >
                        <li
                          class="panel-collapse"
                          v-for="(ad,adIndex) in adgroup.ChildEntities"
                          :key="adIndex"
                        >
                          <div>
                            <span>
                              <i v-bind:class="getEntityStatusIcon(ad.Status)" class="no-pointer"></i>
                              <span>
                                {{ ad.Name }}
                              </span>
                              <span v-if="ad.Id != ''" class="entity-label">
                                ({{ ad.Id }})
                              </span>
                            </span>
                            <span
                              class="entity-export-error"
                              v-if="ad.FailureReason != ''"
                            >{{ad.FailureReason}}</span>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
                 <div class="macro-warning" v-if="!allMacroReplaced">
                   <div class="alert alert-warning" role="alert">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">×</button>
                      <strong> Nomenclature Setup Incomplete! </strong>{{nomenclatureWarningMessage}}
                    </div>
                  </div>
              </div>
              <div class="col-md-12">
                <div v-if="showNewSheet && authFlowExists" class="row submission-box-row">
                  <AuthorizationFlowStages
                    :users="users"
                    type="Facebook"
                    isCampaignLauncher=true
                    :authorizationFlowData="authFlow"
                    @updatedAuthFlowJSON="updatedAuthFlowJSON"
                    :integrationType="getEngineTypeFromPublisherId(selectedPublisher)"
                  />
                  Submission Name <span style='color:#b94a48'>*</span>
                  <input
                    class="form-control"
                    v-show="true"
                    id="submissionName"
                    v-model="submissionName"
                    placeholder="Enter submission name"
                  >
                </div>
                <div class="row" v-if="showPublishButton">
                  <div
                    class="col-md-8 pa-0"
                    >
                  <Select
                    multiple
                    placeholder="Select stores"
                    v-model="storeIds"
                    not-found-text="No results"
                    filterable
                    :transfer="true"
                    :disabled="publishbuttonDisable"
                     v-if="!isLivePromotion"
                  >
                    <Option
                      v-for="store in stores"
                      :key="store.id"
                      :value="store.id"
                      :label="`${store.name} (${store.storeCode })`"
                    >
                      <span>{{ store.name }} ({{ store.storeCode }})</span>
                    </Option>
                  </Select>
                  </div>
                  <div
                    class="col-md-4"
                    >
                      <div>
                        <button :disabled="publishbuttonDisable || (storeIds == null || storeIds.length == 0)" class="btn btn-white" @click="publishStores">
                          Launch
                        </button>
                      </div>
                  </div>
                </div>
                <div :class="[this.selectedPublisher == this.publisher.AMS.SponsoredProducts ? 'row col-md-12' : 'row']">
                  <div v-if="isDV360Trueview" style="margin-bottom: 8px">
                    <RadioGroup v-model="dv360TrueviewPublishMode">
                      <Radio label="downloadSDF">
                        Download SDF Zip
                      </Radio>
                      <Radio label="botDeploy" :disabled="!isDV360MccAccount">
                        <v-tooltip
                          v-if="!isDV360MccAccount"
                          trigger="hover"
                          :content="'Allowed only for MCC account'"
                        >
                          <span>Bot Deploy</span>
                        </v-tooltip>
                        <span v-else>Bot Deploy</span>
                      </Radio>
                    </RadioGroup>
                  </div>

                  <div
                    v-if="isDisabledPublishedButtonRequired || (this.selectedPublisher == this.publisher.AMS.SponsoredBrands && !this.isBudgetValid)"
                    :class="[this.selectedPublisher == this.publisher.AMS.SponsoredProducts ? 'amsButton' : 'col-md-4 pa-0']"
                  >
                  <v-tooltip v-if="(this.selectedPublisher == this.publisher.AMS.SponsoredBrands && !this.isBudgetValid) || !elementsVerified || isTestModeEnabled || isEndedMediaPlan || isEndedLineItems || adsetBudgetError.isAdsetBudgetInvalid" trigger="hover" :content="elementsTooltip">
                      <div>
                        <button disabled="disabled" class="btn btn-success">
                          {{ publishText }}
                          <i class="fa fa-upload"></i>
                        </button>
                      </div>
                    </v-tooltip>
                  </div>
                  <div
                    v-if="isPublishButtonRequired && !(this.selectedPublisher == this.publisher.AMS.SponsoredBrands && !this.isBudgetValid)"
                    :class="[this.selectedPublisher == this.publisher.AMS.SponsoredProducts ? 'amsButton' : 'col-md-4 pa-0']"
                    style="padding:0px;"
                  >
                    <button class="btn btn-success" v-on:click="publish()" :disabled="(this.selectedPublisher == this.publisher.AMS.SponsoredBrands && !this.isBudgetValid) || !elementsVerified || !rnfPredictionsValid || rnfDataProcessing">
                      {{ publishText }}
                      <i class="fa fa-upload"></i>
                    </button>
                  </div>
                  <div
                    class="col-md-4 back-to-setup"
                    v-if="isCampaignLuncher && (!publishInProgress) && isSetupPresent">
                      <div>
                        <button class="btn btn-white" @click="ShowPreviousStep">
                          Back to Setup
                        </button>
                      </div>
                  </div>
                  <div
                    class="col-md-4 back-to-setup"
                    v-if="isWizardFlow">
                      <div>
                        <button class="btn btn-white" @click="ShowAdSheet">
                          Back
                        </button>
                      </div>
                  </div>
                  <div
                    v-if="(showNewSheet && authFlowExists && (!elementsVerified)) || (authFlowExists && isTestModeEnabled) || (authFlowExists && !isPromotionMode && adsetBudgetError.isAdsetBudgetInvalid)"
                    class="col-md-6"
                    style="padding:0px;"
                  >
                    <v-tooltip v-if="!elementsVerified || isTestModeEnabled || isEndedMediaPlan || isEndedLineItems || adsetBudgetError.isAdsetBudgetInvalid" trigger="hover" :content="elementsTooltip">
                      <div>
                        <button disabled="disabled" class="btn btn-success">Submit for Review</button>
                      </div>
                    </v-tooltip>
                  </div>
                  <div
                    v-if="showNewSheet && authFlowExists && elementsVerified && !isTestModeEnabled && (!adsetBudgetError.isAdsetBudgetInvalid && !isPromotionMode)"
                    class="col-md-6"
                    style="padding:0px;"
                  >
                    <button
                      :disabled="submissionName.length <= 0 || submissionsError || !rnfPredictionsValid || rnfDataProcessing"
                      class="btn btn-success"
                      v-on:click="captureElementsTabAndSubmit"
                    >
                      Submit for Review
                      <i v-show="submittedForReview" class="fa fa-spinner fa-spin"></i>
                    </button>
                  </div>

                  <button
                    v-if="(!showNewSheet) && flowCanBeApproved"
                    class="btn btn-success"
                    :disabled="!elementsVerified"
                    v-on:click="approve"
                  >
                    Approve
                    <i v-show="submittedForApproval" class="fa fa-spinner fa-spin"></i>
                  </button>

                  <button
                    v-if="(!showNewSheet) && flowCanBeApproved"
                    class="btn btn-white"
                    v-on:click="showModal = true"
                  >Reject</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-modal v-model="showModal" effect="fade">
        <div slot="modal-header" class="modal-header">
          <h4 class="modal-title">Please add a comment to reject the submission</h4>
        </div>
        <div slot="modal-body" class="modal-body">
          <textarea
            class="comment-area"
            v-model="rejectionComment"
            placeholder="Enter your comment here..."
          ></textarea>
        </div>
        <div slot="modal-footer" class="modal-footer">
          <button type="button" class="btn btn-white" @click="showModal = false">Cancel</button>
          <button
            type="button"
            class="btn btn-success"
            :disabled="rejectionComment.length <= 0"
            @click="reject"
          >
            Reject
            <i v-show="submittedForRejection" class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
      </v-modal>
    </div>
  </div>
</template>

<script>
import { select, alert, tooltip, modal } from "vue-strap";
import ApiService from './ApiService.js';
import { APIService as CampaignLauncherApiService } from '../../ApiService.js';
import PromotionApiService from '../../../Promotion/ApiService.js';
import ElementsView from './ElementsView.vue';
import AdWordsElementsView from './AdWordsElementsView.vue';
import AdWordsElementsViewNew from './AdWordsElementsViewNew.vue';
import AmsElementsView from './AmsElementsView.vue';
import AmsSummary from './AmsSummary.vue'
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { Select, Option, Progress, Input, Row, Col, Icon, ButtonGroup, Button, RadioGroup, Radio, Checkbox } from "iview";
import DBMElementsView from './DBM/DBMElementsView.vue'
import DBMSummary from './DBM/DBMSummary.vue'
import AdwordsSummary from './AdwordsSummary.vue'
import LinkedInElementsView from './LinkedIn/LinkedInElementsView.vue'
import LinkedInSummary from './LinkedIn/LinkedInSummary.vue'
import TwitterElementsView from './Twitter/TwitterElementsView.vue'
import TwitterSummary from './Twitter/TwitterSummary.vue'
import AuthorizationFlowStages from './../../../Settings/components/AuthorizationFlow/AuthorizationFlowStages.vue'
import { dbmEnums } from "../../Constants/DbmEnums.js";
import { EventBus } from "../../EventBus.js"
import AdWordsAdPreviewView from './AdWordsAdPreviewView.vue'
import AmsSdElementView from './AmsSDElementView.vue'
import AmsSpElementView from './AmsSpElementsView.vue'
import AmsSpTargetPreview from './AmsSpTargetPreview.vue'
import MolocoElementsView from './Moloco/MolocoElementsView.vue'
import MolocoSummary from './Moloco/MolocoSummary.vue'
var columnConfiguration = {};

export default {
  name: "app",
  components: {
    "v-select": select,
    "v-modal": modal,
    'elements-view': ElementsView,
    'adwords-elementsView': AdWordsElementsView,
    'adwords-elementsView-new': AdWordsElementsViewNew,
    'ams-elementsView': AmsElementsView,
    'ams-summary': AmsSummary,
    "alert": alert,
    "v-tooltip": tooltip,
    Select,
    Option,
    Progress,
    "i-input": Input,
    RadioGroup,
    Radio,
    Row,
    Icon,
    "button-group": ButtonGroup,
    "i-button": Button,
    "Row-Col": Col,
    'dbm-elementsView': DBMElementsView,
    'dbm-summary': DBMSummary,
    'adwords-summary': AdwordsSummary,
    'linkedin-elements-view': LinkedInElementsView,
    'linkedin-summary': LinkedInSummary,
    'twitter-elements-view': TwitterElementsView,
    'twitter-summary': TwitterSummary,
    AuthorizationFlowStages,
    AdWordsAdPreviewView,
    Checkbox,
    AmsSdElementView,
    AmsSpElementView,
    AmsSpTargetPreview,
    'moloco-elements-view': MolocoElementsView,
    'moloco-summary': MolocoSummary
  },
  created: async function () {
    if (window.location.href.toLowerCase().includes('summary')) {
      this.set_summaryLocation(window.location.href);
    }
    var searchParams = new URLSearchParams((window.location.href.substring(window.location.href.indexOf('?') + 1)));
    if (searchParams.get('view') == 'Full') {
      this.viewType = "Full setup";
    }
    columnConfiguration = {
      "Budget": {
        render: function (columnData, rowData) {
          return `${rowData.BpCurrency}${columnData}`;
        }
      },
      "SpendLimit": {
        render: function (columnData, rowData) {
          var result = [];
          var output = "";
          var helpText = "";
          if (columnData.min != "") {
            helpText = "Min";
            result.push(`${rowData.BpCurrency}${columnData.min}`)
          }
          if (columnData.max != "") {
            helpText = "Max";
            result.push(`${rowData.BpCurrency}${columnData.max}`)
          }

          if (result.length == 1) {
            output += `${result[0]} (${helpText})`
          } else if (result.length == 0) {
            output = "None";
          } else {
            output = result.join(" - ")
          }
          return output;
        }
      }
    };
    if (this.isPromotionMode && deltax.isHubDirectLaunchEnabled) {
      this.getPromotionStores();
    }
    if (this.isPromotionMode) {
      let promoVersion = await PromotionApiService.getPromotionVersions([this.promotionId], true);
      this.latestPromotionVersion = promoVersion[0] || {};
      this.promotion = await PromotionApiService.getPromotion(this.promotionId);
      if (this.promotion.status == 1) {
        EventBus.$emit("PromotionLaunchWizard-TestPublisherEnableNext", false);
      }
    }
  },
  mounted: function () {
    if (!this.isPromotionMode) {
      this.fbEntitiesToShowOption["AdEdited"] = "fa fa-pencil-square yellow"
      this.fbEntitiesToShowOption["AdEditedPartial"] = "fa-pencil-square-o yellow"
    }
    if (this.isCampaignLuncher) {
      this.submissions = this.allSubmissions;
      if (this.facebookEntities) {
        this.setFinalGroupedEntities(this.facebookEntities);
        this.requestId = this.facebookEntities.requestId;
        this.redisKey = this.facebookEntities.redisKey;
        this.allMacroReplaced = this.facebookEntities.allMacroReplaced;
        this.setPublishedEditedSummaryData(this.facebookEntities.publishedEditedSummaryData);
      } else {
        // this.publishSummary = [];
        // this.adsData = [];
        // this.adgroupsData = [];
        // this.structureData = [];
      }
      this.$nextTick(function () {
        if (this.submissionId > 0) {
          this.currentSubmissionId = this.submissionId;
          this.currentSelectedView = this.currentSubmissionId;
        } else {
          this.currentSelectedView = this.viewType;
        }
      })
      if (!this.displayExistingSubmissions) {
        this.getBPUsers()
        this.submissionName = this.getSubmissionName()
      }
    }

    this.isRetryEnabled = deltax.isHub;

    this.$watch(
      vm => [vm.fbSummarySearchQuery, vm.fbEntityStatusToShow, vm.summaryPanelData],
      () => {
        this.filterFbPublishSummary(this.summaryPanelData);
      },
      {
        deep: true,
        immediate: true
      }
    )
    if (!this.isPromotionMode) {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeDestroy () {
    if (!this.isPromotionMode) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  props: {
    userCanApprove: { default: false },
    authFlow: { default: null },
    submissionId: { default: 0 },
    isCampaignLuncher: { default: false },
    facebookEntities: { default: function () { return [] } },
    allSubmissions: { default: function () { return [] } },
    viewType: { default: 'Changes' }
  },
  data: function () {
    return {
      isBudgetValid: false,
      elementsVerified: false,
      currentSubmissionId: 0,
      submissionName: "",
      rejectionComment: "",
      submissions: [],
      searchEngines: {
        facebook: 5
      },
      newSheet: {},
      actionSummary: {
        message: "",
        show: false,
        type: ""
      },
      submittedForReview: false,
      submittedForApproval: false,
      submittedForRejection: false,
      showModal: false,
      publishInProgress: false,
      apiService: ApiService,
      engine: {
        selected: 5,
        options: [{ "id": 5, "label": "Facebook" }]
      },
      adCreationSheet: {
        readyToView: false,
        processing: false,
        refreshing: false,
        data: {}
      },
      requestId: 0,
      publishSummary: [],
      columns: ['CampaignName', 'AdgroupName', 'Budget', 'Bid', 'Ad'],
      adsData: [],
      publishbuttonDisable: false,
      adgroupsData: [],
      targetsInfo: {},
      stores: null,
      promotion: null,
      storeIds: null,
      user: JSON.parse(deltax.user),
      schedulingMode: { runContinuously: 1, schedule: 2 },
      objective: { traffic: 1 },
      elementsTooltip: '',
      structureData: [],
      campaignPublishDataLogId: 0,
      submissionLoading: false,
      adwordsPublishSuccess: false,
      isPublishSuccess: false,
      totalEntitiesCount: 0,
      failedEntitiesCount: 0,
      chunkPublishId: "",
      uploadProgress: {
        toShow: false,
        successCount: 0,
        failureCount: 0,
        totalCount: -1
      },
      users: [],
      submissionsError: false,
      updatedAuthFlow: {},
      creativeMedia: {},
      currentSelectedView: "",
      submissionCreativesJson: [],
      fbSummarySearchQuery: "",
      fbEntityStatusToShow: ["All"],
      fbEntitiesToShowOption: {
        "All": "ios-albums",
        "Success": "fa fa-check-circle green",
        "Failed": "fa fa-exclamation-triangle red",
        "New": "fa fa-clock-o yellow"
      },
      fbFilteredPublishSummary: [],
      fbPublishSummaryFilterTimeout: null,
      fbEntityStatusCount: null,
      summaryPanelData: [],
      isDV360Trueview: false,
      isDV360MccAccount: false,
      dv360TrueviewPublishMode: "downloadSDF",
      redisKey: "",
      endedLineItems: [],
      nomenclatureWarningMessage: "It looks like the nomenclature settings have not been applied correctly.We recommend you to fix it before submitting.",
      allMacroReplaced: true,
      isRetryEnabled: true,
      isCampaignPublishOnly: false,
      retryFlagSpinner: false,
      rnfPredictionsValid: true,
      rnfDataProcessing: false,
      showPublishCampaignsOnlyCheckbox: true,
      latestPromotionVersion: {},
      showTab: {
        summary: false,
        campaignSetup: false,
        reachFrequencyEstimate: false,
        targeting: false,
        locations: false,
        excludedLocations: false,
        pageSets: false,
        pagesCreative: false,
        placements: false
      },
      activeTab: null,
      isSubmissionValid: true
    }
  },
  computed: {
    ...mapState([
      "tableData",
      "amsSdEntities",
      "mediaPlanId",
      "selectedCampaignLauncherConfig",
      "selectedCampaignLauncherConfigId",
      "businessProfilesSearchEngineId",
      "publishedCreatives",
      "creativesToPublish",
      "adFormats",
      "publishedCreativesByCampaignConfigId",
      "mediaPlanLineItems",
      "deltax",
      "campaignLauncherConfigs",
      "campaignLauncherConfigBpseIdMapper",
      "displayExistingSubmissions",
      "isCreationFlow",
      "publishedCampaignsCountByMediaPlan",
      "selectedPublisher",
      "adwordsEntitiesResponse",
      "isPromotionMode",
      "promotionObject",
      "savedTargetsMapperByBpseId",
      "promotionId",
      "amsEntitiesResponse",
      "dv360EntitiesResponse",
      "publisher",
      "mediaPlans",
      "dbmAccounts",
      "adwordsEnums",
      "summaryLocation",
      "linkedInEntitiesResponse",
      "twitterEntitiesResponse",
      "currentVersionOfGeneratedEntities",
      "accounts",
      "adsetBudgetError",
      "changedData",
      "isWizardFlow",
      "molocoCampaignLauncherPublishDataId",
      "publishersSupportingNewLauncherFlow",
      "isPromotionCampaignLaunch",
      "publisherSupportedForSubmissions",
      "editedCampaignBid"
    ]),
    ...mapGetters(["isPublisherSupportedForSubmission", "getEngineTypeFromPublisherId", "isLivePromotion", "getPublisherAccounts", "editedSummaryData", "isPromotionDisabled"]),
    showAdPreview: function () {
      return (this.deltax.isAdwordsCampaignLaucherFlow && this.selectedPublisher === this.publisher.Adwords && this.selectedCampaignLauncherConfig.campaign && this.selectedCampaignLauncherConfig.campaign.type && this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType.Search);
    },
    campaignsGridUrl: function () {
      return `/App/Facebook/Campaign/List/${window.agency}`;
    },
    searchCampaignsGridUrl: function () {
      return `/App/Search/Campaign/List/${window.agency}`;
    },
    showPublishButton: function () {
      return deltax.isHubDirectLaunchEnabled && this.isPromotionMode && (this.user && this.user.IsInternal) && (this.promotion && this.promotion.settings.testedSearchEngines.includes(this.searchEngines.facebook));
    },
    promoDashboardUrl: function () {
      return `/App/Promotion/List/${window.agency}`;
    },
    flowCanBeApproved: function () {
      return this.userCanApprove && this.authFlow && ((!this.authFlow.Status) || this.authFlow.Status == "IN_REVIEW");
    },
    authFlowExists: function () {
      return this.authFlow &&
      this.authFlow.Id > 0 &&
      !this.isPromotionMode &&
      this.isPublisherSupportedForSubmission(this.selectedPublisher)
    },
    submissionApproved: function () {
      return this.authFlow && this.authFlow.Status && this.authFlow.Status == "APPROVED";
    },
    showNewSheet: function () {
      return this.currentSubmissionId <= 0;
    },
    isDisabledPublishedButtonRequired: function () {
      var isDisabledButtonRequired = ((!this.publishInProgress) && !this.authFlowExists && (!this.elementsVerified) && !((this.adwordsPublishSuccess) || (this.isPublishSuccess)) && ((!this.isPromotionDisabled && !this.isLivePromotion) || this.latestPromotionVersion.status == 1)) || (this.adsetBudgetError.isAdsetBudgetInvalid && !this.authFlowExists);
      return isDisabledButtonRequired || (!this.authFlowExists && this.isTestModeEnabled);
    },
    isPublishButtonRequired: function () {
      return (!this.publishInProgress) && ((!this.authFlowExists && this.elementsVerified) || this.submissionApproved) && !((this.adwordsPublishSuccess) || (this.isPublishSuccess)) && ((!this.isPromotionDisabled && !this.isLivePromotion) || this.latestPromotionVersion.status == 1) && !this.isTestModeEnabled && !this.adsetBudgetError.isAdsetBudgetInvalid;
    },
    publishText () {
      return this.isPromotionMode ? "Test" : "Publish"
    },
    publishProgressPercentage () {
      if (this.uploadProgress.toShow == false || this.uploadProgress.totalCount == -1) {
        return 0
      }
      if (this.uploadProgress.totalCount == 0) {
        return 100
      }
      let processedCount = this.uploadProgress.successCount + this.uploadProgress.failureCount
      return Math.round(processedCount / this.uploadProgress.totalCount * 100)
    },
    publishSuccessPercentage () {
      if (this.uploadProgress.totalCount == 0) {
        return 100
      }
      return Math.round(this.uploadProgress.successCount / this.uploadProgress.totalCount * 100)
    },
    publishTotalPercentage () {
      if (this.uploadProgress.totalCount == 0) {
        return 100
      }
      return Math.round((this.uploadProgress.successCount + this.uploadProgress.failureCount) / this.uploadProgress.totalCount * 100)
    },
    aggregratepublishSummary: function () {
      if (this.selectedPublisher == this.publisher.Adwords) {
        return this.adwordsEntitiesResponse
      } else if (this.selectedPublisher == this.publisher.DV360) {
        return this.dv360EntitiesResponse
      } else if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts || this.selectedPublisher == this.publisher.AMS.SponsoredBrands || this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) {
        return this.amsEntitiesResponse
      } else if (this.selectedPublisher == this.publisher.LinkedIn) {
        return this.linkedInEntitiesResponse
      } else if (this.selectedPublisher == this.publisher.Twitter) {
        return this.twitterEntitiesResponse
      }
      return this.publishSummary
    },
    isSetupPresent: function () {
      var isMediaPlanPresent = this.mediaPlanId && this.mediaPlanId > 0;
      var isConfigPresent = this.selectedCampaignLauncherConfigId && this.selectedCampaignLauncherConfigId > 0;
      return isMediaPlanPresent && isConfigPresent;
    },
    isTestModeEnabled: function () {
      if (this.selectedCampaignLauncherConfigId) {
        var campaignLauncherConfigurationId = this.selectedCampaignLauncherConfigId;
        var bpseId = this.campaignLauncherConfigBpseIdMapper[campaignLauncherConfigurationId].BpseId;
        var accounts = this.getPublisherAccounts;
        var bpse = accounts && accounts.length ? accounts.filter(item => {
          return item.Id == bpseId;
        }) : [];
        return Array.isArray(bpse) && bpse.length > 0 && bpse[0].TestMode;
      } else {
        return false;
      }
    },
    isEndedMediaPlan: function () {
      let selectedMediaPlan = this.mediaPlans.find(mediaPlan => mediaPlan.Id === this.mediaPlanId);
      if (selectedMediaPlan != undefined) {
        return new Date(selectedMediaPlan.EndDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
      } else {
        return false;
      }
    },
    isEndedLineItems: function () {
      this.endedLineItems = this.mediaPlanLineItems.filter(lineItem => new Date(lineItem.endDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) && lineItem.config === this.selectedCampaignLauncherConfigId);
      if (this.endedLineItems != undefined && this.endedLineItems.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  filters: {
    render: function (value, row, columnName) {
      var columnData = row[columnName];
      var finalValue = "";
      if (columnConfiguration[columnName]) { finalValue = columnConfiguration[columnName].render(columnData, row); } else { finalValue = columnData; }

      return finalValue;
    }
  },
  methods: {
    ...mapMutations([
      "SET_BPSEID",
      "set_selectedCampaignLauncherConfig",
      "set_selectedCampaignLauncherConfigId",
      "SET_FACEBOOK_ENTITIES",
      "SET_SHOW_PREVIEW",
      "SET_selectedFbObjective",
      "set_displayExistingSubmissions",
      "set_isCreationFlow",
      "set_isSpSubmissionFlow",
      "set_publishedCampaignsCountByMediaPlan",
      "set_existingPostAds",
      "set_mediaPlanId",
      "set_amsEntitiesResponse",
      "set_dv360EntitiesResponse",
      "set_adwordsEntitiesResponse",
      "set_selectedPublisher",
      "set_summaryLocation",
      "set_linkedInEntitiesResponse",
      "set_twitterEntitiesResponse",
      "set_selectedMediaPlan",
      "setEditedSummaryData",
      "setAdsetBudgetError",
      "set_amsLineItemsUpdate",
      "setPublishedEditedSummaryData",
      "set_publishInProgress",
      "set_renderSubmissionSummary",
      "set_editedCampaignBid"
    ]),
    ...mapActions(["setAuthFlows"]),
    UpdateFailedTargets (targets) {
      this.targetsInfo = targets
    },
    handleScroll () {
      if (this.selectedPublisher !== this.publisher.Facebook) return;
      const navList = this.$refs.navList;
      const elements = ['summary', 'campaignSetup', 'reachFrequencyEstimate', 'targeting', 'locations', 'excludedLocations', 'pageSets', 'pagesCreative', 'placements'];
      elements.forEach(element => {
        let elementRef = this.$refs.elementsFacebook.$refs[element];
        if (navList && elementRef && elementRef.getBoundingClientRect().top < (navList.clientHeight + 42)) {
          this.activeTab = element;
        }
      });
    },
    scrollToSection (selector) {
      this.activeTab = selector;
      const navList = this.$refs.navList;
      const targetElement = this.$refs.elementsFacebook.$refs[selector];
      window.removeEventListener('scroll', this.handleScroll);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.getBoundingClientRect().top + window.scrollY - (navList.clientHeight + 40),
          behavior: 'smooth'
        });
      }
      setTimeout(() => {
        window.addEventListener('scroll', this.handleScroll);
      }, 1000);
    },
    showElementsTab (name, show) {
      this.showTab[name] = show;
    },
    setEditedJsonInitialState () {
      var publishInitialState = this.$store.state.publishedCreativesInitialState
      if (this.$store.state.adFormats && publishInitialState) {
        for (let adFormat of this.$store.state.adFormats) {
          if (publishInitialState[adFormat]) {
            for (let row of publishInitialState[adFormat]) {
              if (row["HOT_Preview Ad"] == "true") {
                row["HOT_isEdited"] = "false"
              }
            }
          }
        }
      }
      this.$store.commit("set_publishedCreativesInitialState", publishInitialState)
    },
    toggleFbEntitiesToShow (status) {
      const selected = this.fbEntityStatusToShow.includes(status);
      const count = this.fbEntityStatusToShow.length;

      if (selected && count == 1) {
        return;
      }
      if (!selected) {
        if (status == "All") {
          this.fbEntityStatusToShow = [];
        } else {
          this.fbEntityStatusToShow = this.fbEntityStatusToShow.filter(x => x != "All");
        }
        this.fbEntityStatusToShow.push(status);
      } else {
        this.fbEntityStatusToShow = this.fbEntityStatusToShow.filter(x => x != status);
      }
    },
    filterFbPublishSummary (summary) {
      clearTimeout(this.fbPublishSummaryFilterTimeout);

      let query = this.fbSummarySearchQuery.trim();
      const showAllStatus = this.fbEntityStatusToShow.length == 0 || this.fbEntityStatusToShow.includes("All");
      if (query == "" && showAllStatus) {
        this.fbFilteredPublishSummary = summary;
        return;
      }

      const filterFn = (compareFn) => {
        return summary.flatMap((campaign) => {
          campaign = { ...campaign };

          campaign.ChildEntities = campaign.ChildEntities.flatMap((adgroup) => {
            adgroup = { ...adgroup };
            adgroup.ChildEntities = adgroup.ChildEntities.filter(compareFn);
            return adgroup.ChildEntities.length > 0 || compareFn(adgroup)
              ? [adgroup]
              : [];
          });

          return campaign.ChildEntities.length > 0 || compareFn(campaign)
            ? [campaign]
            : [];
        });
      };

      this.fbPublishSummaryFilterTimeout = setTimeout(() => {
        if (query != "") {
          query = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // escape special chars in query (https://stackoverflow.com/a/6969486)
          const re = new RegExp(query, "i");
          summary = filterFn(entity => {
            const valuesToSearch = [entity.Name, entity.Id, entity.FailureReason];
            return valuesToSearch.some(value => re.test(value));
          })
        }
        if (!showAllStatus) {
          summary = filterFn(entity => this.fbEntityStatusToShow.includes(entity.Status));
        }

        this.fbFilteredPublishSummary = summary;
      }, 350);
    },
    getPromotionStores: async function () {
      this.promotion = await PromotionApiService.getPromotion(this.promotionId);
      let stores = await PromotionApiService.getStores();
      this.processStores(stores);
    },
    checkSubmissionOption: function (value) {
      if (value) {
        if (value == "Changes" || value == "Full setup") {
          this.viewType = value;
          if (!window.location.href.toLowerCase().includes('summary')) {
            window.location.href = this.summaryLocation + (this.viewType == 'Full setup' ? '&view=Full' : '');
            window.location.reload()
          }
        } else {
          this.currentSubmissionId = value;
        }
      }
    },
    processStores: async function (allStores) {
      this.stores = [];
      let self = this;
      allStores.forEach(function (store) {
        if (self.promotion.enabledStoreIds.includes(store.id) && (!self.promotion.launchedStoreIds.includes(store.id) || self.promotion.isTemplate)) {
          self.stores.push(store);
        }
      });
    },
    getSubmissionName: function () {
      let configName = this.selectedCampaignLauncherConfig.name
      var suggestedSubmissionName = this.getMediaPlanName(this.mediaPlanId) + "-" + configName + "-" + new Date().toLocaleString()
      return suggestedSubmissionName
    },
    getAdCreationSheetAndSubmissions: function () {
      this.adCreationSheet.readyToView = false;
      this.apiService.GetAdCreationSheet(this.engine.selected, this.getAdCreationSheetCallback);
    },
    getAdPreviewSuccessCallback: function (response) {
      var finalGroupedEntities = JSON.parse(response.finalGroupedEntities);
      this.setFinalGroupedEntities(finalGroupedEntities)
      this.requestId = response.requestId;
      if (response.errorMessage != "") {
        this.actionSummary.show = true;
        this.actionSummary.message = response.errorMessage;
        this.actionSummary.type = "warning";
      }
    },
    getAdPreviewFailureCallback: function (response) {
      this.actionSummary.show = true;
      this.actionSummary.message = "An error occurred while processing the sheet";
      this.actionSummary.type = "danger";
    },
    getAdPreviewDoneCallback: function (response) {
      this.adCreationSheet.processing = false;
    },
    dv360publishDoneCallback: function () {
      let campaigns = this.dv360EntitiesResponse.data.childEntities;

      var targetsFailedToAssign = false;
      let youtubeLineItemExists = false;
      campaigns.forEach(campaign => {
        if (campaign.status != 8) {
          return;
        }
        campaign.childEntities.forEach(io => {
          if (io.status != 8) {
            return;
          }
          targetsFailedToAssign = io.childEntities.some(li => {
            return li.additionalData.lineItem.targetFailureReason != null && li.additionalData.lineItem.targetFailureReason !== '';
          })
          youtubeLineItemExists = youtubeLineItemExists || io.childEntities.some(li => {
            return li.additionalData.lineItem.Type == 4
          });
        })
      });

      if (targetsFailedToAssign) {
        this.actionSummary.message = " There was a problem while applying the targeting to one or more lineitems.";
        this.actionSummary.type = "danger";
      }

      if (!youtubeLineItemExists) {
        this.publishDoneCallback();
        return;
      }

      let data = {
        id: this.dv360EntitiesResponse.data.requestId,
        userId: parseInt(this.deltax.userId),
        mediaPlanId: parseInt(this.mediaPlanId),
        portalId: Number(this.deltax.portalId) || 0,
        businessProfilesSearchEngineId: parseInt(this.businessProfilesSearchEngineId),
        configId: parseInt(this.selectedCampaignLauncherConfigId),
        campaignPublishDataLogId: parseInt(this.campaignPublishDataLogId)
      };

      let message = this.actionSummary.message;
      this.actionSummary.message = message + " Generating TrueView SDF files";

      const successCb = () => {
        let suffix = this.dv360TrueviewPublishMode == "downloadSDF"
          ? " TrueView SDF files downloaded successfully"
          : " Your TrueView creatives would be published automatically shortly";
        this.actionSummary.message = message + suffix;
      }
      const failCb = () => {
        this.actionSummary.message = message + " Something went wrong while trying to process TrueView SDF files";
        this.actionSummary.type = "warning";
      }

      let apiMethod = this.dv360TrueviewPublishMode == "downloadSDF"
        ? this.apiService.DownloadDv360TrueViewZip
        : this.apiService.Dv360TrueViewBotDeploy;

      apiMethod(
        data,
        successCb,
        failCb,
        this.publishDoneCallback
      )
    },
    publishDoneCallback: function (response) {
      EventBus.$emit("publishClicked");
      if (
        response &&
        response.publishToApiResponse &&
        response.publishToApiResponse.AllEntitiesPublished == false
      ) {
        return;
      }
      this.chunkPublishId = "";
      this.publishInProgress = false;
      this.set_publishInProgress(false)
      this.$emit('campaigns-published')
      if (this.isWizardFlow) {
        EventBus.$emit("CampainLauncher-BulkExport-App-publishInProgress", false);
      }
    },
    publishSuccessCallback: function (response) {
      if (this.deltax.isDeltaxApiFlowEnabled && response.publishToApiResponse) {
        // DxApi response is in camel case
        const toCamelCase = (str) => str.charAt(0).toLowerCase() + str.slice(1);
        let properties = ["PublishToApiBatchFailed", "AllEntitiesPublished", "SuccessfulPublishCount", "FailedPublishCount"];
        let publishResponse = response.publishToApiResponse;

        properties.forEach(prop => {
          let apiProp = toCamelCase(prop);
          if (!publishResponse[prop]) {
            publishResponse[prop] = publishResponse[apiProp];
          }
        })

        /* let statusCount = response.entityStatusCount;
        statusCount.TotalSuccess = statusCount.totalSuccess;
        statusCount.TotalFailed = statusCount.totalFailed;
        statusCount.TotalNew = statusCount.totalNew; */
      }

      if ((response.publishToApiResponse && response.publishToApiResponse.PublishToApiBatchFailed) || response.status == "Failure") {
        this.publishFailureCallback(response)
        return
      }

      this.setEditedJsonInitialState();
      this.setEditedSummaryData("")
      this.setPublishedEditedSummaryData(response.publishedEditedSummaryData)

      if (response.publishToApiResponse.PublishToApiBatchFailed || response.status == "Failure") {
        this.publishFailureCallback(response)
        return
      }
      if (response.entitiesToPublishCount != -1) {
        this.uploadProgress.totalCount = response.entitiesToPublishCount
      }
      if (response.publishToApiResponse.AllEntitiesPublished == false) {
        this.chunkPublishId = response.chunkPublishId
        this.publish(true);
        return
      }

      this.publishSummary = JSON.parse(response.publishSummary)
      this.$store.commit("set_publishJson", this.publishSummary)
      this.fbEntityStatusCount = response.entityStatusCount;
      this.uploadProgress.successCount = response.publishToApiResponse.SuccessfulPublishCount
      this.uploadProgress.failureCount = response.publishToApiResponse.FailedPublishCount

      var publishedCampaigns = response.publishedCampaignsCount;
      let publishedAdsCount = response.publishedAdsCount;

      let newPublishedCampaignsCount = this.publishedCampaignsCountByMediaPlan
      newPublishedCampaignsCount[this.mediaPlanId] = newPublishedCampaignsCount[this.mediaPlanId] || {}
      newPublishedCampaignsCount[this.mediaPlanId][this.campaignLauncherConfigurationId] = publishedCampaigns
      this.set_publishedCampaignsCountByMediaPlan(newPublishedCampaignsCount)
      this.showPublishMessage(
        this.uploadProgress.totalCount,
        this.uploadProgress.failureCount,
        this.uploadProgress.successCount
      )
      if (publishedAdsCount > 0) {
        this.updatePublishedCreatives();
      }

      if (this.campaignPublishDataLogId > 0 && this.deltax.isDeltaxApiFlowEnabled && !this.isPromotionMode) {
        this.apiService.SendSubmissionEmail({
          mediaPlanName: this.getMediaPlanName(this.mediaPlanId),
          campaignPublishDataLogId: this.campaignPublishDataLogId
        })
      }

      if (this.isPromotionMode) {
        this.updatePromoStatus();
      }
      if (!this.isPromotionMode) {
        this.apiService.SyncEntites(this.mediaPlanId, this.selectedCampaignLauncherConfigId);
      }
    },
    showPublishMessage (totalCount, failureCount, successCount) {
      let message = "";
      let type = "";

      if (totalCount == 0) {
        type = "info"
        message = "There are no changes that need to be published.";
      } else if (failureCount > 0 && successCount == 0) {
        message = "Oops, there was a problem while publishing your changes.";
        type = "danger";
      } else if (failureCount == 0 && successCount == totalCount) {
        if (this.selectedPublisher == this.publisher.Adwords) {
          message = `Your changes have been pushed successfully. They will be available on the <a href='${this.searchCampaignsGridUrl}'>Campaigns page</a> soon.`;
        } else if (this.selectedPublisher == this.publisher.Facebook) {
          if (this.isPromotionMode && !this.isWizardFlow) {
            message = `Your changes have been pushed successfully. You can now <a href='${this.promoDashboardUrl}'>activate your promotion</a>`;
          } else if (this.isWizardFlow) {
            message = `Your changes have been pushed successfully. You can now activate your promotion`;
          } else {
            message = `Your changes have been pushed successfully.`;
          }
        }
        type = "success";
      } else if (failureCount > 0 && successCount > 0) {
        type = "warning";
        message = "Some of your changes were not published. Please review the summary information below.";
      } else {
        // In case something goes wrong and publish counts aren't properly set
        type = "warning";
        message = "Something when wrong while publishing your changes. Please review the summary information below."
      }

      this.actionSummary.show = true;
      this.actionSummary.message = message;
      this.actionSummary.type = type;
    },
    adwordsPublishSuccessCallback: function (response) {
      response = response.data.data
      this.set_adwordsEntitiesResponse(response)
      if (response) {
        this.updatePublishedCreatives();
      }
      this.showPublishMessage(
        response.successfulPublishCount + response.failedPublishCount,
        response.failedPublishCount,
        response.successfulPublishCount
      )
      if (this.isPromotionMode) {
        this.updatePromoStatus();
      }
    },
    amsPublishSuccessCallback: function (response) {
      let message = "";
      let type = "";
      let jsonData = response.data;
      this.set_amsEntitiesResponse(jsonData);
      this.isPublishSuccess = true;
      this.verifyPublishStatus(jsonData.data.amsEntities);

      if (this.isPublishSuccess) {
        message = "Your campaigns are published successfully.";
        type = "success";
      } else {
        if (this.failedEntitiesCount === this.totalEntitiesCount) {
          message = "Your campaigns are failed to publish.";
        } else {
          message = "Failed to push some of the campaigns";
        }
        type = "danger";
      }

      this.actionSummary.show = true;
      this.actionSummary.message = message;
      this.actionSummary.type = type;
    },
    dv360PublishSuccessCallback: function (response) {
      let message = "";
      let type = "";
      let jsonData = response.data;
      this.set_dv360EntitiesResponse(jsonData);
      this.isPublishSuccess = true;
      this.verifyPublishStatus(jsonData.data.childEntities);

      if (this.isPublishSuccess) {
        message = "Your changes are published successfully.";
        type = "success";
        this.updatePublishedCreatives();
      } else {
        if (this.failedEntitiesCount === this.totalEntitiesCount) {
          message = "Oops, there was a problem while publishing your changes.";
        } else {
          message = "Some of your changes were not published. Please review the summary information below.";
        }
        type = "danger";
      }

      this.actionSummary.show = true;
      this.actionSummary.message = message;
      this.actionSummary.type = type;
    },
    linkedInPublishSuccessCallback: function (response) {
      let message = "";
      let type = "";
      this.set_linkedInEntitiesResponse(response.data);
      this.isPublishSuccess = true;
      this.verifyPublishStatus(response.data.data.childEntities);

      if (this.isPublishSuccess) {
        message = "Your changes have been pushed successfully.";
        type = "success";
        this.updatePublishedCreatives();
        this.setEditedSummaryData("")
      } else {
        if (this.failedEntitiesCount === this.totalEntitiesCount) {
          message = "Oops, there was a problem while publishing your changes.";
          type = "danger";
        } else {
          message = "Some of your changes were not published. Please review the summary information below.";
          type = "warning";
        }
      }

      this.actionSummary.show = true;
      this.actionSummary.message = message;
      this.actionSummary.type = type;
    },
    twitterPublishSuccessCallback: function (response) {
      let message = "";
      let type = "";
      this.set_twitterEntitiesResponse(response.data);
      this.isPublishSuccess = true;
      this.verifyPublishStatus(response.data.data.childEntities);

      if (this.isPublishSuccess) {
        message = "Your changes have been pushed successfully.";
        type = "success";
        this.updatePublishedCreatives();
      } else {
        if (this.failedEntitiesCount === this.totalEntitiesCount) {
          message = "Oops, there was a problem while publishing your changes.";
          type = "danger";
        } else {
          message = "Some of your changes were not published. Please review the summary information below.";
          type = "warning";
        }
      }

      this.actionSummary.show = true;
      this.actionSummary.message = message;
      this.actionSummary.type = type;
    },
    molocoPublishSuccessCallback: function (response) {
      let message = "";
      let type = "";
      this.isPublishSuccess = true;
      // this.verifyPublishStatus(response.data.data.childEntities);

      if (this.isPublishSuccess) {
        message = "Your changes have been pushed successfully.";
        type = "success";
        // this.updatePublishedCreatives();
      } else {
        if (this.failedEntitiesCount === this.totalEntitiesCount) {
          message = "Oops, there was a problem while publishing your changes.";
          type = "danger";
        } else {
          message = "Some of your changes were not published. Please review the summary information below.";
          type = "warning";
        }
      }

      this.actionSummary.show = true;
      this.actionSummary.message = message;
      this.actionSummary.type = type;
    },
    publishStores: async function () {
      this.publishbuttonDisable = true;
      let promotionCampaign = {};
      promotionCampaign.Name = this.selectedCampaignLauncherConfig.campaign.name;
      promotionCampaign.BudgetAmount = this.promotion.settings.budget.suggested;
      promotionCampaign.DurationType = this.selectedCampaignLauncherConfig.campaign.budgetType;
      promotionCampaign.StartDate = new Date(this.promotion.settings.schedule.startDate).getTime() > new Date().getTime() ? new Date(this.promotion.settings.schedule.startDate).toString('MM/dd/yyyy h:mm tt') : new Date().toString('MM/dd/yyyy h:mm tt');
      promotionCampaign.EndDate = this.promotion.settings.schedule.endDate ? new Date(this.promotion.settings.schedule.endDate).toString('MM/dd/yyyy h:mm tt') : moment().add(1, 'M').format('MM/DD/YYYY');
      promotionCampaign.DayPartingSchedules = this.selectedCampaignLauncherConfig.adset.schedule.dayParting_schedules;
      promotionCampaign.TargetClusterIds = this.promotion.settings.targeting.clusterIds;
      promotionCampaign.SchedulingMode = this.schedulingMode.schedule;
      promotionCampaign.Objective = this.objective.traffic;
      promotionCampaign.Promotion = {};
      promotionCampaign.Promotion.Id = this.promotionId;
      promotionCampaign.StoreIds = this.storeIds;
      try {
        await PromotionApiService.publishStores(this.promotionId, promotionCampaign);
      } catch {
        this.actionSummary.show = true;
        this.actionSummary.message = "Launched Failed for the selected stores";
        this.actionSummary.type = "danger";
        this.publishbuttonDisable = false;
        return;
      }
      if (!this.actionSummary.show) {
        this.actionSummary.show = true;
        this.actionSummary.message = "The Stores have been launched";
        this.actionSummary.type = "success";
        this.storeIds = null;
        this.publishbuttonDisable = false;
        if (!this.promotion.isTemplate) {
          let remainingStores = this.stores.filter(store => !promotionCampaign.StoreIds.includes(store.id));
          this.stores = remainingStores;
        }
      }
    },
    verifyPublishStatus: function (childEntities) {
      this.totalEntitiesCount++;
      childEntities.forEach(element => {
        if (element.status === 10) {
          this.isPublishSuccess = false;
          this.failedEntitiesCount++;
        } else if (element["childEntities"]) {
          this.verifyPublishStatus(element.childEntities)
        }
      });
    },
    publishFailureCallback: function (e) {
      this.actionSummary.show = true;
      this.actionSummary.message = "An error occurred while publishing your changes";
      this.actionSummary.type = "danger";

      if (e.message) {
        this.actionSummary.message = e.message
      } else if (e.response && e.response.data && e.response.data.message) {
        let json = JSON.parse(e.response.data.message);
        this.actionSummary.message = json.details;
      } else if (e.response && e.response.data && e.response.data.errorCode == 62) {
        let account = this.dbmAccounts.find(x => x.id == this.businessProfilesSearchEngineId);
        let accountName = (account && account.accountNickName) || '';
        this.actionSummary.message = `OAuth for DV360 account '${accountName}' has expired.`;
      }
    },
    isPublishInProgress: function () {
      return this.publishInProgress;
    },
    getPublishStatusCallback: function (response) {
      if (!response.data) { return }
      let data = JSON.parse(response.data)
      this.summaryPanelData = data.publishSummary;

      if (!data.uploadCount) { return }
      if (data.uploadCount.EntitiesToPublishCount != -1) {
        this.uploadProgress.totalCount = data.uploadCount.EntitiesToPublishCount
      }
      this.uploadProgress.successCount = data.uploadCount.SuccessfulPublishCount
      this.uploadProgress.failureCount = data.uploadCount.FailedPublishCount
    },
    getAdCreationSheetCallback: function (response) {
      this.adCreationSheet.readyToView = true;
      this.adCreationSheet.data = response.Sheet;
      this.newSheet = response.Sheet;
      this.submissions = response.Submissions;
      var submission = this.submissions.filter(x => x.Id == parseInt(this.currentSubmissionId))[0];
      if (this.currentSubmissionId > 0 && submission) {
        this.adCreationSheet.data = submission.Sheet;
        this.$emit('submissionChanged', submission);
      } else if (this.currentSubmissionId > 0) {
        window.alert(`Submission with Id ${this.currentSubmissionId} does not exists!`);
        this.$router.replace({ path: `/new` });
      } else if (response.Sheet.PreviousSheet != null && response.Sheet.PreviousSheet.ViewableLink != '') {
        this.actionSummary.show = true;
        this.actionSummary.message = ` Sheet has been updated to a newer version, please use <a href="${response.Sheet.PreviousSheet.ViewableLink}" target="_blank">this link</a> to migrate any data from previous sheet.`;
        this.actionSummary.type = "success";
      } else {
        this.fetchDataFromSheet();
      }
    },
    publish: function (chunkedRequest = false) {
      this.actionSummary.show = false;
      this.actionSummary.message = '';
      this.publishInProgress = true;
      if (this.isWizardFlow) {
        EventBus.$emit("CampainLauncher-BulkExport-App-publishInProgress", true);
      }
      // Object.keys(this.creativesToPublish).forEach(async (key, index) => {
      //   this.creativesToPublish[key].forEach(async (creative) => {
      //     let imageKeys = Object.keys(creative).filter(x => x.indexOf("Image") + 1)
      //     imageKeys.forEach(async (imagekey) => {
      //       if (creative[imagekey] != undefined && (creative[imagekey].indexOf("http") != -1 || creative[imagekey].indexOf("www") != -1)) {
      //         let response = await this.UploadImageToS3(creative[imagekey])
      //         if(response == false){

      //         }else{
      //           creative[imagekey] = 'DXC-'+Number.parseInt(deltax.businessProfileId).toString(36) + '-'+response.data.files[0].imageId
      //         }
      //       }
      //     })
      //   })
      // })
      if (this.isCampaignLuncher) {
        if (this.selectedPublisher == this.publisher.Adwords) {
          if (!this.authFlowExists) {
            this.editPublishStageData().then(() => {
              this.initiatePublishRequest();
            }).catch(() => {
              this.actionSummary.show = true;
              this.actionSummary.message = "An error occurred while updating the set up";
              this.actionSummary.type = "danger";
              this.publishInProgress = false;
            });
          } else {
            this.initiatePublishRequest();
          }
        } else if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts || this.selectedPublisher == this.publisher.AMS.SponsoredBrands || this.selectedPublisher == this.publisher.AMS.SponsoredDisplay) {
          var data = {};
          if (this.selectedPublisher == this.publisher.AMS.SponsoredBrands) {
            data = {
              id: this.amsEntitiesResponse.data.requestId,
              userId: parseInt(this.deltax.userId),
              creativeData: JSON.stringify(this.amsSdEntities),
              mediaPlanId: parseInt(this.mediaPlanId),
              campaignPublishDataLogId: parseInt(this.campaignPublishDataLogId),
              configId: this.selectedCampaignLauncherConfigId
            }
          } else {
            data = {
              id: this.amsEntitiesResponse.data.requestId,
              userId: parseInt(this.deltax.userId),
              creativeData: JSON.stringify(this.tableData),
              mediaPlanId: parseInt(this.mediaPlanId),
              campaignPublishDataLogId: parseInt(this.campaignPublishDataLogId),
              configId: this.selectedCampaignLauncherConfigId
            }
          }
          this.apiService.PublishRequestToApi(
            data,
            this.amsPublishSuccessCallback,
            this.publishFailureCallback,
            this.publishDoneCallback
          );
        } else if (this.selectedPublisher == this.publisher.DV360) {
          let allCreatives = this.getAllCreatives();
          let data = {
            id: this.dv360EntitiesResponse.data.requestId,
            userId: parseInt(this.deltax.userId),
            mediaPlanId: parseInt(this.mediaPlanId),
            campaignPublishDataLogId: parseInt(this.campaignPublishDataLogId),
            creativeData: JSON.stringify(allCreatives),
            configId: this.selectedCampaignLauncherConfigId
          }
          this.apiService.PublishRequestToApi(
            data,
            this.dv360PublishSuccessCallback,
            this.publishFailureCallback,
            this.dv360publishDoneCallback
          );
        } else if (this.selectedPublisher == this.publisher.Facebook) {
          if (!chunkedRequest) {
            this.fbEntityStatusCount = null;
            this.uploadProgress = {
              toShow: this.deltax.isBatchUploadEnabled,
              successCount: 0,
              failureCount: 0,
              totalCount: -1
            }
          }

          let allCreatives = this.getAllCreatives();

          if (!this.isPromotionMode) {
            let ldLabelsMap = {}
            for (let adRows of Object.values(this.creativesToPublish)) {
              adRows.forEach(row => {
                for (let [hotColName, value] of Object.entries(row)) {
                  const ldColNameBeginsWith = "HOT_LD_";
                  const charsToSkip = ldColNameBeginsWith.length;
                  if (hotColName.startsWith(ldColNameBeginsWith) && value) {
                    let labelDimensionName = hotColName.substring(charsToSkip);
                    if (!ldLabelsMap[labelDimensionName]) {
                      ldLabelsMap[labelDimensionName] = new Set();
                    }
                    ldLabelsMap[labelDimensionName].add(value);
                  }
                }
              })
            }
            if (Object.keys(ldLabelsMap).length > 0) {
              let request = [];
              for (let [labelTypeName, labels] of Object.entries(ldLabelsMap)) {
                request.push({
                  name: "LABELS",
                  labelTypeName,
                  value: [...labels].join(",")
                })
              }
              this.apiService.UpdateLabelDimensionLabels(request);
            }
          }

          if (this.deltax.isDeltaxApiFlowEnabled) {
            let dataLogId = parseInt(this.campaignPublishDataLogId);
            if (this.changedData && Object.keys(this.changedData).length != 0) {
              this.updateEditedSummaryData()
            }
            let data = {
              userId: parseInt(this.deltax.userId),
              mediaPlanId: parseInt(this.mediaPlanId),
              configId: this.selectedCampaignLauncherConfigId,
              campaignPublishDataLogId: dataLogId > 0 ? dataLogId : null,
              businessProfilesSearchEngineId: parseInt(this.businessProfilesSearchEngineId),
              isPromotionCampaignLaunch: this.isPromotionCampaignLaunch ? this.isPromotionCampaignLaunch : this.isPromotionMode,
              currentVersionOfGeneratedEntities: this.currentVersionOfGeneratedEntities,
              chunkPublishId: this.chunkPublishId,
              creativeData: JSON.stringify(allCreatives),
              IsRetryEnabled: this.isRetryEnabled,
              editedSummaryData: this.editedSummaryData,
              IsCampaignPublishOnly: this.isCampaignPublishOnly
            }
            if (this.campaignPublishDataLogId > 0) {
              data.id = this.campaignPublishDataLogId;
              data.requestId = this.requestId;
            } else {
              data.id = this.requestId;
              data.requestId = this.redisKey;
            }
            this.apiService.PublishRequestToApi(
              data,
              (response) => {
                this.publishSuccessCallback(response.data.data)
                this.publishDoneCallback(response.data.data);
              },
              (error) => {
                this.publishFailureCallback(error)
                this.publishDoneCallback();
              },
              null,
              true
            );
          } else {
            this.apiService.PublishToApi(
              this.publishSummary,
              this.requestId,
              this.publishSuccessCallback,
              this.publishFailureCallback,
              this.publishDoneCallback,
              this.selectedCampaignLauncherConfigId,
              this.mediaPlanId,
              this.businessProfilesSearchEngineId,
              allCreatives,
              this.mediaPlanLineItems,
              this.campaignPublishDataLogId,
              this.getMediaPlanName(this.mediaPlanId),
              this.isPromotionCampaignLaunch ? this.isPromotionCampaignLaunch : this.isPromotionMode,
              this.currentVersionOfGeneratedEntities,
              this.chunkPublishId
            );
          }
        } else if (this.selectedPublisher == this.publisher.LinkedIn) {
          let allCreatives = this.getAllCreatives();
          let data = {
            id: this.linkedInEntitiesResponse.data.requestId,
            userId: parseInt(this.deltax.userId),
            creativeData: JSON.stringify(allCreatives),
            mediaPlanId: parseInt(this.mediaPlanId),
            campaignPublishDataLogId: parseInt(this.campaignPublishDataLogId),
            configId: this.selectedCampaignLauncherConfigId,
            editedSummaryData: this.editedSummaryData
          }
          this.apiService.PublishRequestToApi(
            data,
            this.linkedInPublishSuccessCallback,
            this.publishFailureCallback,
            this.publishDoneCallback
          );
        } else if (this.selectedPublisher == this.publisher.Twitter) {
          let allCreatives = this.getAllCreatives();
          let data = {
            id: this.twitterEntitiesResponse.data.requestId,
            userId: parseInt(this.deltax.userId),
            creativeData: JSON.stringify(allCreatives),
            mediaPlanId: parseInt(this.mediaPlanId),
            campaignPublishDataLogId: parseInt(this.campaignPublishDataLogId),
            configId: this.selectedCampaignLauncherConfigId
          }
          this.apiService.PublishRequestToApi(
            data,
            this.twitterPublishSuccessCallback,
            this.publishFailureCallback,
            this.publishDoneCallback
          );
        } else if (this.selectedPublisher == this.publisher.Moloco) {
          var postData = {
            "submissionId": this.currentSubmissionId || undefined
          }
          this.set_publishInProgress(true)
          this.apiService.UploadToAPi(
            deltax.businessProfileId,
            this.molocoCampaignLauncherPublishDataId,
            postData,
            this.molocoPublishSuccessCallback,
            this.publishFailureCallback,
            this.publishDoneCallback
          );
        }
      } else {
        this.apiService.PublishToApi(this.publishSummary,
          this.requestId,
          this.publishSuccessCallback,
          this.publishFailureCallback,
          this.publishDoneCallback);
      }

      if (this.selectedPublisher == this.publisher.Facebook && !chunkedRequest) {
        let isDxApiUpload = this.deltax.isDeltaxApiFlowEnabled;
        let isSubmission = this.campaignPublishDataLogId > 0;
        let redisCacheId = this.requestId;
        if (isDxApiUpload && !isSubmission) {
          redisCacheId = this.redisKey;
        }
        this.apiService.GetPublishStatus(
          {
            requestId: redisCacheId,
            useEnvPrefix: isDxApiUpload
          },
          this.isPublishInProgress,
          this.getPublishStatusCallback,
          !this.isPublishInProgress()
        );
      }
    },
    initiatePublishRequest: function () {
      let data = {
        id: this.adwordsEntitiesResponse.requestId,
        userId: parseInt(this.deltax.userId),
        mediaPlanId: parseInt(this.mediaPlanId),
        configId: this.selectedCampaignLauncherConfigId,
        campaignPublishDataLogId: parseInt(this.campaignPublishDataLogId),
        isNewFlow: this.deltax.isAdwordsCampaignLaucherFlow,
        isPromotionCampaignlaunch: this.isWizardFlow
      };
      this.apiService.PublishRequestToApi(
        data,
        this.adwordsPublishSuccessCallback,
        this.publishFailureCallback,
        this.publishDoneCallback
      );
    },
    getEntityStatusIcon: function (status) {
      if (status == "Published") {
        return "fa fa-check-circle grey";
      } else if (status == "Success") {
        return "fa fa-check-circle green";
      } else if (status == "Failed") {
        return "fa fa-exclamation-triangle red";
      } else if (status == "New" || status == "Processing") {
        return "fa fa-clock-o yellow";
      } else if (status == "Edited") {
        return "fa fa-pencil-square green";
      } else if (status == "FailedEdit") {
        return "fa fa-pencil-square red";
      } else if (status == "AdEdited") {
        return "fa fa-pencil-square yellow";
      } else if (status == "Skipped") {
        return "fa fa-ban";
      } else if (status == "AdEditedPartial") {
        return "fa-pencil-square-o yellow";
      }
    },
    openSheet: function () {
      window.open(this.adCreationSheet.data.ViewableLink, "_blank");
    },
    fetchDataFromSheet: function () {
      this.elementsVerified = false;
      this.adCreationSheet.processing = true;
      this.actionSummary.show = false;
      this.actionSummary.message = '';
      this.apiService.GetAdPreview(JSON.stringify(this.adCreationSheet.data),
        this.getAdPreviewSuccessCallback,
        this.getAdPreviewFailureCallback,
        this.getAdPreviewDoneCallback);
    },
    refreshSheetSuccessCallback: function () {
      this.adCreationSheet.refreshing = false;
    },
    refreshSheetFailureCallback: function () {
      this.adCreationSheet.refreshing = false;
      this.actionSummary.show = true;
      this.actionSummary.message = " Failed to refresh the sheet meta data.";
      this.actionSummary.type = "danger";
    },
    refreshSheet: function () {
      this.adCreationSheet.refreshing = true;
      this.actionSummary.show = false;
      this.actionSummary.message = '';
      this.apiService.RefreshSheet(this.adCreationSheet.data.SheetId, this.refreshSheetSuccessCallback, this.refreshSheetFailureCallback);
    },
    updateIdsDoneCallback: function (status) {
      if (status) {
        this.actionSummary.show = true;
        this.actionSummary.message += " Sheet has been updated with the published Ids.";
        this.actionSummary.type = "success";
      }
    },
    syncCampaignsDoneCallback: function (status) {
      if (status) {
        this.actionSummary.show = true;
        this.actionSummary.message += ` Your campaigns have been pushed successfully. They will be available on the <a href='${this.campaignsGridUrl}'>Campaigns page</a> shortly.`;
        this.actionSummary.type = "success";
      }
    },
    copySheetUrl: function (e) {
      var field = document.querySelector("#sheetUrl");
      field.select();
      document.execCommand("copy");
      e.target.focus();
      setTimeout(this.closeTooltip, 2000);
    },
    closeTooltip: function (e) {
      this.$refs.clipboardTooltip.$data.show = false;
    },
    displaySubmissions: function () {
      this.currentSubmissionId = this.submissions[0].Id;
    },
    displayNewSheet: function () {
      this.$router.replace({ path: `/new` });
      this.adCreationSheet.data = this.newSheet;
      this.fetchDataFromSheet();
      this.currentSubmissionId = 0;
    },
    captureElementsTabAndSubmit: async function () {
      this.submittedForReview = true;
      var nodes = $("#elements");
      var elementsHtml = encodeURIComponent(nodes.html())
      this.editPublishStageData().then(() => {
        this.submitForReview(elementsHtml);
      }).catch(() => {
        this.actionSummary.show = true;
        this.actionSummary.message = "An error occurred while updating the set up";
        this.actionSummary.type = "danger";
        this.submissionsError = true;
      });
    },
    editPublishStageData: function () {
      return new Promise((resolve, reject) => {
        if (this.selectedPublisher == this.publisher.Adwords) {
          let editedSummaryData = [];
          Object.keys(this.editedCampaignBid).forEach(key => {
            let editedBid = {
              "entityType": this.adwordsEnums.entityType.Campaign,
              "newIdentifier": key,
              "bid": this.editedCampaignBid[key]
            };
            editedSummaryData.push(editedBid);
          });

          if (editedSummaryData.length > 0) {
            var postData = {
              "editedSummaryData": JSON.stringify(editedSummaryData),
              "searchEngineId": this.selectedPublisher
            };

            ApiService.EditPublishStageData(
              deltax.businessProfileId,
              this.adwordsEntitiesResponse.requestId,
              postData
            ).then(() => {
              resolve();
            }).catch(error => {
              reject(error);
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    submitForReview: function (elementsTabHtml = "", submissionId) {
      if (this.selectedPublisher == this.publisher.Moloco) {
        var postData = {
          "authFlow": JSON.stringify(this.updatedAuthFlow),
          "submissionName": this.submissionName,
          "submissionId": submissionId || this.currentSubmissionId || undefined
        }
        ApiService.ValidateSubmission(
          deltax.businessProfileId,
          this.molocoCampaignLauncherPublishDataId,
          postData,
          this.molocoSubmitForReviewCallback
        )
      } else {
        if (this.changedData && Object.keys(this.changedData).length != 0) {
          this.updateEditedSummaryData()
        }
        let allCreatives = this.getAllCreatives()
        let userId = parseInt(this.deltax.userId)
        let portalId = Number(this.deltax.portalId) || 0
        let publishStagetDataId = this.selectedPublisher == this.publisher.Adwords ? this.adwordsEntitiesResponse.requestId : null
        this.apiService.AddSubmission(
          this.aggregratepublishSummary,
          userId,
          portalId,
          this.selectedCampaignLauncherConfigId,
          allCreatives,
          this.mediaPlanId,
          this.updatedAuthFlow,
          this.submissionName,
          this.getMediaPlanName(this.mediaPlanId),
          this.submitForReviewCallback,
          elementsTabHtml,
          this.editedSummaryData,
          publishStagetDataId);
      }
    },
    submitForReviewCallback: function (response) {
      this.submittedForReview = false;
      response.data["SearchEngineTypeId"] = this.selectedPublisher
      this.submissions.unshift(response.data);
      window.alert("Sheet submitted for review successfully. You can access it through submissions");
      this.set_displayExistingSubmissions(true);
      this.submissionName = "";
    },
    approve: function () {
      this.submittedForApproval = true;
      this.$emit('userApproved', { approval: true, comment: "hi" });
    },
    reject: function () {
      this.submittedForRejection = true;
      this.$emit('userApproved', { approval: false, comment: this.rejectionComment });
    },
    updateSubmission: function () {
      let userId = parseInt(this.deltax.userId)
      this.apiService.UpdateSubmission(this.currentSubmissionId, this.authFlow, this.mediaPlanId, userId, this.updateSubmissionCallback);
    },
    updateSubmissionCallback: function () {
      this.getSubmission(this.currentSubmissionId);
      this.submittedForApproval = false;
      this.submittedForRejection = false;
      this.showModal = false;
      this.rejectionComment = '';
    },
    allSBBudgetsVerified (isVerified) {
      this.isBudgetValid = isVerified;
    },
    setElementsVerified: function (status) {
      if (this.isEndedMediaPlan && !this.isPromotionMode) {
        this.elementsTooltip = "This Media plan has ended";
      } else if (this.isEndedLineItems && !this.isPromotionMode) {
        if (this.endedLineItems.length === 1) {
          this.elementsTooltip = `Lineitem "${this.endedLineItems[0].name}" has ended`;
        } else {
          this.elementsTooltip = `Lineitems "${this.endedLineItems.map(x => x.name).join(', ')}" have ended`;
        }
      } else if (this.adsetBudgetError.isAdsetBudgetInvalid && !this.isPromotionMode) {
        this.elementsTooltip = this.adsetBudgetError.errorMessage
      } else {
        this.elementsVerified = status.reduce((s, v) => s && v.value, true);
        if (this.isTestModeEnabled) {
          this.elementsTooltip = "Publish is disabled since test mode is enabled for this account";
        } else {
          this.elementsTooltip = this.elementsVerified ? '' : `Please verify ${status.filter(x => !x.value).map(x => x.label).join(', ')} in Elements `;
        }
      }
      if (this.elementsTooltip == '' && this.selectedPublisher == this.publisher.AMS.SponsoredBrands && !this.isBudgetValid) {
        this.elementsTooltip = "One or more Line items have Invalid budget";
      }
    },
    age: function (targeting) {
      return `${targeting.age_min} - ${targeting.age_max ? targeting.age_max : "No Max"}`
    },
    gender: function (targeting) {
      var genders = targeting.genders;
      if (genders) {
        if (genders.includes(1) && genders.includes(2)) { return "All"; } else if (genders.includes(1)) { return "Male"; } else if (genders.includes(2)) { return "Female"; }
      } else {
        return "All";
      }
    },
    excludedCustomAudienceIds: function (targeting) {
      var excludedCustomAudiences = [];
      if (targeting.excluded_custom_audiences) {
        for (let i = 0; i < targeting.excluded_custom_audiences.length; i++) {
          if (targeting.excluded_custom_audiences[i].hasOwnProperty('name') && targeting.excluded_custom_audiences[i].hasOwnProperty('id')) {
            excludedCustomAudiences.push(targeting.excluded_custom_audiences[i].id + "(" + targeting.excluded_custom_audiences[i].name + ")");
          } else {
            excludedCustomAudiences.push(targeting.excluded_custom_audiences[i].id)
          }
        }
      }
      return excludedCustomAudiences;
    },
    includedCustomAudienceIds: function (targeting) {
      var includedCustomAudiences = [];

      if (targeting.custom_audiences) {
        for (let i = 0; i < targeting.custom_audiences.length; i++) {
          if (targeting.custom_audiences[i].hasOwnProperty('name') && targeting.custom_audiences[i].hasOwnProperty('id')) {
            includedCustomAudiences.push(targeting.custom_audiences[i].id + "(" + targeting.custom_audiences[i].name + ")");
          } else {
            includedCustomAudiences.push(targeting.custom_audiences[i].id)
          }
        }
      }
      return includedCustomAudiences;
    },
    countries: function (targeting, isExcluded = false) {
      var countriesResult = [];
      if (targeting.geo_locations) {
        if (isExcluded) {
          if (targeting.excluded_geo_locations && targeting.excluded_geo_locations.countries) {
            countriesResult = targeting.excluded_geo_locations.countries
          }
        } else {
          if (targeting.geo_locations.countries) {
            countriesResult = targeting.geo_locations.countries
          }
        }
      }
      return countriesResult;
    },
    regions: function (targeting, isExcluded = false) {
      var regionsResult = [];
      if (targeting.geo_locations) {
        if (isExcluded) {
          if (targeting.excluded_geo_locations && targeting.excluded_geo_locations.regions) {
            regionsResult = targeting.excluded_geo_locations.regions.map(x => x.name)
          }
        } else {
          if (targeting.geo_locations.regions) {
            regionsResult = targeting.geo_locations.regions.map(x => x.name)
          }
        }
      }
      return regionsResult;
    },
    cities: function (targeting, isExcluded = false) {
      var citiesResult = [];
      if (isExcluded) {
        if (targeting.excluded_geo_locations && targeting.excluded_geo_locations.cities) {
          var excCitiesList = targeting.excluded_geo_locations.cities;
          $.each(excCitiesList, function (index, item) {
            var cities = [];
            if (item.name) { cities.push(item.name); }
            if (item.region) { cities.push(item.region); }
            if (item.country) { cities.push(item.country); }
            citiesResult.push('[' + cities.join(',') + ']');
          })
        }
      } else {
        if (targeting.geo_locations && targeting.geo_locations.cities) {
          var citiesList = targeting.geo_locations.cities;
          $.each(citiesList, function (index, item) {
            var cities = [];
            if (item.name) { cities.push(item.name); }
            if (item.region) { cities.push(item.region); }
            if (item.country) { cities.push(item.country); }
            citiesResult.push('[' + cities.join(',') + ']');
          })
        }
      }

      return citiesResult;
    },
    locationTypes: function (targeting) {
      if (targeting.geo_locations && targeting.geo_locations.location_types) {
        return targeting.geo_locations.location_types.join(", ");
      } else {
        return "-"
      }
    },
    zips: function (targeting, isExcluded = false) {
      if (isExcluded) {
        if (targeting.excluded_geo_locations && targeting.excluded_geo_locations.zips) {
          return targeting.excluded_geo_locations.zips.map(x => `${x.name ? x.name : ''} Zips : ${x.key}`);
        } else {
          return [];
        }
      } else {
        if (targeting.geo_locations && targeting.geo_locations.zips) {
          return targeting.geo_locations.zips.map(x => `${x.name ? x.name : ''} Zips : ${x.key}`);
        } else {
          return [];
        }
      }
    },
    customLocations: function (adgroup, isExcluded = false) {
      let targeting = adgroup.AdditionalData.targeting
      if (isExcluded) {
        if (!targeting.excluded_geo_locations || !Array.isArray(targeting.excluded_geo_locations.custom_locations)) {
          return []
        }
      } else {
        if (!targeting.geo_locations || !Array.isArray(targeting.geo_locations.custom_locations)) {
          return []
        }
      }

      let storeName = (adgroup.RowData && adgroup.RowData.TargetName) ? adgroup.RowData.TargetName.split("-").slice(-1) : "";
      if (storeName == "") { storeName = "Store Location" }

      if (isExcluded) {
        return targeting.excluded_geo_locations.custom_locations
          .map(x => `${x.name ? x.name : storeName}: (latitude: ${x.latitude}, longitude: ${x.longitude}) (${x.radius} ${x.distance_unit} radius)`)
      } else {
        return targeting.geo_locations.custom_locations
          .map(x => `${x.name ? x.name : storeName}: (latitude: ${x.latitude}, longitude: ${x.longitude}) (${x.radius} ${x.distance_unit} radius)`)
      }
    },
    pageSets: function (adgroup) {
      if (adgroup.RowData && adgroup.RowData.PageSetId && adgroup.RowData.PageSetCount != 0) {
        return `${adgroup.RowData.PageSetName} (${adgroup.RowData.PageSetCount} pages)`
      } else {
        return null;
      }
    },
    setFinalGroupedEntities: function (finalGroupedEntities) {
      var self = this;
      var adsData = [];
      var adgroupsData = [];
      if (finalGroupedEntities != undefined && finalGroupedEntities != null) {
        this.publishSummary = this.summaryPanelData = finalGroupedEntities
        $.each(this.publishSummary, function (i1, campaignGroup) {
          $.each(campaignGroup.ChildEntities, function (i2, adgroup) {
            adgroup = { ...adgroup,
              gender: self.gender(adgroup.AdditionalData.targeting),
              age: self.age(adgroup.AdditionalData.targeting),
              countries: self.countries(adgroup.AdditionalData.targeting),
              regions: self.regions(adgroup.AdditionalData.targeting),
              cities: self.cities(adgroup.AdditionalData.targeting),
              locationTypes: self.locationTypes(adgroup.AdditionalData.targeting),
              customLocations: self.customLocations(adgroup),
              zips: self.zips(adgroup.AdditionalData.targeting),
              excludedCountries: self.countries(adgroup.AdditionalData.targeting, true),
              excludedRegions: self.regions(adgroup.AdditionalData.targeting, true),
              excludedCities: self.cities(adgroup.AdditionalData.targeting, true),
              excludedLocationTypes: self.locationTypes(adgroup.AdditionalData.targeting, true),
              excludedCustomLocations: self.customLocations(adgroup, true),
              excludedZips: self.zips(adgroup.AdditionalData.targeting),
              excludedCustomAudienceIds: self.excludedCustomAudienceIds(adgroup.AdditionalData.targeting),
              includedCustomAudienceIds: self.includedCustomAudienceIds(adgroup.AdditionalData.targeting)
            };
            let adsetStartDate = adgroup.RowData.AdgroupStartDate != null ? `${new Date(adgroup.RowData.AdgroupStartDate).toString('MM/dd/yyyy h:mm tt')}` : "Now"
            let adsetEndDate = adgroup.RowData.AdgroupEndDate != null ? `${new Date(adgroup.RowData.AdgroupEndDate).toString('MM/dd/yyyy h:mm tt')}` : "Continuous"
            let schedule = "";
            if (adsetStartDate == "Now" && adsetEndDate == "Continuous") {
              schedule = "All Time";
            } else {
              schedule = adsetStartDate + " - " + adsetEndDate
            }
            let targetData = Object.values(self.savedTargetsMapperByBpseId).flat().find(x => x.Id === adgroup.RowData.DeltaXTargetId)
            var adgroupData = {
              "Adgroup": adgroup.Name,
              "CampaignName": campaignGroup.Name,
              "Bid": adgroup.RowData.AdgroupBidAmount || "Auto",
              "BudgetType": adgroup.RowData.BudgetType == 'daily_budget' ? "Daily Budget" : "Life Time Budget",
              "Budget": adgroup.RowData.Budget,
              "DeliveryType": adgroup.RowData.DeliveryType ? adgroup.RowData.DeliveryType : "Standard",
              "Schedule": schedule,
              "AdgroupData": adgroup,
              "TargetId": adgroup.RowData.DeltaXTargetId,
              "Platforms": adgroup.AdditionalData.placements.publisher_platforms,
              "BpCurrency": adgroup.AdditionalData.bp_currency,
              "Bpse": adgroup.RowData.BpseId,
              "Ads": adgroup.ChildEntities.length || 0,
              "OpenAdPreview": false,
              "SpendLimit": adgroup.RowData.SpendLimit,
              "TargetInformation": targetData.TargetInformation,
              // "AdData":adgroup.ChildEntities[i2]
              "PageSets": self.pageSets(adgroup)
            };
            adgroupsData.push(adgroupData);
            $.each(adgroup.ChildEntities, function (i3, ad) {
              let adCreative = ad.ChildEntities[0];
              var landingPages = null
              if (ad.Status == "AdEdited" || ad.status == "AdEditedPartial") {
                landingPages = self.getPropValues(ad.ApiData.creative, 'link')
              }
              let creative = {}
              creative.HotRowIdEdit = ad.ChildEntities[0].Name + (ad.Id ? ad.Id : '')
              creative.CreativeName = ad.ChildEntities[0].Name
              creative.HotRowId = ad.ChildEntities[0].Name.slice(4)
              var assets = JSON.parse(self.accounts.find(
                x => x.Id == self.selectedCampaignLauncherConfig.bpseId).AccountAssets)
              var facebook = assets.Data ? assets.Data[0].Pages.find(x => x.Id === self.selectedCampaignLauncherConfig.facebookPageId) : null;
              creative.PageName = facebook && facebook.Name;
              creative.PageId = self.selectedCampaignLauncherConfig.facebookPageId
              creative.LandingPages = ad.AdditionalData.LandingPages
              if (creative.LandingPages && creative.LandingPages.length) {
                var instantExperiences = creative.LandingPages.filter(landingPage => landingPage.includes('fb.com/canvas_doc'));
                if (instantExperiences && instantExperiences.length) {
                  creative["InstantExperience"] = {}
                  creative["InstantExperience"]["Id"] = instantExperiences[0].split('/').pop()
                }
              }
              creative.adLevelLandingPages = landingPages != null ? [...new Set(landingPages)] : null
              var adData = {
                "CampaignName": campaignGroup.Name,
                "AdgroupName": adgroup.Name,
                "AdId": ad.Id,
                "Ad": ad.Name,
                "AdStatus": ad.Status,
                "Creative": creative,
                "Bid": adgroup.RowData.AdgroupBidAmount || "Auto",
                "Budget": adgroup.RowData.Budget,
                "AdData": ad,
                "AdgroupData": adgroup,
                "OpenAdPreview": false,
                "SpendLimit": adgroup.RowData.SpendLimit,
                "BpCurrency": adgroup.AdditionalData.bp_currency,
                "IsAdPresent": true
              }
              adsData.push(adData);
            });
            if (adgroup.ChildEntities.length == 0) {
              var adData = {
                "CampaignName": campaignGroup.Name,
                "AdgroupName": adgroup.Name,
                "Ad": "",
                "AdId": "",
                "Creative": {},
                "Bid": adgroup.RowData.AdgroupBidAmount || "Auto",
                "Budget": adgroup.RowData.Budget,
                "AdData": {},
                "AdgroupData": adgroup,
                "OpenAdPreview": false,
                "IsAdPresent": false,
                "SpendLimit": adgroup.RowData.SpendLimit,
                "BpCurrency": adgroup.AdditionalData.bp_currency
              }
              adsData.push(adData)
            }
          });
        });
      }
      this.adsData = adsData;
      this.adgroupsData = adgroupsData;
      if (this.adsData.length == 0) {
        this.structureData = adgroupsData
        this.columns = ['CampaignName', 'Adgroup', 'SpendLimit', 'Bid']
      } else {
        this.structureData = adsData
        this.columns = ['CampaignName', 'AdgroupName', 'SpendLimit', 'Bid', 'Ad']
      }
    },
    getPropValues (obj, prop) {
      let res = [];
      JSON.stringify(obj, (key, value) => {
        if (key === prop) {
          res.push(value);
        }
        return value;
      })
      return res;
    },
    getAllCreatives () {
      if (!this.showNewSheet && !this.publishersSupportingNewLauncherFlow.includes(this.selectedPublisher)) {
        return this.submissionCreativesJson;
      }
      let newPublishedCreatives = {}
      this.adFormats.forEach(adFormat => {
        newPublishedCreatives[adFormat] = [
          ...((adFormat in this.publishedCreatives) ? this.publishedCreatives[adFormat].filter(x => !x["HOT_IsNotPublished"] || x["HOT_IsNotPublished"].toString() == "false") : []),
          ...((adFormat in this.creativesToPublish) ? this.creativesToPublish[adFormat] : [])
        ]
      })
      return newPublishedCreatives;
    },
    updatePublishedCreatives () {
      let newPublishedCreatives = this.getAllCreatives();
      if (!this.isPromotionMode) {
        this.$store.commit("set_publishedCreatives", newPublishedCreatives)
        this.set_existingPostAds([]);
      }
      this.$store.commit("set_creativesToPublish", {})
      let publishedCreativesByCampaignConfigId = this.publishedCreativesByCampaignConfigId;
      publishedCreativesByCampaignConfigId[this.selectedCampaignLauncherConfigId] = { creativeJson: JSON.stringify(newPublishedCreatives) };
      this.$store.commit("set_publishedCreativesByCampaignConfigId", publishedCreativesByCampaignConfigId);
    },
    ShowPreviousStep () {
      this.resetEntitiesResponse()
      const isCurrentlyShowingSubmission = this.currentSubmissionId > 0;
      this.currentSubmissionId = 0;
      this.campaignPublishDataLogId = 0;
      this.set_isCreationFlow(false);
      this.$emit('backToSetupClicked');
      if (this.authFlowExists && isCurrentlyShowingSubmission) {
        this.$emit('submissionChanged', { Id: 0 });
        var currentConfigId = this.selectedCampaignLauncherConfigId
        EventBus.$emit("campaignLauncherApp-updateConfigIdFromUrl", 0);
        this.$nextTick(() => {
          EventBus.$emit("campaignLauncherApp-updateConfigIdFromUrl", currentConfigId);
        })
      }
      this.$store.commit("SET_SHOW_PREVIEW", false);
      if (!this.isPromotionMode) {
        this.$router.replace({ path: "/new", query: { mediaPlan: this.mediaPlanId, publisher: this.selectedPublisher, config: this.selectedCampaignLauncherConfigId } });
      }
      if (this.selectedPublisher == 10 || this.selectedPublisher == 11) {
        let publishedCount = 0
        this.amsEntitiesResponse.data.amsEntities.forEach(campaign => {
          if (campaign.status == 8) {
            publishedCount++;
          }
        });
        if (publishedCount > 0) {
          this.set_amsLineItemsUpdate(true);
        }
      } else if (this.selectedPublisher == 1 || this.selectedPublisher == 9) {
        let self = this;
        if (self.selectedPublisher == 1 && this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType['Performance Max']) {
          self.$store.state.fetchAdwordsEditTab = true;
          self.$store.state.fetchAdwordsPublishTab = true;
        } else {
          this.apiService.GetPublishedData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, 1)
            .then(response => {
              if (response && response.data && response.data.data.creativeJson) {
                this.$store.commit("set_publishedCreatives", JSON.parse(response.data.data.creativeJson));
                EventBus.$emit("adsheetWidget-configSwitched");
              }
              if (self.selectedPublisher == 1) {
                self.$store.state.fetchAdwordsEditTab = true;
              }
            });
        }
      }
      this.setEditedSummaryData("")
      this.set_editedCampaignBid({});
      this.resetAdsetError()
    },
    getSubmission (to) {
      this.submissionLoading = true;
      var self = this;
      const isNewLauncherFlow = this.$route.path.includes('/review-summary') || this.$route.path.includes('/review-submission')
      this.apiService.GetSubmission(to, isNewLauncherFlow).then(e => {
        this.set_renderSubmissionSummary(false)
        e = e.data
        let submission = JSON.parse(e.submission);
        let campaignLauncherConfigurationId = submission.CampaignLauncherConfigurationId
        if (campaignLauncherConfigurationId in this.campaignLauncherConfigs) {
          this.set_selectedCampaignLauncherConfig(
            JSON.parse(this.campaignLauncherConfigs[campaignLauncherConfigurationId].config)
          );
        } else {
          this.set_selectedCampaignLauncherConfig({});
        }
        if (!isNewLauncherFlow) {
          this.SET_BPSEID(this.campaignLauncherConfigBpseIdMapper[campaignLauncherConfigurationId].BpseId);
          var submissionEntities = JSON.parse(submission.PublishJson);
        }

        this.requestId = e.requestId;
        this.campaignPublishDataLogId = submission.CampaignPublishDataLogId;
        this.setEditedSummaryData(submission.EditedSummaryData)
        this.setPublishedEditedSummaryData(submission.PublishedEditedSummaryData)
        if (submission.SearchEngineTypeId == this.publisher.Facebook) {
          this.apiService.SetLivePublishStatus(this.requestId, submission.PublishJson)
          self.submissionCreativesJson = [];
          var creativeJson = submission.CreativeJson && submission.CreativeJson != '' ? JSON.parse(submission.CreativeJson) : null;
          if (creativeJson) {
            this.submissionCreativesJson = creativeJson;
            this.apiService.Get(
              `${this.deltax.getImagesAndVideos}?bseId=${this.businessProfilesSearchEngineId}&accountId=${this.businessProfilesSearchEngineId}&createdOnDx=true`
            ).then(({ Data }) => {
              self.creativeMedia = Data;
            })
          }
          this.set_selectedPublisher(this.publisher.Facebook);
          this.setFinalGroupedEntities(submissionEntities);
          this.SET_FACEBOOK_ENTITIES(submissionEntities);
        } else if (submission.SearchEngineTypeId == this.publisher.DV360) {
          this.set_selectedPublisher(this.publisher.DV360)
          if (submissionEntities.data) {
            this.set_dv360EntitiesResponse(submissionEntities)
          } else {
            this.set_dv360EntitiesResponse({ data: submissionEntities })
          }
        } else if (submission.SearchEngineTypeId == this.publisher.Adwords) {
          this.set_selectedPublisher(this.publisher.Adwords)
          this.set_adwordsEntitiesResponse(submissionEntities)
        } else if (submission.SearchEngineTypeId == this.publisher.AMS.id && submissionEntities.data.amsEntities[0].campaignType == this.publisher.AMS.SponsoredBrands) {
          this.set_selectedPublisher(this.publisher.AMS.SponsoredBrands)
          this.set_amsEntitiesResponse(submissionEntities)
        } else if (submission.SearchEngineTypeId == this.publisher.AMS.id && submissionEntities.data.amsEntities[0].campaignType == this.publisher.AMS.SponsoredProducts) {
          this.set_selectedPublisher(this.publisher.AMS.SponsoredProducts)
          this.set_amsEntitiesResponse(submissionEntities)
        } else if (submission.SearchEngineTypeId == this.publisher.AMS.id && submissionEntities.data.amsEntities[0].campaignType == this.publisher.AMS.SponsoredDisplay) {
          this.set_selectedPublisher(this.publisher.AMS.SponsoredDisplay)
          this.set_amsEntitiesResponse(submissionEntities)
        } else if (submission.SearchEngineTypeId == this.publisher.LinkedIn) {
          this.set_selectedPublisher(this.publisher.LinkedIn)
          this.set_linkedInEntitiesResponse(this.getUnifiedSubmissionJSON(submissionEntities))
        } else if (submission.SearchEngineTypeId == this.publisher.Twitter) {
          this.set_selectedPublisher(this.publisher.Twitter)
          this.set_twitterEntitiesResponse(this.getUnifiedSubmissionJSON(submissionEntities))
        } else if (submission.SearchEngineTypeId == this.publisher.Moloco) {
          this.set_selectedPublisher(this.publisher.Moloco)
        }
        this.adCreationSheet.readyToView = true;
        this.set_isCreationFlow(false);
        this.submissionLoading = false;
        this.set_mediaPlanId(submission.MediaPlanId)
        var mediaPlanDetails = {
          Name: this.getMediaPlanName(submission.MediaPlanId),
          Id: submission.MediaPlanId
        }
        this.set_selectedMediaPlan(mediaPlanDetails)
        EventBus.$emit("campaignLauncherApp-updateMediaPlanIdFromUrl", mediaPlanDetails);
        if (submission.SearchEngineTypeId == 10) {
          this.set_isSpSubmissionFlow(true);
          EventBus.$emit("campaignLauncherApp-updatePublisherIdFromUrl", submissionEntities.data.amsEntities[0].campaignType);
        } else {
          EventBus.$emit("campaignLauncherApp-updatePublisherIdFromUrl", submission.SearchEngineTypeId);
        }
        this.$nextTick(() => {
          this.set_selectedCampaignLauncherConfigId(campaignLauncherConfigurationId);
          EventBus.$emit("campaignLauncherApp-updateConfigIdFromUrl", campaignLauncherConfigurationId);
          if (this.selectedPublisher == this.publisher.Moloco) {
            EventBus.$emit("app-fetchcampaignLauncherPublishDataId-for-Submission", true)
          }
        })
      });
    },
    // async UploadImageToS3 (url) {
    //   let response = await fetch(url);
    //   if (response.status !== 200) {
    //     throw new Error("Not a valid image url")
    //   }
    //   let imgBlob = await response.blob();
    //   if (!imgBlob.type.match(/image.*/)) {
    //     throw new Error("Not a valid image url")
    //   }
    //   let data = new FormData()
    //   let request = new XMLHttpRequest()
    //   let file = new File([imgBlob],this.getImageNameFromURL(url))
    //   data.append('file', file)
    //   console.log(file)
    //   console.log(typeof file)
    //   let axiosResponse = await axios.post(deltax.saveImageURL,data,bseId=this.businessProfilesSearchEngineId);
    //   if(axiosResponse.status == 200){
    //     return axiosResponse.data.files[0]
    //   }else{
    //     return false
    //   }
    // },
    getImageNameFromURL (url) {
      let index = url.lastIndexOf("/") + 1;
      return url.substr(index);
    },
    getUnifiedSubmissionJSON (submissionEntities) {
      if (!submissionEntities.data) {
        return { data: submissionEntities }
      } else {
        return submissionEntities
      }
    },
    getMediaPlanName (id) {
      if (this.isPromotionMode) {
        return this.promotionObject.name;
      }
      let allMediaPlans = this.mediaPlans;
      let selectedMediaPlan = allMediaPlans.find(function (element, index) {
        if (element.Id === id) { return true; }
      });
      return selectedMediaPlan.Name
    },
    async updatePromoStatus () {
      if (!this.isLivePromotion) {
        let promotion = await PromotionApiService.getPromotion(this.promotionId);
        if (!promotion.settings.testedSearchEngines.includes(this.selectedPublisher)) {
          promotion.settings.testedSearchEngines.push(this.selectedPublisher); // 5 -> Facebook Search Engine Id
        }
        PromotionApiService.patchPromotion(this.promotionId, {
          Settings: promotion.settings
        })
        EventBus.$emit("promotionPublished", promotion)
      }
    },
    updatePublishSummary (value) {
      if (value.updatedPublishSummary) {
        this.publishSummary = value.updatedPublishSummary;
      }
    },
    updateRnfPredictionsValid (value) {
      this.rnfPredictionsValid = value
    },
    updateRnfDataProcessing (value) {
      this.rnfDataProcessing = value
    },
    getBPUsers () {
      var self = this
      this.apiService.GetBPUsers().then(e => {
        self.users = e;
      })
    },
    updatedAuthFlowJSON (value) {
      if (value.authFlowJson) {
        if (value.authFlowJson.Stages[0].Factions[0].Users.length == 0 || value.authFlowJson.Stages[1].Factions[0].Users.length == 0) {
          this.submissionsError = true;
        } else {
          this.updatedAuthFlow = value.authFlowJson
          this.submissionsError = false
          this.setAuthFlows([value.authFlowJson])
        }
      }
      return false;
    },
    setSubmissionData (to, submission) {
      if (to > 0 && submission) {
        this.$emit('submissionChanged', submission);
        this.getSubmission(to);
        this.$router.replace({ path: `/submission/${to}` });
      } else if (to > 0 && this.adCreationSheet.readyToView) {
        window.alert(`Submission with Id ${this.currentSubmissionId} does not exists!`);
        this.displayNewSheet();
      }
    },
    computeEntities (isRetryEnabled, isCampaignPublishOnly) {
      if (isRetryEnabled != undefined && deltax.isDeltaxApiFlowEnabled && this.selectedPublisher == this.publisher.Facebook) {
        this.retryFlagSpinner = true;
        const publishDataLogId = this.campaignPublishDataLogId > 0 ? 0 : this.requestId
        if (publishDataLogId || this.campaignPublishDataLogId) {
          ApiService.GetEntitiesToPublishCount(deltax.businessProfileId, publishDataLogId, isRetryEnabled, this.campaignPublishDataLogId, isCampaignPublishOnly)
            .then(response => {
              if (response.data) {
                this.uploadProgress.successCount = 0;
                this.uploadProgress.totalCount = response.data.totalCount - response.data.successCount;
                this.uploadProgress.toShow = true;
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.retryFlagSpinner = false;
            })
        }
      }
    },
    resetAdsetError () {
      var errorObj = {}
      errorObj['isAdsetBudgetInvalid'] = false;
      errorObj['errorMessage'] = ''
      this.setAdsetBudgetError(errorObj)
    },
    updateEditedSummaryData () {
      if (this.editedSummaryData == '' || this.editedSummaryData == '{}') {
        this.setEditedSummaryData(JSON.stringify(this.changedData))
      } else {
        var editedSummaryDataCopy = JSON.parse(this.editedSummaryData)
        Object.keys(this.changedData).forEach(campaign => {
          if (editedSummaryDataCopy.hasOwnProperty(campaign)) {
            this.changedData[campaign].forEach((adset, index) => {
              var adsetIndex = editedSummaryDataCopy[campaign].findIndex(ele => ele.internalId == adset.internalId)
              if (adsetIndex != -1 && this.changedData[campaign][adsetIndex].internalId == adset.internalId) {
                editedSummaryDataCopy[campaign][adsetIndex]['adsetBudget'] = this.changedData[campaign][index]['adsetBudget']
                editedSummaryDataCopy[campaign][adsetIndex]['budgetType'] = this.changedData[campaign][index]['budgetType']
              } else {
                editedSummaryDataCopy[campaign].push(adset)
              }
            })
          }
        })
        this.setEditedSummaryData(JSON.stringify(editedSummaryDataCopy))
      }
    },
    ShowAdSheet () {
      this.$store.commit("SET_SHOW_PREVIEW", false);
      EventBus.$emit("app-showPreviousStep");
      if (this.isPromotionMode && this.promotion.status == 1) {
        EventBus.$emit("PromotionLaunchWizard-TestPublisherEnableNext", true);
      }
    },
    molocoSubmitForReviewCallback: function (response) {
      let res = response.data.data
      this.isSubmissionValid = res.isValid
      this.submittedForReview = false;
      var submissionObject = {}
      submissionObject["Id"] = res.submissionId
      submissionObject["Name"] = this.submissionName
      if (this.isSubmissionValid) {
        EventBus.$emit("app-fetchcampaignLauncherPublishDataId-for-Submission", false, res.submissionId);
        window.alert("Setup submitted for review successfully. You can access it through submissions");
        var submissionIndex = this.submissions.findIndex(submission => submission.Id == res.submissionId)
        if (submissionIndex != -1) {
          this.submissions.splice(submissionIndex, 1);
        }
        this.submissions.unshift(submissionObject);
        this.set_displayExistingSubmissions(true);
        this.submissionName = "";
      } else {
        window.confirm("There is an open submission for this setup that hasn't been published. Click OK to override the existing submission", "", (action) => {
          if (action) {
            this.submitForReview('', res.submissionId)
          }
        });
      }
    },
    getPublisherName (engineId) {
      if (engineId == 10) {
        return "AMS"
      }
      var publisher = this.publisherSupportedForSubmissions.find(publisher =>
        publisher.id == engineId
      )
      return publisher ? publisher.name : ""
    },
    resetEntitiesResponse () {
      this.SET_FACEBOOK_ENTITIES(null);
      this.set_amsEntitiesResponse({});
      this.set_dv360EntitiesResponse({});
      this.set_linkedInEntitiesResponse({});
      this.set_twitterEntitiesResponse({});
      this.set_adwordsEntitiesResponse({});
    }
  },
  watch: {
    '$route': function (to, from) {
      if (window.location.href.toLowerCase().includes('summary')) {
        this.set_summaryLocation(window.location.href);
      }
      var searchParams = new URLSearchParams((window.location.href.substring(window.location.href.indexOf('?') + 1)));
      if (searchParams.get('view') == 'Full') {
        this.viewType = "Full setup";
      }
    },
    'facebookEntities': function () {
      this.setFinalGroupedEntities(this.facebookEntities);
      this.$store.commit("set_configChangeLoading", false)
    },
    'currentSubmissionId': function (to, from) {
      var submission = this.submissions.filter(x => x.Id == to)[0];
      this.setSubmissionData(to, submission);
    },
    'submissionId': function (to, from) {
      this.currentSubmissionId = to;
      this.isRetryEnabled = deltax.isHub;
      this.computeEntities(this.isRetryEnabled, this.isCampaignPublishOnly);
    },
    'adCreationSheet.readyToView': function (to, from) {
      this.$emit('readyToView', to);
    },
    'authFlow': {
      handler (newValue) {
        this.updatedAuthFlow = this.authFlow
      },
      deep: true
    },
    selectedCampaignLauncherConfig: {
      handler () {
        if (!this.selectedCampaignLauncherConfig || Object.keys(this.selectedCampaignLauncherConfig).length == 0) {
          return;
        }

        if (this.selectedPublisher == this.publisher.DV360) {
          let creativeTypes = this.selectedCampaignLauncherConfig.creativeType.map(Number);
          if (creativeTypes.includes(dbmEnums.creativeType.Youtube)) {
            this.isDV360Trueview = true;
            this.dv360TrueviewPublishMode = "downloadSDF";
            if (this.dbmAccounts && this.dbmAccounts.length) {
              let account = this.dbmAccounts.find(x => x.id == this.selectedCampaignLauncherConfig.bpseId);
              this.isDV360MccAccount = account.isMccAccount;
            }
          } else {
            this.isDV360Trueview = false;
          }
        }
      },
      immediate: true,
      deep: true
    },
    'isRetryEnabled': function (value) {
      this.computeEntities(value, this.isCampaignPublishOnly);
    },
    'isCampaignPublishOnly': function (value) {
      this.computeEntities(this.isRetryEnabled, value);
    },
    'campaignPublishDataLogId': function () {
      this.computeEntities(this.isRetryEnabled, this.isCampaignPublishOnly);
    },
    rnfPredictionsValid: function (value) {
      this.actionSummary.show = !value;
      if (!value) {
        this.actionSummary.message = "One or more Reach & Frequency predictions are invalid";
        this.actionSummary.type = "warning";
      }
    },
    publishSummary: function () {
      this.showPublishCampaignsOnlyCheckbox = this.publishSummary.some(function (campaign) {
        return campaign.Id == ''
      });
      if (!this.showPublishCampaignsOnlyCheckbox) {
        this.isCampaignPublishOnly = false;
      }
    },
    actionSummary: {
      handler () {
        if (this.actionSummary.show && this.isPromotionMode && this.promotion.status == 1 && this.actionSummary.type !== 'danger') {
          EventBus.$emit("PromotionLaunchWizard-TestPublisherEnableNext", true);
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.a-tag-disable {
  pointer-events: none !important;
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}
::v-deep .pt-0 {
  padding-top: 0;
}
::v-deep .pt-15 {
  padding-top: 15px !important;
}
.error-message {
  white-space: pre-line;
  word-wrap: break-word;
}
.new-sheet {
  margin-left: 15px !important;
}
::v-deep .table-bordered th {
  background-color: #eee;
  text-align: center;
  padding: 10px;
  min-width: 100px;
}
.campaign-summary {
  list-style: none;
  padding-left: 20px;
}
.adgroup-summary {
  list-style: none;
  padding-left: 35px;
}
.entity-export-status {
  float: right;
}
.entity-label {
  color: #979898;
}
::v-deep .green {
  color: green;
}
::v-deep .grey {
  color: grey;
}
::v-deep .red {
  color: red;
}
::v-deep .yellow {
  color: #ffc300;
}
.fa.fa-caret-right {
  color: #979898;
}
.summmary-list {
  padding-left: 0px;
}
.amsButton {
  float: left;
  padding-right: 15px;
}
.ad-summary-container {
  border: 1px solid #ddd;
  background-color: white;
  margin-top: 17px;
}
.ad-summary-container-cl {
  position: sticky;
  right: 20px;
  top: 57px;
}
.ad-summary-container-promotion {
  position: sticky;
  right: 6px;
  top: 57px;
}
::v-deep .ad-summary-container ul.summmary-list {
  height: 65vh;
  overflow-y: auto;
  width: 100%;
}
.no-pointer {
  cursor: default;
}
.upload-progress {
  margin: 5px 0px;
}
.summary-collapse {
  cursor: pointer;
}
.summary-collapse:before {
  font-family: "FontAwesome";
  content: "\f0d7";
  color: grey;
  font-size: 1.3em;
  padding-right: 5px;
}
.summary-collapse.collapsed:before {
  content: "\f0da";
}
::v-deep .dropdown-menu {
  overflow-y: auto;
  max-height: 380px;
}
.submission-box-row {
  margin-bottom: 10px;
}
.submission-dropdown {
  margin-right: 0 !important;
}
.comment-area {
  width: 100%;
  min-height: 200px;
  max-height: 350px;
}
.tab-controls {
  padding: 0px;
  border-bottom: none !important;
}
.tab-content {
  padding: 0px !important;
  margin-bottom: 0px !important;
}
.nav.nav-tabs {
  padding-top: 15px;
  top: 48px;
  background: white;
  z-index: 20;
  border-bottom: 0px;
}
.pa-0{
  padding: 0px !important;
}
.status-active-btn {
  background: #eaeeef;
}
.back-to-setup {
  padding: 0px;
  margin-right: 15px;
}
.macro-warning{
  bottom: 60px;
  height: 16px;
  position: relative;
  margin-bottom: 25px;
}
.tab-bar{
  position: sticky;
  top: 40px;
  z-index: 100;
  background-color: white;
  margin-left:0px;
  margin-right:0px;
  border-bottom: 1px solid #ddd
}
.tab-list{
  padding-left: 0px;
  padding-right: 0px;
}
.tab-link{
  padding: 8px 16px !important;
}
.submission-select{
  padding: 18px 0px 0px 0px;
}

.badge {
  float: right;
  margin-left: auto;
  min-width: fit-content;
}
.textContent {
  margin-right: 5px;
}
li.ivu-select-item {
  display: flex !important;
}
</style>
<style>
.upload-progress .ivu-progress-success-bg {
  background-color: green !important;
}
.ivu-progress-outer {
  padding-top: 5px;
  padding-bottom: 5px;
}
.summary-checkboxes {
  padding-top: 10px;
}
.retry-busy-spinner {
  font-size: 15px;
}
.dx-scroll {
  padding-right: 0.8rem !important;
}
.dx-scroll::-webkit-scrollbar {
  width: 0.4rem;
  border-radius: 2.4rem;
  background-color: #f1f1f1;
}
.dx-scroll::-webkit-scrollbar-track {
  background: transparent;
}
.dx-scroll::-webkit-scrollbar-thumb {
  border-radius: 2.4rem;
  background-color: #c1c1c1;
}
.cl-select-dropdown {
  max-width: 650px;
}
</style>
