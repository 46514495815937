<template>
  <div id="campaign-launcher" class="elements">
    {{allVerified}}
    <Collapse v-model="collapseValue" style="border-top: 0">
      <div v-if="summaryPivot.recordCount > 0" class="budget-type-pivot" ref="summary">
        <Panel name="summary" :hide-arrow="true">
          <div class="title-sticky" style="border-top: 0">
            <Icon v-if="collapseValue.includes('summary')" class="collapse-icon" type="ios-arrow-down" />
            <Icon v-else class="collapse-icon" type="ios-arrow-forward" />
            <label class="element-label">Summary</label>
            <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled && (!isLivePromotion || (latestPromotionVersion.status == 1))">
              <label class="checkbox-status-label" @click="addEventListener">
                {{summaryVerified ? "Verified" : "Check to verify"}}
                <input
                  type="checkbox"
                  v-model="summaryVerified"
                />
              </label>
            </span>
          </div>
          <template slot="content">
            <div class="budget-type-table">
            <table class="table perf">
              <thead>
                <tr>
                  <th
                    v-for="item in summaryTable1Columns"
                    :key="item"
                  >{{ item }}</th>
                </tr>
              </thead>
              <tbody v-for="(record,i) in summaryPivot.records" :key="i + '-summary'">
                <tr>
                  <td
                    class="table-data-min-width-medium"
                    v-bind:class="[record.accountId ? '' : 'text-center']"
                  >
                    <span v-if="record.accountName">
                      {{ record.accountName}}
                    </span>
                    <span class="id-label">
                      {{ record.accountId  | showDashWhenIdNull}}
                    </span>
                  </td>
                  <td
                    class="table-data-min-width-medium"
                    v-bind:class="[record.facebookPageId ? '' : 'text-center']"
                  >
                    <span v-if="record.facebookPageName">
                      {{ record.facebookPageName}}
                    </span>
                    <span class="id-label">
                      {{record.facebookPageId | showDashWhenIdNull}}
                    </span>
                  </td>
                  <td
                    class="table-data-min-width-medium"
                    v-bind:class="[record.instagramAccountId ? '' : 'text-center']"
                  >
                    <span v-if="record.instagramAccountName">
                      {{ record.instagramAccountName}}
                    </span>
                    <span class="id-label">
                      {{record.instagramAccountId | showDashWhenIdNull}}
                    </span>
                  </td>
                  <td
                    class="table-data-min-width-small"
                  >{{record.objective}} <span v-if="isAdvantagePlusConfig">(Advantage+)</span></td>
                  <td
                    class="table-data-min-width-small"
                  >{{record.buyType}}</td>
                  <td
                    class="text-center table-data-min-width-large"
                    v-bind:class="[!record.conversionEvent && !record.trackingPixel ? '' : 'text-center']"
                  >
                    <span v-if="!record.conversionEvent && !record.trackingPixel">-</span>
                    <span v-else>
                      <span v-if="record.conversionEvent" class="cell-data-value-split-20">Event: </span>
                      <span v-if="record.conversionEvent" class="cell-data-value-split-80">
                        <span v-if="record.conversionName">
                          {{ record.conversionName}}
                        </span>
                        <span class="id-label">
                          {{record.conversionEvent | showDashWhenIdNull}}
                        </span>
                      </span>
                      <br v-if="record.conversionEvent"/>
                      <span v-if="record.trackingPixel" class="cell-data-value-split-20">Pixel: </span>
                      <span v-if="record.trackingPixel" class="cell-data-value-split-80">
                        <span v-if="record.trackingPixelName">
                          {{ record.trackingPixelName}}
                        </span>
                        <span class="id-label">
                          {{record.trackingPixel | showDashWhenIdNull}}
                        </span>
                      </span>
                      <span v-if="record.customEventType" class="cap">Custom Event:</span>
                      <span v-if="record.customEventType"> {{record.customEventType}} </span>
                    </span>
                  </td>
                  <td
                    class="text-center table-data-min-width-large"
                  >
                    <span v-if="!record.optimizeFor && !record.billingEvent">-</span>
                    <span v-else>
                      <span v-if="record.optimizeFor" class="cell-data-value-split-45">Optimize: </span>
                      <span v-if="record.optimizeFor" class="cell-data-value-split-55">{{ record.optimizeFor | showDashWhenNull }}</span>
                      <br v-if="record.optimizeFor" />
                      <span v-if="record.billingEvent" class="cell-data-value-split-45">Billing: </span>
                      <span v-if="record.billingEvent" class="cell-data-value-split-55">{{ record.billingEvent | showDashWhenNull}}</span>
                    </span>
                    <span v-if="record.reachFrequencyBalance">
                      <span class="cell-data-value-split-45">Freq: </span>
                      <span class="cell-data-value-split-55">{{ record.reachFrequencyBalance }}</span>
                    </span>
                  </td>
                  <td
                    class="text-center table-data-min-width-small"
                  >
                    <span v-if="!record.clickAttributionWindow && !record.viewAttributionWindow">-</span>
                    <span v-else>
                      <span v-if="record.clickAttributionWindow" class="cell-data-value-split-45">Click: </span>
                      <span v-if="record.clickAttributionWindow" class="cell-data-value-split-55">{{ record.clickAttributionWindow | showDashWhenNull }}</span>
                      <br v-if="record.clickAttributionWindow"/>
                      <span v-if="record.viewAttributionWindow" class="cell-data-value-split-45">View: </span>
                      <span v-if="record.viewAttributionWindow" class="cell-data-value-split-55">{{ record.viewAttributionWindow | showDashWhenNull}}</span>
                    </span>
                  </td>
                  <!-- {{record.attributionWindow}} -->
                  <!-- <td class="data-right">{{record.budget | currency(bpCurrency)}}</td> -->
                </tr>
              </tbody>
            </table>
            <table class="table-width-large table perf" v-show="isViewMore">
              <thead>
                <tr>
                  <th
                    v-for="item in summaryTable2Columns"
                    :key="item"
                  >{{ item }}</th>
                </tr>
              </thead>
              <tbody v-for="(record,i) in summaryPivot.records" :key="i + '-summary'">
                <tr>
                  <td class="text-center table-data-min-width-medium">
                    <span v-if="record.schedules.length === 0">Always On</span>
                    <span v-else>
                      <span v-for="schedule in record.schedules" :key="schedule">
                        <div>
                          {{ schedule }}
                        </div>
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="view-more">
            <a @click="isViewMore = !isViewMore">{{ isViewMore ? "View Less" : "View More" }}</a>
          </div>
          </template>
        </Panel>
      </div>
      <div v-if="budgetTypePivot.campaigns > 0" class="budget-type-pivot" ref="campaignSetup">
        <Panel name="campaign_setup" :hide-arrow="true">
          <div class="title-sticky">
            <Icon v-if="collapseValue.includes('campaign_setup')" class="collapse-icon" type="ios-arrow-down" />
            <Icon v-else class="collapse-icon" type="ios-arrow-forward" />
            <label class="element-label">Campaign Setup</label>
            <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled && (!isLivePromotion || (latestPromotionVersion.status == 1))">
              <label class="checkbox-status-label" @click="addEventListener">
                {{budgetTypeVerified ? "Verified" : "Check to verify"}}
                <input
                  :disabled="error"
                  type="checkbox"
                  v-model="budgetTypeVerified"
                />
              </label>
            </span>
          </div>
          <template slot="content">
            <div class="budget-type-table">
            <table class="table perf">
              <thead>
                <tr>
                  <th
                    v-for="item in budgetTypeColumns"
                    :key="item"
                  >{{ item }}</th>
                </tr>
              </thead>
              <tbody v-for="(record,i) in budgetTypePivot.records" :key="i + '-campaign-setup'">
                <tr>
                  <td
                    class="data-vertical-middle"
                  >{{record.CampaignName}}<span v-if="containChanges(record)" style='color:#b94a48'> *</span></td>
                  <td
                    class="data-vertical-middle"
                  >{{record.status}}</td>
                  <td
                    class="data-vertical-middle"
                  >{{record.startDate}} - {{record.endDate}}</td>
                  <td
                    class="data-vertical-middle"
                  >
                    <span v-if="record.spendCap && !isAdsetBudget">
                      {{record.budgetType}} <h6 class="cap">( Spend Cap: {{bpCurrencySymbol}}{{record.spendCap}} )</h6>
                    </span>
                    <span v-else-if="record.spendCap && isAdsetBudget">
                      NA <h6 class="cap">( Spend Cap: {{bpCurrencySymbol}}{{record.spendCap}} )</h6>
                    </span>
                    <span v-else-if="record.budgetType=='Life Time' && isAdsetBudget">NA</span>
                    <span v-else>{{record.budgetType}}</span>
                  </td>
                  <template v-if="!isAdsetBudget">
                  <td
                    class="data-center" v-if="fetchedApiDataSuccess && record.Id"
                  >{{ bpCurrencySymbol +" "}} {{record.TotalBudget | parseBudgetInLocale}}</td>
                  <td
                    class="data-center" v-else-if="fetchedApiDataSuccess"
                  > - </td>
                  <td class="data-center budget-cell">
                    <i v-if="dataReloadingRendrer && dataReloadingRendrer[i]" class="fa fa-spinner fa-spin"/>
                    <span class="budget-input" v-else-if="manageBudgetRendrer[i] && !record.Id">
                      <Tooltip theme="light">
                        <i-input
                          v-model.number="record.EditableBudget"
                          v-bind:disabled="!authFlowExists"
                          v-bind:class="{ 'error-input': errorInInput[i] }"
                          @on-blur="campaignBudgetChanged(record.EditableBudget, record.budgetType, record.durationInDays, i)"
                        >
                          <span slot="prepend">{{bpCurrencySymbol}}</span>
                        </i-input>
                        <span slot="content">{{record.EditableBudget | parseBudgetInLocale}}</span>
                      </Tooltip>
                    </span>
                    <span class="budget-input" v-else-if="record.RowData.BudgetCluster && fetchedApiDataSuccess && manageBudgetRendrer[i]">
                      <Tooltip theme="light">
                        <i-input
                          v-model.number="record.RowData.BudgetCluster.UserBudget"
                          v-bind:disabled="!authFlowExists"
                          v-bind:class="{ 'error-input': errorInInput[i] }"
                          @on-blur="campaignBudgetChanged(record.RowData.BudgetCluster.UserBudget, record.budgetType, record.durationInDays, i)"
                        >
                          <span slot="prepend">{{bpCurrencySymbol}}</span>
                        </i-input>
                        <span slot="content">{{record.RowData.BudgetCluster.UserBudget | parseBudgetInLocale}}</span>
                      </Tooltip>
                    </span>
                    <span class="budget-text" v-else-if="record.RowData.BudgetCluster && fetchedApiDataSuccess && record.Id && !manageBudgetRendrer[i] ">
                      {{ parseFloat(record.RowData.BudgetCluster.UserBudget).toFixed(2)  | budgetString(record.durationInDays, record.isLifetimeBudget, bpCurrencySymbol) }}
                    </span>
                    <span class="budget-text" v-else>
                      {{ bpCurrencySymbol +" "}} {{record.TotalBudget | parseBudgetInLocale}}
                    </span>
                    <button v-if="!isPromotionMode"
                      class="btn btn-success btn-sm manage-budget-button"
                      @click="manageBudget(record.Id, record.RowData, i)"
                      ><i class="fa fa-pencil" />
                    </button>
                  </td>
                </template>
                  <td v-if="record.budget" class="data-right">{{record.budget | currency(bpCurrency)}}</td>
                </tr>
                <tr v-if="!isPromotionMode">
                  <td v-bind:colspan="budgetTypeColumns.length">
                    <facebook-adset-bidEdit
                      :index="i"
                      :adgroupsData="record.ChildEntities"
                      :fetchedApiDataSuccess="fetchedApiDataSuccess"
                      :dataReloading="dataReloadingRendrer"
                      :campaignBudget="editedBudgetAmount(record)"
                      :isLifetimeBudget="record.isLifetimeBudget"
                      :campaginId="record.Id"
                      @updatedAdgroupData="changedAdgroupData"
                      @errorInSpendLimit="errorResolver"
                    >
                    <td
                    class="data-vertical-middle"
                  >
                  </td>
                    </facebook-adset-bidEdit>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </template>
        </Panel>
      </div>
      <div v-if="rnfEstimationPivot.rnfCount > 0" class="budget-type-pivot" ref="reachFrequencyEstimate">
        <Panel name="reach_frequency_estimate" :hide-arrow="true">
          <div class="title-sticky">
            <Icon v-if="collapseValue.includes('reach_frequency_estimate')" class="collapse-icon" type="ios-arrow-down" />
            <Icon v-else class="collapse-icon" type="ios-arrow-forward" />
            <label class="element-label">
              <span>Reach & Frequency Estimate</span>
            </label>
            <span>
              <button
                class="btn btn-success btn-sm rnf-replan"
                @click="openMapperModel()"
                >
                <i class="fa fa-spinner fa-spin" v-if="RnfDataProcessing" />
                <i class="fa fa-refresh" v-else />
                  Replan
              </button>
            </span>
            <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled && (!isLivePromotion || (latestPromotionVersion.status == 1))">
              <label class="checkbox-status-label" @click="addEventListener">
                {{rnfEstimationVerified ? "Verified" : "Check to verify"}}
                <input
                  type="checkbox"
                  v-model="rnfEstimationVerified"
                />
              </label>
            </span>
          </div>
          <template slot="content">
            <div class="budget-type-table">
            <table class="table perf">
              <thead>
                <tr>
                  <th
                    v-for="item in rnfEstimationColumns"
                    :key="item"
                  >{{ item }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rnf, i) in rnfEstimationPivot.records" :key="i">
                  <td class="data-vertical-middle">
                    {{ rnf.lineitemName }}
                    <div v-if="rnf.overAllStatus==10" class="rnf-error-text">
                      Failure Reason: {{rnf.overAllState}}
                    </div>
                    <div v-if="rnf.overAllStatus==8" class="rnf-suggestion-text">
                      {{rnf.overAllState}}
                    </div>
                  </td>
                  <td class="data-right data-vertical-middle">{{ rnf.type }}</td>
                  <td class="data-center">
                    <div class="iconContainer">
                      <i class="fa fa-spinner fa-spin" v-if="RnfDataProcessing" />
                      <Icon v-else-if="rnf.overAllStatus == 8" type="ios-checkmark-circle-outline" class="consider" size="18"/>
                      <Icon v-else type="ios-alert-outline" class="skip" size="18" />
                    </div>
                  </td>
                  <td class="data-right data-vertical-middle">{{ rnf.rnfSettings.budget | parseBudgetInLocale }}</td>
                  <td class="data-vertical-middle date-max-width">{{ getReadableDateTime(rnf.rnfSettings.startDate) }}</td>
                  <td class="data-vertical-middle date-max-width">{{ getReadableDateTime(rnf.rnfSettings.endDate) }}</td>
                  <td class="data-right data-vertical-middle">{{ rnf.rnfSettings.percentageOfUniverse }}</td>
                  <td class="data-right data-vertical-middle">{{ rnf.rnfSettings.reach | parseIntInLocale}}</td>
                  <td class="data-right data-vertical-middle">{{ rnf.rnfSettings.cpa }}</td>
                  <td class="data-right data-vertical-middle">{{ rnf.rnfSettings.frequency }}</td>
                  <td class="data-right data-vertical-middle">{{ rnf.frequencyCap }}</td>
                  <td class="data-right data-vertical-middle">
                    <reach-ad-frequency-history
                      :index="i"
                      :lineItemId="rnf.lineItemId"
                      :targetId="rnf.targetId"
                      :RnfDataProcessing="RnfDataProcessing"
                      ref="lineitemConfigRow"
                    ></reach-ad-frequency-history>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </template>
        </Panel>
      </div>
      <div v-if="targetsPivot.adGroups > 0" class="targets-pivot" ref="targeting">
        <Panel name="targeting" :hide-arrow="true">
          <div class="title-sticky">
            <Icon v-if="collapseValue.includes('targeting')" class="collapse-icon" type="ios-arrow-down" />
            <Icon v-else class="collapse-icon" type="ios-arrow-forward" />
            <label class="element-label">Targeting</label>
            <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled && (!isLivePromotion || (latestPromotionVersion.status == 1))">
              <label class="checkbox-status-label" @click="addEventListener">
                {{targetsVerified ? "Verified" : "Check to verify"}}
                <input
                  type="checkbox"
                  v-model="targetsVerified"
                />
              </label>
            </span>
          </div>
          <template slot="content">
            <div class="targets-table">
            <table class="table perf">
              <thead>
                <tr>
                  <th
                    v-for="item in targetColumns"
                    :key="item"
                  >{{ item }}</th>
                </tr>
              </thead>
              <tbody v-for="(targetRow,i1) in targetsPivot.targetsGroup" :key="i1">
                <tr v-for="(bidRow,i2) in targetRow.bidsGroup" :key="i2">
                  <td v-if="i2==0" :rowspan="targetRow.bidsGroup.length">
                    <div>
                      <label class="target-label">
                          {{targetRow.bidsGroup[0].targetName}}
                      </label>
                        <Tooltip max-width="1000" placement="right-start" class="target-tooltip">
                          <Icon type="md-alert"/>
                          <div slot="content">
                            <div v-html="targetRow.bidsGroup[0].targetHTML"></div>
                          </div>
                        </Tooltip>
                        <div class="target-sub-label">
                        <i>{{targetRow.bidsGroup[0].targetReach | reach(bidRow)}}</i>
                      </div>
                      <div class="target-sub-label">
                        <i>{{targetRow.bidsGroup[0].targetData}}</i>
                        <br>
                          <i v-html="targetRow.bidsGroup[0].excludedAudienceHtml"></i>
                          <i v-html="targetRow.bidsGroup[0].includedAudienceHtml"></i>
                      </div>
                    </div>
                  </td>
                  <td class="data-right">{{bidRow.bid | currency(bpCurrency)}}</td>
                  <td class="data-right">
                    {{bidRow.adgroups.length}}
                    <span class="no-of-ads">({{bidRow.ads}} ads)</span>
                  </td>
                  <!-- <td class="data-right">{{bidRow.budget | currency(bpCurrency)}}</td> -->
                </tr>
              </tbody>
              <tfoot class="total-footer">
                <tr>
                  <td colspan="2" class="table-footer-label data-right">Total</td>
                  <td class="table-footer-label data-right">
                    {{targetsPivot.adGroups}}
                    <span class="no-of-ads">({{targetsPivot.ads}} ads)</span>
                  </td>
                  <!-- <td
                    class="table-footer-label data-right"
                  >{{targetsPivot.budget | currency(bpCurrency)}}</td> -->
                </tr>
              </tfoot>
            </table>
          </div>
          </template>
        </Panel>
      </div>
      <div v-if="locationsPivot.locationsGroup.length > 0" class="locations-pivot" ref="locations">
        <Panel name="locations" :hide-arrow="true">
          <div class="title-sticky">
            <Icon v-if="collapseValue.includes('locations')" class="collapse-icon" type="ios-arrow-down" />
            <Icon v-else class="collapse-icon" type="ios-arrow-forward" />
            <label class="element-label">Locations</label>
            <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled && (!isLivePromotion || (latestPromotionVersion.status == 1))">
              <label class="checkbox-status-label" @click="addEventListener">
                {{locationsVerified ? "Verified" : "Check to verify"}}
                <input
                  type="checkbox"
                  v-model="locationsVerified"
                />
              </label>
            </span>
          </div>
          <template slot="content">
            <div class="locations-table">
            <table class="table perf">
              <thead>
                <tr>
                  <th
                    v-for="item in locationColumns"
                    :key="item"
                  >{{ item }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(locationRow,i1) in locationsPivot.locationsGroup" :key="i1">
                  <td>{{locationRow.location}}</td>
                  <td class="data-right">
                    {{locationRow.adgroups.flat().length}}
                    <span
                      class="no-of-ads"
                    >({{locationRow.ads}} ads)</span>
                  </td>
                  <!-- <td class="data-right">{{locationRow.budget | currency(bpCurrency)}}</td> -->
                </tr>
              </tbody>
            </table>
          </div>
          </template>
        </Panel>
      </div>
      <div v-if="locationsPivot.excludedLocationsGroup.length > 0 " class="locations-pivot" ref="excludedLocations">
        <Panel name="excluded_locations" :hide-arrow="true">
          <div class="title-sticky">
            <Icon v-if="collapseValue.includes('excluded_locations')" class="collapse-icon" type="ios-arrow-down" />
            <Icon v-else class="collapse-icon" type="ios-arrow-forward" />
            <label class="element-label">Excluded Locations</label>
          </div>
          <template slot="content">
            <div class="locations-table" v-if="locationsPivot.excludedLocationsGroup.length > 0">
            <table class="table perf">
              <thead>
                <tr>
                  <th
                    v-for="item in locationColumns"
                    :key="item"
                  >{{ item }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(locationRow,i1) in locationsPivot.excludedLocationsGroup" :key="i1">
                  <td>{{locationRow.location}}</td>
                  <td class="data-right">
                    {{locationRow.adgroups.flat().length}}
                    <span
                      class="no-of-ads"
                    >({{locationRow.ads}} ads)</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </template>
        </Panel>
      </div>
      <div v-if="pageSetsPivot.pageSetsGroup.length > 0" class="locations-pivot" ref="pageSets">
        <Panel name="page_sets" :hide-arrow="true">
          <div class="title-sticky">
            <Icon v-if="collapseValue.includes('page_sets')" class="collapse-icon" type="ios-arrow-down" />
            <Icon v-else class="collapse-icon" type="ios-arrow-forward" />
            <label class="element-label">Page Sets</label>
            <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled && (!isLivePromotion || (latestPromotionVersion.status == 1))">
              <label class="checkbox-status-label" @click="addEventListener">
                {{pageSetsVerified ? "Verified" : "Check to verify"}}
                <input
                  type="checkbox"
                  v-model="pageSetsVerified"
                />
              </label>
            </span>
          </div>
          <template slot="content">
            <div class="locations-table">
            <table class="table perf">
              <thead>
                <tr>
                  <th
                    v-for="item in pageSetsColumns"
                    :key="item"
                  >{{ item }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pageSetRow,i1) in pageSetsPivot.pageSetsGroup" :key="i1">
                  <td>{{pageSetRow.pageSet}}</td>
                  <td class="data-right">
                    {{pageSetRow.adGroups}}
                    <span
                      class="no-of-ads"
                    >({{pageSetRow.ads}} ads)</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          </template>
        </Panel>
      </div>
      <div v-if="pagesPivot.length > 0" class="pages-pivot" ref="pagesCreative">
        <Panel name="pages_creative" :hide-arrow="true">
          <div class="title-sticky">
            <Icon v-if="collapseValue.includes('pages_creative')" class="collapse-icon" type="ios-arrow-down" />
            <Icon v-else class="collapse-icon" type="ios-arrow-forward" />
            <label class="element-label">Pages and Creative</label>
            <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled && (!isLivePromotion || (latestPromotionVersion.status == 1))">
              <label class="checkbox-status-label" @click="addEventListener">
                {{pageVerified ? "Verified" : "Check to verify"}}
                <input
                  type="checkbox"
                  v-model="pageVerified"
                />
              </label>
            </span>
          </div>
          <template slot="content">
            <div class="pages-list">
            <div v-for="page in pagesPivot" :key="page.pageName">
              <div class="page-label">{{page.pageName}}</div>
              <div class="creatives-table">
                <table class="table perf">
                  <thead>
                    <tr>
                      <th
                        v-for="item in creativeColumns"
                        :key="item"
                      >{{ item }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(creativeRow,i2) in page.creativesGroup" :key="i2">
                      <td>{{creativeRow.adCopyId}} <div>{{creativeRow.adName}}</div>
                        <i class="fas fa-eye" v-if="creativeRow.instantExperience" v-on:click="previewInstantExperience(creativeRow)"></i>
                        <div class="label-container">
                          <span v-for="(label, index) in creativeRow.adLabels" :key="index" class="label" style="background-color: rgb(199, 191, 193); margin:1px;">
                              {{ label.Name }}
                            </span>
                        </div>
                      </td>
                      <td style="width:420px;">
                        <div :id="creativeRow.adCopyId" class="col-md-11 no-padding ad-preview-facebook">
                          <div v-if="creativeRow.assetSpecInfo && creativeRow.assetSpecInfo.length > 0">
                            <div class="col-md-3 no-padding" v-for="(asset, assetIndex) in creativeRow.assetSpecInfo" :key="assetIndex">
                              <p class="creative-desc" v-if="asset.title">
                                {{asset.title}}
                              </p>
                              <span v-if="asset.image"><img v-lazy="asset.image" height="50"></span>
                              <p class="creative-desc" v-if="asset.body">
                                {{asset.body}}
                              </p>
                              <p class="creative-desc" v-if="asset.link">
                                {{asset.link}}
                              </p>
                            </div>
                          </div>
                          <div v-if="(!creativeRow.assetSpecInfo || creativeRow.assetSpecInfo.length == 0) && creativeRow.creativeInfo">
                            <div v-if="!(creativeRow.creativeInfo.child_attachments && creativeRow.creativeInfo.child_attachments[0])">
                              <p class="creative-desc" v-if="creativeRow.creativeInfo.message">
                                {{creativeRow.creativeInfo.message}}
                              </p>
                              <span v-if="creativeRow.creativeInfo.picture"><img v-lazy="creativeRow.creativeInfo.picture" height="70"></span>
                              <p class="creative-desc" v-if="creativeRow.creativeInfo.name">
                                {{creativeRow.creativeInfo.name}}
                              </p>
                              <p class="creative-desc" v-if="creativeRow.creativeInfo.description">
                                {{creativeRow.creativeInfo.description}}
                              </p>
                            </div>
                            <div v-else>
                              <div class="col-md-5 no-padding" v-if="creativeRow.creativeInfo.child_attachments[0]">
                                <p class="creative-desc" v-if="creativeRow.creativeInfo.child_attachments[0].message">
                                  {{creativeRow.creativeInfo.child_attachments[0].message}}
                                </p>
                                <span v-if="creativeRow.creativeInfo.child_attachments[0]">
                                  <img v-lazy="creativeRow.creativeInfo.child_attachments[0].picture" height="70">
                                </span>
                                <p class="creative-desc" v-if="creativeRow.creativeInfo.child_attachments[0].name">
                                  {{creativeRow.creativeInfo.child_attachments[0].name}}
                                </p>
                                <p class="creative-desc" v-if="creativeRow.creativeInfo.child_attachments[0].description">
                                  {{creativeRow.creativeInfo.child_attachments[0].description}}
                                </p>
                              </div>
                              <div class="col-md-5 no-padding" v-if="creativeRow.creativeInfo.child_attachments[1]">
                                <p class="creative-desc" v-if="creativeRow.creativeInfo.child_attachments[1].message">
                                  {{creativeRow.creativeInfo.child_attachments[1].message}}
                                </p>
                                <span v-if="creativeRow.creativeInfo.child_attachments[1]">
                                  <img v-lazy="creativeRow.creativeInfo.child_attachments[1].picture" height="70">
                                </span>
                                <p class="creative-desc" v-if="creativeRow.creativeInfo.child_attachments[1].name">
                                  {{creativeRow.creativeInfo.child_attachments[1].name}}
                                </p>
                                <p class="creative-desc" v-if="creativeRow.creativeInfo.child_attachments[1].description">
                                  {{creativeRow.creativeInfo.child_attachments[1].description}}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1">
                          <i
                            v-tooltip="'More previews'"
                            class="more-previews fa fa-chevron-circle-up"
                            @click.stop="showMorePreviews(creativeRow, i2)"
                          ></i>
                        </div>
                        <Row-Col span="3">
                          <div>
                            <a
                              :href="creativeRow.previewLink"
                              :class="{ 'hide-url': submissionId == 0 }"
                              target="_blank"
                              rel="noopener noreferrer"
                              >Preview</a
                            >
                          </div>
                        </Row-Col>
                      </td>
                      <td>
                        <li
                          class="creatives-li"
                          v-for="url in creativeRow.landingPages"
                          :key="url"
                        >{{url}}</li>
                      </td>
                      <td style="width:250px;">
                        <li
                          class="creatives-li"
                          v-for="target in creativeRow.targets"
                          :key="target"
                        >{{target}}</li>
                      </td>
                      <td class="creative-adstatus">
                        {{ creativeRow.adStatus }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </template>
        </Panel>
      </div>
      <div v-if="placementsPivot.adGroups > 0" class="placements-pivot" ref="placements">
        <Panel name="placements" :hide-arrow="true">
          <div class="title-sticky">
            <Icon v-if="collapseValue.includes('placements')" class="collapse-icon" type="ios-arrow-down" />
            <Icon v-else class="collapse-icon" type="ios-arrow-forward" />
            <label class="element-label">Placements</label>
            <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled && (!isLivePromotion || (latestPromotionVersion.status == 1))">
              <label class="checkbox-status-label" @click="addEventListener">
                {{placementsVerified ? "Verified" : "Check to verify"}}
                <input
                  type="checkbox"
                  v-model="placementsVerified"
                />
              </label>
            </span>
          </div>
          <template slot="content">
            <div class="placements-table">
            <table class="table perf">
              <thead>
                <tr>
                  <th
                    v-for="item in placementColumns"
                    :key="item"
                  >{{ item }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(placement,i2) in placementsPivot.placementsGroup" :key="i2">
                  <td>{{placement.platforms | placement}}</td>
                  <td>{{placement.fbPositions | placement('Facebook', placement.platforms)}}</td>
                  <td>{{placement.igPositions | placement('Instagram', placement.platforms)}}</td>
                  <td>{{placement.msPositions | placement('Messenger', placement.platforms)}}</td>
                  <td>{{placement.anPositions | placement('Audience network', placement.platforms)}}</td>
                  <td class="data-right">
                    {{placement.adgroups.length}}
                    <span class="no-of-ads">({{placement.ads}} ads)</span>
                  </td>
                  <!-- <td class="data-right">{{placement.budget | currency(bpCurrency)}}</td> -->
                </tr>
              </tbody>
              <tfoot class="total-footer">
                <tr>
                  <td colspan="5" class="table-footer-label data-right"></td>
                  <td class="table-footer-label data-right">
                    {{placementsPivot.adGroups}}
                    <span class="no-of-ads">({{placementsPivot.ads}} ads)</span>
                  </td>
                  <!-- <td
                    class="table-footer-label data-right"
                  >{{placementsPivot.budget | currency(bpCurrency)}}</td> -->
                </tr>
              </tfoot>
            </table>
          </div>
          </template>
        </Panel>
      </div>
    </Collapse>
    <i-modal v-if="pagesPivot.length > 0" v-model="previewModal" :title="previewTitle" width="80%" :scrollable="true">
      <div slot="header">
        <Row>
          <Row-Col span="3">
            <h4>Preview</h4>
          </Row-Col>
          <Row-Col span="5" offset="12" v-if="!isPromotionMode">
            <Select v-model="creativeInPreview"
              filterable :transfer="true">
              <template v-for="page in pagesPivot">
                <Option v-for="(creative, i2) in page.creativesGroup" :key="creative.adCopyId" :value="i2 + 1">{{ creative.adName }}</Option>
              </template>
            </Select>
          </Row-Col >
          <Row-Col span="4" v-if="!isPromotionMode">
            <Page class-name="cl-adpreview-page" :current="creativeInPreview" :total="pagesPivot[0].creativesGroup.length" :page-size=1 simple mini v-on:on-change="updateCreativeInPreview"/>
          </Row-Col>
        </Row>
      </div>
      <div>
        <facebook-creative-preview
        fromCampaignLauncher='true'
        :accountId="previewAccountId"
        :creativeSpec="previewCreativeData"
        :businessProfilesSearchEngineId="businessProfilesSearchEngineId"
        ></facebook-creative-preview>
      </div>
    </i-modal>
  </div>
</template>

<script>
import { Modal, Tooltip, Icon, locale, Input, Row, Col, Page, Select, Option, Collapse, Panel } from 'iview'
import CreativePreview from './FacebookCreativePreview.vue'
import facebookAdsetBidEdit from './FacebookAdgroup.vue'
import ApiService from './ApiService.js';
import lang from 'iview/dist/locale/en-US';
import { mapState, mapMutations, mapGetters } from "vuex";
import targetHTMLGenerator from "../../../../Mixins/targetStringToHTML.js";
import { EventBus } from "../../EventBus.js"
import * as fbEnums from "../../Constants/FacebookEnums.js"
import reachAndFrequencyHistory from "./Facebook/ReachAndFrequencyLogs.vue";
import PromotionApiService from '../../../Promotion/ApiService.js';

locale(lang); // iview is default chinese

export default {
  days: ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
  mixins: [targetHTMLGenerator],
  components: {
    'i-modal': Modal,
    'facebook-creative-preview': CreativePreview,
    'facebook-adset-bidEdit': facebookAdsetBidEdit,
    'reach-ad-frequency-history': reachAndFrequencyHistory,
    "i-input": Input,
    Tooltip,
    Icon,
    Row,
    "Row-Col": Col,
    Page,
    Select,
    Option,
    Collapse,
    Panel
  },
  created: async function () {
    var response = await ApiService.GetBusinessProfile()
    this.isCampaignLauncherVerificationEnabled = response.isCampaignLauncherVerificationEnabled;
    if (this.isPromotionMode) {
      let promoVersion = await PromotionApiService.getPromotionVersions([this.promotionId], true);
      this.latestPromotionVersion = promoVersion[0] || {};
    }
    if (!this.isCampaignLauncherVerificationEnabled) {
      this.summaryVerified = true;
      this.budgetTypeVerified = true;
      this.rnfEstimationVerified = true;
      this.pageVerified = true;
      this.placementsVerified = true;
      this.locationsVerified = true;
      this.pageSetsVerified = true;
      this.targetsVerified = true;
    } else {
      this.isCampaignLauncherVerificationEnabled = true;
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.setTargetsReach();
      if (this.selectedCampaignLauncherConfigId && this.mediaPlanId) {
        if (this.selectedCampaignLauncherConfig.campaign.buyType == 4) {
          this.setRnfData();
        }
        this.getAccountRelatedEntities()
      }
    });
    this.updateManageButtonRender()
    EventBus.$on("updatedRnFInMapper", value => {
      var self = this;
      if (value) {
        if (self.selectedCampaignLauncherConfig.campaign.buyType == 4) {
          self.setRnfData();
        }
      }
    });
    this.$nextTick(() => {
      this.collapseValue = deltax.isCampaignLauncherSummarySectionCollapse ? [] : ['summary', 'campaign_setup', 'reach_frequency_estimate', 'targeting', 'locations', 'excluded_locations', 'page_sets', 'pages_creative', 'placements'];
    });
  },
  beforeDestroy () {
    EventBus.$off("updatedRnFInMapper");
  },
  props: {
    isSubmission: { default: true },
    adgroupsData: { default: [] },
    adsData: { default: [] },
    publishSummary: { default: [] },
    creativeMedia: { default: {} },
    viewType: { default: "Changes" },
    submissionId: { default: 0 },
    authFlowExists: false
  },
  data: function () {
    return {
      summaryVerified: true,
      budgetTypeVerified: true,
      rnfEstimationVerified: true,
      targetsVerified: true,
      locationsVerified: true,
      pageSetsVerified: true,
      pageVerified: true,
      placementsVerified: true,
      summaryTable1Columns: ["Account", "FB Page", "IG Page", "Objective", "Buy Type", "Tracking Settings", "Budget Settings", "Attribution Window"],
      summaryTable2Columns: ["Dayparting Schedule"],
      budgetTypeColumns: ["Campaign Name", "Status", "Schedule", "Budget Type", "Budget"],
      rnfEstimationColumns: ["Adgroup Name", "", "Status", "Budget", "Start Date", "End Date", "Universe %", "Reach", "CPM", "Frequency", "Frequency Cap", "History"],
      targetColumns: ["Target Name", "Bids", "No. of Adgroups"],
      locationColumns: ["Location", "No. of Adgroups"],
      pageSetsColumns: ["PageSets", "No. of Adgroups"],
      creativeColumns: ["Ad Copy Id", "Creative", "Landing Page", "Targets", "Status"],
      placementColumns: ["Platform", "Facebook Positions", "Instagram Positions", "Messenger Positions", "Audience Network Positions", "No. of Adgroups"],
      pagesPivot: [], // since we need to async update the creative preview, computed won't work
      targetsReach: {},
      previewModal: false,
      previewTitle: '',
      previewAccountId: '',
      previewCreativeId: '',
      previewCreativeData: { noVal: true },
      campaignsData: [],
      fetchedApiDataSuccess: false,
      error: false,
      manageBudgetRendrer: [],
      dataReloadingRendrer: [],
      errorInInput: [],
      budgetDifference: 0,
      isCampaignLauncherVerificationEnabled: true,
      creativeInPreview: 0,
      processedCampaigns: [],
      processedAdgroups: [],
      LineitemCampaignConfigurationData: [],
      trackingPixels: [],
      appEvents: [],
      offlineEvents: [],
      RnfDataProcessing: false,
      adFormatStoryEventTypes: {
        "Image or Carousel": 0,
        "Video": 128,
        "Instant Experience": 256
      },
      isAdsetBudget: false,
      isViewMore: false,
      collapseValue: [],
      latestPromotionVersion: {},
      locationMode: fbEnums.locationMode.Standard
    };
  },
  computed: {
    ...mapState(["businessProfilesSearchEngineId",
      "selectedCampaignLauncherConfig",
      "isPromotionMode",
      "promotionObject",
      "bpCurrencySymbol",
      "posts",
      "imageIdUrlMap",
      "accounts",
      "accountAssetsMapper",
      "mediaPlanId",
      "selectedCampaignLauncherConfigId",
      "previewableRowsCreativeSpec",
      "mappedLineItems",
      "editedSummaryData",
      "promotionId"]),
    ...mapGetters(["isLivePromotion"]),
    budgetTypePivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.budgetTypeVerified = false;
      }
      if (!this.publishSummary) return {}
      let records = this.publishSummary.map(campaigns => ({
        ...campaigns,
        Id: campaigns.Id,
        CampaignName: campaigns.Name,
        budgetType: campaigns.RowData.BudgetType.replace("daily_budget", "Daily").replace("lifetime_budget", "Life Time"),
        status: campaigns.ApiData.status,
        RowSpan: this.publishSummary.length,
        BpCurrency: this.bpCurrencySymbol,
        TotalBudget: campaigns.RowData.BudgetType == "daily_budget" ? parseFloat(campaigns.RowData.AllocatedBudget).toFixed(2) + " (" + parseFloat(campaigns.RowData.Budget).toFixed(2) + " per day)" : parseFloat(campaigns.RowData.Budget).toFixed(2),
        AllocatedBudget: parseFloat(campaigns.RowData.AllocatedBudget).toFixed(2),
        EditableBudget: campaigns.RowData.BudgetType == "daily_budget" ? parseFloat(campaigns.RowData.Budget).toFixed(2) : parseFloat(campaigns.RowData.AllocatedBudget).toFixed(2),
        isLifetimeBudget: campaigns.RowData.BudgetType == "lifetime_budget" ? 1 : 0,
        durationInDays: campaigns.RowData.AdgroupEndDate ? (Math.ceil(Math.abs(new Date(campaigns.RowData.AdgroupEndDate) - new Date(campaigns.RowData.AdgroupStartDate)) / (1000 * 60 * 60 * 24))) : campaigns.RowData.AllocatedBudget / (campaigns.RowData.Budget > 0 ? campaigns.RowData.Budget : campaigns.RowData.AllocatedBudget),
        startDate: this.getReadableDate(campaigns.RowData.AdgroupStartDate, true),
        endDate: this.getReadableDate(campaigns.RowData.AdgroupEndDate),
        spendCap: campaigns.RowData.SpendCap == 922337203685478 ? undefined : Math.ceil(campaigns.RowData.SpendCap)
        // Here we are adding check for 922337203685478 since we cannot set the spendCap to 0, it needs to be this number.
        // Please refer to https://developers.facebook.com/docs/marketing-api/reference/ad-campaign-group/#parameters-3
      }))
      if (this.editedSummaryData) {
        var parsedEditedData = JSON.parse(this.editedSummaryData)
        if (parsedEditedData && records) {
          records.forEach(campaign => {
            if (parsedEditedData.hasOwnProperty(campaign.RowData.InternalCampaignName)) {
              parsedEditedData[campaign.RowData.InternalCampaignName].forEach(editedData => {
                if (editedData.hasOwnProperty('adsetBudget')) {
                  campaign.ChildEntities.find(adgroup =>
                    adgroup.RowData.InternalAdgroupName == editedData.internalId)
                    .RowData.Budget = editedData.adsetBudget
                }
              })
            }
          })
        }
      }
      let totalCampaigns = records.length;
      this.campaignsData = records;
      this.$emit('showElementsTab', 'campaignSetup', totalCampaigns > 0);
      return { records: records, campaigns: totalCampaigns }
    },
    isAdvantagePlusConfig () {
      if (this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign && this.selectedCampaignLauncherConfig.campaign.isAdvantagePlusShoppingCampaign) {
        return true
      }
      return false
    },
    summaryPivot: function () {
      this.$nextTick(() => {
        this.collapseValue = deltax.isCampaignLauncherSummarySectionCollapse ? [] : ['summary', 'campaign_setup', 'reach_frequency_estimate', 'targeting', 'locations', 'excluded_locations', 'page_sets', 'pages_creative', 'placements'];
      });
      var self = this;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.summaryVerified = false;
      }
      if (!this.selectedCampaignLauncherConfig.bpseId) return {}
      var assets = JSON.parse(this.accounts.find(x => x.Id == this.selectedCampaignLauncherConfig.bpseId).AccountAssets)
      var facebook = assets.Data ? assets.Data[0].Pages.find(x => x.Id === this.selectedCampaignLauncherConfig.facebookPageId) : null;
      var instagram = assets.Data ? assets.Data[0].Instagram.find(x => x.Id === this.selectedCampaignLauncherConfig.instagramAccountId) : null;
      var objective = Object.keys(fbEnums.objectives).find(key => fbEnums.objectives[key] === self.selectedCampaignLauncherConfig.campaign.objective);
      var buyType = Object.keys(fbEnums.buyType).find(key => fbEnums.buyType[key] === self.selectedCampaignLauncherConfig.campaign.buyType);
      var account = this.accounts.find(e => e.Id == this.selectedCampaignLauncherConfig.bpseId);
      var trackingPixel = this.selectedCampaignLauncherConfig.adset ? this.selectedCampaignLauncherConfig.adset.trackingPixel : null;
      var trackingPixelName = this.trackingPixels.length > 0 && trackingPixel ? (this.trackingPixels.find(x => x.id == trackingPixel) ? this.trackingPixels.find(x => x.id == trackingPixel).name : "") : ""
      var conversionEvent = this.selectedCampaignLauncherConfig.adset ? this.selectedCampaignLauncherConfig.adset.appEvent : null;
      var conversionName = "";
      if (this.appEvents.length > 0 && conversionEvent) {
        if (conversionEvent === "{{store.appId}}") {
          conversionName = conversionEvent;
        } else {
          const foundEvent = this.appEvents.find(x => x.id == conversionEvent);
          conversionName = foundEvent ? foundEvent.name : "";
        }
      }

      var optimizeFor = this.selectedCampaignLauncherConfig.adset ? fbEnums.optimizationGoalsEnum[this.selectedCampaignLauncherConfig.adset.optimizeFor] : null;
      var billingEvent = this.selectedCampaignLauncherConfig.adset ? fbEnums.billingEventsEnum[this.selectedCampaignLauncherConfig.adset.billingEvent] : null;
      var clickAttributionWindow = this.selectedCampaignLauncherConfig.adset.clickAttributionWindow;
      var viewAttributionWindow = this.selectedCampaignLauncherConfig.adset.viewAttributionWindow;
      var reachFrequencyBalance = this.selectedCampaignLauncherConfig.adset.reachAndFrequencyBalance.cap +
        " per " + this.selectedCampaignLauncherConfig.adset.reachAndFrequencyBalance.days + " days"
      this.isAdsetBudget = this.selectedCampaignLauncherConfig.isAdsetBudget
      var customEventType = this.selectedCampaignLauncherConfig.adset ? this.selectedCampaignLauncherConfig.adset.customEventType : null;
      var schedules = [];
      if (this.selectedCampaignLauncherConfig.adset.adScheduling && this.selectedCampaignLauncherConfig.adset.schedule.dayParting_schedules.length != 0) {
        this.selectedCampaignLauncherConfig.adset.schedule.dayParting_schedules.forEach((schedule) => {
          var scheduleString = schedule.days.sort((a, b) => { return a - b }).map((i) => this.$options.days[i]).join(", ");
          scheduleString += " " + this.format12Hours(schedule.start_minute) + " - " + this.format12Hours(schedule.end_minute);
          scheduleString += " (Timezone type: " + schedule.timezone_type + ")";
          schedules.push(scheduleString);
        });
      }
      var records = []
      var record = {
        accountName: account.NickName,
        accountId: this.selectedCampaignLauncherConfig.facebookAccountId,
        facebookPageName: facebook ? facebook.Name : null,
        facebookPageId: this.selectedCampaignLauncherConfig.facebookPageId || null,
        instagramAccountId: this.selectedCampaignLauncherConfig.instagramAccountId || null,
        instagramAccountName: instagram ? instagram.Name : null,
        objective: objective,
        buyType: buyType,
        trackingPixel: trackingPixel,
        trackingPixelName: trackingPixelName,
        conversionEvent: conversionEvent,
        conversionName: conversionName,
        optimizeFor: optimizeFor,
        billingEvent: billingEvent,
        clickAttributionWindow: clickAttributionWindow,
        viewAttributionWindow: viewAttributionWindow,
        reachFrequencyBalance: reachFrequencyBalance,
        customEventType: customEventType,
        schedules: schedules
      }
      records.push(record)
      this.$emit('showElementsTab', 'summary', records.length > 0);
      return { records: records, recordCount: records.length }
    },
    rnfEstimationPivot: function () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.rnfEstimationVerified = false;
      }
      if (this.LineitemCampaignConfigurationData.length == 0 || this.selectedCampaignLauncherConfig.campaign.buyType != 4) {
        this.rnfEstimationVerified = true
        return {}
      }

      var records = []
      this.LineitemCampaignConfigurationData.forEach((lineitem, index) => {
        lineitem.reachAndFrequencyDataJson.forEach((rnfSettings, index) => {
          var rnfSettingsJson = JSON.parse(rnfSettings.reachAndFrequencySettings)
          var adgroup = this.adgroupsData.find(ag => ag.AdgroupData.RowData.LineItemId == lineitem.lineItemId && ag.AdgroupData.RowData.DeltaXTargetId == rnfSettings.targetId) || {}
          var record = {
            lineitemName: adgroup.Adgroup,
            lineItemId: lineitem.lineItemId,
            overAllState: rnfSettings.overAllState || "",
            overAllStatus: rnfSettings.overAllStatus || "",
            type: "Submitted",
            rnfSettings: rnfSettingsJson,
            frequencyCap: rnfSettingsJson.frequencyCap + " times, " + rnfSettingsJson.frequencyInterval + " days",
            targetId: rnfSettings.targetId,
            reachAndFrequencyPredictionId: rnfSettings.reachAndFrequencyPredictionId
          }
          records.push(record)
        })
      })
      let recordLength = records.length;
      this.$emit('showElementsTab', 'reachFrequencyEstimate', recordLength > 0);
      return { records: records, rnfCount: recordLength }
    },
    targetsPivot: function () {
      var adgroupsData = this.adgroupsData;
      if (this.viewType == "Changes") {
        adgroupsData = this.getFilteredAdgroup()
      }

      if (this.isCampaignLauncherVerificationEnabled && adgroupsData.length > 0) {
        this.targetsVerified = false;
      }

      if (adgroupsData.length == 0 && this.targetsVerified == false) {
        this.targetsVerified = true;
      }

      let targetsGroup = this.groupBy(adgroupsData, 'TargetId', (target, group) => ({
        bidsGroup: this.groupBy(group, 'Bid', (bid, bidGroup) => ({
          targetName: this.getTargetName(group[0].AdgroupData.RowData, target),
          targetReach: this.targetsReach,
          targetData: this.getTargetData(group[0].AdgroupData),
          excludedAudienceHtml: this.getExcludedAudienceHtml(group[0].AdgroupData),
          includedAudienceHtml: this.getIncludedAudienceHtml(group[0].AdgroupData),
          bid: bid,
          adgroups: bidGroup,
          targetKey: `${group[0].Bpse}-${target}`,
          ads: bidGroup.reduce((t, e) => t + e.Ads, 0),
          budget: bidGroup.reduce((t, e) => t + e.Budget, 0),
          targetHTML: this.getTargetHTML(group[0].TargetInformation, group[0].AdgroupData.customLocations, group[0].AdgroupData.zips, group[0].AdgroupData.excludedCustomAudienceIds, group[0].AdgroupData.includedCustomAudienceIds)
        }))
      }));
      let totalAdGroups = targetsGroup.map(x => x.bidsGroup).flat().map(x => x.adgroups).flat().length;
      let totalAds = targetsGroup.map(x => x.bidsGroup).flat().map(x => x.ads).flat().reduce((t, e) => t + e, 0);
      let totalBudget = targetsGroup.map(x => x.bidsGroup).flat().map(x => x.budget).flat().reduce((t, e) => t + e, 0);
      this.$emit('showElementsTab', 'targeting', totalAdGroups > 0);
      return { targetsGroup: targetsGroup, adGroups: totalAdGroups, ads: totalAds, budget: totalBudget }
    },
    locationsPivot: function () {
      var adgroupsData = this.adgroupsData;
      if (this.viewType == "Changes") {
        adgroupsData = this.getFilteredAdgroup()
      }
      if (this.isCampaignLauncherVerificationEnabled && adgroupsData.length > 0) {
        this.locationsVerified = false;
      }

      if (adgroupsData.length == 0 && this.locationsVerified == false) {
        this.locationsVerified = true;
      }

      let cities = adgroupsData.map(x => x.AdgroupData.cities).flat().map(x => x.replaceAll(']', '').replaceAll('[', '').split(',')).flat();
      let countries = adgroupsData.map(x => x.AdgroupData.countries).flat();
      let regions = adgroupsData.map(x => x.AdgroupData.regions).flat();
      let customLocations = adgroupsData.map(x => x.AdgroupData.customLocations).flat();
      let zips = adgroupsData.map(x => x.AdgroupData.zips).flat();

      let excludedCities = adgroupsData.map(x => x.AdgroupData.excludedCities).flat().map(x => x.replaceAll(']', '').replaceAll('[', '').split(',')).flat();
      let excludedCountries = adgroupsData.map(x => x.AdgroupData.excludedCountries).flat();
      let excludedRegions = adgroupsData.map(x => x.AdgroupData.excludedRegions).flat();
      let excludedCustomLocations = adgroupsData.map(x => x.AdgroupData.excludedCustomLocations).flat();
      let excludedZips = adgroupsData.map(x => x.AdgroupData.excludedZips).flat();

      let excludedLocations = [...new Set([...excludedCities, ...excludedCountries, ...excludedRegions, ...excludedCustomLocations, ...excludedZips])];
      let locations = [...new Set([...cities, ...countries, ...regions, ...customLocations, ...zips])];
      let locationsGroup = [];
      let excludedLocationsGroup = [];
      let loc = this.GetLocationGroups(adgroupsData, locations);
      let excLoc = this.GetLocationGroups(adgroupsData, excludedLocations, true);
      locationsGroup = Object.values(loc);
      excludedLocationsGroup = Object.values(excLoc);

      if (locationsGroup.length == 0 && excludedLocationsGroup.length == 0) {
        this.locationsVerified = true;
      }
      this.$emit('showElementsTab', 'locations', locationsGroup.length > 0);
      this.$emit('showElementsTab', 'excludedLocations', excludedLocationsGroup.length > 0);
      return { locationsGroup: locationsGroup, excludedLocationsGroup: excludedLocationsGroup }
    },
    pageSetsPivot: function () {
      var adgroupsData = this.adgroupsData;
      if (this.viewType == "Changes") {
        adgroupsData = this.getFilteredAdgroup()
      }

      if (this.isCampaignLauncherVerificationEnabled && adgroupsData.length > 0) {
        this.pageSetsVerified = false;
      }

      if (adgroupsData.length == 0 && this.pageSetsVerified == false) {
        this.pageSetsVerified = true;
      }

      let pageSetsGroup = [];
      if (adgroupsData.filter(pg => !pg.PageSets).length == 0) {
        pageSetsGroup = this.groupBy(adgroupsData, 'PageSets', (target, group) => ({
          pageSet: target,
          adGroups: group.length,
          ads: group.map(x => x.AdgroupData.ChildEntities).flat().length
        }));
      }

      if (pageSetsGroup.length == 0) {
        this.pageSetsVerified = true;
      }
      this.$emit('showElementsTab', 'pageSets', pageSetsGroup.length > 0);
      return { pageSetsGroup: pageSetsGroup }
    },
    placementsPivot: function () {
      var adgroupsData = this.adgroupsData;
      if (this.viewType == "Changes") {
        adgroupsData = this.getFilteredAdgroup()
      }
      if (this.isCampaignLauncherVerificationEnabled && adgroupsData.length > 0) {
        this.placementsVerified = false;
      }

      if (adgroupsData.length == 0 && this.placementsVerified == false) {
        this.placementsVerified = true;
      }

      let placementsGroup = this.groupBy(adgroupsData, 'Platforms', (platforms, group) => ({
        platforms: platforms,
        igPositions: group.map(e => e.AdgroupData.RowData.Placements.instagram_positions).join(', '),
        fbPositions: group.map(e => e.AdgroupData.RowData.Placements.facebook_positions).join(', '),
        anPositions: group.map(e => e.AdgroupData.RowData.Placements.audience_network_positions).join(', '),
        msPositions: group.map(e => e.AdgroupData.RowData.Placements.messenger_positions).join(', '),
        adgroups: group,
        ads: group.reduce((t, e) => t + e.Ads, 0),
        budget: group.reduce((t, e) => t + e.Budget, 0)
      }));
      let totalAdGroups = placementsGroup.map(x => x.adgroups).flat().length;
      let totalAds = placementsGroup.map(x => x.ads).flat().reduce((t, e) => t + e, 0);
      let totalBudget = placementsGroup.map(x => x.budget).flat().reduce((t, e) => t + e, 0);
      this.$emit('showElementsTab', 'placements', totalAdGroups > 0);
      return { placementsGroup: placementsGroup, adGroups: totalAdGroups, ads: totalAds, budget: totalBudget }
    },
    bpCurrency: function () {
      return this.adgroupsData[0].BpCurrency ? this.adgroupsData[0].BpCurrency : this.bpCurrencySymbol;
    },
    allVerified: function () {
      this.$emit('elementsVerified', [
        { value: this.summaryVerified, label: 'Summary' },
        { value: this.budgetTypeVerified, label: 'Budget Type' },
        { value: this.rnfEstimationVerified, label: 'Reach & Frequency Estimate' },
        { value: this.targetsVerified, label: 'Targeting' },
        { value: this.locationsVerified, label: 'Locations' },
        { value: this.pageSetsVerified, label: 'Page Sets' },
        { value: this.pageVerified, label: 'Pages and Creative' },
        { value: this.placementsVerified, label: 'Placements' }
      ]);
      return '';
    },
    containChanges: function () {
      // If there are any changes under campaign we need to show a red astricks
      return (data) => {
        return data.RowData.Changes ? data.RowData.Changes.filter(x => x.Status == 1).length > 0 : false
      }
    },
    submissionIdUrlParam: function () {
      return this.submissionId > 0 ? this.submissionId : "{{submissionIdMacro}}"
    }
  },
  filters: {
    currency: function (value, bpCurrency) {
      return isNaN(value) ? value
        : `${bpCurrency} ${parseFloat(value).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1
        })}`;
    },
    reach: function (value, adGroup) {
      return 'Estimated Reach: ' + (value[adGroup.targetKey] ? `${value[adGroup.targetKey].toLocaleString()} people` : 'Unknown');
    },
    placement: function (value, platform, platforms) {
      if (platform && !(platforms.includes(platform) || platforms.includes("All"))) {
        return '-';
      }
      return [...new Set(value.split(', '))].join(', ');
    },
    budgetString (budget, durationInDays, isLifetimeBudget, bpCurrencySymbol) {
      if (isLifetimeBudget) {
        var parsedBudget = parseFloat(budget)
        return bpCurrencySymbol + " " + parseFloat(parsedBudget.toFixed(2)).toLocaleString("en")
      }
      return bpCurrencySymbol + " " + parseFloat((durationInDays * Number(budget)).toFixed(2)).toLocaleString("en") + " (" + parseFloat(Number(budget).toFixed(2)).toLocaleString("en") + " per day)"
    },
    showDashWhenIdNull: function (value) {
      if (!value) return "-"
      return " (" + value + ") "
    },
    showDashWhenNull: function (value) {
      if (!value) return "-"
      return value
    },
    parseBudgetInLocale: function (value) {
      if (!value) return "-"
      var floatValue = parseFloat(value);
      return parseFloat(floatValue.toFixed(2)).toLocaleString("en")
    },
    parseIntInLocale: function (value) {
      if (!value) return value
      return Number(value).toLocaleString('en')
    }
  },
  methods: {
    /*
    Used to group an array 'arr' containing property given as 'key'. Similar to GroupBy in C# Linq
    This will return an array as [{key: 'key', group: [{object matching property 'key'}] }];
    An optional selector(func) can be sent that will execute on each object in array with parameters (key, group)
    Eg: arr = [{a:'1', b:'1'},{a:'2', b:'2'},{a:'1', b:'2'}]
    groupBy(arr, 'a') will return [{key: '1', group: [{a:'1', b:'1'}, {a:'1', b:'2'}]}, {key:'2', group: [{a:'2', b:'2'}]}]
    groupBy(arr, 'typeof a') will return [{key: 'string', group: [{a:'1', b:'1'}, {a:'1', b:'2'}, {a:'2', b:'2'}]}]
    groupBy(arr, 'a', (key, group) => key) will return ['1','2']
    */
    ...mapMutations(["SET_FACEBOOK_ENTITIES"]),
    updateCreativeInPreview (pageNumber) {
      this.creativeInPreview = pageNumber;
    },
    GetLocationGroups (adgroupsData, locations, isExcluded = false) {
      let temp = {}

      if (isExcluded) {
        adgroupsData.forEach((x) => {
          x.adgroupExcludedLocations = [...new Set([...x.AdgroupData.excludedCities, ...x.AdgroupData.excludedCountries, ...x.AdgroupData.excludedRegions, ...x.AdgroupData.excludedCustomLocations, ...x.AdgroupData.excludedZips])]
          x.adgroupExcludedLocations = x.adgroupExcludedLocations.map(x => x.replaceAll(']', '').replaceAll('[', ''))
        })

        adgroupsData.forEach((x) => {
          locations.forEach((loc) => {
            if (x.adgroupExcludedLocations.includes(loc)) {
              if (temp[loc]) {
                temp[loc].adgroups.push(x)
                temp[loc].ads = temp[loc].ads + x.AdgroupData.ChildEntities.length
                temp[loc].budget = temp[loc].budget + x.Budget
              } else {
                temp[loc] = {}
                temp[loc].adgroups = []
                temp[loc].adgroups.push(x)
                temp[loc].ads = x.AdgroupData.ChildEntities.length
                temp[loc].budget = x.Budget
                temp[loc].location = loc
              }
            }
          })
        })
      } else {
        adgroupsData.forEach((x) => {
          x.adgroupLocations = [...new Set([...x.AdgroupData.cities, ...x.AdgroupData.countries, ...x.AdgroupData.regions, ...x.AdgroupData.customLocations, ...x.AdgroupData.zips])]
          x.adgroupLocations = x.adgroupLocations.map(x => x.replaceAll(']', '').replaceAll('[', ''))
        })

        adgroupsData.forEach((x) => {
          locations.forEach((loc) => {
            if (x.adgroupLocations.includes(loc)) {
              if (temp[loc]) {
                temp[loc].adgroups.push(x)
                temp[loc].ads = temp[loc].ads + x.AdgroupData.ChildEntities.length
                temp[loc].budget = temp[loc].budget + x.Budget
              } else {
                temp[loc] = {}
                temp[loc].adgroups = []
                temp[loc].adgroups.push(x)
                temp[loc].ads = x.AdgroupData.ChildEntities.length
                temp[loc].budget = x.Budget
                temp[loc].location = loc
              }
            }
          })
        })
      }

      return temp;
    },
    getFilteredAdgroup () {
      var clone = JSON.parse(JSON.stringify(this.adgroupsData))
      var data = clone.filter(x => !x.AdgroupData.Id);
      data.forEach(elem => {
        if (elem.AdgroupData.ChildEntities) {
          elem.AdgroupData.ChildEntities = elem.AdgroupData.ChildEntities.filter(z => !z.Id)
        }
      });
      return data;
    },
    groupBy: function (arr, key, selector) {
      var groupObject = arr.reduce((group, element) => {
        if (key.includes('typeof ')) {
          let type = key.split('typeof ')[1];
          (group[typeof element[type]] = group[typeof element[type]] || []).push(element);
        } else if (key.includes('.')) {
          let props = key.split('.');
          (group[element[props[0]][props[1]]] = group[element[props[0]][props[1]]] || []).push(element);
        } else {
          // get the group array with key as element's key property value or new empty array and push element into group array
          (group[element[key]] = group[element[key]] || []).push(element);
        }
        return group;
      }, {});
      var array = [];
      Object.keys(groupObject).forEach(k => array.push({ key: k, group: groupObject[k] }));
      return selector != null ? array.map(a => selector(a.key, a.group)) : array;
    },
    getPreviewThumbnail (hash, fallbackUrl) {
      var url = fallbackUrl;
      if (hash) {
        if (!this.isSubmission) {
          if (this.creativeMedia && this.creativeMedia.images) {
            var media = this.creativeMedia.images.filter(e => { return e.imageHash && e.imageHash == hash })
            return media.length > 0 ? media[0].imageUrl : url;
          } else {
            return url;
          }
        } else {
          var urlMapKeys = Object.keys(this.imageIdUrlMap);
          var keys = urlMapKeys.filter(e => { return this.imageIdUrlMap[e] && this.imageIdUrlMap[e]['hash'] && this.imageIdUrlMap[e]['hash'] == hash })
          return keys.length > 0 ? this.imageIdUrlMap[keys[0]].src : url;
        }
      } else {
        return url
      }
    },
    getAssetSpecData: function (data) {
      if (!data || !data.asset_customization_rules) {
        return null;
      }

      var assetStrucList = [];
      for (let e of data.asset_customization_rules) {
        let assetStruc = {};
        if (e.body_label && e.body_label.name && data.bodies) {
          assetStruc.body = this.extractAdlabelInfo(data.bodies, e.body_label.name, "text")
        }
        if (!assetStruc.body && assetStrucList.length > 0) {
          assetStruc.body = assetStrucList[0].body
        }
        if (e.image_label && e.image_label.name && data.images) {
          assetStruc.image = this.extractAdlabelInfo(data.images, e.image_label.name, "url")
        }
        if (!assetStruc.image && assetStrucList.length > 0) {
          assetStruc.image = assetStrucList[0].image
        }
        if (e.link_url_label && e.link_url_label.name && data.link_urls) {
          assetStruc.link = this.extractAdlabelInfo(data.link_urls, e.link_url_label.name, "website_url")
        }
        if (!assetStruc.link && assetStrucList.length > 0) {
          assetStruc.link = assetStrucList[0].link
        }
        if (e.title_label && e.title_label.name && data.titles) {
          assetStruc.title = this.extractAdlabelInfo(data.titles, e.title_label.name, "text")
        }
        if (!assetStruc.title && assetStrucList.length > 0) {
          assetStruc.title = assetStrucList[0].title
        }
        let temp = assetStruc.image;
        if (e.video_label && e.video_label.name && data.videos) {
          assetStruc.image = this.extractAdlabelInfo(data.videos, e.video_label.name, "thumbnail_url")
        }
        if (!assetStruc.image) {
          assetStruc.image = temp
        }

        if (!this.isObjectExistsInArray(assetStrucList, assetStruc)) {
          assetStrucList.push(assetStruc)
        }
        if (assetStrucList.length == 3) {
          break;
        }
      }
      return assetStrucList;
    },
    isObjectExistsInArray: function (assetStrucList, assetStruc) {
      for (var item of assetStrucList) {
        if (item.image == assetStruc.image && item.body == assetStruc.body && item.link == assetStruc.link && item.title == assetStruc.title) {
          return true;
        }
      }
      return false;
    },
    extractAdlabelInfo: function (arr, id, propertyName) {
      var value = null;
      arr.forEach(el1 => {
        if (el1.adlabels) {
          el1.adlabels.forEach(el2 => {
            if (el2.name == id) {
              value = el1[propertyName];

              if (propertyName == "url" && !value && el1["hash"] && this.$store.state.imageIdUrlMap) {
                for (let key in this.$store.state.imageIdUrlMap) {
                  if (this.$store.state.imageIdUrlMap[key].hash == el1["hash"]) {
                    value = this.$store.state.imageIdUrlMap[key].src;
                  }
                }
              }
            }
          });
        }
      });
      return value;
    },
    getCreativeData: function (data) {
      var p;
      if (!data) {
        return null;
      }

      // making a clone to prevent modification to original object.
      var obj = JSON.parse(JSON.stringify(data));
      for (p in obj) {
        if (obj.hasOwnProperty(p) && obj[p] && typeof obj[p] === 'object') {
          if (obj[p].child_attachments) {
            obj[p].child_attachments.forEach(element => {
              // Video ads in child components
              if (element.video_id) {
                element.name = element.title;
                element.description = element.link_description;
                element.picture = element.image_url;
              } else {
                var defaultUrl = deltax.s3BucketUrl + "/AllFiles/Ads/dpa-mpa-default.png";
                element.picture = this.getPreviewThumbnail(element.image_hash, defaultUrl)
              }
            });
            return obj[p];
          } else if (obj[p].caption || obj[p].url || obj[p].link || obj[p].message || obj[p].name || obj[p].description || obj[p].picture || obj[p].video_id) {
            // video ads
            if (obj[p].video_id) {
              obj[p].name = obj[p].title;
              obj[p].description = obj[p].link_description;
              obj[p].picture = this.getPreviewThumbnail(obj[p].image_hash, obj[p].image_url);
            } else if (!(obj[p].caption || obj[p].url || obj[p].message || obj[p].name || obj[p].description || obj[p].picture || obj[p].video_id || obj[p].image_hash)) {
              // if no data is available except link => event ads
              obj[p].name = obj[p].link
            } else if (!obj[p].picture) {
              if (obj[p].url) {
                // for page likes objective property names are different (url and caption)
                obj[p].picture = this.getPreviewThumbnail(obj[p].image_hash, obj[p].url);
                obj[p].name = obj[p].name ? obj[p].name : obj[p].caption
              } else {
                obj[p].picture = this.getPreviewThumbnail(obj[p].image_hash, deltax.s3BucketUrl + "/AllFiles/Ads/dpa-mpa-default.png")
              }
            }
            return obj[p];
          }
        }
      }
      if (obj.object_story_id) {
        // for existing posts get image url from all posts.
        var post = this.posts.filter(x => x.pagePostId == obj.object_story_id)
        if (post && post.length > 0) {
          var res = {}
          res.picture = post[0].imgUrl && post[0].imgUrl.src ? post[0].imgUrl.src : deltax.s3BucketUrl + "/AllFiles/Ads/dpa-mpa-default.png"
          res.name = post[0].title
          return res;
        }
      }
      return null;
    },
    getSamplePreview: function (creativeData, sc, fc) {
      let defaultAdFormat = "MOBILE_FEED_STANDARD"
      var accountId = ""
      if (this.selectedCampaignLauncherConfig.adset) {
        accountId = this.selectedCampaignLauncherConfig.adset
          .isDynamicCreative
          ? this.selectedCampaignLauncherConfig.facebookAccountId
          : "";
      }
      // if (creativeName.includes("collection")) {
      //   defaultAdFormat = "MOBILE_FEED_STANDARD"
      // }
      // let data = JSON.stringify({ ad_format: 'RIGHT_COLUMN_STANDARD', height: 300, width: 400 });
      // ApiService.GetPreviews(accountId, creativeId, data, sc, fc);
      var data = { creative: creativeData, ad_format: defaultAdFormat, height: 300, width: 400 }
      ApiService.GenerateAdPreview(JSON.stringify(data), this.businessProfilesSearchEngineId, sc, fc, accountId)
    },
    setPagesPivotData: function () {
      if (this.adsData.length > 0 && this.isCampaignLauncherVerificationEnabled) { this.pageVerified = false; }
      let filteredAds = [];
      if (this.isSubmission && this.viewType == "Changes") {
        filteredAds = this.adsData.filter(x => x.IsAdPresent == true && (x.AdId == "" || x.AdStatus == "AdEdited" || x.AdStatus == "AdEditedPartial"))
      } else {
        filteredAds = this.adsData.filter(x => x.IsAdPresent == true);
      }
      if (filteredAds.length == 0) {
        this.pageVerified = true
      }
      this.locationMode = this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign && this.selectedCampaignLauncherConfig.campaign.locationMode ? this.selectedCampaignLauncherConfig.campaign.locationMode : fbEnums.locationMode.Standard;
      let pageType = this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.adset && this.selectedCampaignLauncherConfig.adset.pageType ? this.selectedCampaignLauncherConfig.adset.pageType : null;
      if (this.locationMode == fbEnums.locationMode.Hyperlocal && pageType) {
        // we are manipulating the way summary page is shown so that creatives are properly visible for 1campForAllStores for all stores
        let creativesArray = [];
        for (let adIndex = 0; adIndex < filteredAds.length; adIndex++) {
          if (filteredAds[adIndex].Creative && filteredAds[adIndex].AdData && filteredAds[adIndex].AdData.ApiData && Object.keys(filteredAds[adIndex].Creative).length && Object.keys(filteredAds[adIndex].AdData).length) {
            let pagePivot = {
              pageName: filteredAds[adIndex].AdgroupData.Name.split(" ")[filteredAds[adIndex].AdgroupData.Name.split(" ").length - 1] + (filteredAds[adIndex].AdData.ApiData.creative && filteredAds[adIndex].AdData.ApiData.creative.object_story_spec ? ' Facebook Page ID:' + filteredAds[adIndex].AdData.ApiData.creative.object_story_spec.page_id : ""),
              creativesGroup: []
            }
            let creativeGroup = {
              adName: filteredAds[adIndex].Ad,
              adLabels: filteredAds[adIndex].AdData.AdditionalData.adLabels,
              adCopyId: filteredAds[adIndex].AdId ? filteredAds[adIndex].AdId + ' (' + filteredAds[adIndex].Creative.CreativeName + ') ' + filteredAds[adIndex].AdgroupData.Name.split(" ")[filteredAds[adIndex].AdgroupData.Name.split(" ").length - 1] : filteredAds[adIndex].Creative.CreativeName + filteredAds[adIndex].AdgroupData.Name.split(" ")[filteredAds[adIndex].AdgroupData.Name.split(" ").length - 1],
              adId: filteredAds[adIndex].AdId,
              accountId: filteredAds[adIndex].AdData.AccountId,
              creativeId: filteredAds[adIndex].AdData.AdditionalData.HotRowId ? filteredAds[adIndex].AdData.AdditionalData.HotRowId : filteredAds[adIndex].Creative.HotRowId,
              creativeData: filteredAds[adIndex].AdData.AdditionalData.creative_data,
              creativeInfo: this.getCreativeData(filteredAds[adIndex].AdData.AdditionalData.creative_data.object_story_spec ? filteredAds[adIndex].AdData.AdditionalData.creative_data.object_story_spec : filteredAds[adIndex].AdData.AdditionalData.creative_data),
              assetSpecInfo: this.getAssetSpecData(filteredAds[adIndex].AdData.AdditionalData.creative_data.asset_feed_spec ? filteredAds[adIndex].AdData.AdditionalData.creative_data.asset_feed_spec : filteredAds[adIndex].AdData.AdditionalData.creative_data),
              adStatus: filteredAds[adIndex].AdData.ApiData.status || "-",
              previewLink:
                window.location.origin +
                "/App/CampaignLauncher/AdPreview/" +
                window.businessProfileId +
                "?submissionId=" +
                this.submissionIdUrlParam +
                "&hotRowId=" +
                filteredAds[adIndex].Creative.HotRowId +
                "&accountId=" +
                filteredAds[adIndex].AdData.AccountId +
                "&businessProfilesSearchEngineId=" +
                this.businessProfilesSearchEngineId +
                "&type=AdPreview&authKey=&key=" +
                deltax.authKey +
                "&userId=" +
                deltax.userId,
              previewTrigger: crt => {
                if (crt.preview == 'Loading Preview...') {
                  this.getSamplePreview(filteredAds[adIndex].AdData.AdditionalData.creative_data,
                    response => {
                      try {
                        let preview = JSON.parse(response.data);
                        if (this.selectedCampaignLauncherConfig.adset.isDynamicCreative) {
                          crt.preview = preview[0].body
                        } else {
                          crt.preview = preview.data[0].body;
                        }
                        this.$nextTick(function () {
                          $("#" + crt.adCopyId + ">iframe").attr("scrolling", "no");
                        });
                      } catch {
                        crt.preview = 'Failed to load preview';
                        console.log(response);
                      }
                    },
                    failure => {
                      crt.preview = 'Failed to load preview';
                      console.log(failure);
                    }
                  )
                }
              },
              preview: 'Loading Preview...',
              landingPages: this.getLandingPages(filteredAds[adIndex]),
              targets: [...new Set(filteredAds.map(x => x.AdgroupData.RowData.TargetName))], // distinct targets
              instantExperience: filteredAds[adIndex].Creative.InstantExperience || false
            }

            const existingObject = creativesArray.find(obj => obj.pageName === pagePivot.pageName);
            if (existingObject) {
              existingObject.creativesGroup.push(creativeGroup);
            } else {
              pagePivot.creativesGroup.push(creativeGroup);
              creativesArray.push(pagePivot);
            }
          }
        }
        this.pagesPivot = creativesArray;
      } else {
        this.pagesPivot = this.groupBy(filteredAds, 'Creative.PageId', (pageId, pageGroup) => {
          if (!pageGroup[0].Creative || !pageGroup[0].AdData || !Object.keys(pageGroup[0].Creative).length || !Object.keys(pageGroup[0].AdData).length) {
            return null;
          }
          return {
            pageName: `${pageGroup[0].Creative.PageName} (${pageGroup[0].Creative.PageId})`,
            creativesGroup: this.groupBy(pageGroup, 'Creative.HotRowIdEdit', (adName, creativeGroup) => ({
              adName: creativeGroup[0].Ad,
              adLabels: creativeGroup[0].AdData.AdditionalData.adLabels,
              adCopyId: creativeGroup[0].AdId ? creativeGroup[0].AdId + ' (' + creativeGroup[0].Creative.CreativeName + ')' : creativeGroup[0].Creative.CreativeName,
              adId: creativeGroup[0].AdId,
              accountId: creativeGroup[0].AdData.AccountId,
              creativeId: creativeGroup[0].AdData.AdditionalData.HotRowId ? creativeGroup[0].AdData.AdditionalData.HotRowId : creativeGroup[0].Creative.HotRowId,
              creativeData: creativeGroup[0].AdData.AdditionalData.creative_data,
              creativeInfo: this.getCreativeData(creativeGroup[0].AdData.AdditionalData.creative_data.object_story_spec ? creativeGroup[0].AdData.AdditionalData.creative_data.object_story_spec : creativeGroup[0].AdData.AdditionalData.creative_data),
              assetSpecInfo: this.getAssetSpecData(creativeGroup[0].AdData.AdditionalData.creative_data.asset_feed_spec ? creativeGroup[0].AdData.AdditionalData.creative_data.asset_feed_spec : creativeGroup[0].AdData.AdditionalData.creative_data),
              adStatus: creativeGroup[0].AdData.ApiData.status || "-",
              previewLink:
                window.location.origin +
                "/App/CampaignLauncher/AdPreview/" +
                window.businessProfileId +
                "?submissionId=" +
                this.submissionIdUrlParam +
                "&hotRowId=" +
                creativeGroup[0].Creative.HotRowId +
                "&accountId=" +
                creativeGroup[0].AdData.AccountId +
                "&businessProfilesSearchEngineId=" +
                this.businessProfilesSearchEngineId +
                "&type=AdPreview&authKey=&key=" +
                deltax.authKey +
                "&userId=" +
                deltax.userId,
              previewTrigger: crt => {
                if (crt.preview == 'Loading Preview...') {
                  this.getSamplePreview(creativeGroup[0].AdData.AdditionalData.creative_data,
                    response => {
                      try {
                        let preview = JSON.parse(response.data);
                        if (this.selectedCampaignLauncherConfig.adset.isDynamicCreative) {
                          crt.preview = preview[0].body
                        } else {
                          crt.preview = preview.data[0].body;
                        }
                        this.$nextTick(function () {
                          $("#" + crt.adCopyId + ">iframe").attr("scrolling", "no");
                        });
                      } catch {
                        crt.preview = 'Failed to load preview';
                        console.log(response);
                      }
                    },
                    failure => {
                      crt.preview = 'Failed to load preview';
                      console.log(failure);
                    }
                  )
                }
              },
              preview: 'Loading Preview...',
              landingPages: this.getLandingPages(creativeGroup[0]),
              targets: [...new Set(creativeGroup.map(x => x.AdgroupData.RowData.TargetName))], // distinct targets
              instantExperience: creativeGroup[0].Creative.InstantExperience || false
            }))
          }
        }).filter(x => x !== null);
      }
      this.$emit('showElementsTab', 'pagesCreative', this.pagesPivot.length > 0);
    },
    getLandingPageUrls (creativeGroup) {
      var urls = [];
      var assets = this.getAssetSpecData(creativeGroup.AdData.AdditionalData.creative_data.asset_feed_spec ? creativeGroup.AdData.AdditionalData.creative_data.asset_feed_spec : creativeGroup.AdData.AdditionalData.creative_data)
      if (assets && assets.length > 0) {
        for (let item of assets) {
          if (item.link) {
            urls.push(item.link);
          }
        }
      }
      return [...new Set(urls)];
    },
    getLandingPages (creativeGroup) {
      if (creativeGroup.Creative.adLevelLandingPages != null) {
        return creativeGroup.Creative.adLevelLandingPages;
      }
      return creativeGroup.Creative.LandingPages ? creativeGroup.Creative.LandingPages.concat(this.getLandingPageUrls(creativeGroup)) : this.getLandingPageUrls(creativeGroup)
    },
    setTargetsReach: function () {
      var bpseTargets = this.groupBy(this.adgroupsData, 'Bpse', (bpse, group) => ({
        bpseId: bpse,
        bpseTargetIds: [...new Set(group.map(x => x.TargetId))]
      }));

      let storeCodes;
      if (this.isPromotionMode ||
            (this.selectedCampaignLauncherConfig &&
              this.selectedCampaignLauncherConfig.campaign &&
                (this.selectedCampaignLauncherConfig.campaign.locationMode == fbEnums.locationType.Hyperlocal && this.selectedCampaignLauncherConfig.campaign.adgroupPerLocation == 'all'))) {
        // In the summary page, the targets details are shown with the custom store locations only when the adgrouplocationMode is 'all'
        // In the other case, the target info shown in the summary doesn't include custom store locations, since each store location will be applied separately during export.
        // Therefore, we show the reachestimate by including store locations only in case of 'all'
        storeCodes = this.mappedLineItems[0].locations;
      }
      bpseTargets.forEach(bpse => {
        ApiService.GetTargetsReach(parseInt(window.businessProfileId), parseInt(bpse.bpseId), bpse.bpseTargetIds, 'LINK_CLICKS', storeCodes,
          response => {
            response.forEach(target => {
              this.$set(this.targetsReach, `${bpse.bpseId}-${target.targetId}`, target.reachEstimate);
            })
          })
      });
    },
    setRnfData: function () {
      this.RnfDataProcessing = true
      var mediaPlanId = this.mediaPlanId
      ApiService.GetLineitemCampaignConfigurationData(
        this.selectedCampaignLauncherConfigId,
        mediaPlanId,
        this.successRnfDataCallback,
        this.failureRnFDataCallback
      );
    },
    successRnfDataCallback: function (response) {
      if (response.status == 200) {
        this.LineitemCampaignConfigurationData = response.data.lineItemCampaignConfigurations
        var allRnFPredictionIds = []
        for (var liData of this.LineitemCampaignConfigurationData) {
          liData.reachAndFrequencyDataJson = liData.reachAndFrequencyData ? JSON.parse(liData.reachAndFrequencyData) : []
          allRnFPredictionIds = allRnFPredictionIds.concat(liData.reachAndFrequencyDataJson.map(rnf => rnf.reachAndFrequencyPredictionId))
        }
        if (allRnFPredictionIds && allRnFPredictionIds.length > 0) {
          this.verifyStatusOfRnF(allRnFPredictionIds);
        } else {
          this.RnfDataProcessing = false
        }
      }
    },
    failureRnFDataCallback: function (response) {
      console.log("Api call failed to fetch Rnf Data", response)
      this.RnfDataProcessing = false
    },
    verifyStatusOfRnF: function (allRnFPredictionIds) {
      this.RnfDataProcessing = true
      var self = this
      ApiService.GetPredictionStatusInBulk(this.businessProfilesSearchEngineId, allRnFPredictionIds)
        .then(response => {
          var predictionStatuses = JSON.parse(response.data);
          predictionStatuses.forEach(predictionStatus => {
            var li = self.LineitemCampaignConfigurationData.find(x => x.reachAndFrequencyDataJson.find(rnf => rnf.reachAndFrequencyPredictionId == predictionStatus.id));
            var record = li.reachAndFrequencyDataJson.find(r => r.reachAndFrequencyPredictionId == predictionStatus.id);
            record.status = predictionStatus.status;
            record.message = predictionStatus.message;
            record.overAllState = predictionStatus.message
            if (predictionStatus.status != 1) {
              // this.generateNewPrediction(record)
              record.overAllStatus = 10
            } else {
              record.overAllStatus = 8
            }
            self.TriggerChangeInLineitemCampaignConfigurationData();
          })
          self.RnfDataProcessing = false
        })
        .catch(error => {
          self.RnfDataProcessing = false
          console.log("Error while fetching status of rnf", error)
        })
    },
    generateNewPrediction (failedPrediction) {
      var self = this
      self.RnfDataProcessing = true
      var failedPredictionObject = JSON.parse(failedPrediction.reachAndFrequencySettings)
      var storyEventType = failedPredictionObject.adformat.reduce((s, e) => {
        if (self.adFormatStoryEventTypes[e] != undefined) {
          return s + self.adFormatStoryEventTypes[e];
        } else {
          return s;
        }
      }, 0)
      var data = {
        configId: this.selectedCampaignLauncherConfigId,
        settings: JSON.stringify({
          budget: 0,
          startDate: this.alterPastDateAndAddBuffer(failedPredictionObject.startDate),
          endDate: failedPredictionObject.endDate,
          frequencyInterval: failedPredictionObject.frequencyInterval,
          frequencyCap: failedPredictionObject.frequencyCap,
          placements: failedPredictionObject.placements,
          placementMode: failedPredictionObject.placementMode,
          storyEventType: storyEventType
        }),
        targetId: failedPredictionObject.targetId
      }
      ApiService.GenerateRnFPrediction(failedPrediction.reachAndFrequencyPredictionId, data, this.successGenerateRnfDataCallback, this.failureGenerateRnfDataCallback);
    },
    successGenerateRnfDataCallback (response, failedPredictionId, failedPrediction) {
      var li = this.LineitemCampaignConfigurationData.find(x => x.reachAndFrequencyDataJson.find(rnf => rnf.reachAndFrequencyPredictionId == failedPredictionId));
      var failedPredictionObject = li.reachAndFrequencyDataJson.find(r => r.reachAndFrequencyPredictionId == failedPredictionId);
      if (response.status == 200 && response.data.status == 1) {
        var parsedData = this.extractDataFromPredictionGenerated(response.data.data, failedPredictionId, failedPrediction)
        failedPredictionObject.rnfSettings = parsedData
        failedPredictionObject.frequencyCap = parsedData.frequencyCap + " times, " + parsedData.frequencyInterval + " days"
        failedPredictionObject.status = 1
        failedPredictionObject.type = "Current"
        failedPredictionObject.overAllState = this.GetTextComparingCpa(failedPredictionObject.rnfSettings.cpa, parsedData.cpa);
        failedPredictionObject.overAllStatus = 8
      } else {
        failedPredictionObject.overAllState = response.data.message
        failedPredictionObject.overAllStatus = 10
      }
      this.TriggerChangeInLineitemCampaignConfigurationData()
      this.RnfDataProcessing = false
    },
    failureGenerateRnfDataCallback (response) {
      console.log("Api call failed to fetch Rnf Data", response)
      this.RnfDataProcessing = false
    },
    alterPastDateAndAddBuffer (date) {
      if (!date) return this.getDateString(new Date())
      else if (new Date(date) < new Date()) {
        return this.getDateString(new Date())
      }
      return null
    },
    getDateString (inputDate) {
      var diff = 1 //  Adding buffer time.
      var date = new Date(inputDate.getTime() + diff * 60000);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hour = date.getHours();
      var hours = hour == 0 ? 12 : (hour < 10 ? `0${hour}` : hour);
      var minutes = date.getMinutes();
      minutes = minutes > 10 ? minutes : `0${minutes}`;
      var currentDate = month + "/" + day + "/" + year + " " + hours + ":" + minutes;
      return currentDate;
    },
    extractDataFromPredictionGenerated (rawData, failedPredictionId, previousData) {
      var rnfCleanData = {}
      var previousProcessedData = JSON.parse(previousData.settings)
      var li = this.LineitemCampaignConfigurationData.find(x => x.reachAndFrequencyDataJson.find(rnf => rnf.reachAndFrequencyPredictionId == failedPredictionId));
      var failedPredictionObject = li.reachAndFrequencyDataJson.find(r => r.reachAndFrequencyPredictionId == failedPredictionId);
      var reachAndFrequencySettingsJson = JSON.parse(failedPredictionObject.reachAndFrequencySettings)
      rnfCleanData.budget = reachAndFrequencySettingsJson.budget * 100;
      var closestBudgetInArray = rawData.curve_budget_reach.budget.reduce(function (prev, curr) {
        return (Math.abs(curr - rnfCleanData.budget) < Math.abs(prev - rnfCleanData.budget) ? curr : prev);
      });
      var lowerIndex = rawData.curve_budget_reach.budget.findIndex(x => x == closestBudgetInArray)
      var upperIndex = rawData.curve_budget_reach.budget.length >= lowerIndex ? lowerIndex + 1 : lowerIndex

      // y−y1 = ((y2−y1)/(x2−x1))(x−x1) Equation to find point y in graph given a budget

      var y2 = rawData.curve_budget_reach.reach[upperIndex]
      var y1 = rawData.curve_budget_reach.reach[lowerIndex]
      var x2 = rawData.curve_budget_reach.budget[upperIndex]
      var x1 = rawData.curve_budget_reach.budget[lowerIndex]
      var x = rnfCleanData.budget
      var y = Math.round(Math.abs((((y2 - y1) / (x2 - x1)) * (x - x1)) - y1))
      rnfCleanData = {
        budget: parseFloat(rnfCleanData.budget / 100).toFixed(2).toString(),
        reach: y,
        id: rawData.id,
        frequencyCap: previousProcessedData.frequencyCap,
        frequencyInterval: previousProcessedData.frequencyInterval,
        percentageOfUniverse: parseFloat((y * 100) / rawData.target_audience_size).toFixed(2),
        cpa: (rawData.external_budget / rawData.external_impression * 1000).toFixed(2),
        frequency: (rawData.external_impression / rawData.external_reach).toFixed(2),
        impressions: rawData.external_impression,
        universe: rawData.target_audience_size,
        startDate: previousProcessedData.startDate,
        endDate: previousProcessedData.endDate
      }
      return rnfCleanData;
    },
    TriggerChangeInLineitemCampaignConfigurationData () {
      var stageData = JSON.parse(JSON.stringify(this.LineitemCampaignConfigurationData))
      this.LineitemCampaignConfigurationData = []
      this.LineitemCampaignConfigurationData = stageData
    },
    GetTextComparingCpa (oldCpa, newCpa) {
      if (!oldCpa || !newCpa) {
        return "Valid";
      } else if (oldCpa < newCpa) {
        return "Revised estimate is higher than submitted rates"
      } else if (oldCpa > newCpa) {
        return "Revised estimate is lower than submitted rates"
      } else {
        return ""
      }
    },
    getTargetName: function (row, target) {
      return `${row.TargetName} (DXT-${(row.Base36BpIdInTarget != '' ? `${row.Base36BpIdInTarget}-` : '')}${target})`;
    },
    getTargetData: function (adgroup) {
      var str = "";
      if (this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign && this.selectedCampaignLauncherConfig.campaign.adgroupPerLocation == "one") {
        str = `Age: ${adgroup.age}, Gender: ${adgroup.gender}, Location Types: ${adgroup.locationTypes}`
      } else {
        str = `Age: ${adgroup.age}, Gender: ${adgroup.gender}, Locations: ${[...new Set([...adgroup.cities, ...adgroup.regions, ...adgroup.countries, ...adgroup.customLocations, ...adgroup.zips])].join(', ')}, Location Types: ${adgroup.locationTypes}`
      }
      return str
    },
    getExcludedAudienceHtml: function (adgroup) {
      var str = "";
      if (adgroup.excludedCustomAudienceIds.length > 0) {
        let ids = "";
        for (let i = 0; i < adgroup.excludedCustomAudienceIds.length; i++) {
          ids += `<li> ${adgroup.excludedCustomAudienceIds[i]} </li>`;
        }
        str = `Excluded Custom Audience Ids: <ul style="padding-left: 20px;"> ${ids} </ul> </span>`
      }
      return str;
    },
    getIncludedAudienceHtml: function (adgroup) {
      var str = "";
      if (adgroup.includedCustomAudienceIds.length > 0) {
        let ids = "";
        for (let i = 0; i < adgroup.includedCustomAudienceIds.length; i++) {
          ids += `<li> ${adgroup.includedCustomAudienceIds[i]} </li>`;
        }
        str = `<span> Included Custom Audience Ids: <ul style="padding-left: 20px;"> ${ids} </ul> </span>`
      }
      return str;
    },
    previewInstantExperience: function (creative) {
      if (creative.instantExperience) {
        EventBus.$emit("instantExperienceWidget-previewCanvas", creative.instantExperience.Id);
      }
    },
    showMorePreviews: function (creative, index) {
      this.previewModal = true;
      this.previewTitle = `Creative ${creative.adCopyId}`;
      this.previewAccountId = undefined;
      if (this.selectedCampaignLauncherConfig.adset) {
        this.previewAccountId = creative.accountId
      }
      this.previewCreativeId = creative.creativeId;
      if (this.isPromotionMode) {
        this.previewCreativeData = { creative: creative.creativeData }
      } else {
        this.creativeInPreview = 0;
        var self = this;
        this.$nextTick(function () {
          self.creativeInPreview = index + 1;
        });
      }
    },
    getTargetHTML (targetString, customLocations = [], zips = [], excludedCustomAudienceIds = [], includedCustomAudienceIds = []) {
      targetString = JSON.parse(targetString)
      if (this.isPromotionMode) {
        // Clear target location values and assign default store location
        const locationTypes = ["Countries", "Regions", "Cities", "Zips", "GeoMarkets", "Address", "LatLong", "LocationTypes"]
        locationTypes.forEach(locType => {
          targetString[locType] = null
        })
        if (customLocations.length > 0) {
          targetString.LocationTypes = customLocations.join("; ")
        }
        if (zips.length > 0) {
          targetString.Zips = zips.join("; ")
        }
        targetString["ExcludedAudiances"] = excludedCustomAudienceIds.join(", ");
        targetString["CustomAudiances"] = includedCustomAudienceIds.join(", ");
      }
      var self = this
      return self.generateTargetStringHtml(
        targetString,
        false,
        false
      );
    },
    manageBudget: function (campaignId, rowData, itemIndex) {
      this.$set(this.manageBudgetRendrer, itemIndex, !this.manageBudgetRendrer[itemIndex]);
      if (campaignId) {
        this.makeApiCallAndUpdateColumns(campaignId, false, itemIndex)
      }
    },
    makeApiCallAndUpdateColumns (campaignId, hasUserEditedBudget = false, index = 0) {
      if (!hasUserEditedBudget && this.manageBudgetRendrer[index]) {
        this.setCampaignsLiveData(index);
        this.setAdgroupLiveData(campaignId, index);
      }
    },
    addColumnInBudgetTable () {
      if (!this.budgetTypeColumns.includes("Current Budget") && !this.isAdsetBudget) {
        var index = this.budgetTypeColumns.indexOf("Budget");
        if (index !== -1) {
          this.budgetTypeColumns[index] = "Original Budget";
        }
        this.budgetTypeColumns.push("Current Budget");
      }
    },
    removeColumnInBudgetTable () {
      if (this.budgetTypeColumns.includes("Original Budget") && !this.isAdsetBudget) {
        var index = this.budgetTypeColumns.indexOf("Original Budget");
        if (index !== -1) {
          this.budgetTypeColumns[index] = "Budget";
        }
        this.budgetTypeColumns.pop();
      }
    },
    setCampaignsLiveData (index) {
      var ids = []
      this.publishSummary.forEach(element => {
        if (element.Id && !this.processedCampaigns.includes(element.Id)) {
          this.processedCampaigns.push(element.Id)
        }
        ids.push(element.Id)
      })
      let data = {
        bpseId: this.businessProfilesSearchEngineId,
        entityType: "campaigns",
        ids: ids.toString(),
        index: index
      }
      if (data.ids && data.ids.length > 0 && this.manageBudgetRendrer[index]) {
        this.$set(this.dataReloadingRendrer, index, true);
        ApiService.GetLiveEntityData(
          data,
          this.successCampaignCallback,
          this.failureCampaignCallback,
          this.doneCallback
        );
      }
    },
    setAdgroupLiveData (CampaignId, index) {
      let adgroupArray = this.publishSummary.find(x => x.Id === CampaignId).ChildEntities;
      var searchEngineAdgroupIds = [];
      adgroupArray.forEach(element => {
        if (element.Id && !this.processedAdgroups.includes(element.Id) && this.manageBudgetRendrer[index]) {
          this.processedAdgroups.push(element.Id)
        }
        searchEngineAdgroupIds.push(element.Id)
      })
      let data = {
        bpseId: this.businessProfilesSearchEngineId,
        entityType: "adgroups",
        ids: searchEngineAdgroupIds.toString(),
        CampaignId,
        index: index
      }
      if (data.ids && data.CampaignId && this.manageBudgetRendrer[index]) {
        this.$set(this.dataReloadingRendrer, index, true);
        ApiService.GetLiveEntityData(
          data,
          this.successAdgroupCallback,
          this.failureAdgroupCallback,
          this.doneCallback
        );
      }
    },
    successAdgroupCallback (response, data) {
      let adgroupArray = this.publishSummary.find(x => x.Id === data.CampaignId).ChildEntities;
      response.data.data.forEach(updatedData => {
        adgroupArray.forEach(publishJsonObject => {
          if (publishJsonObject.Id === updatedData.searchEngineAdgroupIDString) {
            publishJsonObject.RowData.SpendLimitCluster = {
              APISpendLimit: {
                min: Math.round(updatedData.spendLimit.min),
                max: Math.round(updatedData.spendLimit.max)
              },
              UserSpendLimit: {
                min: Math.round(updatedData.spendLimit.min),
                max: Math.round(updatedData.spendLimit.max)
              },
              InitialSpendLimit: {
                min: publishJsonObject.RowData.SpendLimit.min,
                max: publishJsonObject.RowData.SpendLimit.max
              }
            }
            publishJsonObject.RowData.OptimizationGoal["Current"] = null
            publishJsonObject.RowData.OptimizationGoal["API"] = updatedData.facebookOptimizationGoal
          }
        })
      });
      let index = this.publishSummary.findIndex(x => x.Id === data.CampaignId);
      if (index != -1) {
        this.publishSummary[index].ChildEntities = adgroupArray
      }
      this.$set(this.dataReloadingRendrer, data.index, false);
    },
    successCampaignCallback (response, data) {
      if (response.status == 200) {
        response.data.data.forEach(element => {
          var index = this.publishSummary.findIndex(x => x.Id === element.searchEngineCampaignIDString);
          if (index != -1) {
            var isUserBudgetPresent = this.publishSummary[index].RowData.BudgetCluster ? (!!this.publishSummary[index].RowData.BudgetCluster.UserBudget) : false
            if (isUserBudgetPresent) {
              this.publishSummary[index].RowData.BudgetCluster.APIBudget = element.apiBudgetAmount.toFixed(2)
            } else {
              this.publishSummary[index].RowData.BudgetCluster = {
                APIBudget: element.apiBudgetAmount.toFixed(2),
                UserBudget: element.apiBudgetAmount.toFixed(2)
              }
            }

            if (!this.publishSummary[index].RowData.BudgetCluster["UserBudget"]) {
              this.publishSummary[index].RowData.BudgetCluster["UserBudget"] = element.apiBudgetAmount.toFixed(2)
            }
          }
        })
        this.$set(this.dataReloadingRendrer, data.index, false);
        this.fetchedApiDataSuccess = true;
        this.addColumnInBudgetTable()
      }
    },
    failureAdgroupCallback (response) {
      this.processedAdgroups = []
      console.log(response)
    },
    failureCampaignCallback (response) {
      this.processedCampaigns = []
      console.log(response)
    },
    doneCallback () {
      console.log("Success in fetching facebook api data")
    },
    updatepublishSummary (campaignId, data, json, isAdgroup = false) {
      data.forEach(updatedData => {
        json.forEach(publishJsonObject => {
          if (isAdgroup) {
            if (publishJsonObject.searchEngineAdgroupID == updatedData.SeachEngineAdgroupId) {
              publishJsonObject.ApiData.APISpendLimit = {
                min: Math.round(updatedData.spendLimit.Min),
                max: Math.round(updatedData.spendLimit.Max)
              }
              publishJsonObject.ApiData.CurrentSpendLimit = {
                min: Math.round(updatedData.spendLimit.Min),
                max: Math.round(updatedData.spendLimit.Max)
              }
            }
          } else {
            if (publishJsonObject.SeachEngineCampaignId == updatedData.SeachEngineCampaignId) {
              publishJsonObject.ApiCurrentBudget = updatedData.apiBudgetAmount;
            }
          }
        })
      });
      if (isAdgroup) {
        let index = json.findIndex(x => x.id === campaignId);
        if (json && index != -1) {
          this.publishSummary[index] = json
        }
      }
      this.publishSummary = json
    },
    changedAdgroupData (value) {
      if (value.adgroupData && value.adgroupData.length > 0) {
        this.publishSummary[value.index].ChildEntities = value.adgroupData;
        this.updatePublishSummaryInStore();
      }
    },
    errorResolver (isErrorPresent) {
      if (isErrorPresent) {
        this.budgetTypeVerified = false;
      }
      this.error = isErrorPresent
    },
    campaignBudgetChanged (budget, budgetType, duration, index) {
      var budgetValue = parseFloat(budget).toFixed(2)
      if (!isNaN(budgetValue) && budgetValue && budgetValue > 0 && this.publishSummary[index].ApiData) {
        this.errorResolver(false)
        if (this.isCampaignLauncherVerificationEnabled == false) {
          this.budgetTypeVerified = true;
        }
        this.$set(this.errorInInput, index, false);
        if (!this.publishSummary[index].RowData.Changes) {
          this.publishSummary[index].RowData.Changes = []
        }
        if (this.fetchedApiDataSuccess && this.publishSummary[index].RowData.BudgetCluster && this.publishSummary[index].RowData.BudgetCluster.APIBudget != budgetValue) {
          this.publishSummary[index].RowData.BudgetCluster.UserBudget = budgetValue
          this.publishSummary[index].RowData.Changes = this.CreateChanges(this.publishSummary[index], this.publishSummary[index].RowData.BudgetType.toString().toLowerCase(), this.publishSummary[index].RowData.BudgetCluster.APIBudget, budgetValue)
          this.publishSummary[index].RowData.BudgetCluster.AllocatedBudget = budgetValue * duration;
        } else if (!this.fetchedApiDataSuccess) {
          if (budgetType == "Daily" && budgetValue != this.publishSummary[index].RowData.Budget) {
            this.budgetDifference = (budgetValue - this.publishSummary[index].RowData.Budget) * duration;
            this.publishSummary[index].RowData.AllocatedBudget += this.budgetDifference
            this.publishSummary[index].RowData.Changes = this.CreateChanges(this.publishSummary[index], "daily_budget", this.publishSummary[index].ApiData.daily_budget, budgetValue)
            this.publishSummary[index].RowData.Budget = budgetValue;
          } else if (budgetType == "Life Time" && this.publishSummary[index].RowData.AllocatedBudget != budgetValue) {
            this.publishSummary[index].RowData.Changes = this.CreateChanges(this.publishSummary[index], "lifetime_budget", this.publishSummary[index].ApiData.lifetime_budget, budgetValue)
            this.publishSummary[index].RowData.AllocatedBudget = budgetValue
          }
        }
        this.updatePublishSummaryInStore();
      } else {
        this.$set(this.errorInInput, index, true);
        this.errorResolver(true)
      }
    },
    CreateChanges (entity, property, oldValue, newValue) {
      var notRelatedChanges = entity.RowData.Changes.filter(x => (x.Property != property && x.Status != 8)) || []
      var changes = {
        Property: property,
        OldValue: Number(oldValue) || null,
        NewValue: Number(newValue) || null,
        Status: 1
      }
      this.processedCampaigns = this.processedCampaigns.filter(item => item !== entity.RowData.CampaignId)
      notRelatedChanges.push(changes)
      return notRelatedChanges
    },
    updatePublishSummaryInStore () {
      this.SET_FACEBOOK_ENTITIES(this.publishSummary);
      this.$emit("updatedPublishSummary", { updatedPublishSummary: this.publishSummary });
    },
    convertBudgetToMicroCurrency (budget) {
      return budget * 100;
    },
    updateManageButtonRender () {
      if (this.publishSummary) {
        this.publishSummary.forEach(element => {
          this.manageBudgetRendrer.push(false)
          this.dataReloadingRendrer.push(false)
          this.errorInInput.push(false)
        })
      }
    },
    editedBudgetAmount (record) {
      if (this.fetchedApiDataSuccess && record.RowData.BudgetCluster) {
        return record.RowData.BudgetCluster.UserBudget;
      }
      return record.EditableBudget;
    },
    getKeyFromValue (object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    getReadableDate (dateTimeStamp, isStartDate = false) {
      if (!dateTimeStamp) {
        if (isStartDate) {
          return "Now"
        }
        return "N/A";
      }
      var date = new Date(dateTimeStamp)
      return date.toDateString();
    },
    getReadableDateTime (value) {
      return new Date(value).toLocaleString()
    },
    getAccountRelatedEntities () {
      ApiService.GetAccountRelatedEntities(window.businessProfileId, this.selectedCampaignLauncherConfig.bpseId).then(response => {
        let appEvents = JSON.parse(response.appEvents || "{}");
        let offlineEvents = JSON.parse(response.offlineEvents || "{}");
        let pixels = JSON.parse(response.pixels || "{}");
        this.trackingPixels = pixels;
        this.appEvents = appEvents;
        this.offlineEvents = offlineEvents;
      });
    },
    openMapperModel () {
      EventBus.$emit("open-campaign-and-target-mapper", true);
    },
    format12Hours (minutes) {
      return ((minutes / 60) % 12 === 0 ? 12 : (minutes / 60) % 12).toString() + (minutes / 60 < 12 || minutes / 60 === 24 ? "AM" : "PM")
    },
    addEventListener (event) {
      event.stopPropagation();
    }
  },
  watch: {
    'adsData': {
      immediate: true,
      handler (a, b) {
        this.setPagesPivotData();
      },
      deep: true
    },
    creativeInPreview (value) {
      if (!value) {
        return;
      }
      var newCreativeToBePreviewed = this.pagesPivot[0].creativesGroup[value - 1];
      this.previewCreativeData = { creative: newCreativeToBePreviewed.creativeData }
    },
    "selectedCampaignLauncherConfigId": function () {
      if (this.selectedCampaignLauncherConfigId && this.selectedCampaignLauncherConfig.campaign.buyType == 4) {
        this.setRnfData();
      }
    },
    "isAdsetBudget": function (value) {
      if (value) {
        this.budgetTypeColumns = ["Campaign Name", "Status", "Schedule", "Budget Type"]
      } else {
        this.budgetTypeColumns = ["Campaign Name", "Status", "Schedule", "Budget Type", "Budget"]
      }
    },
    "rnfEstimationPivot": {
      immediate: true,
      handler (a, b) {
        if (this.selectedCampaignLauncherConfigId && this.selectedCampaignLauncherConfig.campaign.buyType != 4) {
          this.$emit('updateRnfPredictionsValid', true)
        }
        var failedPrediction = a && a.records ? a.records.filter(x => x.overAllStatus == 10) : [];
        if (failedPrediction && failedPrediction.length > 0) {
          this.$emit('updateRnfPredictionsValid', false)
        } else {
          this.$emit('updateRnfPredictionsValid', true)
        }
      },
      deep: true
    },
    "RnfDataProcessing": function (value) {
      this.$emit('updateRnfDataProcessing', value)
    },
    "viewType": function () {
      this.$nextTick(() => {
        this.collapseValue = deltax.isCampaignLauncherSummarySectionCollapse ? [] : ['summary', 'campaign_setup', 'reach_frequency_estimate', 'targeting', 'locations', 'excluded_locations', 'page_sets', 'pages_creative', 'placements'];
      });
      this.setPagesPivotData()
    },
    submissionId () {
      this.fetchedApiDataSuccess = false;
      this.removeColumnInBudgetTable();
      this.updateManageButtonRender();
    }
  }
}
</script>

<style scoped>
img[lazy=loading] {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJ3aGl0ZSI+CiAgPHBhdGggZD0iTTAgNCBMMCAyOCBMMzIgMjggTDMyIDQgeiBNNCAyNCBMMTAgMTAgTDE1IDE4IEwxOCAxNCBMMjQgMjR6IE0yNSA3IEE0IDQgMCAwIDEgMjUgMTUgQTQgNCAwIDAgMSAyNSA3Ij48L3BhdGg+Cjwvc3ZnPg==") no-repeat center hsl(0, 0%, 80%);
}
.creative-desc {
  color: black;
  margin-bottom: 0px;
  font-size: 12px;
}
.table-width-large {
  width: 50%;
}
.title-sticky {
  padding: 0px 5px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
  cursor: pointer;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
  cursor: pointer;
}
.verified-action {
  float: right;
  padding: 10px;
}
.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
}
.total-footer {
  font-weight: 600;
  background-color: #eee;
}
.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}
.target-label {
  padding-top: 10px;
}
.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}
.page-label {
  padding: 10px 5px;
  top: 140px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.creatives-li {
  padding-bottom: 5px;
  list-style: none;
}
.creative-adstatus {
  min-width: 65px;
}
.more-previews {
  font-size: 1.3em;
}
.data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align : middle;
}
.data-vertical-middle {
  vertical-align : middle;
}
::v-deep .ivu-modal-footer {
  display: none;
}
.social-target-info-tooltip{
  margin: 5px;
}
.budget-input{
  display: inline-block;
  margin: 0px 10px 0 0;
  width: 65%
}
.manage-budget-button{
  float: right;
  margin-top: 2px;
}
.rnf-replan {
  vertical-align: revert;
}
.budget-cell {
  display: table-cell;
}
.budget-text {
  display: inline-block;
  margin: 0px 10px 0 0;
  width: 60%;
  padding-top: 6px;
}
.error-input {
  border: 1px solid red;
}
.table-data-min-width-small {
  min-width:80px
}
.table-data-min-width-medium {
  min-width:130px
}
.table-data-min-width-large {
  min-width:130px
}
.cell-data-value-split-20 {
  display: inline-block;
  width: 30%;
  text-align: right;
  vertical-align: top;
  color: #979898;
}
.cell-data-value-split-80 {
  display: inline-block;
  width: 70%;
  vertical-align: top;
}
.cell-data-value-split-45 {
  display: inline-block;
  width: 48%;
  text-align: right;
  vertical-align: top;
  color: #979898;
}
.cell-data-value-split-55 {
  display: inline-block;
  width: 50%;
  text-align: left;
  margin-left: 2%;
}
.text-center {
  text-align: center;
}
.id-label {
  font-size: 12px;
}
.consider {
  color: green;
}
.skip {
  color: orange;
}
.default {
  background-color: #bbb;
}
.iconContainer {
  min-height: 20px;
  max-width: 20px;
  min-width: 20px;
  display:inline;
  margin-right: 5px;
}
.center-align {
  text-align: center;
}
.rnf-error-text {
  color: red;
  font-size: 11px;
}
.rnf-suggestion-text {
  color: #bbb;
  font-size: 11px;
}
.hide-url {
  display: none;
}
.date-max-width {
  max-width: 20px;
}
.cap{
  color: #858686;
}
.view-more {
  margin: -10px 5px 18px 0px;
  color: #bbb;
  cursor: pointer;
}
.collapse-icon {
  font-size: 25px;
  margin-bottom: 5px;
}
</style>
<style>
table.target-info-table td {
  border: 0 !important;
}
</style>
<style>
.cl-adpreview-page .ivu-page-simple-pager, .cl-adpreview-page .ivu-page-simple-pager input {
  margin: 0px !important;
}

.label-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
}
</style>
