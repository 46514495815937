<template>
    <div>
      <div class="publisher-receipt-header">Receipients</div>
       <div class="publisher-wrapper">
        <i-table
        class="publisher-tbl"
        :columns="tblColumns"
        :data="tblData"
        disabled-hover
        border
       >
       <template slot-scope="{ index }" slot="status">
         <i-switch v-model="tblData[index].activeStatus" size="small"></i-switch>
       </template>
       <template slot-scope="{ index }" slot="publisher">{{ tblData[index].publisher }}</template>
       <template slot-scope="{ index }" slot="email">
            <InputTag
            class="email-input"
            v-model="tblData[index].supplierEmails"
            add-tag-on-blur
            ></InputTag>
       </template>
       </i-table>
       </div>
    </div>
</template>

<script>
import { Table, locale, Switch } from "iview";
import InputTag from 'vue-input-tag'
import lang from 'iview/dist/locale/en-US'

locale(lang)
export default {
  components: {
    "i-table": Table,
    "i-switch": Switch,
    InputTag
  },
  data () {
    return {
      tblColumns: [
        {
          title: "Status",
          key: "activeStatus",
          align: 'center',
          slot: 'status',
          width: 100,
          minWidth: 80
        },
        {
          title: "Publisher",
          key: "publisher",
          align: 'center',
          width: 250,
          minwidth: 200
        },
        {
          title: "Emails",
          key: "supplierEmails",
          slot: 'email',
          align: 'center',
          minwidth: 300
        }
      ],
      tblData: []
    }
  }
}
</script>

<style scoped>
.publisher-receipt-header{
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 13px;
}
::v-deep  .publisher-tbl .ivu-table-cell{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.email-input{
  border: none;
}

::v-deep .publisher-tbl .ivu-table td, .ivu-table th{
  height: 35px !important;
}
::v-deep .vue-input-tag-wrapper .input-tag {
    background-color: #dcdee2 !important;
    border: 1px solid #dcdee2 !important;
    color: #2c2e2f !important;
}
::v-deep .vue-input-tag-wrapper .input-tag .remove {
    color: #2c2e2f !important;
}
</style>
