import axiosInstance from '../../Api'
import CLHelpermethods from "../CampaignLauncher/Mixins/CLHelperMethods";

const baseUrl = deltax.apiEndPoint;
// const baseUrl = 'https://localhost:44359/v1.0/';
const businessProfileId = deltax.businessProfileId;

let APIService = {
  retryVideoProcessing: async (
    businessProfileId,
    businessProfileSearchEngineId,
    videoId,
    pageId,
    userId
  ) => {
    return axiosInstance.post(
      `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/videos/retryProcessing`,
      {
        VideoId: videoId,
        PageId: pageId,
        UserId: userId
      }
    );
  },
  fetchUploadingVideos: (url, bpseId, videoIds) => {
    return axiosInstance.get(url, {
      params: {
        bpseId,
        videoIds
      }
    });
  },
  getOrganicPromotions: status => {
    return axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/Promotions`,
      {
        params: {
          status: status,
          isOrganic: true
        }
      }
    );
  },
  getVideosByTitle: (bpseId, title) => {
    return axiosInstance.get(
      `/App/Facebook/Video/GetVideosByTitle/${businessProfileId}`,
      {
        params: {
          bpseId,
          title
        }
      }
    );
  },
  fetchMedia: url => {
    return fetch(url).then(response => {
      return response.json();
    });
  },
  GetSocialTargets: (url, json) => {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(json),
      headers: { "Content-Type": "application/json" }
    });
  },
  PostCampaignLauncherRequestData: (url, json, isExactErrorMessageRequired = false) => {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(json),
      headers: { "Content-Type": "application/json" }
    }).then(response => {
      if (!response.ok) {
        if (isExactErrorMessageRequired) {
          return response.json().then(data => {
            CLHelpermethods.methods.getCustomError(data);
          });
        } else {
          return response.json().then(data => {
            throw new Error("An error occurred while processing your request.");
          });
        }
      }
      return response.json();
    });
  },
  PostCampaignLauncherRequestWithAuth: (json) => {
    var url = deltaXApiUrl.campaignLauncherPublishData.createCampaignLauncherPublishdata
      .replace('{businessProfileId}', businessProfileId)
    return axiosInstance.post(url, json)
      .then(response => {
        return response.data;
      });
  },
  PutCampaignLauncherRequestData: (url, json) => {
    return fetch(url, {
      method: "PUT",
      body: JSON.stringify(json),
      headers: { "Content-Type": "application/json" }
    }).then(response => {
      return response.json();
    });
  },
  get: async url => {
    const response = await fetch(url);
    return response.json();
  },
  post: async (url, postData, success, failure, always) => {
    success =
      success ||
      function (res) {
        console.log(res);
      };
    failure =
      failure ||
      function (res) {
        console.log(res);
      };
    always = always || function () {};

    axiosInstance
      .post(url, postData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        success(response.data);
      })
      .catch(response => {
        failure(response.response);
      })
      .finally(() => {
        always();
      });
  },
  patch: async (url, postData, success, failure, always) => {
    success = success || function (res) {};
    failure = failure || function (res) {};
    always = always || function () {};

    axiosInstance
      .patch(url, postData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        success(response.data);
      })
      .catch(response => {
        failure(response.data);
      })
      .finally(() => {
        always();
      });
  },
  saveCreative (req) {
    return axiosInstance.post(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/PublishStageData/save",
      req
    );
  },
  getMediaPlanItems: async url => {
    return axiosInstance.get(url);
  },
  getApiData: async url => {
    return axiosInstance.get(url);
  },
  async uploadImage (
    url,
    uploadPercentCallback,
    image,
    userId,
    recipeType = null
  ) {
    let formData = new FormData();
    formData.append("imageFiles", image);
    formData.append("userId", userId);
    if (recipeType !== null) {
      formData.append("recipeType", recipeType);
    }

    let response = await axiosInstance.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: function (progressEvent) {
        uploadPercentCallback(progressEvent);
      }
    });
    return response.data.data;
  },
  async uploadVideo (
    url,
    uploadPercentCallback,
    video,
    image,
    dimensions,
    length,
    userId,
    recipeType = null
  ) {
    let formData = new FormData();
    formData.append("videoFiles", video);
    formData.append("image", image);
    formData.append("userId", userId);
    formData.append("Dimensions", dimensions);
    formData.append("Length", length);
    if (recipeType !== null) {
      formData.append("recipeType", recipeType);
    }

    try {
      let response = await axiosInstance.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: function (progressEvent) {
          uploadPercentCallback(progressEvent);
        }
      });
      return response.data.data;
    } catch (error) {
      // Handle error appropriately
      console.error("Error uploading video:", error);
      throw error;
    }
  },
  getGroupEntities: (url, json, successCb, errorCb, alwaysCb = null) => {
    var postData = json;
    $.post(url, postData, function (response) {
      successCb(response);
    })
      .fail(function (response) {
        errorCb(response);
      })
      .always(function () {
        try {
          if (alwaysCb != null) {
            alwaysCb();
          }
        } catch (err) {
          console.log(err);
        }
      });
  },
  getLeadForms: async (accountId, pageId) => {
    return axiosInstance.get(deltax.getLeadFormsUrl, {
      params: {
        accountId: accountId,
        pageId: pageId
      }
    });
  },
  getLinkedInLeadForms: async bpse => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpse +
        "/live/linkedin/forms"
    );
  },
  getEmbedToken: async (url, userId, businessProfileId, portal) => {
    var params = {
      userId: userId,
      businessProfileId: businessProfileId,
      portal: portal
    };
    return axiosInstance.get(url, {
      params: params
    });
  },
  generatePrediction: (bpseId, data) => {
    return axiosInstance.post(
      `${baseUrl}businessProfiles/${businessProfileId}/accounts/${bpseId}/live/reachAndFrequency/predictions`,
      data
    );
  },
  getPromotionById: promotionId => {
    // /v1.0/businessProfiles/<id>/promotions/<pid>
    return axiosInstance({
      method: "get",
      url:
        baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/promotions/" +
        promotionId
    });
  },
  getPageBackedInstagramId: (bpseId, pageId) => {
    return axiosInstance({
      method: "get",
      url:
        baseUrl +
        `businessProfiles/${businessProfileId}/accounts/${bpseId}/live/pages/${pageId}/pageBackedInstagramAccounts`
    });
  },
  createPageBackedInstagramId: (bpseId, pageId) => {
    return axiosInstance({
      method: "post",
      url:
        baseUrl +
        `businessProfiles/${businessProfileId}/accounts/${bpseId}/live/pages/${pageId}/pageBackedInstagramAccounts`
    });
  },
  replacePromotionMacros: (
    promotionId,
    StoreId,
    creativeJson = null,
    searchEngineId = null
  ) => {
    let requestObj = { StoreId };
    if (creativeJson) {
      requestObj.creativeJson = creativeJson;
    }
    if (searchEngineId) {
      requestObj.searchEngineId = searchEngineId;
    }
    return axiosInstance.post(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/promotions/" +
        promotionId +
        "/StoreConfiguration",
      requestObj
    );
  },
  updatePromotionCreative: (promotionId, creativeJson) => {
    return axiosInstance.patch(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/promotions/" +
        promotionId,
      JSON.stringify(JSON.stringify({ data: creativeJson }))
    );
  },
  updateLineItems: (lineItemId, data) => {
    return axiosInstance.patch(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/lineItems/" +
        lineItemId,
      JSON.stringify(JSON.stringify(data))
    );
  },
  getProductSets: (businessProfileId, accountId, catalogId) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        accountId +
        "/live/productcatalogs/" +
        catalogId +
        "/productsets"
    );
  },
  getProductCatalogs: (businessProfileId, accountId) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        accountId +
        "/live/productcatalogs"
    );
  },
  getPromotedProductSetIdForGivenConfig: configId => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/LineItemCampaignConfigurations/PromotedProductSetId",
      {
        params: {
          configId: configId
        }
      }
    );
  },
  saveAmsAsinTarget: async cluster => {
    var targetEndPoint = baseUrl
      .concat("businessProfiles/")
      .concat(businessProfileId)
      .concat("/Targets")
      .concat("/amazon");
    return axiosInstance.post(targetEndPoint, cluster);
  },
  updateAmsAsinTarget: async cluster => {
    var targetEndPoint = baseUrl
      .concat("businessProfiles/")
      .concat(businessProfileId)
      .concat("/Targets")
      .concat(`/`)
      .concat(cluster.Id);
    return axiosInstance.put(targetEndPoint, cluster);
  },
  getAdwordsConversionMetrics: bpseId => {
    return axiosInstance.post(deltax.totalconversionMetricsUrl, {
      id: businessProfileId,
      accountId: "accountID",
      isNewChannel: false,
      bpseId: bpseId
    });
  },
  getAdwordsNegativeKeywords: bpseId => {
    return axiosInstance.get(
      baseUrl +
        `businessProfiles/${businessProfileId}/accounts/${bpseId}/live/NegativeKeywordLists`,
      {
        id: businessProfileId,
        bpseId: bpseId
      }
    );
  },
  getLinkedInTargetEntities: (bpseId, facetUrn, query = null) => {
    return axiosInstance.get(
      baseUrl +
        `businessProfiles/${businessProfileId}/accounts/${bpseId}/linkedInTarget`,
      {
        params: {
          facetUrn,
          query
        }
      }
    );
  },
  getTwitterTargetEntities: (
    bpseId,
    type,
    searchQuery = null,
    parametersJson = null
  ) => {
    return axiosInstance.get(
      baseUrl +
        `businessProfiles/${businessProfileId}/accounts/${bpseId}/live/TargetingCriterias`,
      {
        params: {
          type,
          searchQuery,
          parametersJson
        }
      }
    );
  },
  getBusinessProfile: () => {
    return axiosInstance.get(
      `${baseUrl}businessProfiles/${deltax.businessProfileId}`
    );
  },
  async getBusinessProfileAsync () {
    let response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${deltax.businessProfileId}`
    );
    return response.data.data;
  },
  saveTarget: targetObj => {
    return axiosInstance.post(
      baseUrl + `businessProfiles/${businessProfileId}/Targets/Save`,
      targetObj
    );
  },
  updateTarget: (targetId, updateTargetData) => {
    return axiosInstance.patch(
      baseUrl + `businessProfiles/${businessProfileId}/Targets/${targetId}`,
      updateTargetData
    );
  },
  getLocations: () => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/publishers/5/locations"
    );
  },
  async getDbLocations (body) {
    let response = await axiosInstance.post(
      baseUrl + "businessProfiles/" + businessProfileId + "/Locations",
      body
    );
    return response.data.data;
  },
  async getUserInterests (bpseId, params) {
    let url = deltaXApiUrl.googleAdsAccounts.getUserInterests.replace('{businessProfileId}', businessProfileId).replace('{accountId}', bpseId);
    let response = await axiosInstance.get(url, {
      params: params
    });
    return response.data.data;
  },
  async getCustomInterests (bpseId, params) {
    let url = deltaXApiUrl.googleAdsAccounts.getCustomInterests.replace('{businessProfileId}', businessProfileId).replace('{accountId}', bpseId);
    let response = await axiosInstance.get(url, {
      params: params
    });
    return response.data.data;
  },
  async getFirstPartyAudiences (bpseId, params) {
    let url = deltaXApiUrl.googleAdsAccounts.getFirstPartyAudiences.replace('{businessProfileId}', businessProfileId).replace('{accountId}', bpseId);
    let response = await axiosInstance.get(url, {
      params: params
    });
    return response.data.data;
  },
  getStores: async (isDefault, storeCodes, advertiserIds, includeDefault, storeIds) => {
    let params = {}
    if (isDefault) {
      params.isDefault = true;
    } if (storeCodes) {
      params.storeCodes = storeCodes;
    } if (advertiserIds) {
      params.advertiserIds = advertiserIds;
    } if (includeDefault) {
      params.includeDefault = true;
    } if (storeIds) {
      params.storeIds = storeIds;
    }
    let response = await axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/Stores`, {
        params
      }
    );
    return response;
  },
  async getLabelsTypesById (labelIds) {
    let url = baseUrl + `businessProfiles/${businessProfileId}/labelTypes`;
    let params = {
      ids: labelIds.join()
    };
    let response = await axiosInstance.get(url, {
      params: params
    });
    return response.data.result;
  },
  getTwitterFundingSource: bpseId => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/Accounts/" +
        bpseId +
        "/live/FundingInstruments"
    );
  },
  getTwitterAdCategories: bpseId => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/Accounts/" +
        bpseId +
        "/live/IabCategories"
    );
  },
  getExistingTweets: (bpseId, tweetIds) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/Accounts/" +
        bpseId +
        "/live/Tweets",
      {
        params: {
          tweetIds: tweetIds
        }
      }
    );
  },
  getApiLocations: (
    bpseId,
    searchQuery,
    type = "",
    limit = 50,
    fetchByCountry = false
  ) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpseId +
        "/live/Locations",
      {
        params: {
          searchQuery,
          type,
          limit,
          ...(fetchByCountry && { locationTypes: "country" })
        }
      }
    );
  },
  getPageSets: (
    bpseId,
    pageIds,
    empty = false,
    includeEmptyPageSets = false
  ) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpseId +
        "/live/pageSets",
      {
        params: {
          pageIds: pageIds.join(","),
          empty,
          includeEmptyPageSets
        }
      }
    );
  },
  createPageSets: (bpseId, name, parentpage, pages = [], metadata = null) => {
    return axiosInstance.post(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpseId +
        "/live/pageSets",
      {
        name,
        parentpage,
        pages,
        metadata
      }
    );
  },
  GetCategories (parentId, bpseId = 0) {
    const getCategoriesURL =
      parentId === 0
        ? `${baseUrl}businessProfiles/${businessProfileId}/Targets/GetCategories?bpseId=${bpseId}`
        : `${baseUrl}businessProfiles/${businessProfileId}/Targets/GetCategories?parentId=${parentId}&bpseId=${bpseId}`;

    return axiosInstance.get(getCategoriesURL);
  },
  GetAllUniqueNegativeKeywordsByTargetId: async targetId => {
    var EndPoint =
      baseUrl +
      `businessProfiles/` +
      businessProfileId +
      `/AmsNegativeKeywords/GetUniqueNegativeKeywords?targetId=`;
    return axiosInstance.get(EndPoint.concat(targetId));
  },
  GetAmsPortfolios: async bpseId => {
    var EndPoint =
      baseUrl +
      `businessProfiles/` +
      businessProfileId +
      `/AmsPortfolio?bpseId=`;
    return axiosInstance.get(EndPoint.concat(bpseId));
  },
  GetAmsBrands: async bpseId => {
    var EndPoint =
      baseUrl +
      `businessProfiles/` +
      businessProfileId +
      `/AmsBrand?bpseId=`;
    return axiosInstance.get(EndPoint.concat(bpseId));
  },
  GetKeywordsByTargetId: async targetId => {
    var EndPoint =
      baseUrl +
      `businessProfiles/` +
      businessProfileId +
      `/keywords/AmazonKeywords?targetId=`;
    return axiosInstance.get(EndPoint.concat(targetId));
  },
  GetProductsByTargetId: async targetId => {
    var EndPoint =
      baseUrl +
      `businessProfiles/` +
      businessProfileId +
      `/products/AmazonProductsByTargetId?targetId=`;
    return axiosInstance.get(EndPoint.concat(targetId));
  },
  getProductsData: async asins => {
    var EndPoint =
      baseUrl +
      `businessProfiles/` +
      businessProfileId +
      `/products/amazon-products/asin?asins=`;
    return axiosInstance.get(EndPoint.concat(asins));
  },
  GetCategoriesByTargetId: async targetId => {
    var EndPoint =
      baseUrl +
      `businessProfiles/` +
      businessProfileId +
      `/Targets/GetCategoriesByTarget?targetId=`;
    return axiosInstance.get(EndPoint.concat(targetId));
  },
  async GetCategoriesNames (bpseId = 0) {
    // For fetching all ams categories Name and Id from core table
    const getCategoriesNamesURL =
      baseUrl +
      `businessProfiles/` +
      businessProfileId +
      `/Targets/GetCategoriesNames?bpseId=${bpseId}`;
    const promise = await axiosInstance.get(getCategoriesNamesURL);
    return promise;
  },
  GetKeywordBid (KeywordsInfo, bpseId) {
    const url =
      baseUrl +
      "businessProfiles/" +
      businessProfileId +
      "/accounts/" +
      bpseId +
      "/BidRecommendations/AmazonKeywords";
    const promise = axiosInstance.post(url, KeywordsInfo);
    return promise;
  },
  GetProductBid (ProductsInfo, bpseId) {
    const url =
      baseUrl +
      "businessProfiles/" +
      businessProfileId +
      "/accounts/" +
      bpseId +
      "/BidRecommendations/AmazonProducts";
    const promise = axiosInstance.post(url, ProductsInfo);
    return promise;
  },
  getCustomConversions: bpseId => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpseId +
        "/live/customConversions"
    );
  },
  getCustomEvents: bpseId => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpseId +
        "/live/customEvents"
    );
  },
  getDV360TargetOptions: (bpseId, targetingType, pageToken = null) => {
    let params = {
      targetingType
    };
    if (pageToken) {
      params.pageToken = pageToken;
    }
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpseId +
        "/live/targetingOptions",
      {
        params
      }
    );
  },
  searchDV360TargetOptions: (bpseId, targetingType, body) => {
    return axiosInstance.post(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpseId +
        "/live/targetingOptions?targetingType=" +
        targetingType,
      body
    );
  },
  getDV360Audience: (bpseId, audienceType, displayName) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpseId +
        "/live/" +
        audienceType +
        "?displayName=" +
        `"${displayName}"`
    );
  },
  getPublishData: (mediaPlanId, configId, searchEngineTypeId) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/PublishStageData?mediaPlanId=" +
        mediaPlanId +
        "&configId=" +
        configId +
        "&searchEngineTypeId=" +
        searchEngineTypeId
    );
  },
  GetChannels: bpseId => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        bpseId +
        "/live/channels"
    );
  },
  getNomenclatureSettings: () => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/NomenclatureSettings"
    );
  },
  getLabelDimensions: () => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/LabelTypes"
    )
  },
  getLabelsByLabelNames: async (labels) => {
    let response = await axiosInstance.post(
      `${baseUrl}businessProfiles/${businessProfileId}/labelsByLabelNames`,
      labels
    )
    return response.data.labels;
  },
  getLineItemLabels: lineItemIds => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/Labels/getByLineItem",
      {
        params: { lineItemIds }
      }
    );
  },
  getConversionDomains: (bpseId, pixelId) => {
    return axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/accounts/${bpseId}/live/pixels/${pixelId}/conversionDomains`
    );
  },
  getReachAndFrequencySuggestions: (bpseId, payload) => {
    return axiosInstance.post(
      `${baseUrl}businessProfiles/${businessProfileId}/accounts/${bpseId}/live/reachAndFrequency/Suggestions`,
      payload
    );
  },
  saveTargets: targetObj => {
    return axiosInstance.post(
      `${baseUrl}businessProfiles/${businessProfileId}/Targets/Save`,
      targetObj
    );
  },
  getTargetsByBpse: async businessProfilesSearchEngineId => {
    let response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfilesSearchEngineId}/Targets`
    );
    return response.data;
  },
  getLanguages: async () => {
    let response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/Languages`
    );
    return response.data;
  },
  getTarget: async (id) => {
    var response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/targets/${id}`
    );
    return response.data.data;
  },
  getTargets: async ids => {
    var response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/targets`,
      {
        params: {
          ids
        }
      }
    );
    return response.data.data;
  },
  getTargetsCluster: async type => {
    var response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/targets`,
      {
        params: {
          type
        }
      }
    );
    return response.data.data;
  },
  getTargetClusters: async ids => {
    var response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/TargetClusters`,
      {
        params: {
          ids
        }
      }
    );
    return response.data.data;
  },
  getMacroValues: async (
    mediaPlanId,
    campaignLauncherConfigId,
    lineItemIds,
    targetIds
  ) => {
    var response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/NomenclatureSettings/GetMacroValues`,
      {
        params: {
          mediaPlanId,
          campaignLauncherConfigId,
          lineItemIds,
          targetIds
        }
      }
    );
    return response.data;
  },
  getAdExtensions: async (
    businessProfilesSearchEngineId,
    extensionType = null,
    ids = null
  ) => {
    let response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfilesSearchEngineId}/adExtensions`,
      {
        params: {
          extensionType,
          ids
        }
      }
    );
    return response.data;
  },
  createAdExtensions: async (businessProfilesSearchEngineId, body) => {
    let response = await axiosInstance.post(
      `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfilesSearchEngineId}/adExtensions`,
      body
    );
    return response.data;
  },
  bulkCreateAdExtensions: async (body) => {
    const url = deltaXApiUrl.AdExtensions.bulkCreate.replace("{businessProfileId}", businessProfileId);
    const response = await axiosInstance.post(url, body);
    return response.data;
  },
  downloadAdExtensions: async (bpseId) => {
    const url = deltaXApiUrl.AdExtensions.download.replace("{businessProfileId}", businessProfileId).replace("{bpseId}", bpseId);
    const response = await axiosInstance.get(url, { responseType: "blob" });
    return response;
  },
  postNegativeKeywords: async (
    businessProfileId,
    businessProfilesSearchEngineId,
    body
  ) => {
    let response = await axiosInstance.post(
      `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfilesSearchEngineId}/live/NegativeKeywordLists`,
      body
    );
    return response.data;
  },
  getLinkedinMatchedAudiences: bpseId => {
    return axiosInstance.get(
      baseUrl +
        `businessProfiles/${businessProfileId}/accounts/${bpseId}/live/matchedAudiences`
    );
  },
  getFacebookLanguages: async () => {
    let response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/FacebookLanguages`
    );
    return response.data;
  },
  getAdSheetData: (mediaPlanId, configId, tab, searchEngineTypeId) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/PublishStageData/1/getadsheetdata?mediaPlanId=" +
        mediaPlanId +
        "&configId=" +
        configId +
        "&tab=" +
        tab +
        "&SearchEngineTypeId=" +
        searchEngineTypeId
    );
  },
  getCampaignLauncherAdSheetData: (mediaPlanId, configId, tab, searchEngineTypeId) => {
    var url = deltaXApiUrl.campaignLauncherPublishData.getAdSheetData.replace('{businessProfileId}', businessProfileId)
    var params = {}
    params.mediaPlanId = mediaPlanId;
    params.configId = configId;
    params.tab = tab;
    params.SearchEngineTypeId = searchEngineTypeId;
    return axiosInstance.get(url, {
      params
    })
  },
  getCampaignLauncherEditTabAdSheetData: (mediaPlanId, configId) => {
    var url = deltaXApiUrl.campaignLauncherPublishData.getEditTabAdSheetData.replace('{businessProfileId}', businessProfileId)
    var params = {}
    params.mediaPlanId = mediaPlanId;
    params.configId = configId;
    return axiosInstance.get(url, {
      params
    })
  },
  getConversionActions: async (businessProfileId, bpseId, type) => {
    let response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/accounts/${bpseId}/live/conversionActions`,
      {
        params: {
          type
        }
      }
    );
    return response.data.data;
  },
  getSubmission: submissionId => {
    return axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/Submissions/${submissionId}`
    );
  },
  setLivePublishStatus: (requestId, finalGroupedEntities) => {
    return axiosInstance.post(
      `${baseUrl}businessProfiles/${businessProfileId}/PublishStageData/livePublishStatus`,
      {
        requestId,
        finalGroupedEntities
      }
    );
  },
  getPostById: async (accountId, postId, isIgPost) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        accountId +
        "/live/posts/" +
        postId +
        "?isIgPost=" +
        isIgPost
    );
  },
  getFbPageRelatedInformation: async (businessProfileId, accountId, pageId) => {
    return axiosInstance.get(
      baseUrl +
        "businessProfiles/" +
        businessProfileId +
        "/accounts/" +
        accountId +
        "/live/pages/" +
        pageId
    );
  },
  getPhasesByBriefId: async (briefBusinessProfileId, briefIds) => {
    let url = baseUrl
      .concat(`businessProfiles/${briefBusinessProfileId}/`)
      .concat(`BriefPhases`);
    let res = await axiosInstance.get(url, {
      params: {
        briefIds: briefIds
      }
    });
    return res.data.data;
  },
  saveBriefNote: async briefObj => {
    const saveBriefNoteUrl = baseUrl
      .concat(`businessProfiles/${deltax.businessProfileId}/`)
      .concat(`Brief`);
    let res = await axiosInstance.post(saveBriefNoteUrl, briefObj);
    return res.data.data;
  },
  postProductSet: async (url, postData) => {
    return axiosInstance.post(url, postData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  getMediaPlanById: async mediaPlanId => {
    let url = baseUrl
      .concat(`businessProfiles/${businessProfileId}/`)
      .concat(`mediaPlans/mediaPlan`);
    let res = await axiosInstance.get(url, {
      params: {
        mediaPlanId: mediaPlanId
      }
    });
    return res.data.data;
  },
  getConfigById: async configId => {
    let url = baseUrl
      .concat(`businessProfiles/${businessProfileId}/`)
      .concat(`campaignLauncherConfigurations/${configId}`);
    let response = await axiosInstance.get(url);
    return response.data.data;
  },
  getLatestPromotionVersion: async promotionId => {
    let response = await axiosInstance.get(
      `${baseUrl}businessProfiles/${businessProfileId}/promotions/${promotionId}/promotionVersions?latest=true`
    );
    return response.data[0];
  },
  createPromotionVersion: async (promotionId, promotionVersionObj) => {
    await axiosInstance.post(
      `${baseUrl}businessProfiles/${businessProfileId}/promotions/${promotionId}/promotionVersions`,
      promotionVersionObj,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
  },
  patchPromotionVersion: async (promotionVersionId, data) => {
    await axiosInstance.patch(`${baseUrl}businessProfiles/${businessProfileId}/promotionVersions/${promotionVersionId}`, `${JSON.stringify(JSON.stringify({ data: data }))}`);
  },
  async getImages (businessProfileId, businessProfileSearchEngineId, imageIds, imageName = null) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/images`;
    let params = imageIds ? { ids: imageIds.join(',') } : { name: imageName };
    return axiosInstance.get(url, {
      params: params
    });
  },
  async getImagesByIdandNames (businessProfileId, businessProfileSearchEngineId, imageIds, imageNames) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/images/fetchImages`;
    let postData = { ImageIds: imageIds, ImageNames: imageNames };
    return axiosInstance.post(url, postData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  async getVideosByIdandNames (businessProfileId, businessProfileSearchEngineId, videoIds, videoNames) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/videos/fetchVideos`;
    let postData = { VideoIds: videoIds, VideoNames: videoNames };
    return axiosInstance.post(url, postData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  async getPostsByIds (businessProfileId, businessProfileSearchEngineId, postIds) {
    let url = deltaXApiUrl.posts.getPostsByIds
      .replace('{businessProfileId}', businessProfileId)
      .replace('{businessProfileSearchEngineId}', businessProfileSearchEngineId);
    let postData = postIds;
    return axiosInstance.post(url, postData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  },
  async getVideos (businessProfileId, businessProfileSearchEngineId, videoIds) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/videos`;
    let params = {
      ids: videoIds.join(',')
    }
    return axiosInstance.get(url, {
      params: params
    });
  },
  getFacebookTargetLocations: async (businessProfileSearchEngineId, query) => {
    try {
      const response = await axiosInstance.get(
        baseUrl + `businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/live/search?query=${query}&targetType=adgeolocation`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  },
  async getTargetingBrowse (businessProfileSearchEngineId) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/live/targetingbrowse`;
    var response = await axiosInstance.get(url);
    return response.data.data;
  },
  async getLanguagesForTarget (businessProfileSearchEngineId, query) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/live/search?targetType=adlocale&query=${query}`;
    var response = await axiosInstance.get(url);
    return response.data.data;
  },
  async searchTargetingBrowse (businessProfileSearchEngineId, query) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/live/targetingsearch?query=${query}&limit=15`;
    var response = await axiosInstance.get(url);
    return response.data.data;
  },
  async getTargetSuggestions (businessProfileSearchEngineId, payload) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/live/suggestions?targetingList=${JSON.stringify(payload)}`;
    var response = await axiosInstance.get(url);
    return response.data.data;
  },
  async getCustomAudiences (businessProfileSearchEngineId, limit) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/live/customaudiences?limit=${limit}`;
    var response = await axiosInstance.get(url);
    return response.data.data;
  },
  async getEstimatedReach (businessProfileSearchEngineId, optimizationGoal, targetingSpec) {
    const response = await axiosInstance.get(
      baseUrl + `businessProfiles/${businessProfileId}/accounts/${businessProfileSearchEngineId}/live/deliveryestimate?optimizationGoal=${optimizationGoal}&targetingSpec=${targetingSpec}`
    );
    return response.data.data;
  },
  async createFacebookTarget (targetObj) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/Targets/Save`;
    try {
      const response = await axiosInstance.post(url, targetObj);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateFacebookTarget (targetObj) {
    let url = `${baseUrl}businessProfiles/${businessProfileId}/Targets/${targetObj.targetId}`;
    try {
      const response = await axiosInstance.patch(url, [{
        "Name": "Name",
        "Value": targetObj.Name
      },
      {
        "Name": "TargetString",
        "Value": targetObj.targetString
      },
      {
        "Name": "EstimatedReach",
        "Value": targetObj.estimatedReach
      },
      {
        "Name": "IsFavoriteTarget",
        "Value": targetObj.isFavoriteTarget ? "1" : "0"
      }]);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async GetMolocoTargetingKeyValuePairs () {
    let apiUrl = baseUrl
    if (!baseUrl) {
      apiUrl = deltax.apiBaseUrl
    }
    let url = `${apiUrl}MolocoTargetingKeyValuePairs`;
    try {
      const response = await axiosInstance.get(url);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
  },
  saveNewLauncherFlowCreatives (requestData) {
    let url = deltaXApiUrl.campaignLauncherPublishData.saveCreatives
      .replace('{businessProfileId}', businessProfileId)
    return axiosInstance.post(
      url,
      requestData
    );
  },
  async getBudgetPackageById (budgetPackageId) {
    let url = `${baseUrl}campaignpackages/${budgetPackageId}`;
    return axiosInstance.get(url);
  },
  getGoogleConversionActions (bpseId) {
    var url = deltaXApiUrl.conversionActions.googleConversionActions.replace('{businessProfileId}', businessProfileId);
    let requestData = JSON.stringify({
      businessProfileSearchEngineId: bpseId
    })
    return axiosInstance.post(
      url,
      requestData
    );
  },
  async getProductById (bpseId, productId) {
    const url = deltaXApiUrl.accounts.getProductById
      .replace("{businessProfileId}", businessProfileId)
      .replace("{accountId}", bpseId)
      .replace("{productId}", productId);

    const response = await axiosInstance.get(url);

    return response.data;
  },
  async getTrackingLinks (bpseId, adAccountId, productId) {
    const url = deltaXApiUrl.accounts.getTrackingLinks
      .replace("{businessProfileId}", businessProfileId)
      .replace("{accountId}", bpseId);

    const response = await axiosInstance.get(url, {
      params: {
        adAccountId,
        productId
      }
    });

    return response.data;
  },
  getMolocoCampaignData: async (bpseId, campaignId) => {
    var url = deltaXApiUrl.campaigns.getMolocoCampaignData.replace('{businessProfileId}', businessProfileId).replace("{accountId}", bpseId).replace("{campaignId}", campaignId)
    const response = await axiosInstance.get(url);
    return response.data.data;
  },
  updateMolocoCampaignData: async (campaignLauncherPublishDataId, editData) => {
    var url = deltaXApiUrl.campaignLauncherPublishData.updateMolocoCampaignData.replace('{businessProfileId}', businessProfileId).replace("{campaignLauncherPublishDataId}", campaignLauncherPublishDataId);
    const response = await axiosInstance.patch(url, editData);
    return response.data;
  },
  PostDV360TargetStringData: (bpseId, targetData) => {
    var url = deltaXApiUrl.targets.postDV360TargetString.replace('{businessProfileId}', businessProfileId).replace("{accountId}", bpseId)
    return axiosInstance.post(url, targetData)
      .then(response => {
        return response.data;
      });
  },
  async getConversionGoals (bpseId) {
    const url = deltaXApiUrl.googleAdsAccounts.googleConversionGoals
      .replace('{businessProfileId}', businessProfileId)
      .replace("{accountId}", bpseId);

    const response = await axiosInstance.get(url);
    return response.data;
  },
  async uploadInstagramVideoToFacebook (bpseId, instagramVideoId) {
    const url = deltaXApiUrl.videos.facebookAdVideos
      .replace('{businessProfileId}', businessProfileId)
      .replace("{accountId}", bpseId);
    const data = { instagramVideoId };
    const response = await axiosInstance.post(url, data);

    return response.data;
  },
  getSubmissionsByPublisherId: async (searchEngineId) => {
    var url = deltaXApiUrl.submissions.getSubmissionsByPublisherId.replace('{businessProfileId}', businessProfileId)
    const response = await axiosInstance.get(url, {
      params: {
        limit: 20,
        isNewLauncherFlow: true,
        searchEngineId: searchEngineId
      }
    });
    return response.data
  },
  BulkUploadInstantExperiences: async (data) => {
    var url = deltaXApiUrl.campaignLauncherPublishData.instantExperience.replace('{businessProfileId}', businessProfileId)
    return axiosInstance.post(url, data)
      .then(response => {
        return response.data.data;
      });
  },
  async getLabels (columnName, condition, query) {
    let url = deltaXApiUrl.Labels.getLabels
      .replace('{businessProfileId}', businessProfileId)
      .replace('{column}', columnName)
      .replace('{condition}', condition)
      .replace('{query}', query);
    var response = await axiosInstance.get(url);
    return response.data;
  },
  async getLabelsBTargetId (businessProfileSearchEngineId, targetId) {
    let url = deltaXApiUrl.targets.getLabelsByTargetId
      .replace('{businessProfileId}', businessProfileId)
      .replace('{businessProfileSearchEngineId}', businessProfileSearchEngineId)
      .replace('{targetId}', targetId);
    var response = await axiosInstance.get(url);
    return response.data.data;
  },
  async addTargetLabels (targetlabels, updateLabels) {
    let url = deltaXApiUrl.targets.addTargetLabels
      .replace('{businessProfileId}', businessProfileId)
      .replace('{updateLabels}', updateLabels);
    try {
      const response = await axiosInstance.post(url, targetlabels);
      return response;
    } catch (error) {
      console.error(error);
    }
  },
  async updateMetaAdgroups (businessProfileId, campaignLauncherPublishDataId, payload) {
    const url = deltaXApiUrl.launcherAdgroups.updateMetaAdgroups
      .replace('{businessProfileId}', businessProfileId)
      .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId);
    const response = await axiosInstance.patch(url, payload);
    return response.data;
  },
  async getMediaPlanLineItems (businessProfileId, mediaPlanIds) {
    const url = deltaXApiUrl.mediaPlans.getLineItems
      .replace('{businessProfileId}', businessProfileId)
      .concat(`?mediaPlanIds=${mediaPlanIds}`);

    const response = await axiosInstance.get(url);
    return response.data.data;
  },
  getDV360Exchanges: () => {
    var url = deltaXApiUrl.PublicInventory.getDV360Exchanges.replace('{businessProfileId}', businessProfileId)
    return axiosInstance.get(url);
  },
  getDV360SubExchanges: () => {
    var url = deltaXApiUrl.PublicInventory.getDV360SubExchanges.replace('{businessProfileId}', businessProfileId)
    return axiosInstance.get(url);
  },
  async getAllLabels () {
    let url = deltaXApiUrl.Labels.getAllLabels
      .replace('{businessProfileId}', businessProfileId)
    var response = await axiosInstance.get(url);
    return response.data;
  },
  async validateTargetingSearch (bpseId, body) {
    let url = deltaXApiUrl.targets.validateTargetingSearch
      .replace('{businessProfileId}', businessProfileId)
      .replace("{accountId}", bpseId);
    try {
      const response = await axiosInstance.post(url, body);
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  },
  getOldFlowEditTabData: (mediaPlanId, configId, searchEngineTypeId) => {
    var url = deltaXApiUrl.publishStageData.getEditTabAdSheetData.replace('{businessProfileId}', businessProfileId)
    var params = {}
    params.searchEngineTypeId = searchEngineTypeId;
    params.mediaPlanId = mediaPlanId;
    params.configId = configId;
    return axiosInstance.get(url, {
      params
    })
  },
  validateLandingPages: async (campaignLauncherPublishDataId, body) => {
    var url = deltaXApiUrl.campaignLauncherPublishData.validateLandingPages
      .replace('{businessProfileId}', businessProfileId)
      .replace('{campaignLauncherPublishDataId}', campaignLauncherPublishDataId);

    var response = await axiosInstance.post(url, body);

    return response.data.data;
  },
  async getTargetsByLabelIds (bpseId, labelIds) {
    let url = deltaXApiUrl.targets.getByLabelIds
      .replace('{businessProfileId}', businessProfileId)
      .replace('{businessProfileSearchEngineId}', bpseId)
      .replace('{labelIds}', labelIds)
    var response = await axiosInstance.get(url);
    return response.data;
  }
};

export { APIService };
