<template>
    <div>
        <i-modal
            v-model="openRequisitionModal"
            width="96%"
            class="mcr-modal"
            @on-cancel="closeModal()"
            >
            <div slot="header">
                <i-row :gutter="24" class="header-row">
                    <i-col span="3" class="bp-selector">
                        <i-input v-model="businessProfileName" disabled></i-input>
                    </i-col>
                    <i-col span="3">
                        <i-input v-model="mediaPlanName" disabled></i-input>
                    </i-col>
                    <i-col span="3">
                        <i-select placeholder="Assignee" v-model="selectedAssignee" multiple  :max-tag-count="1">
                          <i-option
                          v-for="option in assigneeData"
                          :key="option.id"
                          :value="option.id"
                          >
                          {{ option.firstName+" "+option.lastName }}
                          </i-option>
                        </i-select>
                    </i-col>
                    <i-col span="3">
                        <i-select placeholder="Receiver" v-model="selectedReceiver" multiple  :max-tag-count="1">
                          <i-option
                           v-for="option in receiverData"
                           :key="option.id"
                           :value="option.id"
                          >
                          {{ option.firstName+" "+option.lastName }}
                          </i-option>
                        </i-select>
                    </i-col>
                    <i-col span="3">
                        <i-select aria-placeholder="Delivery" v-model="selectedDeliveryMode">
                          <i-option
                           v-for="option in DeliveryActionOptions"
                           :key="option.id"
                           :value="option.id"
                          >
                          {{ option.label }}
                          </i-option>
                        </i-select>
                    </i-col>
                    <i-col span="9" class="view-buttons">
                        <button class="btn btn-success">Asset View</button>
                        <button class="btn btn-white plan-view-btn">Plan View</button>
                    </i-col>
                </i-row>
            </div>
            <div class="modal-body">
              <div class="spinner-wrapper" v-show="loadingData">
                 <i class="fa fa-spinner fa-spin fa-3x"></i>
              </div>
              <div class="mcr-grid">
                <material-copy-grid
                  ref="materialCopy"
                  v-show="!loadingData"
                  v-for="mode in lineItemModes"
                  :key="mode"
                  :lineItemMode="mode"
                  @linkLineItems="addtoSummary"
                  @unLinkLineItems="removeFromSummary"
                ></material-copy-grid>
              </div>
              <div class="pr-grid">
                <publisher-receipt
                  ref="publisherReceipt"
                  v-show="!loadingData"
                >
                </publisher-receipt>
              </div>
            </div>
            <div slot="footer" class="mcr-modal-footer">
              <div class="footer-header" :key="renderKey" v-if="!loadingData">
                <div class="summary-text">
                   summary:
              </div>
              <div class="summary-footer">
                  <div v-for="mode in lineItemModes" :key=mode
                  >
                  {{ getLineItemType(mode) }}: {{ getLinkedLineItems(mode) }}/{{ countTotalLineItems(mode) }} LineItems
                  </div>
              </div>
              </div>
              <div>
                <button class="btn btn-white close-btn" @click="closeModal()">Close</button>
                <button class="btn btn-success" @click="save()">Save</button>
              </div>
            </div>
        </i-modal>
    </div>
</template>

<script>
import { Input, Select, locale, Modal, Row, Col, Option } from 'iview'
import lang from 'iview/dist/locale/en-US'
import MaterialCopyGrid from './MaterialCopyGrid.vue';
import PublisherReceipt from './PublisherReceipt.vue';
import moment from 'moment'
import { ApiService } from '../Services/materialCopyApiService.js'

const apiService = new ApiService();

locale(lang)
export default {
  components: {
    "i-modal": Modal,
    "i-input": Input,
    "i-select": Select,
    "i-row": Row,
    "i-col": Col,
    "material-copy-grid": MaterialCopyGrid,
    "i-option": Option,
    "publisher-receipt": PublisherReceipt
  },
  props: {
    openModal: Boolean,
    mediaPlanId: Number
  },
  data () {
    return {
      openRequisitionModal: false,
      lineItemModes: [],
      mediaPlanData: {},
      inputGroupId: 0,
      inputGroupData: {},
      businessProfileName: '',
      selectedAssignee: [],
      selectedReceiver: [],
      selectedDeliveryMode: null,
      languagesData: [],
      adTypeData: [],
      assigneeData: [],
      receiverData: [],
      mediaPlanName: '',
      renderKey: 0,
      modeLineItemMap: new Map(),
      modeLinkedLineItemMap: new Map(),
      loadingData: false,
      DeliveryActionOptions: [
        {
          id: 1,
          label: 'Send on receipt'
        },
        {
          id: 2,
          label: 'Send after review'
        }
      ],
      publisherSuppliersMap: new Map(),
      supplierData: []

    }
  },
  watch: {
    async openModal (value) {
      if (value) {
        this.loadingData = true;
        this.openRequisitionModal = true;
        await this.init(this.mediaPlanId);
        await this.setGridData();
        if (this.inputGroupId == 0) {
          this.setPublisherGridData();
        } else {
          this.setFromNotificationInfo()
        }
      }
      this.loadingData = false;
    }
  },
  methods: {
    addtoSummary ({ mode, lineItemId }) {
      if (this.modeLinkedLineItemMap.has(mode)) {
        let linkedLineItems = this.modeLinkedLineItemMap.get(mode);
        linkedLineItems.push(lineItemId);
        this.modeLinkedLineItemMap.set(mode, linkedLineItems);
      } else {
        this.modeLinkedLineItemMap.set(mode, [lineItemId]);
      }
      this.renderKey += 1;
    },
    setPublisherGridData () {
      this.publisherSuppliersMap.clear();
      this.mediaPlanData.lineItems.forEach(item => {
        if (this.publisherSuppliersMap.has(item.publisher)) {
          let supplierList = this.publisherSuppliersMap.get(item.publisher);
          if (item.hasOwnProperty('supplier')) {
            supplierList.push(item.supplierId);
          }
          let supplierSet = new Set(supplierList)
          this.publisherSuppliersMap.set(item.publisher, Array.from(supplierSet));
        } else {
          let supplierList = []
          if (item.hasOwnProperty('supplierId')) {
            supplierList.push(item.supplierId);
          }
          this.publisherSuppliersMap.set(item.publisher, supplierList);
        }
      });

      this.$refs.publisherReceipt.tblData = []
      this.publisherSuppliersMap.forEach((value, key) => {
        let suppliersData = this.supplierData.filter(item => value.includes(item.id));
        let emailList = suppliersData.map(item => item.email);
        this.$refs.publisherReceipt.tblData.push(
          {
            activeStatus: true,
            publisher: key,
            supplierEmails: [...emailList]
          }
        )
      })
    },
    setFromNotificationInfo () {
      let notificationData = JSON.parse(this.inputGroupData.notificationInfo);
      this.$refs.publisherReceipt.tblData = []
      notificationData.forEach(item => {
        this.$refs.publisherReceipt.tblData.push({
          activeStatus: item.activeStatus,
          publisher: item.publisher,
          supplierEmails: [...item.supplierEmails]
        })
      })
    },
    removeFromSummary ({ mode, lineItemId }) {
      let lineItems = this.modeLinkedLineItemMap.get(mode);
      let index = lineItems.indexOf(lineItemId);
      lineItems.splice(index, 1);
      this.modeLinkedLineItemMap.set(mode, lineItems);
      this.renderKey += 1;
    },
    async init (mediaPlanId) {
      let bpLanguages = (await apiService.getLanguagesByBpId()).data.data;
      bpLanguages.forEach(element => {
        this.languagesData.push({
          id: element.id,
          name: element.displayName
        })
      });
      /// get adtype data to show in dropdown.
      // for now putting dummy data
      for (let index = 1; index < 10; index++) {
        this.adTypeData.push({
          id: index,
          name: "social" + index
        })
      }
      this.assigneeData = (await apiService.getBPUsers({ roleIds: '5' })).data.data;
      this.receiverData = (await apiService.getBPUsers({ roleIds: '4' })).data.data;
      this.businessProfileName = (await apiService.getBPById()).data.data.name;
      if (mediaPlanId) {
        this.mediaPlanData = (await apiService.getMediaPlanById(mediaPlanId)).data.data;
        if (this.mediaPlanData.hasOwnProperty('inputGroupId')) {
          this.inputGroupId = this.mediaPlanData.inputGroupId;
          this.inputGroupData = (await apiService.getInputGroupById(this.inputGroupId)).data.data;
        }
      }

      let supplierIds = [...new Set(this.mediaPlanData.lineItems.filter(item => item.supplierId !== undefined)
        .map(item => item.supplierId))]
      if (supplierIds.length > 0) {
        this.supplierData = (await apiService.getAllSuppliers({ supplierIds: supplierIds.join(",") })).data.suppliers
      }

      this.mediaPlanName = this.mediaPlanData.name;
      this.lineItemModes = [...new Set(this.mediaPlanData.lineItems.map(item => item.lineItemMode))];
      this.lineItemModes.sort();

      this.lineItemModes.forEach(mode => {
        let lineItemsIds = this.mediaPlanData.lineItems.filter(obj => obj.lineItemMode == mode).map(item => item.id);
        this.modeLineItemMap.set(mode, lineItemsIds);
      })

      this.renderKey += 1;
      if (this.inputGroupId) {
        this.setInputGroupData()
      }
    },
    setInputGroupData () {
      this.selectedDeliveryMode = this.inputGroupData.deliveryMode;
      let assigneList = this.inputGroupData.assignees != undefined ? this.inputGroupData.assignees.split(",") : []
      let receiverList = this.inputGroupData.receivers != undefined ? this.inputGroupData.receivers.split(",") : []
      this.selectedAssignee = assigneList.map(Number);
      this.selectedReceiver = receiverList.map(Number)
    },
    countTotalLineItems (mode) {
      let lineItems = this.modeLineItemMap.get(mode);
      if (lineItems != undefined) {
        return new Set(lineItems).size;
      } else {
        return 0;
      }
    },
    getLineItemType (mode) {
      switch (mode) {
        case 1: return 'Digital'
        case 2: return 'TV'
        case 3: return 'Radio'
        case 4: return 'Press'
        case 5: return 'OOH'
        case 6: return 'Cinema'
        case 7: return 'Magazine'

        default: return ''
      }
    },
    getLinkedLineItems (mode) {
      let linkedLineItmes = this.modeLinkedLineItemMap.get(mode);
      if (linkedLineItmes != undefined) {
        return new Set(linkedLineItmes).size;
      } else {
        return 0;
      }
    },
    async setGridData () {
      let index = 0;
      this.lineItemModes.forEach(mode => {
        this.setLinkedLineItemsOptions(mode, index);
        if (this.inputGroupId) {
          this.setInputData(mode, index);
        }
        index++;
      });
    },
    setInputData (mode, index) {
      let inputTableGridData = []
      let i = 1;
      let lineItemIds = this.mediaPlanData.lineItems.filter(obj => obj.lineItemMode === mode).map(obj => obj.id);
      let inputs = this.inputGroupData.inputs.filter(obj => obj.lineItemIds.some(id => lineItemIds.includes(id)));
      inputs.forEach(input => {
        if (this.modeLinkedLineItemMap.has(mode)) {
          let linkedLineitems = this.modeLinkedLineItemMap.get(mode);
          let combinedLinekedLineItems = [...linkedLineitems, ...input.lineItemIds];
          this.modeLinkedLineItemMap.set(mode, combinedLinekedLineItems)
        } else {
          this.modeLinkedLineItemMap.set(mode, input.lineItemIds)
        }
        inputTableGridData.push({
          index: i,
          id: input.id,
          language: input.languageId,
          adType: input.adTypeId,
          specifications: JSON.parse(input.specifications),
          dueDate: input.dueDate.split("T")[0],
          dueTime: input.dueDate.split("T")[1],
          linkLineItems: input.lineItemIds,
          status: input.status
        })
        i++;
        this.$refs.materialCopy[index].lasttblIndex = i;
      });
      this.$refs.materialCopy[index].tblData = []
      this.$refs.materialCopy[index].tblData = [...inputTableGridData]
    },
    setLinkedLineItemsOptions (mode, index) {
      const linkedlineItemOptionsMap = new Map();
      let linkedLineItemOptions = []
      const lineItems = this.mediaPlanData.lineItems.filter(item => item.lineItemMode === mode);

      lineItems.forEach(item => {
        if (!linkedlineItemOptionsMap.has(item.publisher)) {
          linkedlineItemOptionsMap.set(item.publisher, {
            id: item.publisher,
            label: item.publisher,
            children: []
          });
        }

        linkedlineItemOptionsMap.get(item.publisher).children.push({
          id: item.id,
          label: item.name
        });
      });

      // Convert map values to an array and assign to linkedlineItemOptions
      linkedLineItemOptions = Array.from(linkedlineItemOptionsMap.values());
      this.$refs.materialCopy[index].publisherLineItemOptions = [...linkedLineItemOptions]
      this.$refs.materialCopy[index].languageOptions = [...this.languagesData]
      this.$refs.materialCopy[index].adTypeOptions = [...this.adTypeData]
    },

    closeModal () {
      this.openRequisitionModal = false;
      this.renderKey = 0;
      this.resetData();
      this.$emit("closeMaterialCopyModal");
    },
    async save () {
      let index = 0;
      let inputData = []
      this.loadingData = true;
      let inputGroupRequestData = {
        id: this.inputGroupId,
        name: this.mediaPlanName,
        deliveryMode: this.selectedDeliveryMode,
        assignees: this.selectedAssignee.join(","),
        receivers: this.selectedReceiver.join(","),
        mediaPlanId: this.mediaPlanId,
        inputs: [],
        notificationInfo: JSON.stringify(this.$refs.publisherReceipt.tblData)
      }
      this.lineItemModes.forEach(mode => {
        inputData = [...inputData, ...this.$refs.materialCopy[index].tblData];
        index += 1;
      })
      inputData.forEach(input => {
        var formattedInputDate = moment(input.dueDate).format().toString().split("T")[0];
        inputGroupRequestData.inputs.push({
          id: input.id,
          inputGroupId: this.inputGroupId,
          languageId: input.language,
          adTypeId: input.adType,
          dueDate: formattedInputDate + "T" + input.dueTime,
          specifications: JSON.stringify(input.specifications),
          lineItemIds: input.linkLineItems.join(","),
          status: input.status
        })
      })
      try {
        if (this.inputGroupId == 0) {
          await apiService.create(inputGroupRequestData);
        } else {
          await apiService.edit(this.inputGroupId, inputGroupRequestData);
        }
      } finally {
        this.loadingData = false;
        this.closeModal()
      }
    },
    resetData () {
      this.openRequisitionModal = false
      this.lineItemModes = []
      this.mediaPlanData = {}
      this.inputGroupId = 0
      this.inputGroupData = {}
      this.businessProfileName = ''
      this.selectedAssignee = []
      this.selectedReceiver = []
      this.selectedDeliveryMode = null
      this.anguagesData = []
      this.adTypeData = []
      this.assigneeData = []
      this.receiverData = []
      this.mediaPlanName = ''
      this.renderKey = 0
      this.modeLineItemMap.clear()
      this.modeLinkedLineItemMap.clear()
      this.loadingData = false
      this.publisherSuppliersMap.clear()
      this.supplierData = []
    }
  }
}
</script>

<style scoped>
.close-btn{
    margin-bottom: 0px;
}
.header-title{
    font-size: medium;
    padding-top: 8px;
}
.mcr-modal-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.setting-font{
    font-size: x-large;
    position: relative;
    top: 0.5vh;
}
.plan-view-btn{
    margin-left: 0px;
}
.spinner-wrapper {
  display: grid;
  place-items: center;
}
.summary-footer{
  display: flex;
  justify-content: flex-start;
  gap: 30px;
}
.footer-header{
  display: flex;
  gap: 10px;
  padding-left: 1%;
}
.view-buttons{
  padding-left: 12px  !important;
  padding-right: 3.2%  !important;
  text-align: right !important;
}
.bp-selector{
  padding-left: 1.6% !important;
}
.summary-text{
  font-weight: bold
}
::v-deep .ivu-modal-body{
  padding-top: 0px !important;
}
</style>
