const objectives = {
  "App Installs": 1,
  "Brand Awareness": 2
}
const targetMacros = [
  "Language",
  "Location",
  "Gender",
  "AgeGroup"
]
const priorityList = {
  "TURBO": 1,
  "MAXIMUM": 2,
  "VERY_HIGH": 3,
  "HIGH": 4,
  "MEDIUM": 5,
  "LOW": 6
}
const countries = {
  "India": "IND"
}

const adUnitTargeting = {
  "Pre_Roll_Live_Android": "IPPIkrh1yZV7Tzp0",
  "Pre_Roll_Live_iOS": "Nn3FZ6wg2zDPjgOD",
  "Pre_Roll_Live_Web": "dcU7Ak3zJ0CeDdL6",
  "Pre_Roll_Live_CTV": "WuipAzZZxju9laZc",
  "Mid_Roll_Live_Android": "Dx0yOeZsjzXkGoAN",
  "Mid_Roll_Live_iOS": "wCxPsAJNnZnB4byh",
  "Mid_Roll_Live_Web": "v2N652We02jcz7ay",
  "Mid_Roll_Live_CTV": "TDSe9k3tmixVycbY",
  "Display_Home_Frame_Android": "QqeCv2QTwA2BHbM9",
  "Display_Home_Masthead_Android": "hAOSjn1hVCp7y6U6",
  "Display_Hype_Android": "YFYtpYXOz0pJDrGm",
  "Display_Movies_Free_Frame_Android": "DqnEtU7PWv33bysx",
  "Display_Movies_Free_Masthead_Android": "gyKrzvGVZBKbfJq9",
  "Display_Scorecard_Android": "cdUbI8jWTtfIdjvl",
  "Display_Shows_Premium_Frame_Android": "q6q2bzyOG3l6zFq2",
  "Display_Shows_Premium_Masthead_Android": "KpuDVN7PgFONnZBB",
  "Display_Sports_Frame_Android": "GltGznywahfCqJC1",
  "Display_Sports_Masthead_Android": "yw0zq0xPtWLmuztE",
  "Display_VideoPlayer_Fence_Android": "muFCkiYsh8jp9mz2",
  "Display_Home_Frame_iOS": "NKVV2oRxiai6jbqE",
  "Display_Home_Masthead_iOS": "DuXaOAn7VNYYbYzF",
  "Display_Hype_IOS": "GHDDrWE33ltzsBnR",
  "Display_Movies_Free_Frame_iOS": "o4B5yAuGXEijAdco",
  "Display_Movies_Free_Masthead_iOS": "sgxoDMpLdeN0hO3Y",
  "Display_Scorecard_IOS": "keP1ORvgBVgEpzpo",
  "Display_Shows_Premium_Frame_iOS": "hUnqrhmgov9xGMDg",
  "Display_Shows_Premium_Masthead_iOS": "IWDTzNfPWK5AgS1z",
  "Display_Sports_Frame_iOS": "ANDYU7BJLbjuRYKd",
  "Display_Sports_Masthead_iOS": "N0vJwOHXLG3sYQAm",
  "Display_VideoPlayer_Fence_iOS": "ZvztoFmHGztyRGyb",
  "Display_Home_Frame_Web": "OSlLtJaaIEb2pUxc",
  "Display_Home_Masthead_Web": "tDIpNhgX9o9Ep4eU",
  "Display_Hype_Web": "I14kIbDPzzwvX5OW",
  "Display_Movies_Free_Frame_Web": "rLTQckQxuHKJM2zW",
  "Display_Movies_Free_Masthead_Web": "k0ueAb3AzLvOYrsd",
  "Display_Scorecard_Web": "DJ2JufMW2R1tD1Iq",
  "Display_Shows_Premium_Frame_Web": "dx6aIm05hpuwg8kC",
  "Display_Shows_Premium_Masthead_Web": "bvaCRJiIU94FtLn8",
  "Display_Sports_Frame_Web": "qlBXSTA3HcIQJkzd",
  "Display_Sports_Masthead_Web": "hcYwyFMOfgfJ1LIH",
  "Display_VideoPlayer_Fence_Web": "j8Pc7hejltVMugxk",
  "Display_Home_Masthead_CTV": "QMyDzn1hNmeFvTHc",
  "Display_Movies_Free_Masthead_CTV": "IQ0mOU2xzywnUJKq",
  "Display_Shows_Premium_Masthead_CTV": "wgXjEvLfdhZ3BM56",
  "Display_Sports_Masthead_CTV": "hlFts3fEy18KIIdu",
  "VOD_Android": "OqEzJzxkct9eXnny",
  "VOD_iOS": "wm490N3zV2yCSEIr",
  "VOD_Web": "AgmVcEDckDgyvvlf",
  "VOD_CTV": "py8oywRFdAfniFzg"
}

const adUnitTargetingTypes = [
  "Pre-Roll",
  "Mid-Roll",
  "Display",
  "VOD",
  "Others"
]
const dealTypes = {
  [objectives["App Installs"]]: {
    "DIRECT_DEALS": 2
  },
  [objectives["Brand Awareness"]]: {
    "ROADBLOCK": 1,
    "DIRECT_DEALS": 2,
    "PROMOTIONS": 3
  }
}
const budgetTypes = {
  "Average Daily Budget": 1,
  "Fixed Budget": 2
}
const impressionInterval = {
  "1 Min": 1,
  "1 Day": 2,
  "12 Hours": 3,
  "1 Hour": 4
}
const fixedBudgetTypes = {
  "Daily": 1,
  "DayOfWeek": 2
}
const daysOfWeek = {
  "Sunday": 1,
  "Monday": 2,
  "Tuesday": 3,
  "Wednesday": 4,
  "Thursday": 5,
  "Friday": 6,
  "Saturday": 7
}
const Campaignstatus = {
  "Active": 1,
  "Paused": 2
}

const TargetingKeyValueCondition = {
  "IS": "is_in",
  "IS_NOT": "is_not_in"
}

const summaryColumns = {
  [objectives["App Installs"]]: [
    "Account",
    "Brand",
    "Objective",
    "Tracking Setting",
    "Budget Setting",
    "No of Ads"
  ],
  [objectives["Brand Awareness"]]: [
    "Account",
    "Brand",
    "Objective",
    "Budget Setting",
    "No of Ads"
  ]
}
const campaignColumns = {
  [objectives["App Installs"]]: [
    "Campaign Name",
    "Status",
    "Schedule",
    "Ad Unit Target",
    "Budget Package",
    "Budget Type",
    "Lifetime Budget",
    "Bid",
    "Edit"
  ],
  [objectives["Brand Awareness"]]: [
    "Campaign Name",
    "Status",
    "Schedule",
    "Ad Unit Target",
    "Budget Package",
    "Lifetime Budget",
    "Bid",
    "Landing URL",
    "Edit"
  ]
}
const targetingColumns = ["Campaign Name", "Target"]
const pagesAndCreativeColumn = [
  "Ad Name",
  "Status",
  "Ad Type",
  "Creative",
  "Landing Page",
  "Tracking Link"
]
const adTypes = {
  "InStream": 97,
  "ImageBanner": 98,
  "NativeImage": 99,
  "NativeVideo": 100,
  "NativeLogo": 101,
  "CustomJSON": 102
}

const entityStatus = {
  "None": 0,
  "New": 1,
  "Success": 2,
  "Failed": 3,
  "Published": 4,
  "Skipped": 5,
  "Deleted": 6,
  "SuccessWithoutCreativeGroup": 7,
  "Edited": 8
}

const events = {
  "Impressions": "imps",
  "Clicks": "clicks",
  "Video Starts": "video_starts",
  "Video First Quartiles": "video_first_quartiles",
  "Video Mid Points": "video_mid_points",
  "Video Third Quartiles": "video_third_quartiles",
  "Video Completes": "video_completes"
}

const impressionIntervalUnits = {
  "MINUTE": 1,
  "HOUR": 2,
  "DAY": 3,
  "WEEK": 4
}

const eventTracking = {
  count: 8,
  fieldMacros: ["Event $", "Event $ Tracking URL"]
}

const osTypes = {
  "ANDROID": 1,
  "IOS": 2
}

const AdStatus = {
  1: "ENABLE",
  2: "DISABLE"
}

const AdStatusValue = {
  "ENABLE": 1,
  "DISABLE": 2
}

const AdStatusNew = {
  1: "Active",
  2: "Paused"
}

const AdProperties = {
  "HOT_Ad Name": "Name",
  "HOT_Landing Page URL": "LandingPageURL",
  "HOT_Jio Creative ID": "JioCreativeId",
  "HOT_Status": "Status",
  "HOT_Event": "TrackingURLs",
  "HOT_JSON": "JSON"
}

const CampaignEnablingState = {
  "Active": "ENABLED",
  "Paused": "DISABLED"
}

const campaignEditableFields = {
  "Bid": 1,
  "CreativeGroups": 2,
  "Status": 3,
  "Targets": 4,
  "ExternalAudiences": 5,
  "LandingUrl": 6,
  "Name": 7,
  "Description": 8,
  "DealType": 9,
  "Priority": 10,
  "AdUnitTargeting": 11,
  "Schedule": 12,
  "JioCampaignId": 13,
  "RoId": 14,
  "MaxImpressionCap": 15,
  "DailyMaxImpressionCap": 16,
  "ImpressionInterval": 17
}

const campaignGoalTypes = {
  "FIXED_CPM_GENERIC": 1,
  "OPTIMIZE_CPI_FOR_APP_UA": 2
}

export {
  objectives,
  targetMacros,
  priorityList,
  adUnitTargeting,
  dealTypes,
  impressionInterval,
  budgetTypes,
  fixedBudgetTypes,
  daysOfWeek,
  Campaignstatus,
  TargetingKeyValueCondition,
  summaryColumns,
  targetingColumns,
  campaignColumns,
  pagesAndCreativeColumn,
  adTypes,
  entityStatus,
  events,
  adUnitTargetingTypes,
  countries,
  impressionIntervalUnits,
  eventTracking,
  osTypes,
  AdStatus,
  AdStatusValue,
  AdProperties,
  CampaignEnablingState,
  campaignEditableFields,
  AdStatusNew,
  campaignGoalTypes
};
