<template>
  <div>
    <i-modal
     v-show="!openMaterialCopyModal"
      v-model="showModal"
      width="96%"
      :footer-hide="true"
      :closable="false"
      :mask-closable="false"
      class="mediaplan-main-popup"
    >
    <mediaplan-comment
      ref="mediaPlanComment"
      :mediaPlanName="mediaPlanObj.mediaPlanName"
      :mediaPlanId="mediaPlanId">
    </mediaplan-comment>
    <confirmation-modal
      ref="confirmationModal"
      :confirmModalHeader="confirmationModalHeader"
      :confirmModalBody="confirmationModalBody"
      :showCancelButton="showCancelButton"
      :approveButtonText="approveButtonText"
      @accept="confirmationAccept"
      @cancel="confirmationCancel"
    ></confirmation-modal>
      <div slot="header" style="margin-top: 10px;">
         <div class="col-md-12 media-plan-header-padding bulk-header-padding" style="padding-right: 0px;">
            <div class="col-md-2 no-padding media-plan-name-div">
              <div class="col-md-2 no-padding icon-class">
                <i class="fa fa-list-alt icon-size"></i>
              </div>
              <div class="col-md-10 no-padding media-plan-name-cost-textbox">
                <i-input
                  class=" modal-label"
                  :class="[(validMediaPlanNameComputed && validMediaPlanName) ? '' : 'field-empty-error']"
                  :disabled="!isEditableMediaPlan()"
                  v-model="mediaPlanObj.mediaPlanName"
                  placeholder="Media Plan Name "
                />
              </div>
            </div>
            <div class="col-md-2 no-padding text-box-div media-plan-objective">
              <div class="col-md-2 no-padding icon-class">
                <i class="fa fa-bullseye icon-size"></i>
              </div>
              <div class="col-md-10 no-padding text-box-class">
                <i-select v-model="mediaPlanObj.objective"
                  :disabled="!isEditableMediaPlan()">
                <option-group v-for="(group, category) in mediaPlanObjectivesBasedOnCategory" :label="category" :key="category">
                  <i-option
                    v-for="item in group"
                    :value="item.id"
                    :key="item.id"
                  >{{item.name}}</i-option>
                </option-group>
                </i-select>
              </div>
            </div>
            <div class="col-md-2 no-padding text-box-div">
              <div class="col-md-2 no-padding icon-class">
                <i class="fa fa-calendar icon-size"></i>
              </div>
              <div class="col-md-10 no-padding text-box-class">
                <DatePicker
                  type="daterange"
                  placement="bottom-end"
                  format="MMM d, yyyy"
                  :clearable="false"
                  :split-panels="true"
                  :editable="false"
                  v-model="mediaPlanObj.dateFilters"
                  :disabled="!isEditableMediaPlan()"
                  class="date-picker-width"
                ></DatePicker>
              </div>
            </div>
            <div class="col-md-2 no-padding media-plan-cost-padding">
              <div class="col-md-2 no-padding icon-class cost-icon-padding">
                <i class="fa fa-usd icon-size"></i>
              </div>
              <div class="col-md-10 no-padding media-plan-name-cost-textbox">
                <i-input
                  :disabled="!isEditableMediaPlan()"
                  class=" modal-label"
                  :class="[(validMediaPlanCostComputed && validMediaPlanCost) ? '' : 'field-empty-error']"
                  v-model="mediaPlanObj.mediaCostValue"
                  placeholder="Media Cost"
                  type="number"
                  @on-blur="budgetValueChanged()"
                />
              </div>
            </div>
            <div class="col-md-2 no-padding division-div" v-if="isDivisionEnabled">
              <div class="col-md-2 no-padding icon-class icon-class">
                  <i class="fa-th-large icon-size"></i>
                </div>
              <div class="col-md-10 no-padding text-box-class">
                <i-select v-model="selectedDivision" placeholder="Division" @on-change='fetchDivisionLabels' :class="[validMediaPlanDivision ? '':'field-empty-error']">
                  <i-option v-for="item in divisions"
                          :value="item.id"
                          :key="item.id">{{item.name}}
                  </i-option>
                </i-select>
              </div>
            </div>
            <div class="col-md-2 no-padding label-multiselect">
              <div class="col-md-1 no-padding icon-class label-tag-icon">
                <i class="fa fa-tag icon-size"></i>
              </div>
              <div
              class="modal-label col-md-11 no-padding media-plan-label-multiselect"
              >
                <multi-select
                  v-model="mediaPlanSelectedLabels"
                  :multiple="true"
                  placeholder=""
                  :close-on-select="false"
                  :disabled="!isEditableMediaPlan()"
                  group-values="children"
                  group-label="label"
                  track-by="id"
                  label="name"
                  :show-labels="false"
                  :options="groupedLabels"
                  @tag="createMediaPlanLabel"
                  :taggable="true"
                  :class="mediaPlanLabelFocused?'media-plan-label-focus':'media-plan-label-not-focus'"
                  :tagPlaceholder="''"
                  @open="mediaPlanLabelFocused = true"
                  @close="mediaPlanLabelFocused = false"
                  style="width:100%">
                  <template slot="noResult">
                    No results
                  </template>
                </multi-select>
                <!-- <media-plan-label></media-plan-label> -->
              </div>
            </div>
            <span @click="cancelAction" class="cancel-button-style">
              <i class="fa fa-times fa-lg" />
            </span>
        </div>
      </div>
        <div class="no-padding bulk-style" ref="bulkPopup">
        <div :class="[isAnyTabOpened ? 'col-md-9 tab-opened-hot-table-padding tab-opened-mediaplan-padding-class' : 'col-md-12 no-padding media-plan-padding-class']" class="padding-top-style">
         <div class="col-md-12 no-padding media-plan-tabs-padding">
           <media-plan-tabs
           ref="tabs"
           :isOfflineMediaPlanEnabled="isOfflineMediaPlanEnabled"
           :validMediaPlanLandingPageLink="validMediaPlanLandingPageLink"
           @setSummaryTabData="setSummaryTabData"
           @onSaveSuccessOfMediaPlan="onSaveSuccessOfMediaPlan"
           @onSaveFailOfMediaPlan="onSaveFailOfMediaPlan"
           @validateMediaPlanDetails="validateMediaPlanDetails"
           @disableLineItemCreation="disableLineItemCreation"
           :mediaPlanObject="mediaPlanObj"
           :budgetCenterConfiguration="budgetCenterConfiguration"
           ></media-plan-tabs>
        </div>
        <hr class="footer-line-break" />
        </div>
        <div :class="[isAnyTabOpened ? 'col-md-3 no-padding overflow-hidden panel-top-padding' : '']" ref="summary">
            <div ref="summaryButton" :class="[ isAnyTabOpened  ? 'open-summary-button' : 'close-summary-button']" @click="enableSummaryTab()">
              <span v-if="isSummaryTabOpen"><i class="fa fa-angle-right summary-open-icon-style"></i></span>
              <span v-if="!isSummaryTabOpen"><i class="fa fa-angle-left summary-open-icon-style"></i></span>
              <span><p class="summaryTextStyle">Summary</p></span>
            </div>

             <div ref="briefButton" :class="[ isAnyTabOpened ? 'open-brief-button' : 'close-brief-button']" @click="enableBriefTab()">
              <span v-if="isBriefTabOpen"><i class="fa fa-angle-right summary-open-icon-style"></i></span>
              <span v-if="!isBriefTabOpen"><i class="fa fa-angle-left summary-open-icon-style"></i></span>
              <span><p class="briefTextStyle">Brief</p></span>
            </div>

            <div ref="approachNotesButton" :class="[ isAnyTabOpened ? 'open-approachNotes-button' : 'close-approachNotes-button']" @click="enableApproachNoteTab()">
              <span v-if="isApproachNotesTabOpen"><i class="fa fa-angle-right summary-open-icon-style"></i></span>
              <span v-if="!isApproachNotesTabOpen"><i class="fa fa-angle-left summary-open-icon-style"></i></span>
              <span>
                <p class="summaryTextStyle approach-text-style">Approach</p>
                <p class="summaryTextStyle note-text-style">Note</p>
              </span>
            </div>

            <div v-if="mediaPlanId>0" ref="historyButton" :class="[isAnyTabOpened ? 'open-history-button' : 'close-history-button']" @click="enableHistoryTab()">
              <span v-if="isHistoryTabOpen"><i class="fa fa-angle-right summary-open-icon-style"></i></span>
              <span v-if="!isHistoryTabOpen"><i class="fa fa-angle-left summary-open-icon-style"></i></span>
              <span>
                <p class="summaryTextStyle">History</p>
              </span>
            </div>

            <div ref="summaryPanel" :class="[isSummaryTabOpen ? showSidePanel() : 'close-side-panel']" class="perfect-scroll-bar overflow-hidden">
              <div v-if="isSummaryTabOpen"  :class="isEditableMediaPlan()?'editable-plan summary-panel-height':'non-editable summary-panel-height'" >
              <div class="highcharts-description">
                  <h5 class="summary-cost-allocation-header"> Channel Wise Cost Allocation</h5>
                  <div v-if="channelTypeChartData.length == 0 && publisherChartData.length == 0" role="alert" class="alert alert-warning summary-budget-alert">
                        No Budget input given
                  </div>
                  <highcharts class="highcharts-div-style" :options="highChartOptions"/>
                  <div class="col-md-12" v-if="isSummaryTabOpen" :class="{'summary-kpi-height': channelTypeChartData.length == 0 && publisherChartData.length == 0 }">
                    <div>
                      <h5 class="summary-kpi-header">Planned KPI</h5>
                        <table class="summary-kpi-table-style" v-if="plannedKpiData.length > 0">
                          <tr v-for="kpi in plannedKpiData" :key="kpi.kpiMetric">
                            <td> {{kpi.kpiMetric}} </td>
                            <td> {{kpi.kpiValue}} </td>
                          </tr>
                       </table>
                    </div>
                  <div v-if="plannedKpiData.length == 0" role="alert" class="alert alert-warning summary-kpi-alert">
                          No planned KPI input given
                  </div>
                </div>
              </div>
              </div>
              </div>

            <div ref="summaryPanel" :class="[isBriefTabOpen ? showSidePanel() : 'close-side-panel']" class="perfect-scroll-bar overflow-hidden">
              <div v-if="isBriefTabOpen"  :class="isEditableMediaPlan()?'editable-plan summary-panel-height':'non-editable summary-panel-height'" >
                <div class="highcharts-description">
                  <div class="col-md-12 no-padding">
                         <div class="col-md-4 no-padding"><p class="brief-campaign-style">Project :</p></div>
                        <div class="col-md-8 no-padding">
                          <p class="brief-data-style">{{selectedBrief}}</p>
                        </div>
                   </div>
                   <div class="col-md-12 no-padding">
                         <div class="col-md-4 no-padding"><p class="brief-campaign-style">Phase :</p></div>
                        <div class="col-md-8 no-padding">
                          <p class="brief-data-style">{{selectedPhase.name}}</p>
                        </div>
                   </div>
                    <div class="col-md-12 no-padding">
                         <div class="col-md-4 no-padding"><p class="brief-duration-style">Duration  :</p></div>
                         <div class="col-md-8 no-padding"><p class="brief-data-style">{{selectedPhase.startDate ? getFormattedDate(selectedPhase.startDate) : ""}}{{selectedPhase.endDate ? `- ${getFormattedDate(selectedPhase.endDate)}` : `` }}</p></div>
                    </div>
                    <div class="col-md-12 no-padding">
                          <div class="col-md-4 no-padding"><p class="brief-budget-style">Budget  :</p></div>
                         <div class="col-md-8 no-padding"><p class="brief-data-style">{{selectedPhase.budget}}</p></div>
                     </div>
                  <!-- <div :class="isEditableMediaPlan()?'col-md-12 no padding brief-note':'col-md-12 no padding brief-note non-editable'" style="margin-top: 18px;">
                      <vue-editor
                        v-model="briefNote"
                        :editorToolbar="toolbar"
                        ref="briefEditor"
                        :disabled="!isEditableMediaPlan()"
                      ></vue-editor>
                  </div> -->
                </div>
              </div>
            </div>

            <div :class="[isApproachNotesTabOpen ? showSidePanel() : 'close-side-panel']" class="perfect-scroll-bar overflow-hidden">
              <div v-show="isApproachNotesTabOpen"  :class="isEditableMediaPlan()?'editable-plan summary-panel-height':'non-editable summary-panel-height'" >
                <approach-note
                      ref="approachNoteComponent"
                      :mediaPlanId="mediaPlanId"
                      >
                </approach-note>
              </div>
            </div>

             <div :class="[isHistoryTabOpen ? showSidePanel() : 'close-side-panel']" class="perfect-scroll-bar overflow-hidden">
              <div v-show="isHistoryTabOpen"  class="editable-plan summary-panel-height" >
           <media-plan-history  ref="mediaPlanVersionComponent"
           :mediaPlanId="mediaPlanId"
          @updateMediaPlan="setDataToMediaPlan" >
           </media-plan-history>
              </div>
            </div>

        </div>
          <div class="col-md-12 footer-height">
            <div class="col-md-7 no-padding">
              <div class="col-md-1 no-padding">
              <button
                  :disabled="isLineItemCreationDisabled || !isEditableMediaPlan()"
                  class="btn btn-white add-rows-button"
                  @click="addRows" >
              <span class="fa fa-plus"></span> Row </button>
              </div>
              <div class="col-md-1 no-padding" style="margin-left:0.4%; margin-right:2%"
                v-if="!isValidSelectionforUnmerge">
                <button
                    class="btn btn-white merge-button"
                    @click="mergeRows"
                    :disabled="!isValidSelectionforMerge || !isEditableMediaPlan()"
                    >
                <span class="fa fa-lock"></span> Package </button>
              </div>
              <div class="col-md-1 no-padding" style="margin-left:0.4%; margin-right:2.4%"
                v-if="isValidSelectionforUnmerge">
                  <button
                    class="btn btn-white unmerge-button"
                    @click="UnmergeRows"
                    :disabled="!isEditableMediaPlan()"
                    >
                 <span class="fa fa-unlock"></span> Package </button>
              </div>
              <div class="col-md-2 mediaplan-campaign-target-button" v-show="openLIMapper">
                <line-item-configuration
                  v-on:updatedMappedLineItems="setMappedLineItemsCount($event)"
                  ref="lineItemConfig"
                  @additionalInfo="getAdditionalInfoFromMapper"
                  :key="rerenderLineItemConfiguration"
                  :getAudiences="deltax.getAudiencesURL"
                  :createTarget="deltax.createTargetURL"
                  :targets="accountTargets"
                  :selectedMediaPlanId="mediaPlanId"
                  :fromMediaPlan="true"
                ></line-item-configuration>
              </div>
              <div class="col-md-2 material-copy-btn">
                <button
                  class="btn btn-white material-copy"
                  @click="showCreateMaterialModal()"
                  :disabled="mediaPlanId == 0"
                ><span class="fa fa-copy"></span> Material Requisition </button>
              </div>
              <material-copy
               :openModal="openMaterialCopyModal"
               :mediaPlanId="mediaPlanId"
               @closeMaterialCopyModal="closeRequisitionModal"
              ></material-copy>
                <div class="col-md-6 alert alert-danger duplicate-entity-padding " v-show="!areValidEntitiesLinked || !isValidBuyMetric || !isValidDate || !isValidBundling" role="alert">
                 <div>
                  {{errorMessage}}
                 </div>
                 <i class=" close error-close-padding fa fa-times" @click="checkIfErrorCanBeClosed" aria-hidden="true"></i>
              </div>
            </div>
            <div class="col-md-5 no-padding">
              <div class="col-md-7 no-padding" align="right" :class="{'budget-with-setup': isFractionExist, 'budget-without-setup': !isFractionExist}">
                <div>Budget: {{getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : parseInt(getNumberData(this.mediaPlanObj.mediaCostValue))}},
                Total Allocated: {{getNumberData(this.lineItemTotalBudget) == 0 ? 0 : parseInt(getNumberData(this.lineItemTotalBudget))}}</div>
                <div>Balance : {{getNumberData(this.mediaPlanObj.mediaCostValue) >= 0 && getNumberData(this.lineItemTotalBudget) >= 0 ? parseInt(getNumberData(this.mediaPlanObj.mediaCostValue) - getNumberData(this.lineItemTotalBudget)) : 0}}</div>
              </div>
              <div class="col-md-1 padding piechart-style">
                <svg viewBox="0 -2 38 38" class="percentage-circle">
                  <path
                    class="ring-background"
                    d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path
                    class="ring-fill"
                    :class="{
                            'ring-under-flow': getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : getNumberData(this.lineItemTotalBudget)/ getNumberData(this.mediaPlanObj.mediaCostValue) * 100 < 100,
                            'ring-over-flow': getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : getNumberData(this.lineItemTotalBudget)/ getNumberData(this.mediaPlanObj.mediaCostValue) * 100 > 100,
                            'ring-on-par': getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : getNumberData(this.lineItemTotalBudget)/ getNumberData(this.mediaPlanObj.mediaCostValue) * 100 == 100
                            }"
                    :stroke-dasharray="getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : getNumberData(this.lineItemTotalBudget)/ getNumberData(this.mediaPlanObj.mediaCostValue) * 100 + ' 100'"
                    d="M18 2.0845
                                  a 15.9155 15.9155 0 0 1 0 31.831
                                  a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <text
                    x="18"
                    y="21.5"
                    class="percentage-text"
                  >{{getNumberData(this.mediaPlanObj.mediaCostValue) == 0 ? 0 : Math.round(getNumberData(getNumberData(this.lineItemTotalBudget)/ getNumberData(this.mediaPlanObj.mediaCostValue) * 100))}}%</text>
                </svg>
              </div>
              <div :class="{'buttons-with-setup': isFractionExist, 'buttons-without-setup': !isFractionExist}">
                 <button
                  style="margin-left:3px;"
                  type="button"
                  class="btn create-btn btn-success button-style"
                  @click.stop="saveMediaPlan"
                  :disabled="this.clicked || !isEditableMediaPlan()"
                >Save & Close<i :class="modalOkSpinnerClass"></i></button>
                 <button
                  style="margin-left:3px;"
                  type="button"
                  class="btn create-btn btn-success button-style"
                  @click.stop="intermediateSaveMediaPlan"
                  :disabled="this.clicked || !isEditableMediaPlan()"
                >Save<i :class="intermediateSaveSpinnerClass"></i>
                </button>
                <button
                  v-show="isFractionExist"
                  type="button"
                  class="btn create-btn btn-success button-style"
                  @click="downloadSetup()"
                  :disabled="this.clicked || mediaPlanId == 0"
                ><i id="nomenclature-report-spinner" class="fa-download"></i>  Setup File</button>
              </div>
            </div>
          </div>
       </div>
    </i-modal>
    <div>
      <brief-component
        ref="briefComponent"
        :briefType="briefType"
        @setBriefOnCreatePlan="setBriefOnCreatePlan"
        v-show="showBriefLinkingModal">
      </brief-component>
    </div>
  </div>
</template>

<script>
import {
  Select,
  Option,
  OptionGroup,
  DatePicker,
  Input,
  Modal,
  locale
} from "iview";
import lang from "iview/dist/locale/en-US"; // iview is default chinese
import { ApiService } from ".././Services/mediaPlanBulkCreateApiService.js";
import { Chart } from "highcharts-vue";
import { dxColors } from "../../../Directives/dxHighchartsModule.js";
import MediaPlanTabs from "./MediaPlanTabs.vue";
import Multiselect from "vue-multiselect";
import MediaPlanComment from "./MediaPlanComments.vue";
import ConfirmationModal from "./ConfirmationModal.vue";
import { EventBus } from "../../CampaignLauncher/EventBus.js";
import ApproachNote from "./ApproachNote.vue";
import Brief from "./Brief.vue";
import MediaPlanHistory from "./MediaPlanHistory.vue";
import { mapState, mapMutations } from "vuex";
import LineItemConfiguration from "../../CampaignLauncher/Components/LineItemConfiguration.vue";
import { briefTypeIds } from ".././Constants/executeMediaPlan.js";
import BriefApiService from "../../Projects/Services/BriefApiService.js"
import MaterialCopy from '../../Material Requisition/Components/MaterialCopy.vue'
locale(lang);
export default {
  components: {
    "i-modal": Modal,
    "i-select": Select,
    "i-option": Option,
    "i-input": Input,
    "option-group": OptionGroup,
    DatePicker,
    highcharts: Chart,
    "media-plan-tabs": MediaPlanTabs,
    "multi-select": Multiselect,
    "mediaplan-comment": MediaPlanComment,
    "confirmation-modal": ConfirmationModal,
    "approach-note": ApproachNote,
    "brief-component": Brief,
    "media-plan-history": MediaPlanHistory,
    "line-item-configuration": LineItemConfiguration,
    "material-copy": MaterialCopy
  },
  props: {
    loggedInUserId: { type: String, default: () => "" },
    isCampaignLauncher: { type: Boolean, default: false }
  },
  created () {
    this.apiService = new ApiService();
  },
  mounted () {
    this.getAllLabels();
    this.$nextTick(() => {
      const budgetCenterConfigPromise = this.apiService.getBudgetCenterConfiguration();
      budgetCenterConfigPromise.then(res => {
        this.budgetCenterConfiguration = (res.data != undefined && res.data != '' && res.data.offlineColumnAliasOptions != undefined && res.data.offlineColumnAliasOptions != '') ? JSON.parse(res.data.offlineColumnAliasOptions) : {};
        this.isOfflineMediaPlanEnabled =
                res.data.isOfflineMediaPlanEnabled != undefined && res.data.isOfflineMediaPlanEnabled != null
                  ? res.data.isOfflineMediaPlanEnabled
                  : false
        this.isEstimateEnabled =
                res.data.isEstimateEnabled != undefined && res.data.isEstimateEnabled != null
                  ? res.data.isEstimateEnabled
                  : false
        this.visibleAttributes = res.data.visibleAttributes
      })
        .catch(err => {
          console.log('error in fetching budgetCenterconfig', err);
        })
    })
    this.mediaPlanObj = {
      dateFilters: [
        moment().startOf("day"),
        moment()
          .add(30, "days")
          .startOf("day")
      ]
    };

    $(".perfect-scroll-bar").perfectScrollbar({ });
    EventBus.$on("updateRnfInMediaPlan", rnfLineItems => {
      var self = this;
      self.rnfData = rnfLineItems;
      self.setRnfDataOnLIMapperSave();
    });
  },
  beforeDestroy () {
    EventBus.$off("updateRnfInMediaPlan");
  },
  data: function () {
    return {
      approveButtonText: 'Submit',
      showCancelButton: false,
      isEstimateEnabled: false,
      redirectUrl: '',
      mediaPlanSelectedLabels: [],
      mediaPlanLabelFocused: false,
      data: [],
      spinner: false,
      areValidEntitiesLinked: true,
      mediaPlanData: [],
      editMediaPlanId: 0,
      clicked: false,
      selectedPhase: null,
      selectedPhaseId: null,
      validModel: true,
      isMediaPlanValid: true,
      isValidSelectionforMerge: false,
      isValidSelectionforUnmerge: false,
      mediaPlanObj: {
        mediaPlanName: "",
        mediaCostValue: "",
        objective: 1,
        dateFilters: [],
        mediaPlanVersionId: 0,
        status: 0,
        landingPageLink: "",
        labels: []
      },
      mediaPlanId: 0,
      showModal: false,
      apiService: {},
      mediaPlanObjectivesSource: [],
      isCreatePopUp: true,
      modalOkSpinnerClass: "",
      lineItemTotalBudget: 0,
      selectedBenchmarks: [],
      selectedAttributes: [],
      mediaPlanLabels: [],
      labelTypes: [],
      groupedLabels: [],
      errorMessage: '',
      isValidBuyMetric: true,
      validMediaPlanName: true,
      validMediaPlanCost: true,
      validMediaPlanLandingPageLink: true,
      isValidDate: true,
      isValidBundling: true,
      mediaPlanObjectivesBasedOnCategory: [],
      isSummaryTabOpen: false,
      plannedKpiData: [],
      highChartOptions:
      {
        chart: {
          type: 'pie',
          height: 220,
          width: 250
        },
        title: {
          text: ''
        },
        plotOptions: {
          pie: {
            shadow: false
          }
        },
        tooltip: {
          formatter: function () {
            if (this.point.name != "Slice") {
              return this.point.name + '<br>' + this.y
            } else {
              return this.y
            }
          }
        },
        series: []
      },
      channelTypeChartData: [],
      publisherChartData: [],
      isBriefTabOpen: false,
      briefNote: '',
      toolbar: [
        ["bold"],
        [{ list: "bullet" }]
      ],
      customBorderData: [],
      isOfflineMediaPlanEnabled: false,
      offlineLineItems: [],
      isOfflineMediaPlanValid: true,
      confirmationModalHeader: '',
      confirmationModalBody: '',
      isLineItemCreationDisabled: false,
      isDigitalLineItemsValid: true,
      briefCampaign: "",
      briefDuration: "",
      briefBudget: "",
      visibleAttributes: "",
      briefs: [],
      selectedBriefId: 0,
      showBriefLinkingModal: false,
      isApproachNotesTabOpen: false,
      briefType: "1",
      divisions: [],
      selectedBrief: "",
      briefObj: {},
      isHistoryTabOpen: false,
      latestMediaPlanData: {},
      latestMediaPlanVersionSelected: true,
      mediaPlanVersionSpinner: false,
      intermediateSave: false,
      intermediateSaveSpinnerClass: "",
      accountTargets: [],
      rerenderLineItemConfiguration: 0,
      openLIMapper: false,
      isReloadRequired: false,
      rnfData: [],
      isFractionExist: false,
      selectedDivision: 0,
      validMediaPlanDivision: true,
      isDivisionEnabled: false,
      budgetCenterConfiguration: {},
      versionId: 0,
      deletedLineItems: [],
      openMaterialCopyModal: false
    }
  },
  computed: {
    ...mapState([
      "deltax",
      "mediaPlans"
    ]),
    validMediaPlanNameComputed: function () {
      if (this.mediaPlanObj.mediaPlanName != undefined) {
        if (this.mediaPlanObj.mediaPlanName.length > 0) { this.validMediaPlanName = true; return true; } else { return false }
      } else {
        return true
      }
    },
    validMediaPlanCostComputed: function () {
      if (this.mediaPlanObj.mediaCostValue != undefined) {
        if (this.mediaPlanObj.mediaCostValue.toString().length > 0) { this.validMediaPlanCost = true; return true; } else { return false }
      } else {
        return true
      }
    },
    hot: function () {
      return this.$refs["tabs"].$refs["hotTable"].hot;
    },
    hotTable: function () {
      return this.$refs.tabs.$refs.hotTable;
    },
    isAnyTabOpened: function () {
      return this.isBriefTabOpen || this.isSummaryTabOpen || this.isApproachNotesTabOpen || this.isHistoryTabOpen;
    }
  },
  methods: {
    closeRequisitionModal () {
      this.openMaterialCopyModal = false;
    },
    showCreateMaterialModal () {
      this.openMaterialCopyModal = true
    },
    showSidePanel: function () {
      var sidePanelClass = "";
      if (this.mediaPlanId > 0) {
        sidePanelClass = 'open-side-panel history-panel-style';
      } else {
        sidePanelClass = 'open-side-panel summary-panel-style';
      }
      return sidePanelClass;
    },
    ...mapMutations(["set_mediaPlans", "set_mediaPlanId", "set_mappedLineItems"]),
    disableLineItemCreation: function (disableLineItemCreation) {
      this.isLineItemCreationDisabled = disableLineItemCreation;
    },
    budgetValueChanged: function () {
      this.$refs.tabs.budgetChanged();
    },
    showCommentPopUp: function () {
      this.$refs["mediaPlanComment"].setupModal(true)
    },
    enableSummaryTab: function () {
      var self = this;
      if (!self.isSummaryTabOpen) {
        if (self.isBriefTabOpen) {
          self.isBriefTabOpen = false;
        }
        if (self.isApproachNotesTabOpen) {
          self.isApproachNotesTabOpen = false;
        }
        if (self.isHistoryTabOpen) {
          self.isHistoryTabOpen = false;
        }
        self.isSummaryTabOpen = true;
      } else {
        self.isSummaryTabOpen = false;
      }

      if (self.isSummaryTabOpen) {
        self.setSummaryTabData();
      }
    },
    setSummaryTabData: function () {
      var self = this;
      var hotData = self.$refs.tabs.$refs.hotTable.getTableSourceData();
      self.plannedKpiData = [];
      self.channelTypeChartData = [];
      self.publisherChartData = [];
      var sum;
      var channelTypeIndex = 0;
      var publisherIndex = 0;
      hotData.forEach(element => {
        if (element.kpiMetric) {
          if (self.plannedKpiData.map(x => x.kpiMetric).includes(element.kpiMetric)) {
            var kpiValues = [];
            kpiValues.push(parseInt(self.plannedKpiData.find(x => x.kpiMetric == element.kpiMetric).kpiValue.toString().replace(",", "")));
            sum = kpiValues.reduce(function (a, b) {
              return a + b;
            }, 0)
            self.plannedKpiData.find(x => x.kpiMetric == element.kpiMetric).kpiValue = (sum + element.kpiValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            self.plannedKpiData.push({
              kpiMetric: element.kpiMetric,
              kpiValue: element.kpiValue ? element.kpiValue.toString().replace(",", "").replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0
            })
          }
        }
        if (element.cost && (element.channelType || element.publisher)) {
          if (self.channelTypeChartData.map(x => x.name).includes(element.channelType)) {
            var innerChartCosts = [];
            innerChartCosts.push(self.channelTypeChartData.find(x => x.name == element.channelType).y);
            sum = innerChartCosts.reduce(function (a, b) {
              return a + b;
            }, 0)
            self.channelTypeChartData.find(x => x.name == element.channelType).y = sum + element.cost;
          } else {
            self.channelTypeChartData.push({
              y: element.cost,
              color: dxColors[channelTypeIndex % dxColors.length],
              name: element.channelType
            })
            channelTypeIndex++;
          }

          if (self.publisherChartData.map(x => x.name).includes(element.publisher)) {
            var outerChartCosts = [];
            outerChartCosts.push(self.publisherChartData.find(x => x.name == element.publisher).y);
            sum = outerChartCosts.reduce(function (a, b) {
              return a + b;
            }, 0)
            self.publisherChartData.find(x => x.name == element.publisher).y = sum + element.cost;
          } else {
            self.publisherChartData.push({
              y: element.cost,
              color: dxColors[publisherIndex % dxColors.length],
              name: element.publisher
            })
            publisherIndex++;
          }
        }
      });

      this.highChartOptions.series = [{
        name: '',
        data: self.channelTypeChartData,
        size: '60%'
      },
      {
        name: '',
        data: self.publisherChartData,
        size: '80%',
        innerSize: '60%'
      }
      ]
    },
    enableBriefTab: function () {
      var self = this;
      if (!self.isBriefTabOpen) {
        if (self.isSummaryTabOpen) {
          self.isSummaryTabOpen = false;
        }
        if (self.isApproachNotesTabOpen) {
          self.isApproachNotesTabOpen = false;
        }
        if (self.isHistoryTabOpen) {
          self.isHistoryTabOpen = false;
        }
        self.isBriefTabOpen = true;
      } else {
        self.isBriefTabOpen = false;
      }
    },
    enableHistoryTab: function () {
      var self = this;
      if (!self.isHistoryTabOpen) {
        self.isSummaryTabOpen = false;
        self.isApproachNotesTabOpen = false;
        self.isBriefTabOpen = false;
        self.isHistoryTabOpen = true;
      } else {
        self.isHistoryTabOpen = false;
      }
    },
    getFormattedDate: function (date) {
      return moment(date).format("MMM DD,YYYY");
    },
    cancelAction: function () {
      if (this.isReloadRequired) {
        location.reload();
        window.onbeforeunload = false;
      }
      this.showModal = false;
      this.isValidSelectionforMerge = false;
      this.isValidSelectionforUnmerge = false;
      this.mediaPlanObj.mediaPlanName = "";
      this.mediaPlanObj.mediaCostValue = "";
      this.mediaPlanObj.objective = 1;
      this.mediaPlanObj.dateFilters[0] = moment().startOf("day");
      this.mediaPlanObj.dateFilters[1] = moment()
        .add(30, "days")
        .startOf("day");
      this.areValidEntitiesLinked = true;
      this.isValidBuyMetric = true;
      this.isValidDate = true;
      this.isValidBundling = true;
      this.validMediaPlanName = true;
      this.validMediaPlanCost = true;
      this.validMediaPlanLandingPageLink = true;
      this.modalOkSpinnerClass = "";
      this.isSummaryTabOpen = false;
      this.$refs.tabs.cancelAction();
      this.isBriefTabOpen = false;
      this.briefNote = "";
      this.mediaPlanSelectedLabels = [];
      window.formOpen = false;
      this.$refs.approachNoteComponent.cancelAction();
      this.isApproachNotesTabOpen = false;
      this.briefType = "1";
      this.intermediateSaveSpinnerClass = "";
      this.selectedDivision = 0;
      this.validMediaPlanDivision = true;
      this.isDivisionEnabled = false;
    },
    init: async function () {
      var self = this;
      self.latestMediaPlanVersionSelected = true;
      this.openLIMapper = false;
      this.rnfData = [];
      this.deletedLineItems = [];
      this.redirectUrl = window.location.href;
      if (self.mediaPlanId == 0) {
        self.isCreatePopUp = true;
      } else {
        self.isCreatePopUp = false;
      }
      self.spinner = true;
      self.clicked = true;
      self.isDigitalLineItemsValid = true;
      const briefsPromise = this.apiService.getBriefs();
      briefsPromise.then(async function (res) {
        var briefResponse = res.data.data;
        let briefIds = [];
        briefResponse.forEach(function (element) {
          briefIds.push(element.id);
        });
        let briefPhases = await self.apiService.getPhasesByBriefId(briefIds.join(","));
        briefResponse.forEach(function (element) {
          element.phases = briefPhases.filter((x) => {
            return x.briefId == element.id;
          })
        });
        briefResponse.forEach(function (element) {
          briefIds.push(element.id);
        });
        self.briefs = briefResponse;
        if (self.mediaPlanId == 0) {
          self.$refs.briefComponent.init();
        }
      })

      self.briefCampaign = "";
      self.briefDuration = "";
      self.briefBudget = "";

      var intermediateSave = this.intermediateSave;
      this.isReloadRequired = intermediateSave;
      if (!intermediateSave) {
        const mediaPlanPromise = this.apiService.getMediaPlanObjectives();
        mediaPlanPromise.then(res => {
          self.mediaPlanObjectivesSource = res.data;

          var groupBy = function (objectives, key) {
            return objectives.reduce(function (element, x) {
              (element[x[key]] = element[x[key]] || []).push(x);
              return element;
            }, {});
          };
          self.mediaPlanObjectivesBasedOnCategory = groupBy(self.mediaPlanObjectivesSource, 'category')
        });

        var businessProfilePromise = this.apiService.getBusinessProfile();
        businessProfilePromise.then(res => {
          this.isDivisionEnabled = res.data.data.isDivisionEnabled != undefined && res.data.data.isDivisionEnabled != null
            ? res.data.data.isDivisionEnabled
            : false
          if (this.isDivisionEnabled) {
            const divisionsPromise = this.apiService.getDivisions();
            divisionsPromise.then(res => {
              var divisionResponse = res.data.data;
              self.divisions = divisionResponse != undefined && divisionResponse
                ? res.data.data.filter(x => x.isDisabled != true).map(x => x)
                : []
              self.selectedDivision = divisionResponse != undefined && divisionResponse
                ? self.divisions[0].id
                : 0
            })
              .catch(err => {
                console.log('Error in fetching divisions', err);
              })
          }
        })
      }

      if (self.mediaPlanId > 0) {
        this.$refs.mediaPlanVersionComponent.init(self.mediaPlanId);
        const getMediaPlandDataByIdPromise =
          this.versionId === 0 || this.versionId === undefined
            ? self.apiService.getMediaPlanDataById(this.mediaPlanId)
            : self.apiService.getMediaPlanVersionDataById(this.mediaPlanId, this.versionId)
        getMediaPlandDataByIdPromise
          .then(res => {
            this.$refs.approachNoteComponent.init();
            self.mediaPlanData = res.data.data;
            this.deletedLineItems = self.mediaPlanData.lineItems.filter(item => item.isDelete);
            self.offlineLineItems = self.mediaPlanData.lineItems.filter(item => item.lineItemMode != 1 && !item.isDelete);
            self.mediaPlanData.lineItems = self.mediaPlanData.lineItems.filter(item => item.lineItemMode == 1 && !item.isDelete);

            self.latestMediaPlanData = res.data.data;
            self.setMediaPlanData(self.mediaPlanData);
            var mediaPlans = [];
            var mediaPlan = {
              BriefPhaseId: self.mediaPlanData.briefPhaseId,
              Budget: self.mediaPlanData.budget,
              CreatedAt: self.mediaPlanData.createdAt,
              EffectiveStatus: self.mediaPlanData.effectiveStatus,
              EndDate: self.mediaPlanData.endDate,
              Id: self.mediaPlanData.id,
              LandingPageLink: self.mediaPlanData.landingPageLink,
              Name: self.mediaPlanData.name,
              Objective: self.mediaPlanData.objective,
              StartDate: self.mediaPlanData.startDate,
              Status: self.mediaPlanData.status,
              UpdatedAt: self.mediaPlanData.updatedAt,
              TypeId: self.mediaPlanData.typeId
            }
            mediaPlans = mediaPlans.concat(mediaPlan);
            self.set_mediaPlans(mediaPlans);
            self.set_mediaPlanId(mediaPlan.Id);
            this.$refs.tabs.init(intermediateSave);
            window.campaignLauncherApp.$children[0].getMediaPlanLineItems();
            this.openLIMapper = true;
            this.$nextTick(() => {
              this.rerenderLineItemConfiguration ^= 1;
            })

            self.setFractionCount(self.mediaPlanData.lineItems)
          })
          .catch(x => {
            console.log(x);
            window.alert("Error in fetching data");
          });
      } else {
        self.mediaPlanObj = {
          objective: 1,
          dateFilters: [
            moment().startOf("day"),
            moment()
              .add(30, "days")
              .startOf("day")
          ]
        };
        this.$refs.tabs.init(intermediateSave);
      }
      this.intermediateSave = false;
    },
    setBulkCreate: function (showModal, mediaPlanId, versionId) {
      this.mediaPlanId = mediaPlanId;
      this.versionId = versionId;
      this.init();
      if (mediaPlanId == 0) {
        this.$children[1].associatedBrief = "";
        this.showBriefLinkingModal = true;
        this.$children[1].showBriefPopup = true;
      } else {
        this.showModal = showModal
      }
    },
    isValidModel: function () {
      var name = this.mediaPlanObj.mediaPlanName;
      var cost = this.mediaPlanObj.mediaCostValue;
      if (name === undefined || cost === undefined) {
        this.isMediaPlanValid = false;
        if (name === undefined) {
          window.alert("Please enter media plan name");
        } else {
          window.alert("Please enter media cost");
        }
      } else {
        this.isMediaPlanValid = true;
      }
      return this.hot.validateCells(valid => {
        this.validModel = valid;
        return this.isMediaPlanValid && this.validModel;
      });
    },
    addRows: function () {
      this.$refs.tabs.addRows();
    },
    mergeRows: function () {
      if (this.isValidSelectionforMerge) {
        this.$refs.tabs.mergeCells();
      }
    },
    UnmergeRows: function () {
      if (this.isValidSelectionforUnmerge) {
        this.$refs.tabs.unmergeCells();
      }
    },
    getDuplicateEntities: function (entities) {
      let duplicateEntities = [];
      duplicateEntities = entities.reduce((acc, currentValue, index, array) => {
        if (array.indexOf(currentValue) != index && !acc.includes(currentValue)) acc.push(currentValue);
        return acc;
      }, []);
      return duplicateEntities;
    },
    setInvalidCellsForDuplicateEntities: function (linkedEntityInformation) {
      let campaignIds = [];
      let adgroupIds = [];
      linkedEntityInformation.filter(x => x.entityType == 1).map(function (p) {
        p.entityIds.map(function (entity) {
          campaignIds.push(entity);
        })
      })
      linkedEntityInformation.filter(x => x.entityType == 2).map(function (p) {
        p.entityIds.map(function (entity) {
          adgroupIds.push(entity);
        })
      })
      var duplicateCampaignIds = this.getDuplicateEntities(campaignIds);
      var duplicateAdgroupIds = this.getDuplicateEntities(adgroupIds);
      let duplicateRowIds = [];
      linkedEntityInformation.filter(x => x.entityType == 1).forEach(element => {
        element.entityIds.forEach(campaignId => {
          if (duplicateCampaignIds.includes(campaignId)) {
            duplicateRowIds.push(element.rowId);
          }
        })
      })
      linkedEntityInformation.filter(x => x.entityType == 2).forEach(element => {
        element.entityIds.forEach(adgroupId => {
          if (duplicateAdgroupIds.includes(adgroupId)) {
            duplicateRowIds.push(element.rowId);
          }
        })
      })
      return duplicateRowIds;
    },
    saveMediaPlan: async function () {
      var self = this;
      var name = this.mediaPlanObj.mediaPlanName;
      var cost = this.mediaPlanObj.mediaCostValue;
      var landinglink = this.mediaPlanObj.landingPageLink;
      this.hot.updateSettings({
        invalidCellClassName: "invalid-cell-data"
      });
      let offlineLineItems = [];
      let mediaPlanPromises = [];
      var division = this.selectedDivision;
      if (this.isOfflineMediaPlanEnabled) {
        const offlineLineItemsFetchPromise = this.$refs.tabs.getOfflineLineItems();
        offlineLineItemsFetchPromise.then(resolver => {
          offlineLineItems = resolver;
        })
        mediaPlanPromises.push(offlineLineItemsFetchPromise)
      }
      if (
        name === undefined ||
        name === "" ||
        cost === undefined ||
        cost === "" ||
        (landinglink != undefined &&
          landinglink.length > 0 &&
          !(landinglink.startsWith("http") || landinglink.startsWith("HTTP"))) ||
        (this.isDivisionEnabled == true && division === 0)
      ) {
        self.isMediaPlanValid = false;
        self.modalOkSpinnerClass = "";
        self.intermediateSaveSpinnerClass = "";
        self.validMediaPlanName = !((name === undefined || name === ""))
        self.validMediaPlanCost = !((cost === undefined || cost === ""))
        self.validMediaPlanLandingPageLink = !((landinglink != undefined &&
          landinglink.length > 0 &&
          !(landinglink.startsWith("http") || landinglink.startsWith("HTTP"))))
        self.validMediaPlanDivision = (!(division === 0) || !(this.isDivisionEnabled))
      } else {
        self.isMediaPlanValid = true;
        self.validMediaPlanName = true;
        self.validMediaPlanCost = true;
        self.validMediaPlanLandingPageLink = true;
        self.modalOkSpinnerClass = !self.intermediateSave ? "fa fa-spinner fa-spin" : "";
        self.intermediateSaveSpinnerClass = self.intermediateSave ? "fa fa-spinner fa-spin" : "";
        self.validMediaPlanDivision = true;
      }
      var tableData = this.$refs.tabs.$refs.hotTable.getTableSourceData();
      var handsonRows = tableData.map((value, index) => { return index; })
      return self.hot.validateRows(handsonRows, (valid) => {
        handsonRows.forEach(row => {
          var rowData = this.$refs.tabs.$refs.hotTable.getRowSourceData(row);
          if (valid != false) {
            if (rowData.buyMetric == "Fixed") {
              if (rowData.buyType == "Fixed") { valid = true } else {
                valid = false;
                this.isValidBuyMetric = false
                this.errorMessage = rowData.buyMetric + ' ' + 'is not supported for' + ' ' + rowData.buyType
              }
            }
          }
          if (valid != false) {
            if (rowData.bundleId != null) {
              if (rowData.bundleCost == null || rowData.bundleCost == undefined || rowData.bundleCost == "") {
                valid = false;
                this.isValidBundling = false;
                this.errorMessage = "Some of the MergedLineItems have empty PackageCost";
              }
              if (rowData.bundleCost < 0 || rowData.bundleDiscount < 0) {
                valid = false;
                this.isValidBundling = false;
                this.errorMessage = "Some of the LineItems have Negative PackageCost Or PackageDiscount"
              }
            }
          }
          if (valid != false) {
            if (rowData.buyMetric == "Days") {
              if (rowData.buyType == "CostPerDay") {
                valid = true
              } else {
                valid = false
                this.isValidBuyMetric = false
                this.errorMessage = rowData.buyMetric + ' ' + 'is not supported for' + ' ' + rowData.buyType
              }
            }
          }
          if (valid != false) {
            if (rowData.startdate != undefined && rowData.enddate != undefined) {
              if (moment(rowData.startdate).format("YYYY/MM/DD") <= moment(rowData.enddate).format("YYYY/MM/DD")) {
                valid = true
              } else {
                valid = false;
                this.isValidDate = false;
                this.errorMessage = 'Some line item have End Date less than Start Date'
              }
            }
          }
        })
        self.validModel = valid;
        if (!self.validModel) {
          this.modalOkSpinnerClass = "";
          this.intermediateSaveSpinnerClass = "";
          this.isDigitalLineItemsValid = false;
        } else {
          this.isDigitalLineItemsValid = true;
        }
        if (self.isMediaPlanValid && self.validModel) {
          // Entity Validation
          var lineItemData = this.getLineItemData(tableData);
          self.areValidEntitiesLinked = true;
          if (lineItemData != undefined) {
            self.customBorderData = [];
            self.customBorderData = [];
            self.hot.updateSettings({
              customBorders: self.customBorderData
            })
            var linkedEntityInformation = [];
            lineItemData.forEach(function (lineItem) {
              if (
                lineItem.EntityTypeId == 1 &&
                lineItem.LinkedEntities != undefined &&
                lineItem.LinkedEntities.length > 0
              ) {
                var campaignIds = lineItem.LinkedEntities.map(function (p) {
                  return p.entityId;
                });
                linkedEntityInformation.push({ entityIds: campaignIds, entityType: 1, rowId: lineItemData.indexOf(lineItem) })
              }
              if (
                lineItem.EntityTypeId == 2 &&
                lineItem.LinkedEntities != undefined &&
                lineItem.LinkedEntities.length > 0
              ) {
                var adgroupIds = lineItem.LinkedEntities.map(function (p) {
                  return p.entityId;
                });
                linkedEntityInformation.push({ entityIds: adgroupIds, entityType: 2, rowId: lineItemData.indexOf(lineItem) })
              }
            });
            var duplicateRowIds = self.setInvalidCellsForDuplicateEntities(linkedEntityInformation)
            if (duplicateRowIds.length > 0) {
              duplicateRowIds.forEach(rowIndex => {
                self.customBorderData.push({ row: rowIndex,
                  col: 0,
                  left: { width: 1, color: 'red' },
                  right: { width: 1, color: 'red' },
                  top: { width: 1, color: 'red' },
                  bottom: { width: 1, color: 'red' }
                })
              })
              self.hot.updateSettings({
                customBorders: self.customBorderData
              })
              self.areValidEntitiesLinked = false;
              self.errorMessage = 'Few Entities are linked in multiple Line Item(s)';
              self.modalOkSpinnerClass = "";
              self.intermediateSaveSpinnerClass = "";
            }
          }

          if (self.selectedBriefId != undefined && self.selectedBriefId > 0 && self.briefNote != undefined) {
            let briefNotePromise = self.apiService.updateBriefDescription(self.selectedBriefId,
              JSON.stringify("[{'Name':'Description','Value':'" + self.escapeCharacters(self.briefNote) + "'}]"));
            briefNotePromise.then(res => {}).catch(x => { console.log(x); });
            mediaPlanPromises.push(briefNotePromise);
          } else if (self.briefType == 2) {
            let briefNotePromise = self.apiService.saveBriefNote(self.briefObj);
            briefNotePromise.then(res => {
              self.selectedBriefId = res.data.data;
            }).catch(x => {
              console.log(x);
            });
            mediaPlanPromises.push(briefNotePromise);
          }

          Promise.all(mediaPlanPromises).then(async () => {
            if (!this.isOfflineMediaPlanValid) {
              this.modalOkSpinnerClass = "";
              this.intermediateSaveSpinnerClass = "";
            }
            if (
              self.isMediaPlanValid &&
            self.validModel &&
            self.areValidEntitiesLinked &&
            self.isOfflineMediaPlanValid
            ) {
              self.modalOkSpinnerClass = !self.intermediateSave ? "fa fa-spinner fa-spin" : "";
              self.intermediateSaveSpinnerClass = self.intermediateSave ? "fa fa-spinner fa-spin" : "";
              this.clicked = true;
              let data = self.$refs.tabs.$refs.hotTable.getTableSourceData();
              if (self.$refs.tabs.$refs.hotTable.idEditRowDeleted == true) {
                self.$refs.tabs.$refs.hotTable.deletedLIForEdit.forEach(li => {
                  if (!data.map(x => x.lineItemId).includes(li.lineItemId)) {
                    li.isDelete = true;
                    data.push(li);
                  }
                });
              }
              if (self.briefType == briefTypeIds.new) {
                self.selectedPhase = await self.apiService.getPhasesByBriefId(self.selectedBriefId);
                self.selectedPhaseId = self.selectedPhase[0].id;
              }
              let apiMediaPlanModel = {
                Name: self.mediaPlanObj.mediaPlanName,
                Status: this.mediaPlanId == 0 || this.mediaPlanObj.status == 7 ? 1 : self.mediaPlanObj.status, // status 7 is rejected, if current status is 7 then move the updated mediaplan to draft
                MediaPlanVersionId:
                this.mediaPlanId == 0
                  ? 0
                  : self.mediaPlanObj.mediaPlanVersionId != undefined
                    ? self.mediaPlanObj.mediaPlanVersionId
                    : 0,
                Id:
                this.mediaPlanId == 0
                  ? 0
                  : self.editMediaPlanId != undefined
                    ? self.editMediaPlanId
                    : 0,
                Objective: self.mediaPlanObj.objective,
                Budget: self.mediaPlanObj.mediaCostValue,
                LandingPageLink: self.mediaPlanObj.landingPageLink,
                StartDate: moment(self.mediaPlanObj.dateFilters[0]).format(
                  "MM/DD/YYYY"
                ),
                EndDate: moment(self.mediaPlanObj.dateFilters[1]).format(
                  "MM/DD/YYYY"
                ),
                EffectiveStatus:
                this.mediaPlanId == 0
                  ? 0
                  : self.mediaPlanData.effectiveStatus != undefined
                    ? self.mediaPlanData.effectiveStatus
                    : 0,
                BenchmarksOrdering: self.getBenchmarksOrdering(offlineLineItems),
                Label: this.mediaPlanSelectedLabels,
                LineItemRequest: self.getLineItemData(data, offlineLineItems, this.deletedLineItems).concat(self.getDeletedLineItemData(this.deletedLineItems)),
                CrossChannelSuggestionEnabled: self.mediaPlanObj.crossChannelSuggestionEnabled,
                ApprovedBuyId: self.mediaPlanObj.approvedBuyId,
                BriefId: self.selectedBriefId,
                BriefPhaseId: self.selectedPhaseId,
                OwnerId: self.loggedInUserId,
                DivisionId: this.selectedDivision
              };
              const mediaPlanLISavePromise = self.apiService.saveBulkMediaPlan(
                apiMediaPlanModel
              );
              mediaPlanLISavePromise
                .then(res => {
                  // let status = this.mediaPlanId == 0 ? 1 : self.mediaPlanObj.status;
                  let promises = [];
                  var mediaPlanId = self.mediaPlanId == 0 ? res.data.mediaPlanId : self.mediaPlanId;

                  if (self.hot.countRows() > 0) {
                    var attributes = self.$refs.tabs.$refs.hotTable.attributeColumns.filter(x => x.title != 'Objective').map(x => x.title);
                    const saveVisibleAttributesPromise = self.apiService.updateVisibleAttributes(
                      "[{'Name':'VisibleAttributes','Value':'" + attributes.toString() + "'}]");
                    saveVisibleAttributesPromise.then(res => {}).catch(err => { console.log(err) });
                    promises.push(saveVisibleAttributesPromise);
                  }

                  self.$refs.tabs.$refs.hotTable.onSaveOfMediaPlan = true;
                  self.$refs.tabs.$refs.hotTable.confirmBoxShowCancel = false;
                  self.$refs.tabs.$refs.hotTable.confirmBoxHeader = "Success";
                  var approachNoteId;
                  var formData = new FormData();
                  for (let i = 0; i < self.$refs.approachNoteComponent.imageUploadList.length; i++) {
                    formData.append("files", self.$refs.approachNoteComponent.imageUploadList[i]);
                  }
                  var approachNoteObj = { mediaPlanId: mediaPlanId, description: self.$refs.approachNoteComponent.approachNotes, name: self.mediaPlanObj.mediaPlanName }

                  let approachNotesPromise;
                  if (self.$refs.approachNoteComponent.approachNotesAPIdata != undefined && self.$refs.approachNoteComponent.approachNotesAPIdata.id > 0) {
                    approachNotesPromise = self.apiService.editApproachNotes(self.$refs.approachNoteComponent.approachNotesAPIdata.id, approachNoteObj);
                  } else {
                    approachNotesPromise = self.apiService.saveApproachNotes(approachNoteObj);
                  }
                  approachNotesPromise.then(res => {
                    approachNoteId = self.$refs.approachNoteComponent.approachNotesAPIdata != undefined && self.$refs.approachNoteComponent.approachNotesAPIdata.id > 0 ? self.$refs.approachNoteComponent.approachNotesAPIdata.id : res.data.data;
                    if (self.$refs.approachNoteComponent.imageUploadList != [] && self.$refs.approachNoteComponent.imageUploadList != undefined && self.$refs.approachNoteComponent.imageUploadList.length > 0) {
                      const updateApproachNotesPromise = self.apiService.uploadApprochNotesAttachments(approachNoteId, formData);
                      updateApproachNotesPromise.then(res => {}).catch(x => { console.log(x); });
                      promises.push(updateApproachNotesPromise);
                    }
                  })
                    .catch(x => {
                      console.log(x);
                    });
                  promises.push(approachNotesPromise);

                  if (self.$refs.approachNoteComponent.detachAttachmentIds.length > 0) {
                    const deleteApproachNoteAttachmentsPromise = self.apiService.deleteApproachNoteAttachments(self.$refs.approachNoteComponent.detachAttachmentIds);
                    deleteApproachNoteAttachmentsPromise.then(res => {}).catch(x => { console.log(x); });
                    promises.push(deleteApproachNoteAttachmentsPromise);
                  }

                  Promise.all(promises).then(() => {
                    if (self.mediaPlanId == 0) {
                      if (this.isCampaignLauncher) {
                        var mediaPlan = {
                          id: this.mediaPlanId,
                          name: this.mediaPlanObj.mediaPlanName,
                          objectives: {
                            selected: this.mediaPlanObj.objective
                          },
                          cost: this.mediaPlanObj.mediaCostValue,
                          schedule: [this.mediaPlanObj.dateFilters[0], this.mediaPlanObj.dateFilters[1]]
                        }
                        EventBus.$emit("campaignLauncherApp-selectNewMediaPlan", { mediaPlan: mediaPlan });
                      }
                      self.mediaPlanId = res.data.mediaPlanId;
                      self.$refs.tabs.$refs.hotTable.confirmBoxText = "Media Plan saved successfully."
                    } else {
                      if (self.mediaPlanObj.status == 7) {
                        self.$refs.tabs.$refs.hotTable.confirmBoxText = "Media Plan updated successfully and will be moved to drafts tab."
                      } else {
                        self.$refs.tabs.$refs.hotTable.confirmBoxText = "Media Plan updated successfully."
                      }
                    }
                    self.$refs.tabs.$refs.hotTable.enableConfirmPopup = true;
                    window.formOpen = false;
                  })
                    .catch(x => { console.log(x); })
                })
                .catch(x => {
                  self.$refs.tabs.$refs.hotTable.onFailToSaveMediaPlan = true;
                  self.$refs.tabs.$refs.hotTable.confirmBoxShowCancel = false;
                  self.$refs.tabs.$refs.hotTable.confirmBoxHeader = "Failure";
                  self.$refs.tabs.$refs.hotTable.confirmBoxText = "Failed to save media plan data"
                  self.$refs.tabs.$refs.hotTable.enableConfirmPopup = true;
                });
            }
          }).catch(x => { console.log(x) });
        }
      });
    },
    setBundleId: function (lineItems) {
      const minOrderIdMap = new Map();
      lineItems.forEach(item => {
        const { LineItemMode, BundleId, OrderId } = item;
        if (BundleId) {
          const key = `${LineItemMode}-${BundleId}`;
          const existingminOrderId = minOrderIdMap.get(key);
          if (existingminOrderId == null || existingminOrderId == undefined || OrderId < existingminOrderId) {
            minOrderIdMap.set(key, OrderId);
          }
        }
      });

      minOrderIdMap.forEach((value, key) => {
        const [LineItemMode, BundleId] = key.split('-');
        var filteredlineItems = lineItems.filter(item => item.LineItemMode == LineItemMode && item.BundleId == BundleId && item.OrderId >= value);
        filteredlineItems.forEach(item => {
          item.BundleId = value
        })
      });
    },
    getLineItemData: function (digitalLineItemData, offlineLineItems = [], deletedLineItems = []) {
      var self = this;
      var displayNames = self.$refs.tabs.$refs.hotTable.lineItemPublishersAPIData.map(x => x.displayName);

      let orderIdIterator = 1;

      if (this.mediaPlanId != 0 && this.mediaPlanId != undefined) {
        let maxDigitalOrderId = Math.max(...digitalLineItemData.map(x => x.orderId || x.OrderId));
        let maxOfflineOrderId = Math.max(...offlineLineItems.map(x => x.orderId || x.OrderId));
        let maxDeletedOrderId = Math.max(...deletedLineItems.map(x => x.orderId || x.OrderId));

        orderIdIterator = Math.max(maxDigitalOrderId, maxOfflineOrderId, maxDeletedOrderId) + 1;
      }

      let digitalLineItems = digitalLineItemData.map(row => ({
        IsDelete: row.isDelete != undefined ? row.isDelete : false,
        Id:
          this.mediaPlanId == 0
            ? 0
            : row.lineItemId != undefined
              ? row.lineItemId
              : 0,
        Type: self.$refs.tabs.$refs.hotTable.lineItemTypes.find(
          x => x.name == row.channelType
        ).id,
        Publisher: displayNames.includes(row.publisher)
          ? (self.$refs.tabs.$refs.hotTable.lineItemPublishersAPIData.find(
            x => x.displayName == row.publisher
          ).publisher).replace(/[\t\n]/gm, ' ').trim() : row.publisher.replace(/[\t\n]/gm, ' ').trim(),
        Theme: row.theme != undefined ? row.theme.replace(/[\t\n]/gm, ' ').trim() : "",
        Entity: row.entity,
        Objective: this.mediaPlanObj.objective == 5 && row.objective ? row.objective.replace(' ', '') : null,
        Targeting: row.targeting != undefined ? row.targeting : null,
        Nickname: row.nickname != undefined ? row.nickname : null,
        StartDate:
          row.startdate != undefined
            ? (moment(row.startdate).format("YYYY/MM/DD") <
                moment(self.mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") ||
                moment(row.startdate).format("YYYY/MM/DD") >
                moment(self.mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD"))
              ? moment(self.mediaPlanObj.dateFilters[0]).format("MMM DD,YYYY")
              : row.startdate
            : moment(self.mediaPlanObj.dateFilters[0]).format("MMM DD,YYYY"),
        EndDate:
          row.enddate != undefined
            ? (moment(row.enddate).format("YYYY/MM/DD") >
                moment(self.mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") ||
                moment(row.enddate).format("YYYY/MM/DD") <
                moment(self.mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD"))
              ? moment(self.mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD")
              : row.enddate
            : moment(self.mediaPlanObj.dateFilters[1]).format("MMM DD,YYYY"),
        Budget: row.cost,
        PricingTypeId: self.$refs.tabs.$refs.hotTable.dataParameterPricingTypeMapping
          .filter(
            x =>
              x.name ==
              this.$refs.tabs.$refs.hotTable.costSourceEnums.find(
                y => y.id == row.costSource
              ).name
          )[0]
          .lineItemPricing.filter(x => x.name == row.buyType)[0]
          .DataParameter.filter(x => x.name == row.buyMetric)
          .map(x => x.id)[0],
        PricingTypeReferenceValueId: self.$refs.tabs.$refs.hotTable.dataParameterPricingTypeMapping
          .filter(
            x =>
              x.name ==
              this.$refs.tabs.$refs.hotTable.costSourceEnums.find(
                y => y.id == row.costSource
              ).name
          )[0]
          .lineItemPricing.filter(x => x.name == row.buyType)
          .map(x => x.id)[0],
        KpiMetricId: self.$refs.tabs.$refs.hotTable.benchMarkList.find(
          x => x.name == row.kpiMetric
        ).id,
        UnitPrice: row.unitCost,
        Quantity: Math.round(row[row.buyMetric]),
        CostSource: self.$refs.tabs.$refs.hotTable.dataParameterPricingTypeMapping.find(
          x =>
            x.name ==
            this.$refs.tabs.$refs.hotTable.costSourceEnums.find(
              y => y.id == row.costSource
            ).name
        ).id,
        PerUnitPrice: row.unitCost,
        EntityTypeId: self.$refs.tabs.$refs.hotTable.entityEnum.find(
          x => x.name == row.entity
        ).id,
        OrderId: this.mediaPlanId == 0 || row.lineItemId == undefined || row.lineItemId == 0 ? orderIdIterator++ : row.orderId,
        CostPacingTypeId:
          row.costPacingTypeId != undefined ? row.costPacingTypeId : 1,
        CostPacingJson: row.costPacingJson != null ? row.costPacingJson : null,
        TotalCost: row.cost,
        CreativeFormat:
          row.creativeformat != undefined ? row.creativeformat.replace(/[\t\n]/gm, ' ').trim() : "",
        Note1: row.note1 != undefined ? row.note1.replace(/[\t\n]/gm, ' ').trim() : "",
        Note2: row.note2 != undefined ? row.note2.replace(/[\t\n]/gm, ' ').trim() : "",
        RONumber: row.ronumber != undefined ? row.ronumber.replace(/[\t\n]/gm, ' ').trim() : "",
        LinkedEntities:
          row.linkedEntities != undefined ? row.linkedEntities : [],
        IsCostCalcuated:
          row.costSource == "Ad Server (Cost)" ||
          row.costSource == "Others (Cost)" ||
          row.costSource == "Ad Server (Cost & Discount Delivery)" ||
          row.costSource == "Others (Cost & Discount Delivery)"
            ? 1
            : 0,
        IsDiscountOverdelivery:
          row.costSource == "Ad Server (Cost & Discount Delivery)" ||
          row.costSource == "Others (Cost & Discount Delivery)"
            ? 1
            : 0,
        DataParameter: this.getDataParameters(
          this.$refs.tabs.$refs.hotTable.benchMarkColumns,
          row
        ),
        Label: this.getLabels(row),
        ReferenceKeys: row.referenceKeys,
        LineItemFractions: row.lineItemFractions,
        IsFractionEnabled: row.isFractionEnabled,
        ROId: row.roId,
        SupplierId: displayNames.includes(row.publisher) ? self.$refs.tabs.$refs.hotTable.lineItemPublishersAPIData.find(
          x => x.displayName == row.publisher
        ).supplierId : null,
        Universe: row.universe,
        ReachPercentage: row.reachPercentage,
        BundleCost: row.bundleCost,
        BundleDiscount: row.bundleDiscount,
        BundleId: row.bundleId,
        LineItemMode: 1,
        field1: row.field1,
        field2: row.field2,
        field3: row.field3,
        field4: row.field4,
        field5: row.field5,
        field6: row.field6,
        field7: row.field7,
        field8: row.field8,
        field9: row.field9,
        field10: row.field10
      }));

      offlineLineItems.forEach(li => {
        if (this.mediaPlanId == 0 || li.OrderId == 0) {
          li.OrderId = orderIdIterator++;
        }
      });

      let allLineItems = digitalLineItems.concat(offlineLineItems);
      this.setBundleId(allLineItems);
      return allLineItems;
    },
    getDeletedLineItemData: function (data) {
      return data.map(row => ({
        IsDelete: row.isDelete,
        Id: row.id,
        Type: row.type,
        Publisher: row.publisher,
        Theme: row.theme,
        Entity: row.entityTypeId,
        Objective: row.objective,
        Targeting: row.targeting,
        Nickname: row.nickname,
        StartDate: row.startDate,
        EndDate: row.endDate,
        Budget: row.budget,
        PricingTypeId: row.pricingTypeId,
        PricingTypeReferenceValueId: row.pricingTypeReferenceValueId,
        KpiMetricId: row.kpiMetricId,
        UnitPrice: row.unitCost,
        Quantity: row.quantity,
        CostSource: row.costSource,
        PerUnitPrice: row.perUnitPrice,
        EntityTypeId: row.entityTypeId,
        OrderId: row.orderId,
        CostPacingTypeId: row.costPacingTypeId,
        CostPacingJson: row.costPacingJson,
        TotalCost: row.totalCost,
        CreativeFormat: row.creativeformat,
        Note1: row.note1,
        Note2: row.note2,
        RONumber: row.roNumber,
        LinkedEntities: row.linkedEntities,
        IsCostCalcuated: row.isCostCalcuated,
        IsDiscountOverdelivery: row.isDiscountOverdelivery,
        DataParameter: row.lineItemDataParameters,
        Label: row.labels,
        ReferenceKeys: row.referenceKeys,
        LineItemFractions: row.lineItemFractions,
        IsFractionEnabled: row.isFractionEnabled,
        ROId: row.roId,
        SupplierId: row.supplierId,
        Universe: row.universe,
        ReachPercentage: row.reachPercentage
      }));
    },
    getLabels: function (row) {
      var self = this;
      var labels = [];
      if (row.toBeAddedLabels != undefined && row.toBeAddedLabels.length > 0) {
        labels.push(...row.toBeAddedLabels);
      }
      var allLabelDimension = this.$refs.tabs.$refs.hotTable.labelTypes.map(i => i.name);
      allLabelDimension.forEach(function (labelDimension) {
        if (row[labelDimension] != "" || row[labelDimension] != undefined) {
          var label = self.$refs.tabs.$refs.hotTable.labels.find(
            i => i.name == row[labelDimension]
          );
          if (label != undefined) {
            var labelObject = {
              id: label.id,
              name: label.name
            };
            labels.push(labelObject);
          }
        }
      });
      return labels;
    },
    getDataParameters: function (benchMarkColumns, row) {
      if (benchMarkColumns != "" || benchMarkColumns != null) {
        var dataParameters = [];
        benchMarkColumns
          .filter(
            x =>
              (parseInt(row[x.data]) >= 0) &&
              (x.title == "Fixed" ? row["buyMetric"] == "Fixed" : true) &&
              (x.title == "Days" ? row["buyMetric"] == "Days" : true)
          )
          .map(column => {
            if (row[column.data] == 0) {
              dataParameters.push({
                DataParameterId: this.$refs.tabs.$refs.hotTable.lineItemDataParameters.find(
                  x => x.name == column.data
                ).id,
                DataParameterValue: null,
                OrderId: 0
              });
            }
            if (row[column.data]) {
              dataParameters.push({
                DataParameterId: this.$refs.tabs.$refs.hotTable.lineItemDataParameters.find(
                  x => x.name == column.data
                ).id,
                DataParameterValue: row[column.data],
                OrderId: 0
              });
            }
          });

        return dataParameters;
      } else {
      }
    },
    getNumberData: function (value) {
      if (value != "" && value != null && value != undefined && value > 0) {
        return value;
      } else {
        return 0;
      }
    },
    getBenchmarksOrdering: function (offlineLineItems) {
      var benchmarksOrdering = [];
      var self = this;
      self.$refs.tabs.$refs.hotTable.benchMarkColumns.forEach(element => {
        if (
          !benchmarksOrdering
            .map(x => x)
            .includes(
              self.$refs.tabs.$refs.hotTable.lineItemDataParameters.find(
                x => x.name == element.title
              ).id
            )
        ) {
          benchmarksOrdering.push(
            self.$refs.tabs.$refs.hotTable.lineItemDataParameters.find(
              x => x.name == element.title
            ).id
          );
        }
      });

      offlineLineItems.forEach(element => {
        element.DataParameter.forEach(dp => {
          if (!benchmarksOrdering.includes(dp.DataParameterId)) {
            benchmarksOrdering.push(dp.DataParameterId);
          }
        })
      });

      return benchmarksOrdering;
    },
    getDataParameterOrderId: function (allDataParameters, id) {
      let orderIds = allDataParameters
        .filter(x => x.OrderId != undefined || x.OrderId != null)
        .map(x => x.OrderId);
      if (orderIds.length == 0) {
        return 1;
      } else {
        return (
          Math.max.apply(
            Math,
            orderIds.map(x => x)
          ) + 1
        );
      }
    },
    checkIfErrorCanBeClosed: function () {
      if (this.areValidEntitiesLinked == false) {
        this.areValidEntitiesLinked = true;
      }
      if (this.isValidBuyMetric == false) {
        this.isValidBuyMetric = true;
      }
      if (this.isValidDate == false) {
        this.isValidDate = true;
      }
      if (this.isValidBundling == false) {
        this.isValidBundling = true;
      }
    },
    onSaveSuccessOfMediaPlan: function () {
      var self = this;
      self.apiService.syncMediaPlan(self.mediaPlanId);
      self.$refs.tabs.$refs.hotTable.lineItemData = [];
      this.selectedBenchmarks = [];
      this.selectedAttributes = [];
      this.mediaPlanSelectedLabels = [];
      this.modalOkSpinnerClass = "";
      this.clicked = false;
      self.$refs.tabs.$refs.hotTable.onSaveOfMediaPlan = false;
      self.$refs.tabs.$refs.hotTable.enableConfirmPopup = false;
      this.intermediateSaveSpinnerClass = "";
      this.selectedDivision = 0;
      if (this.intermediateSave) {
        var attributes = self.$refs.tabs.$refs.hotTable.attributeColumns.filter(x => x.title != 'Objective').map(x => x.title);
        self.visibleAttributes = attributes.toString();
        this.init();
      } else {
        self.showModal = false;
        if (this.mediaPlanObj.status == 7) {
          this.redirectUrl = "/App/BudgetCenter/MediaMonitor/CampaignMonitor/" + agency + "#/drafts"
          window.location.href = this.redirectUrl;
        } else {
          location.reload();
        }
        window.onbeforeunload = false;
      }
    },
    onSaveFailOfMediaPlan: function () {
      var self = this;
      self.modalOkSpinnerClass = "";
      self.clicked = false;
      self.$refs.tabs.$refs.hotTable.onFailToSaveMediaPlan = false;
      self.$refs.tabs.$refs.hotTable.enableConfirmPopup = false;
      self.intermediateSaveSpinnerClass = "";
    },
    calculateLineItemTotalBudget: function () {
      if (this.isOfflineMediaPlanEnabled) {
        this.lineItemTotalBudget =
          parseFloat(this.$refs["tabs"].$refs["hotTable"].hot.getDataAtProp("cost").reduce((e, t) => parseFloat(e + "") + t, 0)) +
          parseFloat(this.$refs["tabs"].$refs["televisionLineItem"].hot.getDataAtProp("budget").reduce((e, t) => parseFloat(e + "") + t, 0)) +
          parseFloat(this.$refs["tabs"].$refs["radioLineItem"].hot.getDataAtProp("budget").reduce((e, t) => parseFloat(e + "") + t, 0)) +
          parseFloat(this.$refs["tabs"].$refs["pressLineItem"].hot.getDataAtProp("budget").reduce((e, t) => parseFloat(e + "") + t, 0)) +
          parseFloat(this.$refs["tabs"].$refs["oohLineItem"].hot.getDataAtProp("budget").reduce((e, t) => parseFloat(e + "") + t, 0)) +
          parseFloat(this.$refs["tabs"].$refs["cinemaLineItem"].hot.getDataAtProp("budget").reduce((e, t) => parseFloat(e + "") + t, 0)) +
          parseFloat(this.$refs["tabs"].$refs["magazineLineItem"].hot.getDataAtProp("budget").reduce((e, t) => parseFloat(e + "") + t, 0))
      } else {
        this.lineItemTotalBudget =
          this.$refs["tabs"].$refs["hotTable"].hot.getDataAtProp("cost").reduce((e, t) => parseFloat(e + "") + t, 0)
      }
    },
    createMediaPlanLabel (newLabelName) {
      const newLabelObj = {
        id: 0,
        name: newLabelName
      };

      this.mediaPlanLabels.push(newLabelObj);
      this.mediaPlanSelectedLabels.push(newLabelObj);
      var other = this.groupedLabels.find(label => label.label == 'Others');
      if (other != null) {
        other.children.push(newLabelObj);
      } else {
        var optionObj = {
          id: 0,
          label: 'Others',
          children: []
        }
        optionObj.children.push({
          newLabelObj
        });
        this.groupedLabels.push(optionObj);
      }
    },
    getAllLabels: function () {
      var self = this;
      this.mediaPlanLabels = [];

      var promiseList = [];
      var promise1 = this.apiService.getLabelTypes();
      var labelPromise = this.apiService.getLabels();
      promiseList.push(promise1);
      promiseList.push(labelPromise);
      Promise.all(promiseList).then(res => {
        self.mediaPlanLabels = res[1].data.result;
        self.labelTypes = res[0].data.result;
        self.labelTypes.sort((a, b) => a.id - b.id);
        let groupLabel = [];
        var manualLabel = {
          id: 0,
          label: 'Others',
          children: []
        }
        groupLabel.push(manualLabel);
        self.labelTypes.forEach(labelType => {
          var obj = {
            id: labelType.id,
            label: labelType.name,
            children: []
          }
          groupLabel.push(obj);
        });
        self.mediaPlanLabels.forEach(label => {
          if (label.labelTypeId == null || label.labelTypeId == undefined) {
            groupLabel[0].children.push({
              id: label.id,
              name: label.name
            });
          }
          groupLabel.forEach(labelType => {
            if (labelType.id == label.labelTypeId) {
              labelType.children.push({
                id: label.id,
                name: label.name
              });
            }
          });
        })
        self.groupedLabels = groupLabel;
      })
    },
    validateMediaPlanDetails: function (newValue) {
      this.mediaPlanObj.landingPageLink = newValue
      if (this.mediaPlanObj.landingPageLink != undefined && this.mediaPlanObj.landingPageLink.length > 0) {
        if (this.mediaPlanObj.landingPageLink.toLowerCase().startsWith("http")) {
          this.validMediaPlanLandingPageLink = true;
          return true;
        } else {
          this.validMediaPlanLandingPageLink = false;
          return false
        }
      } else {
        this.validMediaPlanLandingPageLink = true;
        return true
      }
    },
    setOfflineLineItemDate: function (offlineType) {
      if (this.mediaPlanId == 0) {
        let rowsCount = this.$refs.tabs.$refs[offlineType].hot.countRows();
        for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
          let rowData = this.$refs.tabs.$refs[offlineType].getRowSourceData(eachRow);
          rowData.startdate = moment(this.mediaPlanObj.dateFilters[0]).format(
            "MMM DD, YYYY"
          )
          rowData.enddate = moment(this.mediaPlanObj.dateFilters[1]).format(
            "MMM DD, YYYY"
          )
          this.$refs.tabs.$refs[offlineType].skipSettingMediaPlanDateFilter = true;
        }
      } else {
        let rowsCount = this.$refs.tabs.$refs[offlineType].hot.countRows();
        for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
          let rowData = this.$refs.tabs.$refs[offlineType].getRowSourceData(eachRow);
          if (
            moment(rowData.startdate).format("YYYY/MM/DD") <
                moment(this.mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") ||
                 moment(rowData.startdate).format("YYYY/MM/DD") >
                moment(this.mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD")
          ) {
            rowData.startdate = moment(this.mediaPlanObj.dateFilters[0]).format(
              "MMM DD, YYYY"
            )
          }
          if (
            moment(rowData.enddate).format("YYYY/MM/DD") >
                moment(this.mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") ||
                moment(rowData.enddate).format("YYYY/MM/DD") <
                moment(this.mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD")
          ) {
            rowData.enddate = moment(this.mediaPlanObj.dateFilters[1]).format(
              "MMM DD, YYYY"
            )
          }
          this.$refs.tabs.$refs[offlineType].skipSettingMediaPlanDateFilter = true;
        }
      }
      if (this.$refs.tabs.$refs[offlineType].attributeColumns.map(x => x.title).includes("Segment Start Date")) {
        var startDateDetails = this.$refs.tabs.$refs[offlineType].attributeColumns.find(
          x => x.title == "Segment Start Date"
        );
        startDateDetails.datePickerConfig.minDate = moment(
          this.mediaPlanObj.dateFilters[0]
        ).toDate();
        startDateDetails.datePickerConfig.maxDate = moment(
          this.mediaPlanObj.dateFilters[1]
        ).toDate();
        this.$refs.tabs.$refs[offlineType].hot.updateSettings({
          data: this.$refs.tabs.$refs[offlineType].lineItemData,
          columns: [
            ...this.$refs.tabs.offlineMediaPlanColumns,
            ...this.$refs.tabs.$refs[offlineType].attributeColumns,
            ...this.$refs.tabs.$refs[offlineType].benchMarkColumns
          ]
        })
      }
      if (this.$refs.tabs.$refs[offlineType].attributeColumns.map(x => x.title).includes("Segment End Date")) {
        var endDateDetails = this.$refs.tabs.$refs[offlineType].attributeColumns.find(
          x => x.title == "Segment End Date"
        );
        endDateDetails.datePickerConfig.minDate = moment(
          this.mediaPlanObj.dateFilters[0]
        ).toDate();
        endDateDetails.datePickerConfig.maxDate = moment(
          this.mediaPlanObj.dateFilters[1]
        ).toDate();
        this.$refs.tabs.$refs[offlineType].hot.updateSettings({
          data: this.$refs.tabs.$refs[offlineType].lineItemData,
          columns: [
            ...this.$refs.tabs.offlineMediaPlanColumns,
            ...this.$refs.tabs.$refs[offlineType].attributeColumns,
            ...this.$refs.tabs.$refs[offlineType].benchMarkColumns
          ]
        })
      }
    },

    confirmationAccept: function () {
      if (!this.showCancelButton) {
        window.location.href = this.redirectUrl;
        return;
      }
      this.$refs["confirmationModal"].setupModal(false)
    },
    confirmationCancel: function () {
      this.$refs["confirmationModal"].setupModal(false)
    },
    briefChanged: function () {
      var self = this;
      var selectedBriefId = this.selectedBriefId;
      if (this.selectedBriefId != 0 && this.selectedBriefId != null && this.selectedBriefId != undefined) {
        var briefObj = self.briefs.find(i => i.id == selectedBriefId);
        if (briefObj != undefined && briefObj) {
          self.selectedBrief = briefObj.name;
          self.briefDuration = self.getFormattedDate(briefObj.startDate) + " - " + self.getFormattedDate(briefObj.endDate)
          self.briefBudget = briefObj.budget;
          if (briefObj.description != undefined) {
            self.briefNote = briefObj.description
          } else {
            self.briefNote = "";
          }
        }
      } else {
        self.briefDuration = "";
        self.briefBudget = "";
        self.briefNote = "";
      }
    },
    async phaseChanged () {
      if (this.selectedPhaseId) {
        var phase = await this.apiService.getPhaseById(this.selectedPhaseId);
        if (phase) {
          this.selectedPhase = phase;
        }
      } else {
        this.selectedPhase = {};
        this.selectedPhase.briefId = null;
        this.selectedPhase.startDate = null;
        this.selectedPhase.endDate = null;
        this.selectedPhase.name = null;
      }
    },
    enableApproachNoteTab: function () {
      var self = this;
      if (!self.isApproachNotesTabOpen) {
        if (self.isBriefTabOpen) {
          self.isBriefTabOpen = false;
        }
        if (self.isSummaryTabOpen) {
          self.isSummaryTabOpen = false;
        }
        if (self.isHistoryTabOpen) {
          self.isHistoryTabOpen = false;
        }
        self.isApproachNotesTabOpen = true;
      } else {
        self.isApproachNotesTabOpen = false;
      }

      if (self.isApproachNotesTabOpen) {
        self.setApproachNotesTabData();
      }
    },
    setBriefOnCreatePlan: function (brief) {
      if (brief != undefined && brief != null) {
        if (brief.divisionId > 0) {
          var division = this.divisions.find(x => x.id == brief.divisionId);
          if (division != undefined && division) {
            var divisionLabels = division.labels;
            this.mediaPlanSelectedLabels = this.mediaPlanLabels.filter(f => divisionLabels.map(x => x.id).includes(f.id));
          }
        }
        if (this.briefType == 1) {
          this.selectedBriefId = brief.id;
          this.selectedBrief = brief.name;
          this.selectedPhase = brief.selectedPhase;
          this.selectedPhaseId = brief.selectedPhase.id;
          this.briefDuration = this.getFormattedDateFromPhase(brief.selectedPhase);
          this.briefBudget = brief.selectedPhase.budget;
          this.briefNote = brief.description;
          this.mediaPlanObj.mediaPlanName = brief.name;
          this.mediaPlanObj.dateFilters = moment(brief.selectedPhase.startDate).startOf("day") != undefined && moment(brief.selectedPhase.startDate).startOf("day") != null && moment(brief.selectedPhase.endDate).startOf("day") != undefined && moment(brief.selectedPhase.endDate).startOf("day") != null ? [moment(brief.selectedPhase.startDate).startOf("day"), moment(brief.selectedPhase.endDate).startOf("day")] : [moment().startOf("day"), moment().add(30, "days").startOf("day")];
          this.mediaPlanObj.objective = parseInt(brief.objectiveId) ? brief.objectiveId : 1;
          this.briefObj = brief;
        }
        if (this.briefType == 2) {
          this.selectedBrief = brief.name;
          this.selectedPhaseId = brief.selectedPhase.id;
          this.selectedPhase = brief.selectedPhase;
          this.briefDuration = this.getFormattedDateFromPhase(brief.selectedPhase);
          this.briefBudget = brief.selectedPhase.budget;
          this.briefNote = brief.description;
          this.mediaPlanObj.mediaPlanName = brief.name;
          this.mediaPlanObj.dateFilters = moment(brief.selectedPhase.startDate).startOf("day") != undefined && moment(brief.selectedPhase.startDate).startOf("day") != null && moment(brief.selectedPhase.endDate).startOf("day") != undefined && moment(brief.selectedPhase.endDate).startOf("day") != null ? [moment(brief.selectedPhase.startDate).startOf("day"), moment(brief.selectedPhase.endDate).startOf("day")] : [moment().startOf("day"), moment().add(30, "days").startOf("day")];
          this.mediaPlanObj.objective = parseInt(brief.objectiveId) ? brief.objectiveId : 1;
          this.briefObj = brief;
        }

        this.showBriefLinkingModal = false;
        this.$children[1].showBriefPopup = false;
        this.showModal = true;
      }
      this.$children[1].briefModalOkSpinnerClass = "";
    },
    setApproachNotesTabData: function () {
      var currentDefaultFileList = this.$refs.approachNoteComponent.defaultFileList.filter((el) => !this.$refs.approachNoteComponent.removedDefaultFileList.includes(el))
      var allUploadedList = currentDefaultFileList.concat(this.$refs.approachNoteComponent.imageUploadList);
      this.$refs.approachNoteComponent.defaultFileList = [...new Set(allUploadedList)];
    },
    isEditableMediaPlan: function () {
      if (!this.latestMediaPlanVersionSelected) {
        return false;
      }
      if (this.isEstimateEnabled) {
        return false;
      } else {
        return true;
      }
    },
    getFormattedDateFromPhase (phase) {
      return moment(phase.startDate).format("MMM D, YYYY") + " - " + moment(phase.endDate).format("MMM D, YYYY");
    },
    setDataToMediaPlan: function (mediaPlanObj, isLatestVersionSelected) {
      var self = this;
      self.mediaPlanVersionSpinner = true;
      self.spinner = true;
      if (isLatestVersionSelected) {
        this.latestMediaPlanVersionSelected = true;
        this.mediaPlanData = self.latestMediaPlanData;
        this.setMediaPlanData(self.latestMediaPlanData);
        this.$refs.tabs.init();
      } else {
        this.latestMediaPlanVersionSelected = false;
        this.mediaPlanData = mediaPlanObj;
        this.setMediaPlanData(mediaPlanObj);
        this.$refs.tabs.init();
        this.$refs.tabs.showRecommendationTooltip = false;
      }
      this.disableEditingLineitem();
    },
    setMediaPlanData: async function (mediaPlanObj) {
      var self = this;
      self.mediaPlanObj = {
        mediaPlanName: mediaPlanObj.name,
        objective: mediaPlanObj.objective,
        dateFilters: [
          moment(mediaPlanObj.startDate).format("MMM DD,YYYY"),
          moment(mediaPlanObj.endDate).format("MMM DD,YYYY")
        ],
        mediaCostValue: mediaPlanObj.budget,
        landingPageLink: mediaPlanObj.landingPageLink,
        crossChannelSuggestionEnabled: mediaPlanObj.crossChannelSuggestionEnabled,
        approvedBuyId: mediaPlanObj.approvedBuyId,
        briefId: mediaPlanObj.briefId,
        briefPhaseId: mediaPlanObj.briefPhaseId,
        divisionId: mediaPlanObj.divisionId,
        typeId: mediaPlanObj.typeId
      };
      self.selectedPhaseId = mediaPlanObj.briefPhaseId;
      await self.phaseChanged();
      self.selectedBriefId = self.selectedPhase.briefId;
      self.briefChanged();
      self.mediaPlanObj.mediaPlanVersionId =
            mediaPlanObj.mediaPlanVersionId;
      self.editMediaPlanId = mediaPlanObj.id;
      self.mediaPlanObj.status = mediaPlanObj.status;
      self.mediaPlanObj.labels = mediaPlanObj.labels;
      this.mediaPlanSelectedLabels = self.mediaPlanObj.labels;
      self.mediaPlanObj.divisionId = mediaPlanObj.divisionId;
      this.selectedDivision = self.mediaPlanObj.divisionId
    },
    disableEditingLineitem: function () {
      var disableEditing = false;
      if (!this.isEditableMediaPlan()) {
        disableEditing = true;
      } else {
        disableEditing = false;
      }
      this.hot.updateSettings({
        readOnly: disableEditing
      })
    },
    intermediateSaveMediaPlan: function () {
      this.intermediateSave = true;
      this.isValidSelectionforMerge = false;
      this.isValidSelectionforUnmerge = false;
      this.saveMediaPlan();
    },
    getAdditionalInfoFromMapper (args) {
      EventBus.$emit("launchSettings-mappedConfigsLoaded", args.configs);
    },
    setMappedLineItemsCount (config) {
      this.set_mappedLineItems(config.mappedLineItems);
    },
    setRnfDataOnLIMapperSave: function () {
      var self = this;
      if (self.rnfData.length > 0) {
        var lineItems = this.$refs.tabs.$refs.hotTable.lineItemData;
        self.pushIntoBenchmarkColumns("Reach");
        self.pushIntoBenchmarkColumns("Frequency");
        self.$refs.tabs.$refs.hotTable.pushIntoAttributeColumns(["Universe"]);
        self.rnfData.forEach(lineItem => {
          var index = lineItems.findIndex(x => x.lineItemId == lineItem.id);
          lineItems[index].Reach = lineItem.reach;
          lineItems[index].Frequency = lineItem.frequency;
          lineItems[index].cost = lineItem.budget;
          lineItems[index].universe = lineItem.universe;
          self.$refs.tabs.$refs.hotTable.setBudgetUnitCost(lineItems[index], "cost", lineItems[index]);
        });

        self.hot.updateSettings({
          data: lineItems,
          columns: [
            ...self.$refs.tabs.$refs.hotTable.fixedDependentColumns,
            ...self.$refs.tabs.$refs.hotTable.bundleColumns,
            ...self.$refs.tabs.$refs.hotTable.fixedCostColumns,
            ...self.$refs.tabs.$refs.hotTable.attributeColumns,
            ...self.$refs.tabs.$refs.hotTable.fixedColumns,
            ...self.$refs.tabs.$refs.hotTable.benchMarkColumns
          ]
        });
      }
    },
    pushIntoBenchmarkColumns: function (element) {
      if (
        !this.$refs.tabs.$refs.hotTable.fixedDependentColumns.map(x => x.title).includes(element) &&
          !this.$refs.tabs.$refs.hotTable.fixedCostColumns.map(x => x.title).includes(element) &&
          !this.$refs.tabs.$refs.hotTable.fixedColumns.map(x => x.title).includes(element) &&
          !this.$refs.tabs.$refs.hotTable.attributeColumns.map(x => x.title).includes(element) &&
          !this.$refs.tabs.$refs.hotTable.benchMarkColumns.map(x => x.title).includes(element)
      ) {
        this.$refs.tabs.$refs.hotTable.benchMarkColumns.push({
          title: element,
          data: element,
          type: "numeric",
          minwidth: "100px",
          width: "100px",
          allowEmpty: true,
          numericFormat: {
            pattern: {
              thousandSeparated: true
            }
          }
        });
      }
    },
    downloadSetup: function () {
      if (this.mediaPlanId != 0) {
        this.apiService.downloadNomenclatureReport(this.mediaPlanId);
      }
    },
    setFractionCount: function (lineItems) {
      if (lineItems.length > 0) {
        for (const lineItem of lineItems) {
          if (lineItem.lineItemFractions.length > 0) {
            this.isFractionExist = true
          }
        }
      }
    },
    escapeCharacters: function (str) {
      return str.replaceAll("'", "\\'\\'");
    },
    fetchDivisionLabels: function (divisionId) {
      this.validMediaPlanDivision = true;
    }
  },
  watch: {
    "mediaPlanObj.dateFilters": {
      handler: async function (newValue, oldValue) {
        if (oldValue != newValue) {
          if (this.mediaPlanId == 0) {
            let rowsCount = this.hot.countRows();
            for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
              let rowData = this.$refs.tabs.$refs.hotTable.getRowSourceData(eachRow);
              rowData.startdate = moment(this.mediaPlanObj.dateFilters[0]).format(
                "MMM DD, YYYY"
              )
              rowData.enddate = moment(this.mediaPlanObj.dateFilters[1]).format(
                "MMM DD, YYYY"
              )
              this.$refs.tabs.$refs.hotTable.skipSettingMediaPlanDateFilter = true;
            }
          } else {
            let rowsCount = this.hot.countRows();
            for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
              let rowData = this.$refs.tabs.$refs.hotTable.getRowSourceData(eachRow);
              if (
                moment(rowData.startdate).format("YYYY/MM/DD") <
                moment(this.mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") ||
                 moment(rowData.startdate).format("YYYY/MM/DD") >
                moment(this.mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD")
              ) {
                rowData.startdate = moment(this.mediaPlanObj.dateFilters[0]).format(
                  "MMM DD, YYYY"
                )
              }
              if (
                moment(rowData.enddate).format("YYYY/MM/DD") >
                moment(this.mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") ||
                moment(rowData.enddate).format("YYYY/MM/DD") <
                moment(this.mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD")
              ) {
                rowData.enddate = moment(this.mediaPlanObj.dateFilters[1]).format(
                  "MMM DD, YYYY"
                )
              }
              this.$refs.tabs.$refs.hotTable.skipSettingMediaPlanDateFilter = true;
            }
          }
          if (this.$refs.tabs.$refs.hotTable.attributeColumns.map(x => x.title).includes("Start Date")) {
            var startDateDetails = this.$refs.tabs.$refs.hotTable.attributeColumns.find(
              x => x.title == "Start Date"
            );
            startDateDetails.datePickerConfig.minDate = moment(
              this.mediaPlanObj.dateFilters[0]
            ).toDate();
            startDateDetails.datePickerConfig.maxDate = moment(
              this.mediaPlanObj.dateFilters[1]
            ).toDate();
            this.hot.updateSettings({
              data: this.$refs.tabs.$refs.hotTable.lineItemData,
              columns: [
                ...this.$refs.tabs.$refs.hotTable.fixedDependentColumns,
                ...this.$refs.tabs.$refs.hotTable.bundleColumns,
                ...this.$refs.tabs.$refs.hotTable.fixedCostColumns,
                ...this.$refs.tabs.$refs.hotTable.attributeColumns,
                ...this.$refs.tabs.$refs.hotTable.fixedColumns,
                ...this.$refs.tabs.$refs.hotTable.benchMarkColumns
              ]
            })
          }
          if (this.$refs.tabs.$refs.hotTable.attributeColumns.map(x => x.title).includes("End Date")) {
            var endDateDetails = this.$refs.tabs.$refs.hotTable.attributeColumns.find(
              x => x.title == "End Date"
            );
            endDateDetails.datePickerConfig.minDate = moment(
              this.mediaPlanObj.dateFilters[0]
            ).toDate();
            endDateDetails.datePickerConfig.maxDate = moment(
              this.mediaPlanObj.dateFilters[1]
            ).toDate();
            this.hot.updateSettings({
              data: this.$refs.tabs.$refs.hotTable.lineItemData,
              columns: [
                ...this.$refs.tabs.$refs.hotTable.fixedDependentColumns,
                ...this.$refs.tabs.$refs.hotTable.bundleColumns,
                ...this.$refs.tabs.$refs.hotTable.fixedCostColumns,
                ...this.$refs.tabs.$refs.hotTable.attributeColumns,
                ...this.$refs.tabs.$refs.hotTable.fixedColumns,
                ...this.$refs.tabs.$refs.hotTable.benchMarkColumns
              ]
            })
          }
          if (this.isOfflineMediaPlanEnabled) {
            this.setOfflineLineItemDate('televisionLineItem');
            this.setOfflineLineItemDate('radioLineItem');
            this.setOfflineLineItemDate('pressLineItem');
            this.setOfflineLineItemDate('oohLineItem');
            this.setOfflineLineItemDate('cinemaLineItem');
            this.setOfflineLineItemDate('magazineLineItem');
          }
        }
      }
    },
    "mediaPlanObj.objective": {
      handler: async function (newValue, oldValue) {
        if (newValue == 5) {
          this.$refs.tabs.$refs.hotTable.checkboxAttributeList.push({ id: "Objective", name: "Objective" })
        } else if (oldValue == 5) {
          this.$refs.tabs.$refs.hotTable.checkboxAttributeList = this.$refs.tabs.$refs.hotTable.checkboxAttributeList.filter(function (obj) {
            return obj.id != "Objective";
          });
          this.$refs.tabs.$refs.hotTable.attributeColumns = this.$refs.tabs.$refs.hotTable.attributeColumns.filter(
            x => x.title != "Objective"
          );
          this.$refs.tabs.$refs.hotTable.selectedAttributes = this.$refs.tabs.$refs.hotTable.selectedAttributes.filter(x => x != "Objective");
          this.hot.updateSettings({
            columns: [
              ...this.$refs.tabs.$refs.hotTable.fixedDependentColumns,
              ...this.$refs.tabs.$refs.hotTable.bundleColumns,
              ...this.$refs.tabs.$refs.hotTable.fixedCostColumns,
              ...this.$refs.tabs.$refs.hotTable.attributeColumns,
              ...this.$refs.tabs.$refs.hotTable.fixedColumns,
              ...this.$refs.tabs.$refs.hotTable.benchMarkColumns
            ],
            invalidCellClassName: "invalid-cell-data"
          });
        }
      }
    }
  }
};
</script>
<style scoped>
.icon-class {
  margin-top: 5px;
  float: left;
  margin-right: -10px;
}
.import-LI-padding {
  margin-left: 10px;
}
.select-columns-padding {
  margin-left: 7px;
}
.footer-line-break {
  margin-left: -0.3%;
}
.media-plan-name-div{
  margin-left: -15px;
}
.select-columns-span {
  padding-left: 10px;
}
.icon-size {
  font-size: 20px;
  cursor: initial;
}
.media-plan-name-cost-textbox {
  float: left;
  padding-left: 10px;
}
.media-plan-label-multiselect {
  float: left;
  padding-left: 5px;
  width: 180px;
}
.label-multiselect{
    margin-left: 30px;
}
.text-box-div {
  margin-left: 5px !important;
}
.text-box-class {
  float: left;
  padding-left: 10px;
}
.select-columns-class {
  font-size: 25px;
  margin-top: 5px;
}
.float-right {
  float: right;
}
.handson-container {
  padding-left: 3px;
}
.media-plan-bulk-create ::v-deep .modal-header {
  border-bottom-width: 0 !important;
}
::v-deep .ivu-select-input::placeholder {
  /* Firefox, Chrome, Opera */
  font-weight: 400 !important;
  border-color: 1px solid #cecdcd !important;
  color: black !important;
}
.merge-button{
  margin-top: 2px;
}
.unmerge-button{
  margin-top: 2px;
}
::v-deep .ivu-select-input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 400;
  border-color: 1px solid #cecdcd !important;
  color: black !important;
}

::v-deep .ivu-input.ivu-input-default {
  border: 1px solid #cecdcd !important;
}

::v-deep .ivu-select-selection {
  padding: 0 24px 0 0px;
  border: 1px solid #cecdcd !important;
}

::v-deep .ivu-select-dropdown-list {
  padding: 0;
}

.modal-label {
  text-align: right !important;
}

.media-plan-budget-chart {
  margin-right: 10px;
  display: inline-block;
  vertical-align: bottom;
}
.media-plan-budget {
  display: inline-block;
  font-size: 11px;
  margin-right: 10px;
  white-space: pre-line;
  vertical-align: bottom;
}

::v-deep .ivu-modal {
  top: 53px !important;
}
::v-deep .text-center {
  text-align: center;
}
::v-deep .ivu-tooltip-inner {
  margin-bottom: 0px;
  margin-right: 25px;
}
.modal-container {
  height: 210px;
}
.percentage-circle {
  width: 35px;
  height: 35px;
  vertical-align: bottom;
}
.ring-background {
  stroke: #ddd;
  stroke-width: 4;
}
.ring-fill {
  fill: none;
  stroke-width: 4;
}
.ring-under-flow {
  stroke: #ff7f50;
}
.ring-over-flow {
  stroke: #ff0000;
}
.ring-on-par {
  stroke: #32cd32;
}
.percentage-text {
  fill: #fff;
  font-weight: 600;
  font-size: 10px;
  text-anchor: middle;
}
.overflow-hidden {
  overflow: hidden;
}

.piechart-style {
  height: 100%;
}
.button-style {
  text-align: center;
  float: right !important;
}
ul {
  list-style-type: none;
  padding: 5px;
  margin: 10px;
}

ul li {
  margin-top: -2px; /* Prevent double borders */
  text-decoration: none;
  font-size: 13px;
  display: block;
  position: relative;
}

.close {
  cursor: pointer;
  z-index:9999;
  position: absolute;
  top: 49%;
  transform: translateY(-30%);
  right: 10px
}
.list-style {
  margin-left: 20px;
  margin-top: 20px;
  font-size: 13px;
}

.vue-multiselect-tree.searchbar {
  padding: 10px !important;
  background-color: white !important;
  border-bottom: 2px solid #f5f5f5 !important;
}

.vue-multiselect-tree.body {
  height: 85%;
}

.vue-multiselect-tree.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.vue-multiselect-tree .list-group-item {
  padding: 7px 7px !important;
}

.vue-multiselect-tree .list-group-item > ul {
  padding-left: 20px;
}

.vue-multiselect-tree > ul.list-group {
  margin-bottom: 50px;
}
.checkbox-div-style {
  margin-left: 12px;
  margin-top: 10px;
  font-size: 13px;
}
.checkbox-style {
  margin-left: 25px;
  margin-top: 10px;
}
.checkbox-label {
  margin-left: 6px;
}
.right-icons-style {
  margin-right: 20px;
}
.column-reorder-modal-body {
  padding-top: 15px;
  height: 350px;
}
.benchmark-column-reorder-element {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
  margin: 0px;
  cursor: move;
}
.attribute-column-reorder-element {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
  margin: 0px;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.sortable-ghost {
  background: #ffffff;
}
::v-deep .ivu-icon .ivu-icon-ios-calendar-outline .ivu-input-icon .ivu-input-icon-normal{
  display: none !important;
}
.alert{
  padding: 7px !important;
}
.duplicate-entity-padding{
  margin-left:25px !important;
  margin-top: 9px;
}
::v-deep .ivu-tooltip-popper[x-placement=bottom]  .ivu-tooltip-arrow{
  display: none !important;
}
.ko-multiselect-tree.body {
    padding: 0px !important;
    height: 100%;
    margin-top: 15px;
}
.header-size{
  font-size:17px;
}
.footer-height{
  margin-top: -4px;
}
.bulk-popup-padding{
  padding-left: 0px;
}
.required-landing-link-style{
  color:#CC3F44;
  margin-left: 23px;
  margin-right: 30px;
  font-size: 12px;
}
.required-cost-message-style{
  color:#CC3F44;
  margin-left: 20px;
  margin-right: 35px;
  font-size: 12px;
}
.landing-link-required-style{
  color:#CC3F44;
  margin-right: 5px;
  margin-left: 30px;
  font-size: 12px;
}
::v-deep .ivu-select-group-title {
    color: black;
    font-weight: bold;
}
.close-summary-button{
  float:right;
  width: 25px;
  z-index: 105;
  background-color: #f5f5f5;
  transition: 0.5s;
  top: 11.5%;
  right: 1%;
  position: absolute;
  border: 1px solid #ddd;
  margin-right: -10px;
}
.close-brief-button{
  float:right;
  width: 25px;
  z-index: 105;
  background-color: #f5f5f5;
  transition: 0.5s;
  top: 27%;
  right: 1%;
  position: absolute;
  border: 1px solid #ddd;
  margin-right: -10px;
}
.open-summary-button{
    width: 25px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-right: 0px solid #ddd;
    margin-left: 6%;
    height: 75px;
    transition: width 1s;
    margin-top:2px;
}
.open-brief-button{
    width: 25px;
    background-color: #f5f5f5;
    margin-left: 6%;
    height: 65px;
    border: 1px solid #ddd;
    border-right: 0px solid #ddd;
    transition: width 1s;
    margin-top: 3%;
}
.summaryTextStyle{
  	transform: rotate(270deg);
    color: black;
    margin-top: 25px;
    cursor: pointer;
}
.close-side-panel {
  width: 0px;
  position: fixed;
  z-index: 105;
  top: 16.5%;
  bottom: 16.5%;
  right: 2%;
  background-color: #f0f0f0;
  transition: width 0.5s;
}
.open-side-panel{
     width: 280px;
    background-color: #f0f0f0;
    height: 415px;
    margin-top: -23%;
    float: right;
    margin-right: -2px;
    transition: width 0.5s;
}
::v-deep .ivu-modal-body {
    padding: 10px;
    font-size: 12px;
    line-height: 1.5;
}
.hot-table-style{
    height: 335px;
}
.summary-open-icon-style{
  padding-left: 10px;
  font-size: 20px;
  cursor: initial;
}
.tab-opened-hot-table-style{
  margin-left: 20px;
  height: 335px;
}
  .grid {
  display: grid;
  height: 100%;
}

.grid::before {
  content: '';
  width: 0;
  padding-bottom: 70%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.grid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}
 .grid > * {
  background: white;
  border: 1px solid #f0f0f0;
}
.highcharts-figure, .highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
	font-family: Verdana, sans-serif;
	border-collapse: collapse;
	border: 1px solid #EBEBEB;
	margin: 10px auto;
	text-align: center;
	width: 100%;
	max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
	font-weight: 600;
    padding: 0.5em;
}
.merge-button{
  margin-top:2px;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

input[type="number"] {
	min-width: 50px;
}

::v-deep .highcharts-background {
  display: none !important;
}
::v-deep .highcharts-container{
      margin-top: -10% !important;
}
::v-deep .highcharts-description{
      margin-top: 0%;
}
::v-deep .highcharts-data-label {
  display: none !important;
}
::v-deep .highcharts-data-label-connector{
  display: none !important;
}
::v-deep .highcharts-credits{
  display: none !important;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px;
  width: 35px;
  min-width: 35px;
}
.material-copy-btn{
  padding-left: 3.8%;
  padding-right: 0px;
  margin-top: 0.3%;
}
tr:nth-child(even) {
  background-color: #f0f0f0;
}

.summary-message-style{
  color: black;
  text-align: center;
}
.summary-cost-allocation-header{
    font-size: 15px;
    margin-left: 5px;
}
.summary-kpi-header{
  font-size: 15px;
  margin-left: -10px;
}
.summary-kpi-table-style{
  width: 100%;
}
.highcharts-div-style{
  height: 48%;
}
.tab-opened-bulk-popup-padding{
  margin-left: -18px;
}
.tab-opened-hot-table-padding{
      padding-right: 0px;
      padding-left: 0px;
}
.panel-top-padding{
  margin-top: 5px;
}
.date-picker-width{
  width:190px;
}
.tab-opened-date-picker-width{
  width: 180px;
}
.bulk-style{
  margin-top: -12px;
  margin-left: 5px;
}
.bulk-header-padding{
  margin-top: -20px;
}
.media-plan-cost-padding{
  margin-left:0px;
  width: 170px;
}
.cost-icon-padding{
  margin-right: -10px !important;
}
.add-rows-padding{
  margin-left:-10px !important;
}
.media-plan-tabs-padding{
  margin-top: 12px;
  height: 420px;
}
::v-deep .wtHolder{
  width: auto !important;
}
::v-deep .ht_master{
  width: 100% !important;
}
.history-panel-style{
  border: 1px solid #f0f0f0;
  background-color: white;
  margin-top: -365px;
  /* margin-top:-113%; */
}
.summary-panel-style{
  border: 1px solid #f0f0f0;
  background-color: white;
  margin-top:-87%
}
.summary-panel-height{
  height: 10%;
}
.summary-kpi-height{
  margin-top: -20%;
}
.summary-budget-alert{
  margin-left: 10px;
}
.summary-kpi-alert{
  margin-left: -5px;
  width: 108%;
}
.brief-campaign-style{
  font-size:13px;
  margin-top: 8px;
  margin-left:26px;
  color: black;
}
.brief-duration-style{
  font-size:13px;
  margin-top: 8px;
  margin-left: 25px;
  color: black;
}
.brief-budget-style{
  font-size:13px;
  margin-top: 8px;
  margin-left: 30px;
  color: black;
}
.brief-data-style{
  font-size:13px;
  margin-top: 8px;
  color: black;
}
.briefTextStyle{
  transform: rotate(270deg);
  margin-top: 25px;
  color: black;
  cursor: pointer;
  margin-top: 12px;
}
.padding-top-style{
  margin-top: -10px;
}
::v-deep textarea.ivu-input{
  max-height: 800px !important;
  font-size: 12px !important;
}
::v-deep .ql-editor>* {
    color: #000 !important;
    font-size: 15px !important;
}
::v-deep .multiselect{
  font-size:12px;
  min-height: 32px;
}
::v-deep .multiselect--active{
  font-size:12px
}
::v-deep .multiselect__single{
  font-size:12px;
  margin-bottom:0px;
}
::v-deep .multiselect__input{
  font-size: 12px;
}
::v-deep .media-plan-label-not-focus > .multiselect__tags{
    height: 32px;
}
::v-deep .multiselect__tags{
    padding: 2px 20px 2px 5px;
    border-radius: 0px;
    min-height: 32px;
    overflow: hidden;
}
::v-deep .multiselect__select{
    width: 30px;
    background: none !important;
    top: -2px;
    height:32px;
}
::v-deep .multiselect__tag{
  font-size:12px;
  margin-bottom:0px;
  margin-right: 5px;
  border-radius: 0px;
  color: #7d7f7f !important;
  background: #f5f5f5 !important;
}
::v-deep .multiselect__placeholder{
  margin-bottom:0px;
  padding-top:0px;
  font-size:12px;
}
::v-deep .multiselect__tag-icon{
  margin-left: 0px;
  width: 20px;
  border-radius: 0px;
  background: #eee;
}
::v-deep .multiselect__option--highlight{
  background: #fff;
  color: #000;
}
::v-deep .multiselect__option{
  padding: 5px;
  min-height: 15px;
  line-height: 15px;
}
::v-deep .multiselect__tag-icon:focus, .multiselect__tag-icon:hover{
  background: #fff
}
::v-deep .multiselect {
  min-width: 32px;
}
::v-deep .ivu-tabs-nav-container{
  margin-top: 10px;
}
::v-deep .multiselect__option .multiselect__option--selected{
  background: #eeeeee;
}
::v-deep .multiselect__option--selected{
  background: #eeeeee;
}
::v-deep .multiselect__tags-wrap{
    line-height: 32px;
    height: 32px !important;
    max-height: 32px !important;
    min-height: 32px !important;
}
::v-deep .multiselect__option--selected{
  background: #ccc;
  font-weight: 700;
}
::v-deep .multiselect__option--highlight{
  background: #eeeeee;
}
::v-deep .multiselect__option{
  background: #fff;
  padding-left: 16px;
}
::v-deep .multiselect__option--highlight.multiselect__option{
  background: #eeeeee;
  font-weight: 700;
}
::v-deep .multiselect__option.multiselect__option--selected.multiselect__option--highlight{
  color: #000;
  background: #eeeeee;
}
::v-deep .multiselect__option.multiselect__option--selected{
  color: #000;
  background: #f5f5f5!important;
}
.label-multiselect, .division-div{
  margin-left:10px;
}
.label-tag-icon{
  margin-left: -5px;
  margin-right: 5px;
}
.media-plan-objective{
  margin-left: 5px !important;
}
::v-deep .ivu-tabs.ivu-tabs-card.ivu-tabs-no-animation.tabs-padding{
  z-index: 0 !important;
}
.ivu-tabs.ivu-tabs-card.ivu-tabs-no-animation.tabs-padding{
  z-index: 0 !important;
}
::v-deep .ivu-icon.ivu-icon-ios-calendar-outline.ivu-input-icon.ivu-input-icon-normal{
  display: none;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
}
::v-deep .ivu-date-picker-editor .ivu-input.ivu-input-default{
  padding-right: 5px;
}
::v-deep .comment-popup-btn{
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 7px;
}
.non-editable{
  pointer-events: none;
}
.media-plan-objective .icon-class{
  margin-right: -13px;
}
::v-deep .multiselect--disabled .multiselect__single{
  background: #f3f3f3;
}
::v-deep .multiselect--disabled .multiselect__tags{
  background: #f3f3f3;
}
::v-deep .brief-note.non-editable .quillWrapper{
  background: #f3f3f3;
}
::v-deep .multiselect__option.multiselect__option--group{
  padding-left: 8px;
}
.brief-dropdown{
width: 170px;
padding-top: 6px;
}
.close-approachNotes-button{
  float:right;
  width: 25px;
  z-index: 105;
  background-color: #f5f5f5;
  transition: 0.5s;
  top: 40%;
  right: 1%;
  position: absolute;
  border: 1px solid #ddd;
  margin-right: -10px;
  height: 120px;
}
.open-approachNotes-button{
    width: 25px;
    background-color: #f5f5f5;
    margin-left: 6%;
    height: 120px;
    border: 1px solid #ddd;
    border-right: 0px solid #ddd;
    transition: width 1s;
    margin-top: 3%;
}
.open-history-button{
    width: 25px;
    background-color: #f5f5f5;
    margin-left: 6%;
    height: 76px;
    border: 1px solid #ddd;
    border-right: 0px solid #ddd;
    transition: width 1s;
    margin-top: 3%;
}
.close-history-button{
  float:right;
  width: 25px;
  z-index: 110;
  background-color: #f5f5f5;
  transition: 0.5s;
  top: 63%;
  right: 1%;
  position: absolute;
  border: 1px solid #ddd;
  margin-right: -10px;
  height: 75px;
}
.attachments-text-style{
  font-size: 15px;
  margin-top: 7px;
  margin-left: 5px;
  color: black;
}
::v-deep .approach-notes.non-editable .quillWrapper{
  background: #f3f3f3;
}
.approach-text-style{
  margin-top: 65px !important;
}
.note-text-style{
  margin-top: -85px !important;
}
.campaign-target-popup{
  margin-top:2px;
}
.cancel-button-style{
  float: right;
  margin-top: -7px;
  margin-right: -8px;
}
.budget-with-setup {
  margin-left: -135px;
}
.budget-without-setup {
  margin-left: -30px;
}
.mediaplan-campaign-target-button{
  padding-left: 10px;
}
.buttons-with-setup {
 margin-left: 15px;
}
.buttons-without-setup {
  margin-left: -15px;
}
.field-empty-error {
  border: red solid 1px !important;
}
::v-deep .multiselect__option--group{
  color: #515a6e !important;
  font-weight: bold;
}
</style>
<style>
.mediaplan-main-popup .ivu-modal-content {
  height: 570px !important;
}
.mediaplan-main-popup .ivu-modal-header {
  padding: 26px 16px !important;
  border-bottom: none !important;
}
.mediaplan-main-popup .ivu-modal-footer {
  padding: 0px !important;
}
</style>
<style src="../../../Styles/dx-iview.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
