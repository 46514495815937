import axiosInstance from "../../../Api";

export class ApiService {
  getMediaPlanById (id) {
    const url = deltaXApiUrl.mediaPlans.getChildEntities.replace("{businessProfileId}", deltax.businessProfileId)
    const promise = axiosInstance({
      url: url,
      method: 'GET',
      params: { mediaplanId: id }
    });
    return promise;
  }

  getInputGroupById (id) {
    const url = deltaXApiUrl.InputGroups.getById.replace("{businessProfileId}", deltax.businessProfileId)
      .replace("{inputGroupId}", id);

    const promise = axiosInstance({
      url: url,
      method: 'GET'
    });

    return promise;
  }
  getLanguagesByBpId () {
    const url = deltaXApiUrl.Languages.getByBpId.replace("{businessProfileId}", deltax.businessProfileId);

    const promise = axiosInstance({
      url: url,
      method: 'GET'
    });
    return promise;
  }
  getBPUsers (params) {
    const url = deltaXApiUrl.users.getUsersByBpId.replace("{businessProfileId}", deltax.businessProfileId);

    const promise = axiosInstance({
      url: url,
      method: 'GET',
      params
    });

    return promise;
  }
  getBPById () {
    const url = deltaXApiUrl.businessProfiles.getById.replace("{businessProfileId}", deltax.businessProfileId);

    const promise = axiosInstance({
      url: url,
      method: 'GET'
    });
    return promise;
  }
  create (data) {
    const url = deltaXApiUrl.InputGroups.create.replace("{businessProfileId}", deltax.businessProfileId);

    const promise = axiosInstance({
      url: url,
      method: 'POST',
      data
    });

    return promise;
  }

  edit (id, data) {
    const url = deltaXApiUrl.InputGroups.edit.replace("{businessProfileId}", deltax.businessProfileId)
      .replace("{inputGroupId}", id);

    const promise = axiosInstance({
      url: url,
      method: 'PUT',
      data
    });

    return promise;
  }
  getAllSuppliers (data) {
    const url = deltaXApiUrl.Suppliers.get.replace("{portalId}", deltax.BillingPortalId)
    const promise = axiosInstance({
      url: url,
      method: 'GET',
      data
    })

    return promise;
  }
}
